import { Rate } from 'services/booking/bookingTypes';

export type Selectability = 'INCLUDED' | 'MANDATORY' | 'OPTIONAL' | 'NOT_NORMALLY_OFFERED';

export enum ProtectionsFormFields {
  Items = 'items',
}

export enum ProtectionsItemFields {
  Checked = 'checked',
  Item = 'item',
}

export type BaseProtection = {
  code: string;
  category: string | undefined;
  type: string;
  selectability: Selectability | undefined;
  rate: Rate | undefined;
  addOnType: string;
};

export type ProtectionItemModel = {
  [ProtectionsItemFields.Checked]: boolean;
  [ProtectionsItemFields.Item]: BaseProtection;
};

export type ProtectionFormModel = {
  [ProtectionsFormFields.Items]: ProtectionItemModel[];
};

export enum EquipmentFormFields {
  Items = 'items',
}

export enum EquipmentItemFields {
  Checked = 'checked',
  Quantity = 'quantity',
  Item = 'item',
}

export interface BaseEquipment {
  quantity: number;
  type: string;
  addOnType: string;
  code?: string;
  rate?: Rate;
  selectability?: string;
}

export type EquipmentItemModel = {
  [EquipmentItemFields.Checked]: boolean;
  [EquipmentItemFields.Quantity]: number;
  [EquipmentItemFields.Item]: BaseEquipment;
};

export type EquipmentFormModel = {
  [EquipmentFormFields.Items]: EquipmentItemModel[];
};

export interface BaseAncillaryType {
  type: string;
  addOnType: string;
  code?: string;
  rate?: Rate;
}
