export function isDevBuild(): boolean {
  return import.meta.env.DEV;
}
export const isAndroidTablet = (): boolean => navigator.userAgent.includes('Android');

export const isRunningInFrame = (): boolean => {
  return window.self !== window.top;
};

export const isCypressRun = (): boolean => !!(window as any).Cypress;

export const isLocalhost = () => {
  return window.location.href.includes('localhost');
};

export function isProductionWebEnvironment(webEnvironment: string): boolean {
  return webEnvironment.toLowerCase() === 'prod';
}

export function isSandboxEnvironment(webEnvironment: string | undefined): boolean {
  const DEV_ENVIRONMENTS = ['DEV', 'DEV1', 'DEV2', 'DEV3', 'DEV4'];
  return webEnvironment ? DEV_ENVIRONMENTS.includes(webEnvironment.toUpperCase()) : false;
}
