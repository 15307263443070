import { styled } from '@mui/material';
import { Box } from '@mui/system';
import { ehiTheme } from '@ehi/ui';

export const StyledWarningBanner = styled(Box)`
  padding: ${ehiTheme.spacing(4, 3, 0, 3)};
`;
export const StyledRenterWarningWrapper = styled('div')`
  margin: ${ehiTheme.spacing(2, 0)};
`;
