import { logError } from 'components/shared/logger/splunkLogger';
import { ErrorSeverity } from '@ehi/analytics';

export const WORKFLOW_ID_KEY = 'WORKFLOW_ID_KEY';

export const saveItem = (key: string, item: unknown): void => {
  try {
    const serializedState = JSON.stringify(item);
    localStorage.setItem(key, serializedState);
  } catch (error) {
    logError({
      error: { message: 'Unable to save data for ' + key, supportInformation: error },
      severity: ErrorSeverity.Error,
    });
    throw error;
  }
};

export const removeItem = (key: string): void => {
  localStorage.removeItem(key);
};

export const loadItem = (key: string): any => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState) {
      return JSON.parse(serializedState);
    }
    return undefined;
  } catch (err) {
    logError({
      error: { message: 'Unable to load state for ' + key, supportInformation: err },
      severity: ErrorSeverity.Error,
    });
    throw err;
  }
};
