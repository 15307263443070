import { FC } from 'react';

export const BrandPlaceholderIcon: FC = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-testid={'brandPlaceholderLogo'}>
      <rect x='0.5' y='0.5' width='39' height='39' rx='19.5' fill='white' />
      <rect x='0.5' y='0.5' width='39' height='39' rx='19.5' fill='#4D789D' fillOpacity='0.08' />
      <rect x='0.5' y='0.5' width='39' height='39' rx='19.5' stroke='#4D789D' />
      <path
        d='M25.7667 14.1737C25.6 13.682 25.1333 13.332 24.5833 13.332H15.4167C14.8667 13.332 14.4083 13.682 14.2333 14.1737L12.5 19.1654V25.832C12.5 26.2904 12.875 26.6654 13.3333 26.6654H14.1667C14.625 26.6654 15 26.2904 15 25.832V24.9987H25V25.832C25 26.2904 25.375 26.6654 25.8333 26.6654H26.6667C27.125 26.6654 27.5 26.2904 27.5 25.832V19.1654L25.7667 14.1737ZM15.7083 14.9987H24.2833L25.1833 17.5904H14.8083L15.7083 14.9987ZM25.8333 23.332H14.1667V19.1654H25.8333V23.332Z'
        fill='#4D789D'
      />
      <path
        d='M16.25 22.4987C16.9404 22.4987 17.5 21.9391 17.5 21.2487C17.5 20.5583 16.9404 19.9987 16.25 19.9987C15.5596 19.9987 15 20.5583 15 21.2487C15 21.9391 15.5596 22.4987 16.25 22.4987Z'
        fill='#4D789D'
      />
      <path
        d='M23.75 22.4987C24.4404 22.4987 25 21.9391 25 21.2487C25 20.5583 24.4404 19.9987 23.75 19.9987C23.0596 19.9987 22.5 20.5583 22.5 21.2487C22.5 21.9391 23.0596 22.4987 23.75 22.4987Z'
        fill='#4D789D'
      />
    </svg>
  );
};
