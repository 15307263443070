import { FC } from 'react';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { StyledDivider } from './QuickRes.styles';
import RentalPickup from 'components/quickRes/RentalPickup';
import RentalReturn from 'components/quickRes/RentalReturn';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { updatePickupInformation, updateReturnInformation } from 'services/booking/bookingService';
import { useAppSelector } from 'redux/hooks';
import { useFormContext } from 'react-hook-form';
import { QuickResFields } from './QuickResTypes';
import { combineDateAndTime } from 'utils/dateUtils';
import { DateTime } from 'luxon';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { useAlert } from 'components/shared/alert/AlertContext';
import { safelyCatchError } from 'utils/errorUtils';

const QuickResDateTime: FC = () => {
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const {
    formState: { errors },
    getValues,
    setError,
    setValue,
    trigger,
  } = useFormContext();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { showAlert } = useAlert();
  const [pickupDate, startTime, returnDate, returnTime, returnDateTime, currentLocationTimezone, currentLocationUrn] = [
    getValues(QuickResFields.StartDate),
    getValues(QuickResFields.StartTime),
    getValues(QuickResFields.ReturnDate),
    getValues(QuickResFields.ReturnTime),
    getValues(QuickResFields.ReturnDateTime),
    getValues(QuickResFields.CurrentLocationTimezone),
    getValues(QuickResFields.CurrentLocationUrn),
  ];

  const handlePickupDateChange = async (date: DateTime | '') => {
    setValue(QuickResFields.StartDate, date);

    if ((date && startTime) || (!date && !startTime)) {
      await updatePickupDateTime(date, startTime);
    }
  };

  const handlePickupTimeChange = async (time: DateTime | '') => {
    setValue(QuickResFields.StartTime, time);

    if ((pickupDate && time) || (!pickupDate && !time)) {
      await updatePickupDateTime(pickupDate, time);
    }
  };

  const updatePickupDateTime = async (date: DateTime | '', time: DateTime | '') => {
    const combinedDateTime = date && time ? combineDateAndTime(date, time, currentLocationTimezone) : '';
    // Note: Set to default one day rental if no return date entered
    if (combinedDateTime && combinedDateTime.toISO() && !Object.keys(errors).length && !returnDateTime) {
      setValue(QuickResFields.StartDateTime, combinedDateTime.toISO());
      try {
        const { errors: pickupErrors } = await updateAndRefresh(() =>
          updatePickupInformation(bookingEditorId, {
            branch: currentLocationUrn,
            dateTime: combinedDateTime.toISO() || '',
          })
        );
        if (pickupErrors) {
          setError(QuickResFields.StartDate, { message: pickupErrors?.[0]?.localizedMessage });
        } else {
          const combinedToISO = combinedDateTime.toISO();
          const defaultReturnDateTime =
            combinedToISO && DateTime.fromISO(combinedToISO).plus({ day: 1 }).setZone(currentLocationTimezone).toISO();
          try {
            const { errors: returnErrors } = await updateAndRefresh(() =>
              updateReturnInformation(bookingEditorId, {
                branch: currentLocationUrn,
                dateTime: defaultReturnDateTime || '',
              })
            );
            if (returnErrors?.length) {
              await showAlert({ responseMessages: returnErrors });
            }
          } catch (error) {
            const ehiErrorsResponse = safelyCatchError(error);
            const errors = ehiErrorsResponse.errors;
            await showAlert({ responseMessages: errors });
          }
        }
      } catch (error) {
        setError(QuickResFields.StartDate, { message: (error as EhiErrors)?.errors?.[0]?.localizedMessage });
      }
    } else if (combinedDateTime && combinedDateTime.toISO() && !Object.keys(errors).length) {
      setValue(QuickResFields.StartDateTime, combinedDateTime.toISO());
      try {
        const { errors } = await updateAndRefresh(() =>
          updatePickupInformation(bookingEditorId, {
            branch: currentLocationUrn,
            dateTime: combinedDateTime.toISO() || '',
          })
        );
        if (errors) {
          setError(QuickResFields.StartDate, { message: errors?.[0]?.localizedMessage });
        }
      } catch (error) {
        setError(QuickResFields.StartDate, { message: (error as EhiErrors)?.errors?.[0]?.localizedMessage });
      }
    } else if (!date && !time && !Object.keys(errors).length) {
      setValue(QuickResFields.StartDateTime, '');
      try {
        const { errors } = await updateAndRefresh(() =>
          updatePickupInformation(bookingEditorId, {
            branch: currentLocationUrn,
          })
        );
        if (errors) {
          setError(QuickResFields.StartDate, { message: errors?.[0]?.localizedMessage });
        }
      } catch (error) {
        setError(QuickResFields.StartDate, { message: (error as EhiErrors)?.errors?.[0]?.localizedMessage });
      }
    }
  };

  const handleReturnDateChange = async (date: DateTime | '') => {
    setValue(QuickResFields.ReturnDate, date);
    await trigger([QuickResFields.StartDate, QuickResFields.StartTime, QuickResFields.ReturnTime]);

    if ((date && returnTime) || (!date && !returnTime)) {
      await updateReturnDateTime(date, returnTime);
    }
  };

  const handleReturnTimeChange = async (time: DateTime | '') => {
    setValue(QuickResFields.ReturnTime, time);
    await trigger([QuickResFields.StartDate, QuickResFields.StartTime, QuickResFields.ReturnDate]);
    if ((returnDate && time) || (!returnDate && !time)) {
      await updateReturnDateTime(returnDate, time);
    }
  };

  const updateReturnDateTime = async (date: DateTime | '', time: DateTime | '') => {
    const combinedDateTime = date && time ? combineDateAndTime(date, time, currentLocationTimezone) : '';
    if (combinedDateTime && combinedDateTime.toISO() && !Object.keys(errors).length) {
      setValue(QuickResFields.ReturnDateTime, combinedDateTime.toISO());
      try {
        const { errors } = await updateAndRefresh(() =>
          updateReturnInformation(bookingEditorId, {
            branch: currentLocationUrn,
            dateTime: combinedDateTime.toISO() || '',
          })
        );
        if (errors) {
          setError(QuickResFields.ReturnDate, { message: errors?.[0]?.localizedMessage });
        }
      } catch (error) {
        setError(QuickResFields.ReturnDate, { message: (error as EhiErrors)?.errors?.[0]?.localizedMessage });
      }
    } else if (!date && !time && !Object.keys(errors).length) {
      setValue(QuickResFields.ReturnDateTime, '');
      try {
        const { errors } = await updateAndRefresh(() =>
          updateReturnInformation(bookingEditorId, {
            branch: currentLocationUrn,
          })
        );
        if (errors) {
          setError(QuickResFields.ReturnDate, { message: errors?.[0]?.localizedMessage });
        }
      } catch (error) {
        setError(QuickResFields.ReturnDate, { message: (error as EhiErrors)?.errors?.[0]?.localizedMessage });
      }
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={6}>
        <RentalPickup
          handleDateChange={handlePickupDateChange}
          handleTimeChange={handlePickupTimeChange}
          timezone={currentLocationTimezone}
        />
      </GridItem>
      <GridItem xs={12} sm={6}>
        <RentalReturn
          handleReturnDateChange={handleReturnDateChange}
          handleReturnTimeChange={handleReturnTimeChange}
          pickupDate={pickupDate}
          timezone={currentLocationTimezone}
        />
      </GridItem>
      <GridItem xs={12} sm={12}>
        <StyledDivider />
      </GridItem>
    </GridContainer>
  );
};

export default QuickResDateTime;
