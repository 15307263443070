import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { LogoutParams } from '@ehi/auth/lib/types/shared';
import { CALLING_APP_ID } from 'utils/constants';

export function getLogoutParams(): LogoutParams {
  const appConfig = getAppConfigCache();
  return {
    callingAppId: CALLING_APP_ID,
    authWebUrl: new URL(appConfig?.externalAppEndpoints.authWeb ?? ''),
    port: 3000,
    redirectUrl: appConfig?.webEnvironment === 'LOCALHOST' ? undefined : new URL(window.location.origin),
  };
}
