import { StyledContainer, StyledSetCounter } from './SetCounter.styles';

import { EhiCounter, loadCounterCookie, loadEhiLocationCookie, saveCounter } from '@ehi/location';
import { useLocationQuery } from 'services/location/locationQueries';
import { SetCounterForm } from './SetCounterForm';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from 'app/router/RouterPaths';

export const SetCounterPage = () => {
  const navigate = useNavigate();
  const cookie = loadEhiLocationCookie();
  const counterCookie = loadCounterCookie();
  const { data: locationQuery } = useLocationQuery(cookie?.peoplesoftId ?? '');
  const defaultCounter = counterCookie ?? locationQuery?.defaultCounter;
  const counters = locationQuery?.location?.counters ?? [];
  const onSubmit = (counter: EhiCounter) => {
    saveCounter(counter?.counterId ?? '', counter?.description ?? '');
    navigate(RouterPaths.Search);
  };
  const onCancel = () => {
    navigate(RouterPaths.SetLocation);
  };

  return (
    <StyledContainer>
      <StyledSetCounter>
        <SetCounterForm defaultCounter={defaultCounter} counters={counters} onSubmit={onSubmit} onCancel={onCancel} />
      </StyledSetCounter>
    </StyledContainer>
  );
};
