import { Box, styled } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import { secondaryText } from 'components/shared/ui/styles/Global.styles';

export const NoResultsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${ehiTheme.spacing(4, 0)};
  &.isBackgroundColor {
    background-color: ${ehiTheme.palette.background.default};
  }
`;

export const StyledLink = styled('span')`
  color: ${ehiTheme.palette.primary.main};
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledDescription = styled('div')`
  font-size: ${ehiTheme.typography.body1.fontSize};
  font-weight: normal;
  line-height: ${ehiTheme.spacing(1.5)};
  color: ${secondaryText};
  padding: ${ehiTheme.spacing(1, 2, 4, 0)};
`;
