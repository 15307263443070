import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { TRANSACTION_CACHE_OPTIONS } from 'context/queryClient/cacheOptions';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { retrieveAccount } from './businessAccountService';
import { BusinessAccount } from 'services/businessAccount/businessAccountTypes';

const BUSINESS_ACCOUNT_QUERY_KEY = 'businessAccount';

export const useBusinessAccountQuery = (
  accountNumber: string,
  queryOptions: Partial<UseQueryOptions<BusinessAccount, EhiErrors>> = {}
): UseQueryResult<BusinessAccount, EhiErrors> => {
  const queryKey = [BUSINESS_ACCOUNT_QUERY_KEY, accountNumber];
  const queryFn = () =>
    accountNumber ? retrieveAccount(accountNumber) : Promise.reject(new Error('Account number is undefined'));
  const options = {
    ...TRANSACTION_CACHE_OPTIONS,
    enabled: !!accountNumber,
    ...queryOptions,
  };

  return useQuery<BusinessAccount, EhiErrors>(queryKey, queryFn, options);
};
