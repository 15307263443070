import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { logError } from 'components/shared/logger/splunkLogger';
import { ErrorSeverity } from '@ehi/analytics';
import { defaultLaunchDarklyFeatureFlags } from 'utils/featureFlagUtils';
import { defaultOverriddenFeatureFlags, FeatureFlags, OverriddenFeatureFlags } from './FeatureFlagsSliceTypes';

const FLAGS_NAME = 'featureFlags';

export const getFeatureFlags = ({ featureFlags }: RootState): FeatureFlags => {
  return featureFlags;
};

export const getInitialState = (): FeatureFlags => {
  const win = window as any;
  if (win.Cypress && win.initialState?.featureFlags) {
    return {
      ldFlags: win.initialState?.featureFlags?.ldFlags,
      overriddenFlags: win.initialState?.featureFlags?.overriddenFlags,
    };
  }
  const savedState = window.localStorage.getItem(FLAGS_NAME);
  try {
    if (savedState) {
      const savedFlags = JSON.parse(savedState);
      return {
        ldFlags: defaultLaunchDarklyFeatureFlags(),
        overriddenFlags: savedFlags,
      };
    }
  } catch (e) {
    logError({
      error: { message: 'Unable to load state for ' + FLAGS_NAME, supportInformation: e },
      severity: ErrorSeverity.Error,
    });
  }
  return {
    ldFlags: defaultLaunchDarklyFeatureFlags(),
    overriddenFlags: defaultOverriddenFeatureFlags,
  };
};

export const featureFlagsSlice = createSlice({
  name: FLAGS_NAME,
  initialState: getInitialState(),
  reducers: {
    initLDFeatureFlags: (state, { payload }: PayloadAction<FeatureFlags>) => {
      const { overriddenFlags, ldFlags } = payload;
      state.ldFlags = ldFlags;
      state.overriddenFlags = overriddenFlags;
    },
    updateFeatureFlags: (state, { payload }: PayloadAction<FeatureFlags>) => {
      const { overriddenFlags, ldFlags } = payload;
      state.ldFlags = ldFlags;
      state.overriddenFlags = overriddenFlags;
      window.localStorage.setItem(FLAGS_NAME, JSON.stringify(overriddenFlags));
    },
  },
});

export const { initLDFeatureFlags, updateFeatureFlags } = featureFlagsSlice.actions;

export const selectOverriddenFeatureFlags = ({ featureFlags }: RootState): OverriddenFeatureFlags => {
  return featureFlags.overriddenFlags;
};

export default featureFlagsSlice.reducer;
