export enum CreateDriverFields {
  LastName = 'lastName',
  FirstName = 'firstName',
  Age = 'age',

  // Driver Section
  DriverCountry = 'driverCountry',
  StreetAddress1 = 'streetAddress1',
  StreetAddress2 = 'streetAddress2',
  City = 'city',
  SubRegion = 'subRegion',
  PostalCode = 'postalCode',

  // License Section
  LicenseCountry = 'licenseCountry',
  LicenseNumber = 'licenseNumber',
  LicenseIssuerRegion = 'licenseIssuerRegion',
  LicenseIssuerAuth = 'licenseIssuerAuth',
  LicenseExpirationDate = 'licenseExpirationDate',
  IdentificationNumber = 'identificationNumber',
  LicenseDVLA = 'licenseDVLA',
  LicenseIssuedDate = 'licenseIssuedDate',
  DOB = 'dateOfBirth',
  PlaceOfBirth = 'placeOfBirth',
  IdIssuingCountry = 'idIssuingCountry',
  IdExpirationDate = 'idExpirationDate',
  IdType = 'idType',
  IdPassportNumber = 'idPassportNumber',

  // Contact Section
  Phone = 'phone',
  Email = 'email',
  ContactType = 'contactType',
}

export enum PhoneFieldNames {
  Type = 'type',
  CountryCode = 'countryCode',
  Number = 'number',
  Priority = 'priority',
}

export interface PhoneFormModel {
  countryCode?: string;
  number?: string;
  type?: string;
  priority?: number;
}

export interface CreateDriverValues {
  [CreateDriverFields.LastName]: string;
  [CreateDriverFields.FirstName]: string;
  [CreateDriverFields.Age]: string;
  [CreateDriverFields.DriverCountry]: string;
  [CreateDriverFields.StreetAddress1]: string;
  [CreateDriverFields.StreetAddress2]: string;
  [CreateDriverFields.City]: string;
  [CreateDriverFields.SubRegion]: string;
  [CreateDriverFields.PostalCode]: string;
  [CreateDriverFields.LicenseCountry]: string;
  [CreateDriverFields.LicenseNumber]: string;
  [CreateDriverFields.LicenseIssuerRegion]?: string;
  [CreateDriverFields.LicenseIssuerAuth]?: string;
  [CreateDriverFields.LicenseExpirationDate]?: string;
  [CreateDriverFields.IdentificationNumber]?: string;
  [CreateDriverFields.LicenseDVLA]?: string;
  [CreateDriverFields.LicenseIssuedDate]?: string;
  [CreateDriverFields.DOB]?: string;
  [CreateDriverFields.PlaceOfBirth]?: string;
  [CreateDriverFields.IdIssuingCountry]: string;
  [CreateDriverFields.IdExpirationDate]?: string;
  [CreateDriverFields.IdType]: string;
  [CreateDriverFields.IdPassportNumber]?: string;
  [CreateDriverFields.ContactType]?: string;
  [CreateDriverFields.Phone]: PhoneFormModel[];
  [CreateDriverFields.Email]: string;
}
