import { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DateTime } from 'luxon';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import Error from '@mui/icons-material/Error';
import { BaseDateValidationProps } from '@mui/x-date-pickers/internals';
import { FormTextFieldProps } from 'components/shared/forms/FormFieldTypes';
import { useTranslations } from 'components/shared/i18n';

export const DatePickerField: FunctionComponent<
  Omit<FormTextFieldProps, 'onChange' | 'onError' | 'rules'> &
    BaseDateValidationProps<DateTime> & { submitOnChange?: (value: DateTime | '') => void }
> = ({ submitOnChange, disablePast, disableFuture, minDate, disabled, ...rest }) => {
  const { t } = useTranslations();
  const form = useFormContext();

  const getHelperText = (errorMessage: string) => {
    return (
      <span style={{ display: 'flex' }}>
        <Error fontSize='small' sx={{ paddingRight: '6px', display: 'flex' }} />
        {errorMessage}
      </span>
    );
  };

  return (
    <Controller
      control={form.control}
      {...rest}
      render={({ field, fieldState }) => {
        return (
          <DesktopDatePicker
            {...field}
            format={t('format.date')}
            data-testid={'dateTextFieldId'}
            value={field.value ? field.value : null}
            minDate={minDate}
            disableFuture={disableFuture}
            disablePast={disablePast}
            disabled={disabled}
            onAccept={async (value) => {
              form.setValue(field.name, value, { shouldDirty: true });
              if (await form.trigger(field.name)) {
                submitOnChange?.(value as DateTime);
              }
            }}
            onChange={(date: DateTime | null) => {
              form.setValue(rest.name, date || null, {
                shouldDirty: true,
              });
            }}
            slots={{
              textField: TextField,
            }}
            slotProps={{
              openPickerIcon: {
                color: fieldState.error?.message ? 'error' : 'inherit',
              },
              inputAdornment: {
                sx: { svg: { paddingRight: '12px' } },
              },
              textField: {
                name: field.name,
                label: rest.label,
                required: rest.required,
                error: !!fieldState.error,
                helperText: !!fieldState.error?.message && getHelperText(fieldState.error?.message),
                onBlur: async () => {
                  if (await form.trigger(field.name)) {
                    submitOnChange?.(form.getValues(field.name));
                  }
                },
                onChange: async (e: any) => {
                  form.setValue(rest.name, (e as DateTime) || null, {
                    shouldDirty: true,
                  });
                  if (e && !e.invalid && e.c.year.toString().length === 4) {
                    await form.trigger(field.name);
                  }
                },
              },
            }}
          />
        );
      }}
    />
  );
};
