import { useCallback } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { useSaveActionContext } from 'context/saveAction/SaveActionContext';
import { useResActionsContext } from 'context/resActions/ResActionsContext';

// Overriding router NavigateFunction here as we do not need delta (back navigation)
export interface NavigateFunction {
  (
    to: To | -1,
    options?: {
      replace?: boolean;
      state?: any;
    }
  ): void;
}

export interface SaveAndNavigationHelper {
  saveOnNavigate: NavigateFunction;
}

/**
 * Since this hook is "at the component level", it can pass along react-router's useNavigate.
 * useNavigate uses the component's hierarchical location within routers to determine relative routing
 * */
export const useSaveOnNavigateHelper = (): SaveAndNavigationHelper => {
  const navigate = useNavigate();
  const { saveOnNavigate: resSaveAndNav } = useSaveActionContext();
  const { setUpdateFloatingButtonAction } = useResActionsContext();

  const saveOnNavigate = useCallback(
    (
      to: To | -1,
      options?: {
        replace?: boolean;
        state?: any;
      }
    ) => {
      resSaveAndNav(navigate, to as any, options);
      setUpdateFloatingButtonAction(false);
    },
    [navigate, resSaveAndNav, setUpdateFloatingButtonAction]
  );

  return { saveOnNavigate };
};
