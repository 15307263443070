import { useCallback } from 'react';
import { updatePayersPerson } from 'services/booking/bookingService';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { PaymentFields, PaymentValues } from 'components/flexFlow/payment/PaymentTypes';
import { EHI_DOMAINS, generateReferenceUrn, parseUrn } from 'utils/urnUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';

export type SavePayment = {
  save: (data: PaymentValues) => Promise<ServiceResultType>;
};

export const useSavePayment = (): SavePayment => {
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const { showAlert } = useAlert();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();

  const save = useCallback(
    async (values: PaymentValues) => {
      const paymentValue = values[PaymentFields.PaymentType];
      const appConfig = getAppConfigCache();
      const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? '';
      const payload = {
        paymentMethod: generateReferenceUrn(
          EHI_DOMAINS.rental.name,
          EHI_DOMAINS.rental.booking + ':' + EHI_DOMAINS.rental.person + ':' + EHI_DOMAINS.rental.paymentMethod,
          parseUrn(paymentValue.key),
          defaultEhiDatabase
        ),
        cardProvided: false,
      };

      const { errors } = await updateAndRefresh(async () => {
        await updatePayersPerson(bookingEditorId, payload);
      });

      if (errors) {
        await showAlert({ responseMessages: errors });
      }
      return { success: !errors, errors: errors };
    },
    [bookingEditorId, showAlert, updateAndRefresh]
  );

  return { save };
};
