import { Box, Paper, styled } from '@mui/material';

export const ResSummaryDrawerHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 20px;
`;

export const SummaryContent = styled(Paper)`
  flex-grow: 0;
`;

export const DrawerContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 360px;
  // Note: 140px denotes the height of the customer header
  height: calc(100vh - 140px);
  overflow-y: auto;
`;
