import { Context, FC, forwardRef, RefObject, useCallback, useMemo, useState } from 'react';
import { ListProps, Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import BranchCard from 'components/flexFlow/whenAndWhere/branchLookup/BranchCard';
import {
  StyledBranchListBox,
  StyledHeaderText,
  StyledMap,
  StyledVirtuosoWrapper,
  VirtuosoContentContainer,
} from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookup.styles';
import { useTranslations } from 'components/shared/i18n';
import { ehiTheme } from '@ehi/ui';
import { BranchListItem } from 'components/flexFlow/whenAndWhere/branchLookup/BranchCard.styles';
import { useFormContext } from 'react-hook-form';
import { BranchLookupFields } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupTypes';
import { BranchLookupCard } from 'components/shared/uiModels/branchLookup/branchLookupDataTypes';
import { useMediaQuery } from '@mui/material';
import { BottomDrawer } from './BottomDrawer';
import { BranchLookupMap } from './BranchLookupMap';

// Denotes the height of dialog footer and header along with the height of the branch search section
export const BRANCH_LIST_OFFSET = 326.5;
// Denotes the percent offset of the dialog height. Currently, the dialog is set at 94%
export const DIALOG_HEIGHT_PERCENT_OFFSET = 0.06;

type BranchCardListProps = {
  branches: BranchLookupCard[];
  branchListRef: RefObject<VirtuosoHandle> | undefined;
  searchInputText: string;
  isMapView: boolean;
};

const BranchCardList: FC<BranchCardListProps> = ({ branches, branchListRef, isMapView, searchInputText }) => {
  const [bottomDrawerOpen, setBottomDrawerOpen] = useState(false);
  const isMobileOrTablet = useMediaQuery('(max-width:800px)');
  const disableBottomDrawer = useMediaQuery(ehiTheme.breakpoints.up('md'));
  const { t } = useTranslations();
  const { setValue, watch } = useFormContext();
  const selectedBranch = watch(BranchLookupFields.SelectedBranch);

  const scrollAndSelectBranch = useCallback(
    (stationId?: string) => {
      const selectedIndex =
        stationId &&
        branches.findIndex((item) => {
          return item.stationId === stationId;
        });

      if (typeof selectedIndex === 'number') {
        branchListRef?.current && branchListRef.current.scrollToIndex({ index: selectedIndex, behavior: 'smooth' });
        branches[selectedIndex].isDisabled
          ? setValue(BranchLookupFields.SelectedBranch, undefined)
          : setValue(BranchLookupFields.SelectedBranch, branches[selectedIndex]);
      }
    },
    [branches, branchListRef, setValue]
  );

  const selectedCard = useMemo(() => {
    return (
      selectedBranch &&
      branches.findIndex((item) => {
        return item.stationId === selectedBranch.stationId;
      })
    );
  }, [selectedBranch, branches]);

  function BranchListHeader() {
    return (
      <VirtuosoContentContainer>
        <StyledHeaderText
          isMapView={isMapView}
          isMobileOrTablet={isMobileOrTablet}
          disableBottomDrawer={disableBottomDrawer}
          color='textSecondary'
          data-testid='search-results-header'>
          {`${t('common.showing')} ${branches.length} ${t('whenWhere.resultsFor')} `}
          <b>{searchInputText}</b>
        </StyledHeaderText>
      </VirtuosoContentContainer>
    );
  }

  const BranchListWrapper = forwardRef<HTMLDivElement, ListProps & { context?: Context<unknown> }>((props, ref) => {
    return (
      <VirtuosoContentContainer data-testid='search-results-list'>
        <StyledVirtuosoWrapper
          isMapView={isMapView}
          isMobileOrTablet={isMobileOrTablet}
          disableBottomDrawer={disableBottomDrawer}
          {...props}
          ref={ref}>
          {props.children}
        </StyledVirtuosoWrapper>
      </VirtuosoContentContainer>
    );
  });

  return (
    <StyledBranchListBox
      containerHeight={window.innerHeight - window.innerHeight * DIALOG_HEIGHT_PERCENT_OFFSET - BRANCH_LIST_OFFSET}>
      {branches.length > 0 && (
        <>
          <BottomDrawer
            branchHeader={BranchListHeader()}
            open={bottomDrawerOpen}
            onOpen={() => setBottomDrawerOpen(true)}
            onClose={() => setBottomDrawerOpen(false)}
            disabled={disableBottomDrawer}
            isMapView={isMapView}>
            <Virtuoso
              data-testid='branch-card-list'
              ref={branchListRef}
              totalCount={branches.length}
              components={{
                Header: disableBottomDrawer || !isMapView ? BranchListHeader : () => <></>,
                Item: BranchListItem,
                List: BranchListWrapper,
              }}
              itemContent={(index) => (
                <BranchCard
                  index={index}
                  result={branches[index]}
                  isSelected={index === selectedCard}
                  onClick={() => {
                    if (selectedCard === index) {
                      setValue(BranchLookupFields.SelectedBranch, undefined);
                    } else {
                      setValue(BranchLookupFields.SelectedBranch, branches[index]);
                    }
                  }}
                />
              )}
            />
          </BottomDrawer>

          {isMapView && (
            <StyledMap>
              <BranchLookupMap branches={branches} scrollAndSelectBranch={scrollAndSelectBranch} />
            </StyledMap>
          )}
        </>
      )}
    </StyledBranchListBox>
  );
};

export default BranchCardList;
