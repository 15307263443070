import { styled } from '@mui/material';
import { Caption as UiCaption, H6 } from '@ehi/ui';

export const Title = styled(H6)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

/**
 * Caption defaulted to secondary color
 */
export const Caption2 = styled(UiCaption)``;
Caption2.defaultProps = {
  color: 'secondary',
};
