import { createContext, Dispatch, FC, ReactNode, SetStateAction, useCallback, useContext, useState } from 'react';
import { ResActionButton } from 'context/resActions/ResActionButton';
import { useSaveActionContext } from 'context/saveAction/SaveActionContext';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';
import { saveToReservation } from 'services/booking/bookingService';
import { getLocationHeaderFromUrl } from 'components/shared/preprocessor/ReservationSessionHelper';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectBookingEditorIssues } from 'redux/selectors/bookingEditor';
import { LoadableView } from 'components/shared/ui/spinner/loadableView/LoadableView';
import { FullScreenSpinner } from 'components/shared/ui/spinner/FullScreenSpinner';
import NetworkError from 'components/shared/errors/NetworkError';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { useReservationSessionHelper } from 'components/shared/preprocessor/useReservationSessionHelper';
import { RouterPaths } from 'app/router/RouterPaths';
import { useNavigate } from 'react-router-dom';
import { useResSnackbarContext } from 'context/resSnackbar/ResSnackbarContext';
import { safelyCatchError } from 'utils/errorUtils';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useTranslations } from 'components/shared/i18n';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import { parseUrn } from 'utils/urnUtils';
import { bookingIssues } from 'services/booking/bookingIssues';
import { getCorrelatedRoutePathForBookingError } from 'utils/routing/routeUtils';

export type ResActionsContextType = {
  setUpdateFloatingButtonAction: Dispatch<SetStateAction<boolean>>;
};

export const ResActionsContext = createContext<ResActionsContextType>({
  setUpdateFloatingButtonAction: () => undefined,
});

export const useResActionsContext = () => {
  const context = useContext(ResActionsContext);

  if (!context) {
    throw Error('ResActionsContext is not initialized');
  }

  return context;
};

type ResActionsProviderProps = {
  label: string;
  children?: ReactNode;
};

export const ResActionsProvider: FC<ResActionsProviderProps> = ({ label, children }) => {
  const { t } = useTranslations();
  const { saveOnUpdate } = useSaveActionContext();
  const { clearEditorSession } = useReservationSessionHelper();
  const { showAlert, hideAlert } = useAlert();
  const { setSnackBarRes } = useResSnackbarContext();
  const navigate = useNavigate();

  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const bookingEditorIssues = useAppSelector(selectBookingEditorIssues);

  const [updateFloatingButtonAction, setUpdateFloatingButtonAction] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleResActionButtonClick = async () => {
    if (updateFloatingButtonAction) {
      saveOnUpdate();
    } else {
      if (bookingEditorIssues && bookingEditorIssues.length > 0) {
        await handleMissingDataError();
      } else {
        try {
          setLoading(true);
          const { headers } = await saveToReservation(bookingEditorId, { skipValidation: false });
          const resNum = getLocationHeaderFromUrl(headers?.location);
          setSnackBarRes({
            num: resNum,
            isOpen: true,
          });
          clearEditorSession();
          navigate(RouterPaths.Search);
        } catch (error) {
          const ehiErrorsResponse = safelyCatchError(error);
          await showAlert({ responseMessages: ehiErrorsResponse?.errors });
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const handleMissingDataError = useCallback(async () => {
    const errorList =
      bookingEditorIssues &&
      bookingEditorIssues.map((issue) => {
        const issueCode = parseUrn(issue.issueCode);
        const issueDescription = bookingIssues.find((value) => value.code === issueCode)?.translatedString ?? issueCode;
        const routePath = getCorrelatedRoutePathForBookingError(issueCode);
        return (
          <div key={issueCode} data-testid='missingDataAlert'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <ErrorOutlineIcon color={'error'} style={{ paddingRight: ehiTheme.spacing(0.5) }} />
              <span style={{ textAlign: 'left', width: '100%' }}>{t(issueDescription)}</span>
              {routePath && (
                <Button
                  variant='text'
                  data-testid='viewButton'
                  onClick={() => {
                    navigate(routePath, { replace: true });
                    hideAlert();
                  }}
                  size={'medium'}>
                  {t('common.view')}
                </Button>
              )}
            </div>
            <EhiDivider style={{ marginRight: ehiTheme.spacing(2) }} />
          </div>
        );
      });

    await showAlert({
      title: t('error.errors'),
      description: <>{errorList}</>,
    });
  }, [bookingEditorIssues, hideAlert, navigate, showAlert, t]);

  return (
    <ResActionsContext.Provider value={{ setUpdateFloatingButtonAction }}>
      <LoadableView
        loadingComponent={<FullScreenSpinner />}
        errorComponent={<NetworkError />}
        state={mapLoadingState(loading, false) ?? LoadingState.SUCCESS}>
        {children}
      </LoadableView>
      <ResActionButton
        label={label}
        updateFloatingButtonAction={updateFloatingButtonAction}
        handleButtonClick={handleResActionButtonClick}
      />
    </ResActionsContext.Provider>
  );
};
