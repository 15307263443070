import { EhiButton, EhiText, ehiTheme, TextType } from '@ehi/ui';
import { FC, useCallback } from 'react';
import { useTranslations } from 'components/shared/i18n/useTranslations';
import { ErrorIcon } from 'assets/error/ErrorIcon';
import { NetworkErrorContainer } from './Error.styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouterPaths } from 'app/router/RouterPaths';

const NetworkError: FC = () => {
  const { t } = useTranslations();
  const location = useLocation();
  const navigate = useNavigate();

  const handleReload = useCallback(() => {
    if (location.pathname === RouterPaths.PreProcessor) {
      navigate(RouterPaths.Search);
    } else {
      window.location.reload();
    }
  }, [location, navigate]);

  return (
    <NetworkErrorContainer data-testid={'network-error'}>
      <ErrorIcon />
      <EhiText variant={TextType.H5} color={ehiTheme.palette.error.main} padding={ehiTheme.spacing(2, 0, 0, 0)}>
        {t('error.problemHasOccurred')}
      </EhiText>
      <EhiText variant={TextType.Subtitle2} color={ehiTheme.palette.error.main} padding={ehiTheme.spacing(2)}>
        {t('error.reloadSuggestion')}
      </EhiText>
      <EhiButton variant='contained' onClick={handleReload}>
        {t('error.reload')}
      </EhiButton>
    </NetworkErrorContainer>
  );
};

export default NetworkError;
