import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import {
  DepartmentType,
  LegalIdentificationType,
  PhoneType,
  WarningReason,
} from 'services/renter/renterReferenceTypes';
import { WarningDepartment } from 'services/renter/driverProfile/driverProfileTypes';

const RENTER_REFERENCE_ENDPOINT = '/reference/renter';
const RENTER_ENDPOINT = '/renter';

export const getPhoneTypes = async (): Promise<PhoneType[]> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${RENTER_REFERENCE_ENDPOINT}/phoneType`, {
    genericUrl: `${RENTER_REFERENCE_ENDPOINT}/phoneType`,
  });

  return response.data;
};

export const getRenterDepartmentTypes = async (): Promise<DepartmentType[]> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${RENTER_REFERENCE_ENDPOINT}/departmentType`, {
    genericUrl: `${RENTER_REFERENCE_ENDPOINT}/departmentType`,
  });

  return response.data;
};

export const getWarningReasons = async (): Promise<WarningReason[]> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${RENTER_REFERENCE_ENDPOINT}/warningReason`, {
    genericUrl: `${RENTER_REFERENCE_ENDPOINT}/warningReason`,
  });

  return response.data;
};

export const getWarningDepartments = async (): Promise<WarningDepartment[]> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${RENTER_ENDPOINT}/warningDepartment`, {
    genericUrl: `${RENTER_ENDPOINT}/warningDepartment`,
  });

  return response.data;
};

export const retrieveLegalIdentificationType = async (): Promise<LegalIdentificationType[]> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${RENTER_REFERENCE_ENDPOINT}/legalIdentificationType`, {
    genericUrl: `${RENTER_REFERENCE_ENDPOINT}/legalIdentificationType`,
  });
  return response.data;
};
