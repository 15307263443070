import { useAppSelector } from 'redux/hooks';
import {
  selectBookingEditorId,
  selectHasDatetimeData,
  selectHasLocationData,
  selectIsReadOnlyFlow,
  selectPickup,
  selectRateSource,
  selectReturn,
} from 'redux/selectors/bookingEditor';
import { parseUrn } from 'utils/urnUtils';
import { UseVehicleRatesReturn } from 'services/booking/vehicleRateTypes';
import { useVehicleRatesQuery } from 'services/booking/bookingQueries';
import { useGetRenterProfile } from 'services/renter/useGetRenterProfile';
import { NegotiatedRateSource } from 'services/booking/bookingTypes';

export const useVehicleRates = (): UseVehicleRatesReturn => {
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const pickupData = useAppSelector(selectPickup);
  const returnData = useAppSelector(selectReturn);
  const hasLocationData = useAppSelector(selectHasLocationData);
  const hasDatetimeData = useAppSelector(selectHasDatetimeData);
  const isResViewOnly = useAppSelector(selectIsReadOnlyFlow);
  const { driverData } = useGetRenterProfile();
  const rateSource = useAppSelector(selectRateSource) as NegotiatedRateSource;

  let vehicleRatesKey = `${bookingEditorId}-${parseUrn(pickupData?.branch)}-${pickupData?.dateTime?.trim()}-${parseUrn(
    returnData?.branch
  )}-${returnData?.dateTime?.trim()}`;
  vehicleRatesKey =
    vehicleRatesKey && driverData?.age ? vehicleRatesKey + `-${driverData?.age?.toString()}` : vehicleRatesKey;
  vehicleRatesKey =
    vehicleRatesKey && rateSource?.account ? vehicleRatesKey + `-${parseUrn(rateSource.account)}` : vehicleRatesKey;

  const enableApi = !!bookingEditorId && !isResViewOnly && hasLocationData && hasDatetimeData;

  const { data, isLoading, error } = useVehicleRatesQuery(bookingEditorId, vehicleRatesKey, {
    enabled: enableApi,
  });

  return {
    vehicleRates: data?.vehicleRates,
    isLoading: enableApi && isLoading,
    errors: error,
  };
};
