import { fetchDomainQuery, useDomainQuery } from 'context/queryClient/queryUtil';
import { UseQueryResult } from '@tanstack/react-query';
import {
  BranchEffectivePolicy,
  BranchV2,
  Countries,
  CountrySubdivision,
  GroupSummary,
} from 'services/location/locationTypes';
import { OperationalLocationType } from 'services/location/locationReferenceTypes';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { Location } from 'services/location/locationRollupTypes';
import { useSetLocationService } from 'services/location/useSetLocationService';
import { QUERY_KEY_LOCATION, QUERY_KEY_LOCATION_REFERENCE_PAYMENT_TYPES } from 'services/location/locationCache';
import {
  getBranchByPeopleSoftId,
  getCountries,
  getCountrySubdivisions,
  getGroups,
  getLocationByUrn,
  getLocationTypes,
  getPaymentTypes,
  getRentalBrands,
  getStation,
  retrieveBranchEffectivePolicies,
} from 'services/location/locationService';
import { useTranslations } from 'components/shared/i18n/useTranslations';
import { RentalBrand } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupTypes';

const COUNTRIES_QUERY_KEY = 'countries';
const SUB_DIVISION_QUERY_KEY = 'subDivision';
const BRANCH_INFO_QUERY_KEY = 'branch';
const BRANCH_INFO_BY_URN_QUERY_KEY = 'branchInfoByUrn';
const STATION_INFO_QUERY = 'stationInfo';
export const QUERY_KEY_LOCATION_GROUP = 'groups';
const LOCATION_TYPES_QUERY_KEY = 'locationTypes';
const RENTAL_BRANDS = 'rentalBrands';
const BRANCH_EFFECTIVE_POLICIES = 'branchEffectivePolicies';

export const useCountriesQuery = (): UseQueryResult<Countries, EhiErrors> => {
  return useDomainQuery({ queryKey: COUNTRIES_QUERY_KEY, queryFn: getCountries });
};

export const useSubdivisionsQuery = (country: string): UseQueryResult<CountrySubdivision[], EhiErrors> => {
  return useDomainQuery({
    queryKey: [SUB_DIVISION_QUERY_KEY, country],
    queryFn: () => getCountrySubdivisions(country),
    enabled: !!country,
  });
};

export const useBranchInfoByPeopleSoftIdQuery = (locationId: string): UseQueryResult<BranchV2, EhiErrors> => {
  return useDomainQuery({
    queryKey: [BRANCH_INFO_QUERY_KEY, locationId],
    queryFn: () => getBranchByPeopleSoftId(locationId),
    enabled: !!locationId,
  });
};

export const useLocationQuery = (locationId: string): UseQueryResult<Location> => {
  const { getLocation } = useSetLocationService();
  return useDomainQuery({
    queryKey: [QUERY_KEY_LOCATION, locationId],
    queryFn: () => getLocation(locationId),
    enabled: !!locationId,
  });
};

export const useBranchInfoByUrnQuery = (
  urn: string | undefined,
  isEnabled = true
): UseQueryResult<BranchV2, EhiErrors> => {
  const { t } = useTranslations();
  return useDomainQuery({
    queryKey: [BRANCH_INFO_BY_URN_QUERY_KEY, urn],
    queryFn: () => getLocationByUrn(urn, t('error.branchNotFound')),
    enabled: !!urn && isEnabled,
  });
};

export const fetchBranch = (locationId: string) => {
  return fetchDomainQuery({
    queryKey: [BRANCH_INFO_QUERY_KEY, locationId],
    queryFn: () => getBranchByPeopleSoftId(locationId),
  });
};

export const fetchStation = (stationId: string) => {
  return fetchDomainQuery({
    queryKey: [STATION_INFO_QUERY, stationId],
    queryFn: () => getStation(stationId),
  });
};

export const useLocationReferencePaymentTypes = () => {
  return useDomainQuery({ queryKey: QUERY_KEY_LOCATION_REFERENCE_PAYMENT_TYPES, queryFn: () => getPaymentTypes() });
};

export const useLocationGroups = (
  countryCode: string,
  capabilities: string
): UseQueryResult<GroupSummary[], EhiErrors> => {
  return useDomainQuery({
    queryKey: [QUERY_KEY_LOCATION_GROUP, countryCode],
    queryFn: () => getGroups(countryCode, capabilities),
    enabled: !!(countryCode || capabilities),
  });
};

export const useLocationTypesQuery = (): UseQueryResult<OperationalLocationType[], EhiErrors> => {
  return useDomainQuery({ queryKey: LOCATION_TYPES_QUERY_KEY, queryFn: getLocationTypes });
};

export const useRentalBrandsQuery = (): UseQueryResult<RentalBrand[]> => {
  return useDomainQuery({
    queryKey: RENTAL_BRANDS,
    queryFn: getRentalBrands,
  });
};

export const useRetrieveBranchEffectivePolicies = (
  branchId: string
): UseQueryResult<BranchEffectivePolicy[], EhiErrors> => {
  return useDomainQuery({
    queryKey: [BRANCH_EFFECTIVE_POLICIES, branchId],
    queryFn: () => retrieveBranchEffectivePolicies(branchId),
    enabled: !!branchId,
  });
};
