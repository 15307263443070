import { FC, useState } from 'react';
import { RateSourceInfoBox, RateSourceLink } from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import InfoIcon from '@mui/icons-material/Info';
import { Body2, ehiTheme } from '@ehi/ui';
import { RadioGroup } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { useTranslations } from 'components/shared/i18n';

export const RateSourceInfo: FC = (): JSX.Element => {
  const { t } = useTranslations();
  const billingCycleList = [t('rateAndBilling.calendarDay'), t('rateAndBilling.24Hour')];
  const radioButtonsDisabled = true;
  const [billingCycleSelection, setBillingCycleSelection] = useState<string>(t('rateAndBilling.24Hour'));

  return (
    <RateSourceInfoBox data-testid='rateSourceInfo'>
      <RateSourceLink data-testid='rateQualificationLink'>
        <InfoIcon sx={{ paddingRight: ehiTheme.spacing(1) }} />
        <Body2 bold>{t('rateAndBilling.rateQualifications')}</Body2>
      </RateSourceLink>
      <RadioGroup data-testid='rateSourceRadioGroup' style={{ paddingTop: ehiTheme.spacing(1) }}>
        <Caption2 style={{ paddingBottom: ehiTheme.spacing(1) }}>{t('rateAndBilling.billingCycle')}</Caption2>
        {billingCycleList.map((value: string) => (
          <FormControlLabel
            key={value}
            value={value}
            checked={billingCycleSelection === value}
            disabled={radioButtonsDisabled}
            data-testid={`${value}-radio`}
            onClick={() => {
              !radioButtonsDisabled && setBillingCycleSelection(value);
            }}
            control={<Radio color='primary' />}
            label={value}
            sx={{
              '& .MuiRadio-root': {
                paddingTop: ehiTheme.spacing(0.5),
                paddingBottom: ehiTheme.spacing(0.5),
              },
              '& .MuiSvgIcon-root': {
                fontSize: 24,
              },
            }}
          />
        ))}
      </RadioGroup>
      <EhiDivider style={{ margin: ehiTheme.spacing(2, 0) }} />
      <RateSourceLink disabled data-testid='contractualConditionsLink'>
        <InfoIcon sx={{ paddingRight: ehiTheme.spacing(1) }} />
        <Body2 bold>{t('rateAndBilling.contractualConditions')}</Body2>
      </RateSourceLink>
    </RateSourceInfoBox>
  );
};
