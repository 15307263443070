import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { useTranslations } from 'components/shared/i18n';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { ALL_VALUE, SelectField } from 'components/shared/forms/SelectField';
import {
  fuelTypeOptions,
  MINIMUM_PASSENGER_NUM,
  passengerNumberOptions,
  vehicleSearchInitialValues,
  vehicleTypeOptions,
} from 'components/flexFlow/vehicle/vehicleSearch/vehicleSearchUtils';
import { StyledResetButton, VehicleSearchHeader } from 'components/flexFlow/vehicle/VehiclePage.styles';
import { FlexEndItem, FlexStartItem } from 'components/shared/ui/card/CardNavigation.styles';
import { VehicleAutoCompleteField } from 'components/shared/forms/VehicleAutoCompleteField';
import { VehicleSearchFields, VehicleSectionProps } from 'components/flexFlow/vehicle/VehicleTypes';

export const VehicleSearchForm: FC<VehicleSectionProps> = ({ vehicles }) => {
  const { t } = useTranslations();
  const { reset, resetField, setValue } = useFormContext();

  const [carClass, carType, fuelType, passengerNumber] = useWatch({
    name: [
      VehicleSearchFields.VehicleClass,
      VehicleSearchFields.VehicleType,
      VehicleSearchFields.FuelType,
      VehicleSearchFields.PassengerNumber,
    ],
  });

  return (
    <>
      <VehicleSearchHeader>
        <FlexStartItem>
          <h3>{t('vehicle.vehicleClassSearch')}</h3>
        </FlexStartItem>
        <FlexEndItem>
          <StyledResetButton type={'reset'} onClick={() => reset({ ...vehicleSearchInitialValues })} disabled={false}>
            {t('common.reset')}
          </StyledResetButton>
        </FlexEndItem>
      </VehicleSearchHeader>
      <GridContainer>
        <GridItem sm={12}>
          <VehicleAutoCompleteField
            vehicles={vehicles}
            setVehicle={(vehicle) => setValue(VehicleSearchFields.VehicleClass, vehicle)}
            resetVehicle={() => resetField(VehicleSearchFields.VehicleClass)}
            selectedVehicleClass={carClass}
            disabled={
              carType != ALL_VALUE || fuelType != ALL_VALUE || passengerNumber != MINIMUM_PASSENGER_NUM.toString()
            }
          />
        </GridItem>
        <GridItem sm={12}>
          <EhiDivider />
        </GridItem>
        <GridItem sm={4}>
          <SelectField
            name={VehicleSearchFields.VehicleType}
            label={t('vehicle.vehicleType')}
            data-testid={'vehicleTypeDropdown'}
            disabled={!!carClass}
            options={vehicleTypeOptions}
            hasNoneOption={false}
            hasAllOption
            onChange={(event: any) => {
              setValue(VehicleSearchFields.VehicleType, event.target.value);
            }}
          />
        </GridItem>
        <GridItem sm={4}>
          <SelectField
            name={VehicleSearchFields.FuelType}
            label={t('vehicle.fuelType')}
            data-testid={'fuelTypeDropdown'}
            disabled={!!carClass}
            options={fuelTypeOptions}
            hasNoneOption={false}
            hasAllOption
            onChange={(event: any) => {
              setValue(VehicleSearchFields.FuelType, event.target.value);
            }}
          />
        </GridItem>
        <GridItem sm={4}>
          <SelectField
            name={VehicleSearchFields.PassengerNumber}
            label={t('vehicle.passengerNumber')}
            data-testid={'passengerNumberDropdown'}
            disabled={!!carClass}
            options={passengerNumberOptions}
            hasNoneOption={false}
            onChange={(event: any) => {
              setValue(VehicleSearchFields.PassengerNumber, event.target.value);
            }}
          />
        </GridItem>
      </GridContainer>
    </>
  );
};
