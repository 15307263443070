import { LoadableView } from 'components/shared/ui/spinner/loadableView/LoadableView';
import React, { FunctionComponent, useState } from 'react';

import { useEffectOnlyOnce } from 'hooks/useEffectOnlyonce';
import { useLdProvider } from 'components/shared/launchDarkly/LaunchDarklyService';

import ErrorWrapper from 'components/shared/errors/ErrorWrapper';
import { FullScreenSpinner } from 'components/shared/ui/spinner/FullScreenSpinner';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { logError } from 'components/shared/logger/splunkLogger';
import { useEffectWhen } from 'hooks/useEffectWhen';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';

const Wrapper: FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const ldClient = useLDClient();

  useEffectWhen(() => {
    if (ldClient !== undefined) {
      ldClient.waitForInitialization().catch((e) => logError(e, 'Error connecting to Launch Darkly'));
    }
  }, !!ldClient);

  return <>{children}</>;
};

export const LaunchDarklyProvider: FunctionComponent<{
  children?: React.ReactNode;
}> = ({ children }): React.JSX.Element => {
  const [LDProvider, setLDProvider] = useState<FunctionComponent<{ children?: React.ReactNode }> | undefined>(
    undefined
  );
  const [loadingState, setLoadingState] = React.useState<LoadingState>(LoadingState.LOADING);

  const { createLdProvider } = useLdProvider();

  useEffectOnlyOnce(async () => {
    await createLdProvider().then((provider: any) => setLDProvider(() => provider));
    setLoadingState(LoadingState.SUCCESS);
  });

  return LDProvider ? (
    <LDProvider>
      <Wrapper>{children}</Wrapper>
    </LDProvider>
  ) : (
    <LoadableView loadingComponent={<FullScreenSpinner />} errorComponent={<ErrorWrapper />} state={loadingState}>
      <></>
    </LoadableView>
  );
};
