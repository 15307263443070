import { useState } from 'react';

enum SwipeDirection {
  Up,
  Down,
  Left,
  Right,
}

type SwipeInfo = {
  x: number | null;
  y: number | null;
  date: Date | null;
};

const useSwipe = (
  direction: SwipeDirection,
  swipeAction: () => void,
  useAsMobileClick?: boolean,
  allowedSwipeTimeInMilli?: number,
  pixelDistanceThreshold?: number
) => {
  const useMobileClick = useAsMobileClick ? useAsMobileClick : false;
  const mobileClickThreshold = 25;
  const allowedTime = allowedSwipeTimeInMilli ? allowedSwipeTimeInMilli : 500;
  const threshold = pixelDistanceThreshold ? pixelDistanceThreshold : 50;
  const [swipeInfo, setSwipeInfo] = useState<SwipeInfo>({ x: null, y: null, date: null });

  const handleSwipeStart = (e: any) => {
    const touchObj = e.changedTouches[0];
    setSwipeInfo({ x: touchObj.pageX, y: touchObj.pageY, date: new Date() });
  };

  const handleSwipeEnd = (e: any) => {
    const touchObj = e.changedTouches[0];
    const endCoords = { x: touchObj.pageX, y: touchObj.pageY };

    if (!swipeInfo.x || !swipeInfo.y || !endCoords.x || !endCoords.y || !swipeInfo.date) {
      return;
    }

    let dist = 0;

    switch (direction) {
      case SwipeDirection.Up:
        dist = swipeInfo.y - endCoords.y;
        break;
      case SwipeDirection.Down:
        dist = endCoords.y - swipeInfo.y;
        break;
      case SwipeDirection.Left:
        dist = swipeInfo.x - endCoords.x;
        break;
      case SwipeDirection.Right:
        dist = endCoords.x - swipeInfo.x;
        break;
    }

    const elapsedTime = new Date().getTime() - swipeInfo.date.getTime();

    if (elapsedTime <= allowedTime && dist >= threshold) {
      swipeAction();
    } else if (useMobileClick && dist <= mobileClickThreshold) {
      swipeAction();
    }

    setSwipeInfo({ x: null, y: null, date: null });
  };

  return { handleSwipeStart, handleSwipeEnd };
};

export { useSwipe, SwipeDirection };
