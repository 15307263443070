import { ehiTheme } from '@ehi/ui';
import { Card, styled, Typography } from '@mui/material';
import { Box } from '@mui/system';

export const PeoListItemContainer = styled(Card)(() => ({
  padding: '0.75rem 1.5rem 0.75rem 1rem',
  display: 'grid',
  gridTemplateColumns: '3fr 1fr',
  gap: '0.5rem',
  marginBottom: '0.75rem',
  '& .MuiCheckbox-root': {
    padding: 0,
  },
}));

export const PeoCheckboxContainer = styled('div')(() => ({
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'flex-start',
}));

export const PeoTitle = styled(Typography)<{ readOnly: boolean }>((props) => ({
  color: props.readOnly ? '#4d789d' : ehiTheme.typography.body1.color,
  fontWeight: 700,
  fontSize: ehiTheme.typography.body1.fontSize,
  lineHeight: ehiTheme.typography.body1.lineHeight,
}));

export const PeoCode = styled(Typography)(() => ({
  color: 'rgba(0, 0, 0, 0.54)',
  fontWeight: 400,
  fontSize: ehiTheme.typography.caption.fontSize,
  lineHeight: ehiTheme.typography.caption.lineHeight,
}));

export const PeoAmount = styled(Typography)(() => ({
  color: ehiTheme.typography.body1.color,
  fontWeight: 700,
  fontSize: ehiTheme.typography.body1.fontSize,
  lineHeight: ehiTheme.typography.body1.lineHeight,
}));

export const PeoSelectability = styled(Typography)(() => ({
  color: ehiTheme.typography.caption.color,
  fontWeight: 400,
  fontSize: ehiTheme.typography.caption.fontSize,
  lineHeight: ehiTheme.typography.caption.lineHeight,
  textAlign: 'end',
}));

export const PeoQuantityAndRate = styled('div')`
  display: flex;
  justify-content: flex-end;

  &.displayQuantity {
    justify-content: space-between;
  }
`;

export const StyledPeoContainer = styled(Box)`
  margin: ${ehiTheme.spacing(9, 1)};
  &.peoNiResults {
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
