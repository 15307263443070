import { FC, useMemo } from 'react';
import { Body1, ehiTheme, Subtitle1 } from '@ehi/ui';
import { useLocale, useTranslations } from 'components/shared/i18n';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Box, Grid } from '@mui/material';
import { RenterWarningDivider } from 'components/shared/ui/styles/Divider.styles';
import { piiField } from '@ehi/support';
import { parseUrn } from 'utils/urnUtils';
import { ReferenceDocument } from 'services/renter/driverProfile/driverProfileTypes';
import { toDateTimeString } from 'utils/dateUtils';
import { useCountriesQuery, useSubdivisionsQuery } from 'services/location/locationQueries';
import { getCountry, getSubdivision } from 'utils/countryUtils';
import { DriverWarningData } from './RenterWarningTypes';
import { formatPhoneNumber } from 'utils/phoneNumberUtils';
import { formatCurrency } from 'utils/currencyUtils';
import { usePhoneTypesQuery } from 'services/renter/renterReferenceQueries';

type DriverWarningDetailsProps = {
  driverWarningData?: DriverWarningData;
};

export const DriverWarningDetails: FC<DriverWarningDetailsProps> = ({
  driverWarningData,
}: DriverWarningDetailsProps) => {
  const { t } = useTranslations();
  const { locale } = useLocale();
  const { data: countries } = useCountriesQuery();
  const { data: subDivisions } = useSubdivisionsQuery(parseUrn(driverWarningData?.legalIdentification?.country) ?? '');
  const { data: phoneTypeDomain } = usePhoneTypesQuery();
  const phoneType = useMemo(() => {
    return phoneTypeDomain?.find((phone) => phone.urn === driverWarningData?.phoneType)?.name;
  }, [phoneTypeDomain, driverWarningData?.phoneType]);

  return (
    <>
      {/* RENTER INFORMATION */}
      <Subtitle1 bold>{t('driver.renterInformation')}</Subtitle1>
      <Grid
        container
        spacing={2}
        className={piiField}
        style={{ paddingTop: ehiTheme.spacing(2), justifyContent: 'space-between' }}
        data-testid={'renterInformation'}>
        <LabelAndData
          label={t('driver.address')}
          size={6}
          data={
            <>
              <Body1 data-testid={'address-lines'}>{driverWarningData?.address?.lines}</Body1>
              <Body1 data-testid={'address-cityStateZip'}>{driverWarningData?.address?.cityStateZip}</Body1>
            </>
          }
        />
        <LabelAndData
          label={t('driver.contact')}
          data={
            <>
              {phoneType && (
                <Body1 data-testid={'address-phone'}>
                  {`${phoneType}: ${formatPhoneNumber(driverWarningData?.phone) ?? ''}`}
                </Body1>
              )}
              <Body1 data-testid={'address-email'} style={{ wordBreak: 'break-all' }}>
                {driverWarningData?.email ?? ''}
              </Body1>
            </>
          }
          size={6}
        />
      </Grid>

      <RenterWarningDivider />

      {/* LICENSE INFORMATION */}
      <Subtitle1 bold>{t('driver.license')}</Subtitle1>
      <Box className={piiField} data-testid={'renterLicense'}>
        <Grid container spacing={2} style={{ paddingTop: ehiTheme.spacing(2) }}>
          <LabelAndData
            label={t('driver.driverLicenseNum')}
            data={driverWarningData?.legalIdentification?.number}
            size={4}
          />
          <LabelAndData
            label={t('driver.issuingCountry')}
            data={getCountry(countries, parseUrn(driverWarningData?.legalIdentification?.country))?.name}
            size={4}
          />
          <LabelAndData
            label={t('driver.issuingState')}
            data={getSubdivision(subDivisions, driverWarningData?.legalIdentification?.countrySubdivision)?.name}
            size={4}
          />
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: ehiTheme.spacing(2) }}>
          <LabelAndData
            label={t('driver.issuingDate')}
            data={toDateTimeString(driverWarningData?.legalIdentification?.issueDate, t('format.date'))}
            size={4}
          />
          <LabelAndData
            label={t('driver.expirationDate')}
            data={toDateTimeString(driverWarningData?.legalIdentification?.expirationDate, t('format.date'))}
            size={4}
          />
          <LabelAndData label={t('driver.dateOfBirth')} data={driverWarningData?.dob} size={4} />
        </Grid>
      </Box>

      <RenterWarningDivider />

      {/* RENTER WARNING INFORMATION */}
      <Subtitle1 bold>{t('driver.warnings.renterWarningTitle')}</Subtitle1>
      <Box className={piiField} data-testid={'renterWarningInfo'}>
        {driverWarningData?.referenceDocuments?.map((referenceDocument: ReferenceDocument, index) => (
          <Grid container spacing={2} key={index} style={{ paddingTop: ehiTheme.spacing(2) }}>
            <LabelAndData
              label={t('driver.warnings.refDocNumber')}
              data={referenceDocument.number}
              size={4}
              data-testid={'refDocNumber'}
            />
            <LabelAndData
              label={t('location.groupBranch')}
              data={parseUrn(referenceDocument.branch)}
              size={4}
              data-testid={'groupBranch'}
            />
            {referenceDocument.amountOwed && (
              <LabelAndData
                label={t('driver.warnings.amountOwed')}
                data={
                  referenceDocument.amountOwed.includes('$')
                    ? referenceDocument.amountOwed
                    : formatCurrency(parseInt(referenceDocument.amountOwed), '', locale)
                }
                size={4}
                data-testid={'amountOwed'}
              />
            )}
          </Grid>
        ))}
        <Grid container spacing={2} style={{ paddingTop: ehiTheme.spacing(2) }}>
          <LabelAndData
            label={t('driver.warnings.reasonDesc')}
            data={driverWarningData?.reason}
            size={4}
            data-testid={'reasonDesc'}
          />
          <LabelAndData
            label={t('driver.warnings.departmentDescription')}
            data={driverWarningData?.department.description}
            size={4}
            data-testid={'departmentDescription'}
          />
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: ehiTheme.spacing(2) }}>
          <LabelAndData
            label={t('driver.warnings.message')}
            data-testid={'warningMessage'}
            size={12}
            data={driverWarningData?.message}
          />
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: ehiTheme.spacing(2) }}>
          <LabelAndData
            label={t('driver.contact')}
            data-testid={'departmentContact'}
            size={4}
            data={formatPhoneNumber(driverWarningData?.department.phoneNumber)}
          />
          <LabelAndData
            label={t('location.region')}
            data={parseUrn(driverWarningData?.department.region)}
            size={4}
            data-testid={'region'}
          />
          <LabelAndData
            label={t('location.group')}
            data={parseUrn(driverWarningData?.department?.group)}
            size={4}
            data-testid={'group'}
          />
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: ehiTheme.spacing(2) }}>
          <LabelAndData
            label={t('driver.warnings.dateReported')}
            data={driverWarningData?.reportDate}
            size={4}
            data-testid={'dateReported'}
          />
        </Grid>
      </Box>
      <RenterWarningDivider />
    </>
  );
};

const LabelAndData = ({ label, size, data }: { label: string; size: number; data?: string | JSX.Element | null }) => {
  return (
    <Grid item {...{ xs: size, sm: size }}>
      <Caption2>{label}</Caption2>
      {typeof data === 'string' ? <Body1>{data || ''}</Body1> : data}
    </Grid>
  );
};
