import { TFunction } from 'i18next';
import * as Yup from 'yup';
import {
  CreateDriverFields,
  CreateDriverValues,
  PhoneFieldNames,
  PhoneFormModel,
} from 'components/flexFlow/driver/createDriver/CreateDriverTypes';
import { CORPORATE_COUNTRIES, CorporateCountry } from 'utils/countryUtils';
import { DateTime } from 'luxon';
import { LegalIdentificationType } from 'services/renter/renterReferenceTypes';
import { NoResultSearchValuesType } from 'components/flexFlow/driver/searchDriver/DriverSearch';
import { calculateAge } from 'utils/dateUtils';
import { EMPTY_VALUE } from 'utils/constants';

export const FRANCE_LICENSE_FIELDS = [CreateDriverFields.PlaceOfBirth];
export const GERMANY_LICENSE_FIELDS = [CreateDriverFields.IdentificationNumber];
export const SPAIN_LICENSE_FIELDS = [
  CreateDriverFields.IdIssuingCountry,
  CreateDriverFields.IdType,
  CreateDriverFields.IdPassportNumber,
  CreateDriverFields.IdExpirationDate,
];
export const DVLA_LICENSE_VALUE = 'DVLA';
export const PHONE_COUNT_MAX = 4;
const EMPTY_PHONE: PhoneFormModel = { type: '', number: '', countryCode: '', priority: 1 };

const generateAddressValidation = (countries: string[], t: TFunction<'translation'>) => {
  return {
    is: (driverCountry: string) => countries.includes(driverCountry),
    then: () => Yup.string().required(t('validation.requiredField')),
    otherwise: () => Yup.string(),
  };
};

export const createDriverValidationSchema = (t: TFunction<'translation'>) =>
  Yup.object().shape({
    lastName: Yup.string()
      .min(2, t('validation.minFieldLength'))
      .required(t('validation.requiredField'))
      .matches(/^(\D*)$/i, t('validation.invalidLastName')),
    firstName: Yup.string()
      .matches(/^(\D*)$/i, t('validation.invalidFirstName'))
      .min(2, t('validation.minFieldLength'))
      .required(t('validation.requiredField'))
      .matches(/^(\D*)$/i, t('validation.invalidLastName')),
    streetAddress1: Yup.string().when(CreateDriverFields.DriverCountry, {
      is: (driverCountry: any) => !!driverCountry,
      then: () => Yup.string().required(t('validation.requiredField')),
      otherwise: () => Yup.string(),
    }),
    streetAddress2: Yup.string(),
    city: Yup.string().when(CreateDriverFields.DriverCountry, {
      is: (driverCountry: any) => !!driverCountry,
      then: () => Yup.string().required(t('validation.requiredField')),
      otherwise: () => Yup.string(),
    }),
    subRegion: Yup.string().when(CreateDriverFields.DriverCountry, generateAddressValidation(CORPORATE_COUNTRIES, t)),
    postalCode: Yup.string().when(CreateDriverFields.DriverCountry, {
      is: (driverCountry: any) => !!driverCountry,
      then: () => Yup.string().required(t('validation.requiredField')),
      otherwise: () => Yup.string(),
    }),

    // LICENSE SECTION
    licenseCountry: Yup.string(),
    licenseNumber: Yup.string().when(CreateDriverFields.LicenseCountry, {
      is: (licenseCountry: string) => licenseCountry.length,
      then: () => Yup.string().required(t('validation.requiredField')),
    }),
    licenseIssuerRegion: Yup.string().when(CreateDriverFields.LicenseCountry, {
      is: (licenseCountry: string) =>
        licenseCountry.length > 0 && issuerRegionCountries.find((value) => value === licenseCountry),
      then: () => Yup.string().required(t('validation.requiredField')),
    }),
    licenseIssuerAuth: Yup.string().when(CreateDriverFields.LicenseCountry, {
      is: (licenseCountry: string) =>
        licenseCountry.length > 0 && !issuerRegionCountries.find((value) => value === licenseCountry),
      then: () => Yup.string().required(t('validation.requiredField')),
    }),
    licenseExpirationDate: Yup.date()
      .typeError(t('validation.invalidDateFormat'))
      .test(CreateDriverFields.LicenseExpirationDate, t('validation.requiredField'), (value, context) => {
        const { licenseCountry } = context.parent;
        if (
          licenseCountry &&
          (licenseCountry === CorporateCountry.UnitedStates || licenseCountry === CorporateCountry.Canada)
        ) {
          return !!value;
        } else {
          return true;
        }
      })
      .test(CreateDriverFields.LicenseExpirationDate, t('validation.expOrIssueDateRequired'), (value, context) => {
        const { licenseCountry, licenseIssuedDate } = context.parent;
        if (
          licenseCountry &&
          !licenseIssuedDate &&
          !(licenseCountry === CorporateCountry.UnitedStates || licenseCountry === CorporateCountry.Canada)
        ) {
          return !!value;
        } else {
          return true;
        }
      }),
    licenseIssuedDate: Yup.date()
      .typeError(t('validation.invalidDateFormat'))
      .max(DateTime.now().startOf('day').toJSDate(), t('validation.pastDateRequired'))
      .test(CreateDriverFields.LicenseIssuedDate, t('validation.expOrIssueDateRequired'), (value, context) => {
        const { licenseCountry, licenseExpirationDate } = context.parent;
        if (
          licenseCountry &&
          !licenseExpirationDate &&
          !(licenseCountry === CorporateCountry.UnitedStates || licenseCountry === CorporateCountry.Canada)
        ) {
          return !!value;
        } else {
          return true;
        }
      }),
    dateOfBirth: Yup.date().when(CreateDriverFields.LicenseCountry, {
      is: (licenseCountry: string) => licenseCountry,
      then: () =>
        Yup.date()
          .required(t('validation.requiredField'))
          .typeError(t('validation.invalidDateFormat'))
          .max(DateTime.now().startOf('day').toJSDate(), t('validation.pastDateRequired')),
    }),
    identificationNumber: Yup.string().when(CreateDriverFields.LicenseCountry, {
      is: (licenseCountry: string) => licenseCountry && licenseCountry === CorporateCountry.Germany,
      then: () => Yup.string().required(t('validation.requiredField')),
    }),
    placeOfBirth: Yup.string().when(CreateDriverFields.LicenseCountry, {
      is: (licenseCountry: string) => licenseCountry && licenseCountry === CorporateCountry.France,
      then: () => Yup.string().required(t('validation.requiredField')),
    }),
    idExpirationDate: Yup.date().typeError(t('validation.invalidDateFormat')),

    // CONTACT SECTION
    phone: Yup.array().of(
      Yup.object().shape({
        number: Yup.string()
          .matches(/^[0-9]*$/, t('validation.positiveNumber'))
          .test('length', t('validation.phoneNumberValidation'), (val) => (val && val.length == 10) || !val),
        type: Yup.string().when(PhoneFieldNames.Number, {
          is: (number: string) => number,
          then: () => Yup.string().required(t('validation.requiredField')),
        }),
        countryCode: Yup.string().when(PhoneFieldNames.Number, {
          is: (number: string) => number,
          then: () => Yup.string().required(t('validation.requiredField')),
        }),
      })
    ),
    email: Yup.string().email(t('validation.invalidEmail')),
  });

export const createDriverInitialValues = (noResultsFormValues: NoResultSearchValuesType): CreateDriverValues => {
  return {
    [CreateDriverFields.LastName]: noResultsFormValues?.lastName ?? EMPTY_VALUE,
    [CreateDriverFields.FirstName]: noResultsFormValues?.firstName ?? EMPTY_VALUE,
    [CreateDriverFields.Age]:
      noResultsFormValues?.dob.length > 0 ? getAgeOption(calculateAge(noResultsFormValues.dob)) : EMPTY_VALUE,
    [CreateDriverFields.DriverCountry]: EMPTY_VALUE,
    [CreateDriverFields.StreetAddress1]: EMPTY_VALUE,
    [CreateDriverFields.StreetAddress2]: EMPTY_VALUE,
    [CreateDriverFields.City]: EMPTY_VALUE,
    [CreateDriverFields.SubRegion]: EMPTY_VALUE,
    [CreateDriverFields.PostalCode]: EMPTY_VALUE,
    [CreateDriverFields.LicenseCountry]: EMPTY_VALUE,
    [CreateDriverFields.LicenseNumber]: EMPTY_VALUE,
    [CreateDriverFields.LicenseIssuerRegion]: EMPTY_VALUE,
    [CreateDriverFields.LicenseIssuerAuth]: EMPTY_VALUE,
    [CreateDriverFields.LicenseExpirationDate]: undefined,
    [CreateDriverFields.IdentificationNumber]: undefined,
    [CreateDriverFields.LicenseDVLA]: undefined,
    [CreateDriverFields.LicenseIssuedDate]: undefined,
    [CreateDriverFields.DOB]: undefined,
    [CreateDriverFields.PlaceOfBirth]: undefined,
    [CreateDriverFields.IdIssuingCountry]: EMPTY_VALUE,
    [CreateDriverFields.IdExpirationDate]: undefined,
    [CreateDriverFields.IdType]: EMPTY_VALUE,
    [CreateDriverFields.IdPassportNumber]: undefined,
    [CreateDriverFields.Phone]: noResultsFormValues?.phoneNumber
      ? [{ type: '', number: noResultsFormValues.phoneNumber, countryCode: '', priority: 1 }]
      : [EMPTY_PHONE],
    [CreateDriverFields.ContactType]: undefined,
    [CreateDriverFields.Email]: EMPTY_VALUE,
  };
};

enum AgeRange {
  RANGE_18_20 = '18-20',
  RANGE_21_24 = '21-24',
  RANGE_25_PLUS = '25+',
}

export const AGE_OPTIONS = [
  { label: AgeRange.RANGE_18_20, value: AgeRange.RANGE_18_20 },
  { label: AgeRange.RANGE_21_24, value: AgeRange.RANGE_21_24 },
  { label: AgeRange.RANGE_25_PLUS, value: AgeRange.RANGE_25_PLUS },
];

export const getAgeOption = (age: number) => {
  if (age >= 18 && age <= 20) {
    return AgeRange.RANGE_18_20;
  } else if (age >= 21 && age <= 24) {
    return AgeRange.RANGE_21_24;
  } else if (age >= 25) {
    return AgeRange.RANGE_25_PLUS;
  } else {
    return EMPTY_VALUE;
  }
};

const provinceCountries = [CorporateCountry.Canada, CorporateCountry.France, CorporateCountry.Spain];
const countyCountries = [CorporateCountry.Germany, CorporateCountry.Ireland, CorporateCountry.GreatBritain];
export const getRegionLabel = (countryCode: CorporateCountry): string | undefined => {
  if (provinceCountries.includes(countryCode)) {
    return 'location.province';
  } else if (countyCountries.includes(countryCode)) {
    return 'location.county';
  } else if (countryCode === CorporateCountry.UnitedStates) {
    return 'location.state';
  } else {
    return undefined;
  }
};

export const issuerRegionCountries = [CorporateCountry.Canada, CorporateCountry.France, CorporateCountry.UnitedStates];

export const getLicenseIssuerLabel = (countryCode: CorporateCountry | ''): string => {
  if (countryCode === CorporateCountry.Canada || countryCode === CorporateCountry.France) {
    return 'driver.issuingProvince';
  } else if (countryCode === CorporateCountry.UnitedStates) {
    return 'location.subRegionLabel';
  } else if (countryCode === CorporateCountry.Germany) {
    return 'driver.issuingAuth';
  } else if (countryCode === CorporateCountry.Spain) {
    return 'driver.issuedIn';
  } else {
    return 'driver.issuingAuth';
  }
};

export const resetFieldsClearErrors = (
  fields: string[],
  resetField: (field: string) => void,
  clearErrors: (field: string) => void
) => {
  fields.forEach((field: string) => {
    resetField(field);
    clearErrors(field);
  });
};

export const transformLegalIdTypes = (data: LegalIdentificationType[]) => {
  return data?.map((item) => {
    return {
      label: item.name ?? '',
      value: item.code ?? '',
    };
  });
};

export const isValidFullProfile = (values: CreateDriverValues) => {
  const {
    firstName,
    lastName,
    licenseNumber,
    dateOfBirth,
    licenseCountry,
    licenseExpirationDate,
    licenseIssuerRegion,
  } = values;
  const containsFullName = firstName.length > 0 && lastName.length > 0;
  const containsLicense = licenseNumber.length > 0;
  const containsDOB = dateOfBirth && DateTime.fromISO(dateOfBirth).isValid;

  let containsLicenseLocationInfo = DateTime.fromISO(licenseExpirationDate ?? '').isValid;
  if (containsLicenseLocationInfo) {
    const isCanOrUsa = licenseCountry === CorporateCountry.Canada || licenseCountry === CorporateCountry.UnitedStates;
    if (isCanOrUsa && !!licenseIssuerRegion) {
      containsLicenseLocationInfo = true;
    } else if (licenseCountry.length > 0 && !isCanOrUsa) {
      containsLicenseLocationInfo = true;
    }
  }

  return containsFullName && containsLicense && containsDOB && containsLicenseLocationInfo;
};
