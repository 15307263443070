import { useEffect, useRef } from 'react';

/**
 * - When a specific condition (the "when") is true
 * - Only once, despite being sent a true condition multiple times;
 * - Is reset and can happen again if the 'when' is set to false and then true again
 *
 * @param fn The function to execute
 * @param when The condition on which to execute (usually `open` for a modal)
 */
export const useEffectWhen = (fn: () => void | (() => void), when: boolean): void => {
  const last = useRef(false);

  useEffect(() => {
    if (last.current === when) {
      return;
    }
    last.current = when;

    if (when) {
      return fn();
    }
  }, [fn, when]);
};
