import { Box, FormControl, InputLabel, MenuItem, styled } from '@mui/material';
import Select from '@mui/material/Select';
import { Body1, EhiButton, ehiTheme } from '@ehi/ui';

interface SelectStyledProps {
  anchorEl: HTMLElement | null;
}

export const ClearButton = styled(EhiButton)(() => ({
  justifyContent: 'flex-end',
  textTransform: 'uppercase',
  marginBottom: 0,
}));

export const SelectStyled = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'anchorEl',
})<SelectStyledProps>(({ anchorEl }) => ({
  borderRadius: '33px',
  height: '40px',
  width: anchorEl ? `${anchorEl.clientWidth}px` : 'auto',

  '@media (max-width: 800px)': {
    minWidth: '25%',
    paddingRight: '0px',
  },
}));

export const StyledBrandFormControl = styled(FormControl)`
  width: 140px;
  padding: ${ehiTheme.spacing(0, 1)};
`;

export const ContainerBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: ehiTheme.spacing(0, 1, 2, 2),
}));

export const TitleTypography = styled(Body1)(() => ({
  fontWeight: 'bold',
  lineHeight: '24px',
}));

export const ClearButtonContainer = styled(Box)(() => ({
  borderTop: '1px solid #ccc',
  marginTop: 0,
}));

export const MenuItemStyled = styled(MenuItem)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: 0,
}));

export const EmptySelectionContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const SelectedBrandsContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}));

export const CustomBody2 = styled(Body1)(() => ({
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
}));
export const StyledInputLabel = styled(InputLabel)`
  &.Mui-focused {
    color: inherit;
  }
  &.MuiInputLabel-shrink {
    transform: translate(0.875rem, -0.5rem) scale(0.75);
    background: #fff;
    padding: 0 0.25rem;
    z-index: 1;
  }
`;
