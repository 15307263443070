import { FC, useMemo } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { GridContainer } from 'components/shared/ui/styles/Grid.styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { PhoneGridItem } from 'components/flexFlow/driver/createDriver/CreateDriverStyles';
import { SelectField } from 'components/shared/forms/SelectField';
import { CountrySelectField } from 'components/shared/forms/CountrySelectField';
import { Controller, UseFieldArrayReplace, useFormContext } from 'react-hook-form';
import {
  CreateDriverFields,
  CreateDriverValues,
  PhoneFieldNames,
  PhoneFormModel,
} from 'components/flexFlow/driver/createDriver/CreateDriverTypes';
import { PhoneTextField } from 'components/shared/forms/PhoneTextField';
import { PhoneType } from 'services/renter/renterReferenceTypes';
import { useAlert } from 'components/shared/alert/AlertContext';
import { formatPhoneNumber } from 'utils/phoneNumberUtils';
import { isNaCountryCode } from 'utils/locationUtils';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ehiTheme } from '@ehi/ui';
import { parseUrn } from 'utils/urnUtils';
import { SelectedAction } from 'components/shared/alert/AlertDialogTypes';

export type PhoneSubformProps = {
  index: number;
  length: number;
  primaryIndex: number;
  replace: UseFieldArrayReplace<CreateDriverValues, CreateDriverFields.Phone>;
  onRemovePhone: (index: number) => void;
  phoneTypeDomainValues: PhoneType[];
};

export const PhoneSubform: FC<PhoneSubformProps> = ({
  index,
  length,
  primaryIndex,
  replace,
  onRemovePhone,
  phoneTypeDomainValues,
}: PhoneSubformProps) => {
  const { t } = useTranslations();
  const { showAlert } = useAlert();
  const namePrefix = `${CreateDriverFields.Phone}[${index}]`;
  const smallGridSize = useMemo(() => {
    return length > 1 ? 3 : 4;
  }, [length]);
  const smallGridSizeNumber = useMemo(() => {
    return length > 1 ? 3.5 : 4;
  }, [length]);

  const { getValues, watch } = useFormContext();

  const buildSubtitle = (phone: PhoneFormModel): string => {
    let subtitle = '';
    if (phone.type) {
      const phoneTypeDomainVal = phoneTypeDomainValues?.find((value) => value.code === parseUrn(phone?.type));
      subtitle = subtitle.concat(phoneTypeDomainVal?.name ?? '');
      if (phone.number?.trim()) {
        subtitle = subtitle.concat(' - ');
      }
    }
    subtitle = subtitle.concat(
      isNaCountryCode(phone.countryCode) ? formatPhoneNumber(phone.number) : phone.number ?? ''
    );
    return subtitle;
  };

  const confirmRemove = async () => {
    const selectedAction = await showAlert({
      title: t('driver.confirmAlertTitle'),
      description: t('driver.confirmRemovePhoneNumber'),
      subtitle: buildSubtitle(getValues()[CreateDriverFields.Phone][index]),
      primaryActionText: t('driver.remove'),
      secondaryActionText: t('common.cancel'),
    });
    if (selectedAction === SelectedAction.Primary) {
      onRemovePhone(index);
    } else if (selectedAction === SelectedAction.Secondary) {
      return;
    }
  };

  return (
    <GridContainer data-testid={`phone-subform-${index}`} overflow={'hidden'}>
      <PhoneGridItem xs={smallGridSize} sm={smallGridSize} md={3}>
        <SelectField
          name={`${namePrefix}.${PhoneFieldNames.Type}`}
          label={t('driver.type')}
          data-testid={`contact-type-field-${index}`}
          options={
            phoneTypeDomainValues?.map((option) => ({
              label: option.name ?? '',
              value: option.urn ?? '',
            })) || []
          }
          inputProps={{
            'aria-label': `${namePrefix}.${PhoneFieldNames.Type}`,
            'data-testid': `${namePrefix}.${PhoneFieldNames.Type}`,
          }}
          required={!!watch(`${namePrefix}.${PhoneFieldNames.Number}`)}
        />
      </PhoneGridItem>
      <PhoneGridItem xs={smallGridSize} sm={smallGridSize} md={3}>
        <CountrySelectField
          name={`${namePrefix}.${PhoneFieldNames.CountryCode}`}
          label={t('location.countryCode')}
          data-testid={`phone-country-field-${index}`}
          inputProps={{
            'aria-label': `${namePrefix}.${PhoneFieldNames.CountryCode}`,
            'data-testid': `${namePrefix}.${PhoneFieldNames.CountryCode}`,
          }}
          required={!!watch(`${namePrefix}.${PhoneFieldNames.Number}`)}
        />
      </PhoneGridItem>
      <PhoneGridItem xs={smallGridSizeNumber} sm={smallGridSizeNumber} md={3}>
        <PhoneTextField
          name={`${namePrefix}.${PhoneFieldNames.Number}`}
          label={t('driver.phone')}
          data-testid={`phone-number-field-${index}`}
          country={watch(`${namePrefix}.${PhoneFieldNames.CountryCode}`)}
          allowClearing={!!watch(`${namePrefix}.${PhoneFieldNames.Number}`)}
        />
      </PhoneGridItem>
      {length > 1 && (
        <PhoneGridItem
          xs={4}
          sm={2}
          md={2}
          display={'flex'}
          alignItems={'flex-start'}
          paddingTop={0}
          data-testid={`ancillary-phone-section-${index}`}>
          <PhonePriority index={index} initialPrimaryIndex={primaryIndex} replace={replace} />
          <DeleteOutlineIcon
            color={'error'}
            onClick={confirmRemove}
            data-testid={`remove-phone-button-${index}`}
            style={{ padding: ehiTheme.spacing(1) }}
          />
        </PhoneGridItem>
      )}
    </GridContainer>
  );
};

const PhonePriority: FC<{
  index: number;
  initialPrimaryIndex: number;
  replace: UseFieldArrayReplace<CreateDriverValues, CreateDriverFields.Phone>;
}> = ({ index, initialPrimaryIndex, replace }) => {
  const { getValues, control } = useFormContext();
  const { t } = useTranslations();

  const fieldName = `${CreateDriverFields.Phone}[${index}].${PhoneFieldNames.Priority}`;

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field }) => {
        return (
          <FormControlLabel
            label={t('driver.primary')}
            labelPlacement='end'
            {...field}
            control={
              <Radio
                color='primary'
                checked={field.value === 1}
                data-testid={`primary-phone-radio-${index}`}
                onChange={() => {
                  const newFieldArray = [...getValues(CreateDriverFields.Phone)];
                  // set previous primary as this phone's priority
                  newFieldArray[initialPrimaryIndex] = {
                    ...newFieldArray[initialPrimaryIndex],
                    priority: newFieldArray[index].priority,
                  };
                  // set this phone to primary
                  newFieldArray[index] = { ...newFieldArray[index], priority: 1 };
                  // use replace because chained setValues within FieldArray does funky things
                  replace(newFieldArray);
                }}
              />
            }
          />
        );
      }}
    />
  );
};
