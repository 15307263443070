import { FC, ReactNode, useState } from 'react';

import { LoadableView } from 'components/shared/ui/spinner/loadableView/LoadableView';
import ErrorWrapper from 'components/shared/errors/ErrorWrapper';
import {
  AuthIdleTimerModel,
  CountdownTime,
  IdleGuard,
  updateAuthStore,
  updateProfilePictureStore,
  useLogout,
} from '@ehi/auth';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { CALLING_APP_ID } from 'utils/constants';
import { CALLING_APPLICATION } from 'services/headerConstants';
import { isDevBuild, isLocalhost, isRunningInFrame, isSandboxEnvironment } from 'utils/buildInfoUtil';
import { FullScreenSpinner } from 'components/shared/ui/spinner/FullScreenSpinner';
import { getLogoutParams } from 'utils/logoutUtils';
import { useEffectOnlyOnce } from 'hooks/useEffectOnlyonce';
import { isThrownError } from 'utils/errorUtils';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';

type AuthGuardProps = {
  children: ReactNode;
};

export const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING);
  const appConfig = getAppConfigCache();
  const logout = useLogout(getLogoutParams());
  const IDLE_LOGOUT_TIME =
    isDevBuild() ||
    appConfig?.webEnvironment.toUpperCase() === 'INT' ||
    isSandboxEnvironment(appConfig?.webEnvironment) ||
    isRunningInFrame()
      ? 12 * 3600000
      : 900000; // 12 hours for local and INT builds or iframe, otherwise 15 minutes

  useEffectOnlyOnce(async () => {
    if (appConfig) {
      try {
        await updateAuthStore({
          audience: appConfig.audience,
          authWebUrl: new URL(appConfig.externalAppEndpoints.authWeb),
          callingAppId: CALLING_APP_ID,
          ehiCallingApplication: CALLING_APPLICATION,
          environment: appConfig.serviceEndpoints.authProxy.env,
          isDevMode: isLocalhost(),
          port: 3000,
          redirectUrl: appConfig.webEnvironment === 'localhost' ? undefined : new URL(window.location.origin),
        });
        updateProfilePictureStore();
        setLoadingState(LoadingState.SUCCESS);
      } catch (error) {
        if (isThrownError(error)) {
          setLoadingState(LoadingState.ERROR);
        } else {
          // It's expected that auth library rejects and throws an error in the login required case
          setLoadingState(LoadingState.LOADING);
        }
      }
    } else {
      setLoadingState(LoadingState.ERROR);
    }
  });

  return (
    <IdleGuard logoutParams={getLogoutParams()} idleLogoutTimeMs={IDLE_LOGOUT_TIME}>
      <LoadableView loadingComponent={<FullScreenSpinner />} errorComponent={<ErrorWrapper />} state={loadingState}>
        <AuthIdleTimerModel timeout={IDLE_LOGOUT_TIME} countdownTime={CountdownTime.FIVE} logout={logout} />
        {children}
      </LoadableView>
    </IdleGuard>
  );
};
