import { Box } from '@mui/material';
import { FunctionComponent } from 'react';
import { SpacingWrapperProps } from './ReservationRouteTypes';

export const ReservationPageSpacingWrapper: FunctionComponent<SpacingWrapperProps> = ({
  spacingEnabled = true,
  children,
}) => {
  return (
    // Extra bottom margin added to ensure users can get to information on
    // screen without being blocked by floating button (so they can scroll more)
    <>
      {spacingEnabled ? (
        <Box sx={{ marginBottom: (theme) => theme.spacing(17), padding: (theme) => theme.spacing(0, 0, 0, 0) }}>
          {children}
        </Box>
      ) : (
        children
      )}
    </>
  );
};
