import { FC, useCallback, useEffect } from 'react';
import { useOutlet } from 'react-router-dom';
import {
  passErrorToParent,
  passScreenshotToParent,
  useAttachmentContext,
  SCREENSHOT_CAPTURE_REQUEST_MESSAGE,
} from '@ehi/support';
import { IFrameContainer } from 'components/shared/ui/styles/Global.styles';

export const IFrameLayout: FC = () => {
  const outlet = useOutlet();
  const { source, error, generateScreenshot } = useAttachmentContext();

  useEffect(() => {
    if (source && source.length > 0) {
      passScreenshotToParent(source[0]);
    } else if (error) {
      passErrorToParent(error);
    }
  }, [source, error]);

  const receiveMessage = useCallback(
    async (e: MessageEvent) => {
      if (e.data === SCREENSHOT_CAPTURE_REQUEST_MESSAGE) {
        await generateScreenshot();
      }
    },
    [generateScreenshot]
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, true);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [receiveMessage]);

  return <IFrameContainer>{outlet}</IFrameContainer>;
};
