import { ehiTheme } from '@ehi/ui';
import { Card, styled } from '@mui/material';

export const RentalCard = styled(Card)`
  margin: ${ehiTheme.spacing(9, 0, 3)};
  border: 1px solid ${ehiTheme.ehi.palette.border.main};

  &.dateTimeRentalLocation {
    min-height: 36vh;
    @media (max-width: 700px) {
      min-height: 42vh;
    }
  }

  &.dateTimeError {
    min-height: 26vh;
    @media (max-width: 1000px) {
      min-height: 30vh;
    }
  }

  &.rentalLocation {
    min-height: 28vh;
    @media (max-width: 800px) {
      min-height: 32vh;
    }
  }
`;
