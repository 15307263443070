import { useCallback } from 'react';
import { parseUrn } from 'utils/urnUtils';
import { Error002 } from 'components/shared/errors/ErrorCodes';
import { parseBranch } from 'services/location/locationRollupUtils';
import { Location, SetLocationServiceHook } from 'services/location/locationRollupTypes';
import { filterBranches, isECARSOnlyBranch } from 'utils/locationUtils';
import { fetchQuery } from 'context/queryClient/queryUtil';
import { DOMAIN_CACHE_OPTIONS } from 'context/queryClient/cacheOptions';
import _ from 'lodash';
import { QUERY_KEY_LOCATION } from 'services/location/locationCache';
import { fetchBranch, fetchStation } from 'services/location/locationQueries';
import { Station } from 'services/location/locationTypes';
import { EhiCounter } from '@ehi/location';
import { getMultipleBranchDetails } from './locationService';

// remove counters logic with https://enterprisemobility.atlassian.net/browse/GRES-743 and rename this hook appropriately
export const useSetLocationService = (): SetLocationServiceHook => {
  const getLocation = useCallback(async (locationId: string): Promise<Location> => {
    // search for locations
    const branchDetails = await getMultipleBranchDetails([locationId.toUpperCase()]);

    const branches = await Promise.all(
      branchDetails.map((branch) => {
        return fetchBranch(parseUrn(branch.urn));
      })
    );
    const filteredBranches = filterBranches(branches);
    if (filteredBranches.length < 1) {
      return Promise.reject(new Error(Error002));
    }

    // Retrieve counter info for primary station
    // Delete isECARSOnlyBranch method, when we delete the counters functionality.
    let station: Station | undefined;
    if (!isECARSOnlyBranch(filteredBranches[0]) && filteredBranches[0].stationId !== undefined) {
      station = await fetchStation(filteredBranches[0].stationId);
    }
    const counters = _(station?.counters)
      .uniqBy((counter) => counter.ordinal)
      .map((counter) => {
        return { description: counter.name ?? '', counterId: counter.ordinal ?? '' };
      })
      .sort((a: EhiCounter, b: EhiCounter) => {
        return a?.counterId.localeCompare(b?.counterId) || 0;
      })
      .valueOf();

    const location = parseBranch(filteredBranches[0], counters);

    return {
      location,
      defaultCounter: counters.length > 0 ? counters[0] : undefined,
    };
  }, []);

  const queryLocation = async (locationId: string) => {
    return fetchQuery({
      queryKey: [QUERY_KEY_LOCATION, locationId?.toUpperCase()],
      queryFn: () => getLocation(locationId),
      ...DOMAIN_CACHE_OPTIONS,
    });
  };

  return {
    getLocation: getLocation,
    queryLocation: queryLocation,
  };
};
