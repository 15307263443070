import { useCallback, useState } from 'react';
import { searchBranches } from 'services/location/locationService';
import {
  BranchLookupFields,
  BranchLookupValues,
  SearchByType,
} from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupTypes';
import { useAlert } from 'components/shared/alert/AlertContext';
import { logError } from 'components/shared/logger/splunkLogger';
import { ErrorSeverity } from '@ehi/analytics';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { BranchLookupCard } from 'components/shared/uiModels/branchLookup/branchLookupDataTypes';
import {
  BranchDetailsSearchCriteria,
  BranchPhase,
  BranchSearchResults,
  BranchTypeSearch,
  Brand,
  DistanceMeasurementMethod,
  DistanceUnitOfMeasure,
  MatchType,
} from 'services/location/locationTypes';
import { transformBranchLookupData } from 'components/shared/uiModels/branchLookup/branchLookupTransformer';
import { adjustSearchRequestForLocationTypes, getLocationTypeUrns } from 'utils/branchLookupUtils';

const MAX_PAGE_SIZE = 50;

export type UseBranchSearchReturn = {
  isLoading: boolean;
  executeSearch: () => Promise<BranchLookupCard[]>;
};

const buildRequestBody = (values: BranchLookupValues): BranchDetailsSearchCriteria => {
  const mappedBrands = values[BranchLookupFields.Brands] as Brand[];

  const searchRequestObject: BranchDetailsSearchCriteria = {
    country: values[BranchLookupFields.Country],
    paginationCriteria: {
      pageSize: MAX_PAGE_SIZE,
    },
    branchPhase: BranchPhase.OPEN,
    branchTypes: [BranchTypeSearch.DAILY_RENTAL, BranchTypeSearch.TRUCK_RENTAL],
    brand: mappedBrands,
  };

  const searchType = values[BranchLookupFields.SearchBy];
  const searchInput = values[BranchLookupFields.SearchInputValue];

  if (searchType === SearchByType.Keyword) {
    searchRequestObject.tag = {
      searchText: searchInput.toUpperCase(),
      matchType: MatchType.FUZZY,
    };
  }

  if (searchType === SearchByType.PostalCode) {
    searchRequestObject.geographical = {
      distanceMeasurementMethod: DistanceMeasurementMethod.DRIVING_DISTANCE,
      postalCode: searchInput,
      searchRadius: {
        distance: parseInt(values[BranchLookupFields.Distance], 10),
        unit: DistanceUnitOfMeasure.MILES,
      },
      countryCode: values[BranchLookupFields.Country],
    };
  }

  if (values[BranchLookupFields.LocationTypes].length) {
    searchRequestObject.operationalLocationTypes = getLocationTypeUrns(
      values[BranchLookupFields.LocationTypes].filter((type) => type.code !== 'HOME_CITY')
    );
  }

  const types = values[BranchLookupFields.LocationTypes];

  // Adjust the search request object based on location types
  adjustSearchRequestForLocationTypes(searchRequestObject, types);

  return searchRequestObject;
};

export const useFetchBranches = (values: BranchLookupValues): UseBranchSearchReturn => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showAlert } = useAlert();

  const handleUnexpectedError = useCallback((error: unknown) => {
    logError({
      error: {
        message: 'Unexpected error occurred during branch search',
        supportInformation: {
          serviceError: error,
        },
      },
      severity: ErrorSeverity.Fatal,
    });
  }, []);

  const executeSearch = useCallback(async () => {
    setIsLoading(true);
    try {
      if (values[BranchLookupFields.SearchBy] === SearchByType.PostalCode) {
        // The Location API has a cap of 25 results for Geographical Searches.
        // This will make to two calls if the initial Geo Search has over 25 results
        return await Promise.all<BranchLookupCard[]>(
          await searchBranches(buildRequestBody(values)).then(async (value: BranchSearchResults) => {
            if (value.pagination?.nextCursor && value.branches && value.branches?.length < MAX_PAGE_SIZE) {
              const response = await searchBranches({
                ...buildRequestBody(values),
                paginationCriteria: {
                  pageSize: MAX_PAGE_SIZE,
                  nextCursor: value.pagination.nextCursor,
                },
              });
              return [...transformBranchLookupData(value.branches), ...transformBranchLookupData(response.branches)];
            } else {
              return transformBranchLookupData(value.branches);
            }
          })
        );
      } else {
        const response = await searchBranches(buildRequestBody(values));
        return transformBranchLookupData(response.branches);
      }
    } catch (err) {
      const errorMessage = (err as EhiErrors)?.errors?.[0]?.localizedMessage;
      if (errorMessage) {
        await showAlert({
          title: 'Error',
          description: errorMessage,
        });
      } else {
        handleUnexpectedError(err);
      }
      return [];
    } finally {
      setIsLoading(false);
    }
  }, [values, showAlert, handleUnexpectedError]);

  return {
    isLoading,
    executeSearch,
  };
};
