/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * Street address.
 *
 * At least one field must not be empty.
 */
export interface Address {
  /**
   * First line of address will be uppercased, and leading and trailing whitespace will be removed.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ???? LADUE ????
   * @maxLength 35
   * @example "8844 LADUE ROAD"
   */
  addressLine1?: string;
  /**
   * Second line of address will be uppercased, and leading and trailing whitespace will be removed.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ????E????
   * @maxLength 35
   * @example "SUITE 200"
   */
  addressLine2?: string;
  /**
   * Third line of address will be uppercased, and leading and trailing whitespace will be removed.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   * @maxLength 35
   */
  addressLine3?: string;
  /**
   * Fourth line of address will be uppercased, and leading and trailing whitespace will be removed.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   * @maxLength 35
   */
  addressLine4?: string;
  /**
   * Name of city will be uppercased, and leading and trailing whitespace will be removed.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ?????????
   * @maxLength 25
   * @example "LADUE"
   */
  city?: string;
  /**
   * URN to country subdivision.
   *
   * URN environment must match system environment.
   *
   * URN value must be a valid reference value from Location Services.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: urn:com.ehi:xqa:reference:location:countrySubdivision:???
   */
  countrySubdivision?: ProtectedCountrySubdivision;
  /**
   * URN to country.
   *
   * URN environment must match system environment.
   *
   * URN value must be a valid reference value from Location Services.
   */
  country?: Country;
  /**
   * Postal code will be uppercased, and leading and trailing whitespace will be removed.
   *
   * `postalCode` is validated by `country`:
   *
   * | Country          | Format                 | Example             |
   * | ---------------- | ---------------------- | ------------------- |
   * | USA              | ##### or #####-####    | 73044 or 73044-4055 |
   * | CAN              | A#A #A#                | M4B 1G5             |
   * | Other or blank   | alphas, digits, dashes | ABC-123123          |
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ?????????
   * @maxLength 10
   * @example "63124"
   */
  postalCode?: string;
}

/**
 * Birth information.
 *
 * One of `date`, `city`, or `country` must not be empty.
 */
export interface BirthInformation {
  /**
   * Date of birth in ISO-8901 format (YYYY-MM-DD).
   *
   * Date must be valid, must not fall in the future, and must fall between 16 and 99 years ago.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Cannot be removed.
   * Masked example: ????-??-22
   * @example "1990-02-22"
   */
  date?: string;
  /**
   * Birth city will be uppercased, and leading and trailing whitespace will be removed.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ?????????
   * Cannot be removed
   * @maxLength 35
   * @example "Albuquerque"
   */
  city?: string;
  /**
   * URN to country.
   *
   * URN environment must match system environment.
   *
   * URN value must be a valid reference value from Location Services.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: urn:com.ehi:xqa:reference:location:country:????
   */
  country?: ProtectedCountry;
}

/**
 * URN to context.
 *
 * URN environment must match system environment.
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z]+:rental.(booking:reservation|fulfillment:rentalAgreement):[a-zA-Z0-9]{1,}$
 * @example "urn:com.ehi:xqa:rental.booking:reservation:12345678"
 */
export type ContextURN = string;

/** Workflow. */
export enum Workflow {
  RESERVATION = 'RESERVATION',
  RENTAL_AGREEMENT = 'RENTAL_AGREEMENT',
}

/**
 * URN to lockContext.
 *
 * URN environment must match system environment.
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:renter:driverProfile:[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$
 * @example "urn:com.ehi:xqa:renter:driverProfile:bd1180d1-4209-4dee-bca3-09d563bfd0c5"
 */
export type LockContextURN = string;

/**
 * URN to country.
 *
 * URN environment must match system environment.
 *
 * URN value must be a valid reference value from Location Services.
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:country:[A-Z]{3}$
 * @example "urn:com.ehi:xqa:reference:location:country:USA"
 */
export type Country = string;

/**
 * URN to country.
 *
 * URN environment must match system environment.
 *
 * URN value must be a valid reference value from Location Services.
 *
 * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
 *
 * Masked example: urn:com.ehi:xqa:reference:location:country:????
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:country:(?:[A-Z]{3}|?{4})$
 * @example "urn:com.ehi:xqa:reference:location:country:USA"
 */
export type ProtectedCountry = string;

/**
 * URN to country subdivision.
 *
 * URN environment must match system environment.
 *
 * URN value must be a valid reference value from Location Services.
 *
 * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
 *
 * Masked example: urn:com.ehi:xqa:reference:location:countrySubdivision:???
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:countrySubdivision:(?:[A-Z]{1,3}|?{3})$
 * @example "urn:com.ehi:xqa:reference:location:countrySubdivision:NM"
 */
export type ProtectedCountrySubdivision = string;

export interface DriversLicense {
  /**
   * License number will be uppercased.
   *
   * Characters must be alphanumeric or star - other characters will be removed.
   *
   * `number` must not be blacklisted.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ????2777
   * @maxLength 25
   * @example "F109283742777"
   */
  number?: string;
  /**
   * Issue date in ISO-8601 date format (YYYY-MM-DD).
   *
   * `issueDate` must not fall after current date.
   * If `issueDate` is not empty and `expirationDate` is not empty, `issueDate` must fall before `expirationDate`.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ????-??-22
   * @example "2021-02-22"
   */
  issueDate?: string;
  /**
   * Expiration date in ISO-8601 date format (YYYY-MM-DD).
   *
   * `expirationDate` must not fall before current date.
   * If `issueDate` is not empty and `expirationDate` is not empty, `issueDate` must fall before `expirationDate`.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ????-??-22
   * @example "2021-02-22"
   */
  expirationDate?: string;
  /**
   * City of Issue will be uppercased, and leading and trailing whitespace will be removed.
   * @maxLength 35
   * @example "San Diego"
   */
  issuingAuthority?: string;
  /**
   * URN to country subdivision.
   *
   * URN environment must match system environment.
   *
   * URN value must be a valid reference value from Location Services.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: urn:com.ehi:xqa:reference:location:countrySubdivision:???
   */
  countrySubdivision?: ProtectedCountrySubdivision;
  /**
   * URN to country.
   *
   * URN environment must match system environment.
   *
   * URN value must be a valid reference value from Location Services.
   */
  country?: Country;
  /**
   * Classification of driver license
   * @maxLength 3
   * @example "C"
   */
  classification?: string;
}

export interface EmailAddress {
  /**
   * Email address will be lowercased.
   *
   * `emailAddress` must not be blacklisted.
   *
   * If email addresses are not unique, only the highest priority entry will persist.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: e?????r@example.com
   *
   * **Note: Some existing email addresses might not follow this pattern.**
   * @maxLength 80
   * @example "exampleuser@example.com"
   */
  emailAddress: string;
  /**
   * Ordinal describing priority of email.
   *
   * If one `emailAddress` is provided, `priority` defaults to 1. If more than one `emailAddress` is provided, `priority` must not be empty and must be unique. Unique priorities not sequenced starting from 1 will be resequenced starting from 1.
   * @min 1
   * @max 9
   * @example 1
   */
  priority?: number;
}

/**
 * EmployeeID
 * @maxLength 30
 * @pattern ^[a-zA-Z0-9]+$
 * @example "E123RS"
 */
export type EmployeeIdentificationNumber = string;

/** Lock data */
export interface Lock {
  /**
   * URN to lockContext.
   *
   * URN environment must match system environment.
   */
  lockContextUrn?: LockContextURN;
  /** @format date-time */
  lockDateTime?: string;
}

/** Name of an individual */
export interface Name {
  /**
   * Title will be uppercased, and leading and trailing whitespace will be removed.
   * @maxLength 35
   * @example "Mr."
   */
  title?: string;
  /**
   * Given name will be uppercased, and leading and trailing whitespace will be removed. Updatable. Not removable.
   * @minLength 2
   * @maxLength 35
   * @pattern [^*0-9%_]*
   * @example "John"
   */
  givenName?: string;
  /**
   * Last name (family name) will be uppercased, and leading and trailing whitespace will be removed. Updatable. Not removable.
   * @minLength 2
   * @maxLength 60
   * @pattern [^*0-9%_]*
   * @example "Smith"
   */
  surname: string;
  /**
   * Suffix will be uppercased, and leading and trailing whitespace will be removed.
   * @maxLength 35
   * @example "Sr."
   */
  suffix?: string;
}

/** PhoneNumber uniqueness is determined by the combination of type, number and country. If PhoneNumbers are not unique, only the highest priority entry will persist. */
export interface PhoneNumber {
  /**
   * Phone Type URN.
   *
   * URN environment must match system environment.
   *
   * URN value must be a valid domain reference value.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:phoneType:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:renter:phoneType:HOME"
   */
  type?: string;
  /**
   * Phone number in E.164 format.
   *
   * `number` must be numeric, must not be the same repeated digit, and must not be blacklisted.
   *
   * If `country` is 'USA' or 'CAN', `number` must have length of 10 digits.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ????0665
   * @pattern ^[0-9?]+$
   * @example "3149843012"
   */
  number: string;
  /**
   * Ordinal describing priority of phone number.
   *
   * If one `phoneNumber` is provided, `priority` defaults to 1. If more than one `phoneNumber` is provided, `priority` must not be empty and must be unique. Unique priorities not sequenced starting from 1 will be resequenced starting from 1.
   * @min 1
   * @max 4
   * @example 1
   */
  priority?: number;
  /**
   * URN to country.
   *
   * URN environment must match system environment.
   *
   * URN value must be a valid reference value from Location Services.
   */
  country?: Country;
  /**
   * Phone number extension.
   *
   * `extension` must be numeric.
   * @maxLength 6
   * @pattern ^[0-9]*$
   * @example "5"
   */
  extension?: string;
  /** Denotes whether phone number supports SMS */
  supportsSMS?: boolean;
}

/**
 * URN to transactional profile
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:people:transactionalProfile:[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$
 * @example "urn:com.ehi:xqa:people:transactionalProfile:e014e1ab-c820-458d-a0ac-143b2c84c19b"
 */
export type TransactionalProfileURN = string;

/**
 * UUID of profile
 * @pattern ^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$
 * @example "e014e1ab-c820-458d-a0ac-143b2c84c19b"
 */
export type TransactionalProfileUuid = string;

/** Personal information for profile */
export interface PersonalInformation {
  /** Name of an individual */
  name?: Name;
  /**
   * Birth information.
   *
   * One of `date`, `city`, or `country` must not be empty.
   */
  birthInformation?: BirthInformation;
}

/**
 * Contact information for individual.
 *
 * One of `address`, `emailAddresses`, or `phoneNumbers` must not be empty.
 */
export interface ContactInformation {
  /**
   * Street address.
   *
   * At least one field must not be empty.
   */
  address?: Address;
  /**
   * @maxItems 9
   * @minItems 1
   */
  emailAddresses?: EmailAddress[];
  /**
   * @maxItems 4
   * @minItems 1
   */
  phoneNumbers?: PhoneNumber[];
}

/** Identifying document. */
export interface LegalIdentification {
  driversLicense: DriversLicense;
}

/** Lock transactional profile based on contextUrn */
export interface LockTransactionalProfile {
  /**
   * URN to lockContext.
   *
   * URN environment must match system environment.
   */
  lockContextUrn: LockContextURN;
}

/** At least one property must not be empty. */
export interface TransactionalProfileBase {
  /** Personal information for profile */
  personalInformation?: PersonalInformation;
  /**
   * Contact information for individual.
   *
   * One of `address`, `emailAddresses`, or `phoneNumbers` must not be empty.
   */
  contactInformation?: ContactInformation;
  /** Identifying document. */
  legalIdentification?: LegalIdentification;
}

/**
 * Describe which properties in driver profile to create.
 * Request must not contain masking characters.
 * If `personalInformation.birthInformation.date` is not empty and `legalIdentification.driverLicense.issueDate` is not empty, `personalInformation.birthInformation.date` must fall before `legalIdentification.driverLicense.issueDate`.
 */
export type TransactionalProfileRequestContent = {
  /**
   * URN to context.
   *
   * URN environment must match system environment.
   */
  contextUrn?: ContextURN;
  /** Workflow. */
  workflow: Workflow;
} & TransactionalProfileBase;

/** State of profile after created or updated. */
export type TransactionalProfileResponseContent = {
  /** UUID of profile */
  uuid?: TransactionalProfileUuid;
  /** URN to transactional profile */
  urn?: TransactionalProfileURN;
  /**
   * URN to context.
   *
   * URN environment must match system environment.
   */
  contextUrn?: ContextURN;
  /** Workflow. */
  workflow?: Workflow;
} & TransactionalProfileBase;

/** Describe which properties in transactional profile to retrieve. */
export type RetrieveTransactionalProfileResponseContent = TransactionalProfileResponseContent & Lock;

export interface Errors {
  errors: Error[];
}

export interface Error {
  /**
   * A unique id for the particular error/validation.
   * This is **NOT** an overall response HTTP status code.
   * This allows callers to reference the error and potentially provide custom behaviors (such as custom error messages in a GUI) if needed.
   * @example "FOO123"
   */
  code: string;
  /** The locale in [ISO 639-3](https://iso639-3.sil.org/) for language and  [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html) for country code */
  locale: Locale;
  /**
   * A human readable string describing the error which can be shown to an end-user.
   * This SHOULD be translated based on the locale in the request.
   * If the requested locale does not have a translation, the service SHOULD return the closest  available translation based on language, then country.
   * @example "Last name is required"
   */
  localizedMessage: string;
  /**
   * A list of references to the specific element/field related to the error.
   * This may be useful to distinguish to which field(s) an error applies, as a single code could be used for multiple fields.
   * @example ["/user/lastname"]
   */
  paths?: string[];
  /**
   * The severity of the message.
   * @example "ERROR"
   */
  severity: 'INFO' | 'WARNING' | 'ERROR';
  /**
   * Any additional debugging details which could be useful by a developer.
   * This SHOULD never be shown to a user and MUST NOT include stack traces or convey sensitive details of the service.
   * @example "Example text for debugging"
   */
  supportInformation?: string;
}

/**
 * The locale in [ISO 639-3](https://iso639-3.sil.org/) for language and  [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html) for country code
 * @pattern ^[a-z]{3}-[A-Z]{3}$
 * @example "eng-USA"
 */
export type Locale = string;

/**
 * This is how an X-B3-SpanId should look when used in a header or parameter.
 * This must be a string of 16 lower case hex characters representing a unique 64 bit integer.
 * @pattern ^[a-f0-9]{16}$
 * @example "48bed08e521e46e4"
 */
export type XB3SpanId = string;

/**
 * This is how an X-B3-TraceId should look when used in a header or parameter.
 * This must be a string of 16 or 32 lower case hex characters representing a unique 64 or 128 bit integer.
 * @pattern ^([a-f0-9]{16}|[a-f0-9]{32})$
 * @example "9815106142d4405980e8848b16130a7d"
 */
export type XB3TraceId = string;

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'https://{env}.api.ehi.dev';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Transactional Profile
 * @version 1.6.0
 * @license Enterprise Holdings, Inc. API Marketplace Terms of Service
 * @baseUrl https://{env}.api.ehi.dev
 * @contact Renter Services <RenterServicesSupport@ehi.com>
 *
 * API to manage transactional profiles
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  people = {
    /**
     * @description Create new profile.
     *
     * @tags Transactional Profile
     * @name CreateTransactionalProfile
     * @summary Create Transactional Profile.
     * @request POST:/people/transactionalProfile
     */
    createTransactionalProfile: (data: TransactionalProfileRequestContent, params: RequestParams = {}) =>
      this.request<TransactionalProfileResponseContent, Errors>({
        path: `/people/transactionalProfile`,
        method: 'POST',
        body: data,
        ...params,
      }),

    /**
     * @description Get Transactional Profile by UUID.
     *
     * @tags Transactional Profile
     * @name GetTransactionalProfileByUuid
     * @summary Get Transactional Profile by UUID.
     * @request GET:/people/transactionalProfile/{uuid}
     */
    getTransactionalProfileByUuid: (uuid: TransactionalProfileUuid, params: RequestParams = {}) =>
      this.request<RetrieveTransactionalProfileResponseContent, void | Errors>({
        path: `/people/transactionalProfile/${uuid}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Modify existing transactional profile.
     *
     * @tags Transactional Profile
     * @name ModifyTransactionalProfile
     * @summary Update Transactional Profile.
     * @request PUT:/people/transactionalProfile/{uuid}
     */
    modifyTransactionalProfile: (
      uuid: TransactionalProfileUuid,
      data: TransactionalProfileRequestContent,
      params: RequestParams = {}
    ) =>
      this.request<TransactionalProfileResponseContent, Errors>({
        path: `/people/transactionalProfile/${uuid}`,
        method: 'PUT',
        body: data,
        ...params,
      }),

    /**
     * @description Locks the existing profile.
     *
     * @tags Transactional Profile
     * @name LockTransactionalProfile
     * @summary Locks the profile for a given context.
     * @request POST:/people/transactionalProfile/{uuid}/lock-transactional-profile
     */
    lockTransactionalProfile: (
      uuid: TransactionalProfileUuid,
      data: LockTransactionalProfile,
      params: RequestParams = {}
    ) =>
      this.request<Lock, Errors>({
        path: `/people/transactionalProfile/${uuid}/lock-transactional-profile`,
        method: 'POST',
        body: data,
        ...params,
      }),
  };
}
