import { FunctionComponent } from 'react';
import { useTranslations } from 'components/shared/i18n';
import DynamicHelmet from 'components/shared/helmet/DynamicHelmet';
import { Outlet } from 'react-router-dom';

export const ModifyRouter: FunctionComponent = () => {
  const { t } = useTranslations();
  return (
    <>
      <DynamicHelmet title={t('common.modifyReservation')} />
      <Outlet />
    </>
  );
};
