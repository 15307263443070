import WarningIcon from '@mui/icons-material/Warning';
import { FunctionComponent } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { StyledWarningBanner } from 'components/flexFlow/driver/renterWarning/RenterWarningDialogStyles';
import { Alert } from '@mui/material';

interface RenterWarningProps {
  warningMessage: string;
}

export const WarningBanner: FunctionComponent<RenterWarningProps> = ({ warningMessage }) => {
  const { t } = useTranslations();

  return (
    <StyledWarningBanner data-testid={'renterWarningBanner'}>
      <Alert
        severity='warning'
        sx={{
          border: (theme) => `1px solid ${theme.palette.warning.main}`,
          display: 'flex',
          alignItems: 'center',
        }}
        icon={<WarningIcon />}>
        <span style={{ fontWeight: 'bold' }}>{`${t('error.warning')}: `}</span>
        {warningMessage}
      </Alert>
    </StyledWarningBanner>
  );
};
