import { ehiTheme, H4, H6 } from '@ehi/ui';
import {
  NoResultsContainer,
  StyledDescription,
  StyledLink,
} from 'components/shared/ui/noResultsView/NoResultsViewStyles';
import { FC } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { Trans } from 'react-i18next';

export const BASE_URL = '../';

type NoResultsViewType = {
  noResultsTitle: string;
  noResultsDescription?: string;
  handleNavigate?: () => void;
  isBackgroundColor?: boolean;
  // uses to differentiate page vs sub page title
  // notes: Branch lookup modal has bigger font vs sub pages like Vehicle, Driver, PEO
  pageTitle?: boolean;
};

export const NoResultsView: FC<NoResultsViewType> = ({
  noResultsTitle,
  noResultsDescription,
  isBackgroundColor = true,
  handleNavigate,
  pageTitle,
}: NoResultsViewType) => {
  const { t } = useTranslations();
  return (
    <NoResultsContainer className={isBackgroundColor ? 'isBackgroundColor' : ''}>
      {pageTitle ? (
        <H4 color={ehiTheme.palette.text.disabled} data-testid={'noResultsTitle'}>
          {t(noResultsTitle)}
        </H4>
      ) : (
        <H6 color={ehiTheme.palette.text.disabled} data-testid={'noResultsTitle'}>
          {t(noResultsTitle)}
        </H6>
      )}
      {noResultsDescription && (
        <StyledDescription data-testid={'noResultsDescription'}>
          <Trans
            i18nKey={noResultsDescription}
            components={{
              Link: <StyledLink data-testid={'navigationLink'} onClick={handleNavigate} />,
            }}
          />
        </StyledDescription>
      )}
    </NoResultsContainer>
  );
};
