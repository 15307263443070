import {
  TransactionalProfileRequestContent,
  Workflow,
} from 'services/renter/transactionalProfile/transactionalProfileTypes';

export const createQuickResProfileRequestBody = (
  lastName: string,
  firstName: string,
  phone?: string
): TransactionalProfileRequestContent => {
  return {
    workflow: Workflow.RESERVATION,
    personalInformation: {
      name: {
        givenName: firstName,
        surname: lastName,
      },
    },
    contactInformation: phone
      ? {
          phoneNumbers: [{ number: phone }],
        }
      : undefined,
  };
};
