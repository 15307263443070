import { FlexFlowCard } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import CardContent from '@mui/material/CardContent';
import { ProtectionsTab } from 'components/flexFlow/peo/ProtectionsTab';
import { EquipmentTab } from 'components/flexFlow/peo/EquipmentTab';
import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { Tab, Tabs } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { createElement, FunctionComponent, useCallback } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useQueryParamsByKey } from 'utils/routing/routeUtils';
import { Box } from '@mui/system';
import { pageContainerBackground } from 'components/shared/ui/styles/Global.styles';
import { selectAddons, selectIsReadOnlyFlow, selectVehicleClassSelection } from 'redux/selectors/bookingEditor';
import { useAppSelector } from 'redux/hooks';
import { useSaveOnNavigateHelper } from 'hooks/useSaveOnNavigateHelper';
import { RouterPaths } from 'app/router/RouterPaths';
import { StyledPeoContainer } from './Peo.styles';
import { BASE_URL, NoResultsView } from 'components/shared/ui/noResultsView/NoResultsView';

export const PEO_TAB_KEY = 'peoTab';

export enum TabNames {
  Protections = 'ProtectionsTab',
  Equipment = 'EquipmentTab',
}

type TabPanelProps = {
  isActiveTab: boolean;
  tabName: string;
  component: FunctionComponent;
  props?: any;
};

const PeoTabPanel = ({ isActiveTab, tabName, component, props }: TabPanelProps) => {
  return isActiveTab ? (
    <Box
      height='90%'
      style={{ overflowY: 'scroll', marginTop: '1.5rem' }}
      role='tabpanel'
      hidden={!isActiveTab}
      id={tabName}
      aria-labelledby={tabName}>
      {createElement(component, props)}
    </Box>
  ) : (
    <></>
  );
};

export const AddOns: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { saveOnNavigate } = useSaveOnNavigateHelper();
  const { peoTab = '' } = useQueryParamsByKey([PEO_TAB_KEY]);
  const addOns = useAppSelector(selectAddons);
  const vehicleClassSelection = useAppSelector(selectVehicleClassSelection);
  const isResViewOnly = useAppSelector(selectIsReadOnlyFlow);
  const protectionsCount = addOns?.protections?.selected?.length;
  const equipmentCount = addOns?.equipment?.selected?.length;

  const tabPages = [
    {
      title: `${t('peo.protections')} (${protectionsCount ?? 0})`,
      component: ProtectionsTab,
      value: TabNames.Protections,
    },
    {
      title: `${t('peo.equipment')} (${equipmentCount ?? 0})`,
      component: EquipmentTab,
      value: TabNames.Equipment,
    },
  ];

  const handleTabValueChange = useCallback(
    (_: any, newTab: string) => {
      const searchParams: URLSearchParams = createSearchParams(search);
      searchParams.set(PEO_TAB_KEY, newTab);
      saveOnNavigate({ search: searchParams.toString() }, { replace: true });
    },
    [saveOnNavigate, search]
  );

  const isNoResults = !isResViewOnly && !vehicleClassSelection?.preferred;

  return (
    <FlexFlowCard loadingState={mapLoadingState(false, false)} sx={{ backgroundColor: pageContainerBackground }}>
      <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
      <CardContent>
        <StyledPeoContainer className={isNoResults ? 'peoNiResults' : ''}>
          {isNoResults ? (
            <NoResultsView
              noResultsTitle='peo.unableToDisplay'
              noResultsDescription='peo.noResultsDescription'
              handleNavigate={() => {
                navigate(BASE_URL + RouterPaths.Vehicle);
              }}
            />
          ) : (
            <>
              <Tabs
                value={peoTab}
                onChange={handleTabValueChange}
                indicatorColor='primary'
                textColor='primary'
                variant={'fullWidth'}>
                {tabPages.map((page) => (
                  <Tab
                    label={page.title}
                    key={page.title}
                    value={page.value}
                    data-testid={`dialog-tab-${page.title}`}
                  />
                ))}
              </Tabs>

              {tabPages.map((page) => (
                <PeoTabPanel
                  isActiveTab={peoTab === page.value}
                  tabName={page.value}
                  component={page.component}
                  key={page.value}
                />
              ))}
            </>
          )}
        </StyledPeoContainer>
      </CardContent>
    </FlexFlowCard>
  );
};
