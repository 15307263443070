import { Box, Grid } from '@mui/material';
import { Body1, ehiTheme } from '@ehi/ui';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { StyledWarningIcon } from 'components/shared/ui/styles/WarningIcon.styles';
import { formatName } from 'services/renter/driverTransformer';
import { formatPhoneNumber } from 'utils/phoneNumberUtils';
import { mask } from 'utils/maskUtils';
import { parseUrn } from 'utils/urnUtils';
import { toDateTimeString } from 'utils/dateUtils';
import { LoyaltyProgramLogo } from 'components/shared/ui/logos/LoyaltyProgramLogo';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';
import { FC, useMemo } from 'react';
import { useLocale, useTranslations } from 'components/shared/i18n';
import { PhoneType } from 'services/renter/renterReferenceTypes';
import { getItemRates } from 'utils/peoUtils';
import { getCountry, getSubdivision } from 'utils/countryUtils';
import { useCountriesQuery, useSubdivisionsQuery } from 'services/location/locationQueries';

type DriverCardProps = {
  driver: DriverData;
  phoneTypeDomain: PhoneType[] | undefined;
  isAdditionalDriver?: boolean;
};

export const DriverCard: FC<DriverCardProps> = ({ driver, phoneTypeDomain, isAdditionalDriver }): JSX.Element => {
  const { t } = useTranslations();
  const { locale } = useLocale();
  const { data: countries } = useCountriesQuery();
  const { data: subDivisions } = useSubdivisionsQuery(parseUrn(driver?.legalIdentification?.country) ?? '');
  const phoneType = useMemo(() => {
    return phoneTypeDomain?.find((phone) => phone.code === driver?.phone?.type)?.name;
  }, [driver?.phone?.type, phoneTypeDomain]);
  const containsDriverAddress = useMemo(() => {
    if (!driver.address) {
      return false;
    }

    let containsAddressInfo = false;
    for (const key in Object.keys(driver.address)) {
      if (Object.values(driver.address)[key].length > 0) {
        containsAddressInfo = true;
        break;
      }
    }

    return containsAddressInfo;
  }, [driver.address]);

  return (
    <Box display={'flex'} flexDirection={'column'} data-testid={'flexiFlow-driver-card'}>
      <Grid container spacing={2}>
        {driver?.lastName && (
          <Grid item xs={6} sm={6} data-testid={'driver-contact-flex-flow'}>
            <Caption2 data-testid={'driver-contact-label'}>{t('driver.contact')}</Caption2>
            <Body1 bold data-testid={'driver-name'}>
              {/* Note: get driver profile doesn't return returnWarningFlag, so look for renterWarning object */}
              {driver?.renterWarning && (
                <StyledWarningIcon data-testid='renterWarningIcon' sx={{ paddingTop: ehiTheme.spacing(0.2) }} />
              )}
              {formatName(driver?.lastName, driver?.firstName) ?? '--'}
            </Body1>
            <Body1 data-testid={'driver-profile-created'}>{`${t('driver.profileCreated')}: ${
              toDateTimeString(driver.creationDate, t('format.date')) ?? ''
            }`}</Body1>
            {phoneType && (
              <Body1 data-testid={'driver-phone-number'}>
                {`${phoneType}: ${formatPhoneNumber(driver?.phone?.number)}`}
              </Body1>
            )}
            <Body1 data-testid={'driver-email'} style={{ wordBreak: 'break-all' }}>{`${t('driver.email')}: ${
              driver?.email ?? ''
            }`}</Body1>
          </Grid>
        )}
        {containsDriverAddress && (
          <Grid item xs={6} sm={6} data-testid={'driver-address-flex-flow'}>
            <Caption2 data-testid={'driver-address-label'}>{t('driver.address')}</Caption2>
            {driver.address?.lines &&
              driver.address.lines.map((line: string, index: number) => {
                return (
                  <Body1 key={line} data-testid={`driver-address-line-${index + 1}`}>
                    {line}
                  </Body1>
                );
              })}
            <Body1 data-testid={'driver-address-info'}>
              {` ${
                !!driver.address?.city && driver.address?.city.length > 0
                  ? driver.address?.city + ','
                  : driver.address?.city
              } ${driver.address?.countrySubdivisionCode} ${driver.address?.postalCode} ${driver.address?.countryCode}`}
            </Body1>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} paddingTop={ehiTheme.spacing(2)}>
        {driver?.legalIdentification && (
          <Grid item xs={6} sm={6} data-testid={'driver-license-flex-flow'}>
            <Caption2 data-testid={'driver-license-label'}>{t('driver.license')}</Caption2>
            <Body1 data-testid={'driver-license-number'}>{mask(driver.legalIdentification.number, 4)}</Body1>
            <Body1 data-testid={'driver-license-subdivision'}>
              {getSubdivision(subDivisions, driver.legalIdentification.countrySubdivision)?.name}
            </Body1>
            <Body1 data-testid={'driver-license-country'}>
              {getCountry(countries, parseUrn(driver.legalIdentification.country))?.name}
            </Body1>
            <Body1 display='inline' data-testid={'driver-license-exp-date'}>
              {`${t('driverSearch.dlExp')} ${
                toDateTimeString(driver.legalIdentification.expirationDate, t('format.date')) ?? ''
              }`}
            </Body1>
            <br />
            <Body1 display='inline' data-testid={'driver-dob-date'}>
              {`${t('driverSearch.dob')} ${toDateTimeString(driver?.birthInformation?.date, t('format.date')) ?? ''}`}
            </Body1>
          </Grid>
        )}
        {driver?.loyaltyType && (
          <Grid item xs={6} sm={6} data-testid={'driver-loyalty-flex-flow'}>
            <Caption2 data-testid={'driver-loyalty-label'}>{t('driver.loyalty')}</Caption2>
            <Body1 data-testid={'driver-loyalty-type'}>{driver.loyaltyProgram?.name}</Body1>
            <Body1 data-testid={'driver-loyalty-logo'}>
              <LoyaltyProgramLogo membershipProgram={driver.loyaltyType} height={24} width={64} />
            </Body1>
            <Body1 data-testid={'driver-loyalty-number'}>{driver.loyaltyNumber}</Body1>
          </Grid>
        )}
        {isAdditionalDriver && (
          <Grid item xs={12}>
            <Body1 data-testid={'driver-fees-label'}>
              <strong>{t('driver.additionalDrivers.driverFees')}</strong>
            </Body1>
            <Body1 data-testid='driver-fees'>
              {driver.driverFees ? getItemRates(driver.driverFees, t, locale) : '--'}
            </Body1>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
