import { useFieldArray, useFormContext } from 'react-hook-form';
import { PeoListItem } from './PeoListItem';
import { EquipmentFormFields, EquipmentFormModel, EquipmentItemFields } from 'components/flexFlow/peo/peoTypes';
import { DISABLE_EQUIPMENT_QUANTITY_CODE, getPeoTitle } from 'utils/peoUtils';
import { useEquipmentTypesQuery } from 'services/rentalReference/rentalReferenceQueries';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow } from 'redux/selectors/bookingEditor';

export const EquipmentList = () => {
  const { data: equipmentDetails = [] } = useEquipmentTypesQuery();
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);

  const formContext = useFormContext<EquipmentFormModel>();
  const { fields } = useFieldArray({ control: formContext.control, name: EquipmentFormFields.Items });

  return (
    <>
      {fields.map((field, index) => {
        const item = field[EquipmentItemFields.Item];
        const namePrefix = `${EquipmentFormFields.Items}[${index}]`;
        const title = getPeoTitle(item?.code ?? '', equipmentDetails);
        const quantity = isReadOnly && item.quantity > 1 ? ` (${item.quantity})` : '';
        const equipmentTitle = title + quantity;
        const disableQuantity = item?.code ? DISABLE_EQUIPMENT_QUANTITY_CODE.includes(item.code) : false;

        return (
          <PeoListItem
            key={field.id}
            index={index}
            title={equipmentTitle}
            selectability={item?.selectability ?? ''}
            code={item?.code ?? ''}
            rate={item?.rate}
            checkBoxFieldName={`${namePrefix}.${EquipmentItemFields.Checked}`}
            quantityName={`${namePrefix}.${EquipmentItemFields.Quantity}`}
            isQuantityDisabled={disableQuantity}
            showCountDropdown
          />
        );
      })}
    </>
  );
};
