/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface Product {
  /**
   * The protection product pricing code for the country level protection for National.
   * @format ^[A-Z]{2,3}$
   * @example "RSP"
   */
  pricingCode?: string;
  /** URN to the Brand */
  brand?: BrandURN;
  /** A URN to a country. `Protected Data` Updates can change it, but it cannot be removed. */
  country?: Country;
  regionalProtectionTypes?: RegionalProtectionTypeURN[];
  /**
   * Add On Insurance Identifier for additional/Excess protection that can be applied on top of main country specific protection (for example CDW).
   * @format ^[A-Z]{2,3}$
   * @example "EXS"
   */
  additionalCoveragePricingCode?: string;
}

export type MembershipProduct = Product & {
  /** Whether the protection has been accepted. A null value is not allowed. */
  isAccepted?: ProtectionIsAccepted;
  /** Whether the protection is signed */
  isSigned?: boolean;
};

export interface Address {
  /**
   * First line of the address.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: •••• LADUE ••••
   * @maxLength 35
   * @example "8844 LADUE ROAD"
   */
  addressLine1?: string;
  /**
   * Second line of the address.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ••••E••••
   * @maxLength 35
   * @example "SUITE 200"
   */
  addressLine2?: string;
  /**
   * Third line of the address.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   * @maxLength 35
   */
  addressLine3?: string;
  /**
   * Fourth line of the address.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   * @maxLength 35
   */
  addressLine4?: string;
  /**
   * Name of the city.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: •••••••••
   * @maxLength 25
   * @example "LADUE"
   */
  city?: string;
  /**
   * A URN to a country subdivision.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: urn:com.ehi:xqa:reference:location:countrySubdivision:•••
   */
  countrySubdivision?: CountrySubdivision;
  /**
   * The zip code or postal code appropriate for the country of this address.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: •••••••••
   * @maxLength 10
   * @example "63124"
   */
  postalCode?: string;
  /** A URN to a country. `Protected Data` Updates can change it, but it cannot be removed. */
  country?: Country;
}

export type CreateAlamoProfileAddress = UtilRequiredKeys<Address, 'country'>;

export type CreateECEPProfileAddress = UtilRequiredKeys<Address, 'addressLine1' | 'city' | 'postalCode' | 'country'>;

/**
 * @pattern ^\d{4,10}$
 * @example "1234567890"
 */
export type DriverId = string;

/** A payment method of type billing account */
export type BillingAccount = BasePaymentMethod & {
  /**
   * Business account number.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ••••dYH8
   * @maxLength 20
   * @example "adf61hwa88ktk1asdYH8"
   */
  number?: string;
  /**
   * @maxLength 8
   * @pattern ^[a-zA-Z0-9]{1,8}$
   * @example "341ad65h"
   */
  partnerAccountId?: string;
};

export interface BirthInformation {
  /**
   * Date of birth in YYYY-MM-DD format.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ••••-••-22
   * Cannot be removed
   * @pattern ^[\d\-•]{10}$
   * @example "1990-02-22"
   */
  date?: string;
  /**
   * City of Birth.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: •••••••••
   * Cannot be removed
   * @example "Albuquerque"
   */
  city?: string;
  /** A URN to a country. `Protected Data` Updates can change it, but it cannot be removed. */
  country?: Country;
}

export type CreateProfileBirthInformation = UtilRequiredKeys<BirthInformation, 'date'>;

export type ModifyProfileBirthInformation = BirthInformation;

/** A payment method of type central billing */
export type CentralBilling = BasePaymentMethod & {
  /**
   * Business account number.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ••••dYH8
   * @maxLength 20
   * @example "adf61hwa88ktk1asdYH8"
   */
  number?: string;
};

/** A payment method of type credit card */
export type CreditCard = BasePaymentMethod & {
  /** @maxLength 35 */
  financialTransactionReferenceIdentifier?: string;
  /**
   * The unique identifier within Payment Services
   * @maxLength 35
   * @pattern ^[a-zA-Z0-9]{1,35}$
   * @example "asd1hb8asdfasdf456181ads526d1fasd6f"
   */
  paymentServiceToken?: string;
  /**
   * The brand of credit card.
   * @example "Visa"
   */
  cardBrand?: string;
  /**
   * The name of the credit card
   * @maxLength 30
   */
  name?: string;
  /**
   * The expiration of the credit card with a a format of MMYYYY.
   * Conditionally required: if payment method of referenceId is a credit card.
   */
  expiration?: CreditCardExpiration;
  /**
   * The last four digits of the credit card
   * @minLength 4
   * @maxLength 4
   * @pattern ^[0-9]{4}$
   * @example "1234"
   */
  cardNumberLastFour?: string;
  /**
   * The first six digits of the credit card
   * @minLength 6
   * @maxLength 6
   * @pattern ^[0-9]{6}$
   * @example "123456"
   */
  cardNumberFirstSix?: string;
  /**
   * If the credit card was verified at time of rental.
   * Conditionally required: if payment method of referenceId is a credit card.
   */
  wasVerifiedAtRental?: CreditCardWasVerifiedAtRental;
  /**
   * Unique identifier representing a single card in a wallet
   * @maxLength 13
   * @example "MLSS65G27ZTT5"
   */
  paymentMethodInstrumentIdentifier?: string;
};

/**
 * If the credit card was verified at time of rental.
 * Conditionally required: if payment method of referenceId is a credit card.
 */
export type CreditCardWasVerifiedAtRental = boolean;

/**
 * The expiration of the credit card with a a format of MMYYYY.
 * Conditionally required: if payment method of referenceId is a credit card.
 * @format string
 * @minLength 6
 * @maxLength 6
 * @pattern ^[0-1][0-9]20[0-9]{2}$
 * @example "012020"
 */
export type CreditCardExpiration = string;

export interface SearchResponse {
  /** Object for basic pagination fields. */
  pagination?: Pagination;
  results?: SearchResult[];
}

export interface SearchSummaryResponse {
  /** Object for basic pagination fields. */
  pagination?: Pagination;
  results?: SearchSummaryResult[];
}

export interface SearchProfileSearchResponse {
  /** Object for basic pagination fields. */
  pagination?: Pagination;
  results?: SearchProfileSearchResult[];
}

export interface IdSearchRequestItem {
  driverId?: DriverId;
  /** @pattern ^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$ */
  uuid?: string;
  /**
   * Determines what loyalty program membership will be returned for this request item.
   * - Exactly one of driverId or uuid must be supplied.
   * - If an individual does not have a membership that matches a requested loyalty program, then no profile would be returned.
   * - If multiple loyalty programs are requested and the individual has matching loyalty program memberships,
   *   multiple copies of a driver profile with that id may be returned.
   * @minItems 1
   */
  loyaltyPrograms: LoyaltyProgramIdNonLoyaltyExcludeOneClub[];
}

/** Only BillingAccount is supported for Subscription paymentMethod. */
export interface Subscription {
  /**
   * - The length of the sequence must be exactly 8 characters.
   * - There can be no vowels in the sequence.
   * - The numbers ‘0’ and '1' cannot exist in the sequence.
   * @pattern ^[^AEIOUaeiou01]{8}$
   * @example "37TWKF63"
   */
  number?: string;
  /** @example "ACTIVE" */
  status?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'SUSPENDED';
  /**
   * @format date
   * @example "2022-02-22"
   */
  enrollDate?: string;
  activePeriod: {
    /**
     * @format date
     * @example "2024-02-22"
     */
    startDate: string;
    /**
     * @format date
     * @example "2026-02-22"
     */
    endDate?: string;
  };
  acceptedTermsAndConditions?: {
    /**
     * @minLength 1
     * @maxLength 20
     * @example "9.0"
     */
    acceptedVersion: string;
    /**
     * @format date
     * @example "2021-02-22"
     */
    acceptedDate?: string;
  };
  paymentMethod?: PaymentMethodItem;
}

/** Object for basic pagination fields. */
export interface Pagination {
  /**
   * The index of the page to return.
   * @min 1
   * @default 1
   * @example 1
   */
  pageIndex?: number;
  /**
   * The number of results to be returned per page.
   * @min 1
   * @default 99
   * @example 99
   */
  pageSize?: number;
  /**
   * The number of results returned.
   * @min 0
   * @example 75
   */
  totalResults?: number;
}

/**
 * Describes the search by date of birth request object
 * @example {"pagination":{"pageIndex":1,"pageSize":99},"givenName":"John","surname":"Smith","dateOfBirth":"1990-02-22","loyaltyPrograms":["ENTERPRISE_PLUS","NON_LOYALTY"]}
 */
export type SearchByNameDateOfBirthRequestObject = UtilRequiredKeys<SearchNameObject, 'surname'> & {
  /** Object for basic pagination fields. */
  pagination?: Pagination;
  /** Date of birth object */
  dateOfBirth: DateOfBirth;
  /** @minItems 1 */
  loyaltyPrograms: LoyaltyProgramIdNonLoyaltyExcludeOneClub[];
};

/**
 * Describes the search by name country request object
 * @example {"pagination":{"pageIndex":1,"pageSize":99},"givenName":"John","surname":"Smith","country":"urn:com.ehi:xqa:reference:location:country:USA","countrySubdivision":"urn:com.ehi:xqa:reference:location:countrySubdivision:NM","loyaltyPrograms":["ENTERPRISE_PLUS","NON_LOYALTY"]}
 */
export type SearchByNameCountryRequestObject = UtilRequiredKeys<SearchNameObject, 'givenName' | 'surname'> & {
  /** Object for basic pagination fields. */
  pagination?: Pagination;
  /** A URN to a country. `Protected Data` Updates can change it, but it cannot be removed. */
  country: Country;
  /**
   * A URN to a country subdivision.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: urn:com.ehi:xqa:reference:location:countrySubdivision:•••
   */
  countrySubdivision?: CountrySubdivision;
  /** @minItems 1 */
  loyaltyPrograms: LoyaltyProgramIdNonLoyaltyExcludeOneClub[];
};

/** @example {"pagination":{"pageIndex":1,"pageSize":99},"surname":"Smith","licenseVerified":true,"number":"F109283742777","country":"urn:com.ehi:xqa:reference:location:country:USA","countrySubdivision":"urn:com.ehi:xqa:reference:location:countrySubdivision:NM","dateOfBirth":"1990-02-22","loyaltyPrograms":["ENTERPRISE_PLUS","NON_LOYALTY"]} */
export type SearchByDriverLicenseRequestObject = UtilRequiredKeys<LicenseInformation, 'number'> & {
  /** Object for basic pagination fields. */
  pagination?: Pagination;
  /**
   * @minLength 2
   * @maxLength 60
   * @example "Smith"
   */
  surname?: string;
  /**
   * Search will not be performed on Driver's License numbers that are in the blacklist and a warning will be returned.
   * The blacklist validation will not occur when the flag 'licenseVerified' is passed as true, the default value of the flag will be false, however,
   * by setting the value of the licenseVerified flag to true Clients will be able to override the validation for blacklisted license and perform a search with such a number.
   */
  licenseVerified?: boolean;
  /** @minItems 1 */
  loyaltyPrograms: LoyaltyProgramIdNonLoyaltyExcludeOneClub[];
};

/** Describes fields for modify last rental requests. */
export type ModifyLastRentalRequestObject = UtilRequiredKeys<LastRental, 'pickupInformation' | 'returnInformation'> & {
  /** URN to the reservation */
  reservation: ReservationURN;
  /**
   * The total number of rental days.
   * @min 0
   */
  numberOfRentalDays: number;
  /**
   * The time and mileage.
   * @pattern ^\d+(()|(\.\d+)?)$
   * @example "346.50"
   */
  timeAndMileage: string;
  /**
   * The alternate id of the contract
   * @example "EMXL02"
   */
  alternateContractId?: string;
};

/** Describes the search by membership number request object */
export interface SearchByMembershipNumberRequestObject {
  /** the Individual membership number */
  membershipNumber: LoyaltyProgramNumber;
  /** The type of Loyalty Program. */
  loyaltyProgram: LoyaltyProgramType;
}

/**
 * Describes the search by name email request object
 * @example {"pagination":{"pageIndex":1,"pageSize":99},"givenName":"John","surname":"Smith","emailAddress":{"emailAddress":"exampleuser@example.com"},"loyaltyPrograms":["ENTERPRISE_PLUS","NON_LOYALTY"]}
 */
export type SearchByNameEmailRequestObject = UtilRequiredKeys<SearchNameObject, 'givenName' | 'surname'> & {
  /** Object for basic pagination fields. */
  pagination?: Pagination;
  /** Email address only search object */
  emailAddress: SearchEmailAddress;
  /** @minItems 1 */
  loyaltyPrograms: LoyaltyProgramIdNonLoyaltyExcludeOneClub[];
};

/**
 * Describes the search by phone request object
 * @example {"pagination":{"pageIndex":1,"pageSize":99},"phoneNumbers":["3149843012"],"loyaltyPrograms":["ENTERPRISE_PLUS","NON_LOYALTY"]}
 */
export interface SearchByPhoneRequestObject {
  /** Object for basic pagination fields. */
  pagination?: Pagination;
  phoneNumbers: SearchPhoneNumber[];
  /** @minItems 1 */
  loyaltyPrograms: LoyaltyProgramIdNonLoyaltyExcludeOneClub[];
}

/**
 * Describes the search by name request object
 * @example {"pagination":{"pageIndex":1,"pageSize":99},"givenName":"John","surname":"Smith","loyaltyPrograms":["ENTERPRISE_PLUS","NON_LOYALTY"]}
 */
export type SearchByNameRequestObject = UtilRequiredKeys<SearchNameObject, 'givenName' | 'surname'> & {
  /** Object for basic pagination fields. */
  pagination?: Pagination;
  /** @minItems 1 */
  loyaltyPrograms: LoyaltyProgramIdNonLoyaltyExcludeOneClub[];
};

/**
 * Describes the search by phone request object
 * @example {"pagination":{"pageIndex":1,"pageSize":99},"givenName":"John","surname":"Smith","dateOfBirth":"1990-02-22","phoneNumbers":["3149843012"],"priorities":[1,2],"loyaltyPrograms":["ENTERPRISE_PLUS","NON_LOYALTY"]}
 */
export type SearchByNamePhoneRequestObject = UtilRequiredKeys<SearchNameObject, 'givenName' | 'surname'> & {
  /** Object for basic pagination fields. */
  pagination?: Pagination;
  /** Date of birth object */
  dateOfBirth?: DateOfBirth;
  /** @minItems 1 */
  phoneNumbers: SearchPhoneNumber[];
  /**
   * Describes the priority of the phone number. Highest priority is 1, Lowest is 4.
   * @min 1
   * @max 4
   * @uniqueItems true
   */
  priorities?: number[];
  /** @minItems 1 */
  loyaltyPrograms: LoyaltyProgramIdNonLoyaltyExcludeOneClub[];
};

/** Patterned given name and surname fields for search */
export interface SearchNameObject {
  /**
   * Given name field with a pattern which takes 2-34 word characters and an additional word or * wildcard character.
   * @minLength 2
   * @maxLength 35
   * @pattern ^[^0-9_%*]{2,}\*?$
   * @example "John"
   */
  givenName?: string;
  /**
   * Surname field with a pattern which takes 2-59 word characters and an additional word or * wildcard character.
   * @minLength 2
   * @maxLength 60
   * @pattern ^[^0-9_%*]{2,}\*?$
   * @example "Smith"
   */
  surname?: string;
}

export interface SearchRequestObject {
  /** Object for basic pagination fields. */
  pagination?: Pagination;
  /**
   * @min 1
   * @max 75
   * @example [{"driverId":"1234567890","loyaltyPrograms":["EMERALD_CLUB"]},{"uuid":"79b44767-d626-4ec8-8d4a-524c060437bc","loyaltyPrograms":["ENTERPRISE_PLUS","NON_LOYALTY"]}]
   */
  searchCriteria: IdSearchRequestItem[];
}

export interface MRARequestObject {
  /** URN to the branch */
  branch: BranchURN;
}

/** Stateful data object */
export interface UpdateLoyaltyMembership {
  /**
   * Notes on the membership containing a maximum of 4000 characters.
   * @example "Some stateful membership data"
   */
  notes?: string | null;
}

/** Describes what terms and conditions have been accepted */
export interface AcceptTermsConditionsRequestObject {
  /**
   * The accepted version
   * @maxLength 256
   * @pattern ^[0-9]+\.[0-9]+$
   * @example "1.2"
   */
  acceptedVersion: string;
}

/** Describes what terms and conditions have been accepted */
export interface ModifyTermsConditionsRequestObject {
  /**
   * The accepted version for modified terms and conditions
   * @maxLength 256
   * @example "1.2"
   */
  version: string;
  /** Indicates if the loyalty program member has accepted or declined the terms & conditions. Required for Modifyied terms and conditions */
  accepted: boolean;
}

/** Describes what contact info to change */
export interface ModifyContactInfoRequestObject {
  address?: Address;
  /**
   * @maxItems 9
   * @minItems 1
   */
  emailAddresses?: ModifyContactEmailAddress[];
  /** @minItems 1 */
  phoneNumbers?: ModifyContactPhoneNumber[];
  notificationOptions?: NotificationOptions;
}

/** Describes what properties in driver profile need to be updated */
export interface ModifyDriverProfileRequestObject {
  name?: ModifyProfileName;
  birthInformation?: ModifyProfileBirthInformation;
  /** URN to the gender */
  gender?: GenderURN;
  /** The employee id */
  employeeIdentificationNumber?: EmployeeIdentificationNumber;
}

/** Information to update a Loyalty Driver Profile and Preferences */
export type ModifyDriverProfileAndLoyaltyPreferencesRequestObject = ModifyWholeProfileRequestObject & Preferences;

/** Information to update a given Driver Profile. */
export interface ModifyWholeProfileRequestObject {
  name: ModifyProfileName;
  address?: Address;
  /**
   * @maxItems 9
   * @minItems 1
   */
  emailAddresses?: ModifyContactEmailAddress[];
  /** @minItems 1 */
  phoneNumbers?: ModifyContactPhoneNumber[];
  birthInformation?: ModifyProfileBirthInformation;
  legalIdentifications?: ManageLegalIdentification[];
  /** URN to the gender */
  gender?: GenderURN;
  /** @example false */
  declinedToProvideInsurance?: boolean;
  insurancePolicies?: InsurancePolicy[];
  notificationOptions?: NotificationOptions;
  /** The employee id */
  employeeIdentificationNumber?: EmployeeIdentificationNumber;
}

/** Echo's the back the request */
export interface ModifyDriverProfileResponseObject {
  /** Name of an individual */
  name?: ModifyName;
  birthInformation?: BirthInformation;
  /** URN to the gender */
  gender?: GenderURN;
  /** The employee id */
  employeeIdentificationNumber?: EmployeeIdentificationNumber;
}

export interface AddPaymentMethodRequestObject {
  notificationOptions?: NotificationOptions;
  paymentMethod: PaymentMethodItem;
}

export interface EnrollLoyaltyMethodRequestObject {
  notificationOptions?: NotificationOptions;
  /** the Individual membership number */
  number?: LoyaltyProgramNumber;
  /** URN to the marketing source. */
  marketingSource?: MarketingSource;
  /** The alternate id of the contract associated with the profile. */
  rateContract?: EditableRateContract;
  tier?: Tier;
}

export type Tier = EnterprisePlusTier | EmeraldClubTier;

export interface RemovePaymentMethodRequestObject {
  notificationOptions?: NotificationOptions;
  /** The unique number used to identify the method of payment */
  referenceId: PaymentMethodReferenceId;
}

/** A loyalty member can modify a membership status with an update request */
export interface ModifyStatusRequestObject {
  /**
   * Loyalty member's EHI account status to map.
   * @example "ACTIVE"
   */
  loyaltyMembershipStatus: 'ACTIVE' | 'INACTIVE';
  /** URN to the Loyalty Status Reason */
  statusReason?: LoyaltyStatusReasonURN;
}

export interface ManageProtectionsRequestObject {
  notificationOptions?: NotificationOptions;
  regionalProtections?: ModifyMembershipRegionalProtection[];
}

export interface ModifyPreferencesRequestObject {
  notificationOptions?: NotificationOptions;
  /** Whether or not GPS was accepted. Conditionally required if program is Emerald Club. */
  gpsAccepted?: boolean;
  /** Whether or not to include fuel service. Conditionally required if program is Emerald Club. */
  includeFuelService?: boolean;
  /** Whether or not to email the invoice to the driver. */
  emailInvoice: boolean;
  /** Global Email Opt In applies to all loyalty memberships and it captures the consumers desire to opt in or opt out of all email communication. */
  globalEmailOptIn: boolean;
}

export interface ModifyRateContractRequestObject {
  notificationOptions?: NotificationOptions;
  /** The alternate id of the contract associated with the profile. */
  rateContract: EditableRateContract;
}

/** Describes fields for processing no show requests. */
export interface ProcessNoShowRequestObject {
  /** URN to the Brand */
  brand?: BrandURN;
  /** Information about the pickup of a vehicle during a rental. */
  pickupInformation: PickupInformationWithDateTime;
  /** Information about the return of a vehicle during a rental. */
  returnInformation: ReturnInformationWithDateTime;
  /** URN to the reservation */
  reservation: ReservationURN;
  /**
   * Reservation date time.
   * @format date-time
   */
  reservationDateTime: string;
  /** Redemption Transaction Id. */
  redemptionTransactionId?: RedemptionTransactionId;
  /** Represents loyalty coupon id, example free day */
  loyaltyCouponId: LoyaltyCouponId;
}

/** Describes fields for redeposit points requests. */
export interface RedepositPointsRequestObject {
  /** URN to the Brand */
  brand: BrandURN;
  /** Information about the pickup of a vehicle during a rental. */
  pickupInformation: PickupInformationWithNoMaskedDate;
  /** Information about the return of a vehicle during a rental. */
  returnInformation: ReturnInformationWithNoMaskedDate;
  /** URN to the reservation */
  reservation: ReservationURN;
}

/** Describes fields for retro claim requests. */
export interface RetroClaimRequestObject {
  /** URN to the Brand */
  brand: BrandURN;
  /**
   * The pickup location.
   * @example "Chicago O'Hare International Airport"
   */
  pickupLocationFreeForm: string;
  /**
   * The date of pickup.
   * @format date
   * @example "1990-05-23T00:00:00.000Z"
   */
  pickupDate: string;
  /**
   * The date of return.
   * @format date
   * @example "1990-06-23T00:00:00.000Z"
   */
  returnDate: string;
  /** A context for an external reference into another system for either a Reservation Number or Rental Agreement Number */
  context: RetroClaimContext;
}

/** Describes fields for redeem loyalty points. */
export interface LoyaltyPointsRedemptionRequestObject {
  /** URN to the reservation */
  reservationNumber: ReservationURN;
  /** Information about the pickup of a vehicle during a rental. */
  pickupInformation: PickupInformationWithDateTime;
  /** Information about the return of a vehicle during a rental. */
  returnInformation: ReturnInformationWithDateTime;
  /** URN to the Vehicle Class */
  vehicleClass: VehicleClassURN;
  /**
   * The number of rental days to pay for using reward points.
   * @min 1
   * @example 20
   */
  numberOfFreeDays: number;
  /**
   * Total points redeemed.
   * @min 1
   * @example 10
   */
  totalPointsRedeemed: number;
}

export type ReplaceLoyaltyPointsRedemptionRequestObject = LoyaltyPointsRedemptionRequestObject &
  LoyaltyPointsRedemptionTransaction;

export interface WarningDepartment {
  /**
   * The URN to the Warning Department
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:renter:warningDepartment:[0-9]{1,19}$
   * @example "urn:com.ehi:xqa:renter:warningDepartment:1234567890"
   */
  urn?: string;
  /**
   * The URN to the Warning Department Type
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:departmentType:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:renter:departmentType:T"
   */
  type?: string;
  /**
   * The phone number for the Warning Department
   * @maxLength 20
   * @example "+1-541-754-3010"
   */
  phoneNumber?: string;
  /**
   * The URN to the Warning Department Group
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:location:group:[A-Z0-9_]+$
   * @example "urn:com.ehi:xqa:location:group:A0010"
   */
  group?: string;
  /** URN to the Region (Location) */
  region?: LocationRegionURN;
  availableReasons?: string[];
}

/**
 * The id of the Warning Department
 * @format int64
 * @example 1234567890123456800
 */
export type WarningDepartmentId = number;

/** @max 9 */
export type DriverProfileAddresses = EmailAddress[];

/**
 * @maxItems 9
 * @minItems 1
 */
export type CreateProfileEmailAddresses = CreateProfileEmailAddress[];

export interface EmailAddress {
  /**
   * An email address.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: e•••••r@example.com
   *
   * **Note: Some existing email addresses might not follow this pattern.**
   * @maxLength 80
   * @example "exampleuser@example.com"
   */
  emailAddress?: string;
  /**
   * An ordinal describing the priority of the email
   * @min 1
   * @max 9
   * @example 1
   */
  priority?: number;
  LoyaltyProgram?: string;
}

export type CreateProfileEmailAddress = UtilRequiredKeys<EmailAddress, 'emailAddress'>;

export type ModifyContactEmailAddress = UtilRequiredKeys<EmailAddress, 'emailAddress'>;

/** Email address only search object */
export interface SearchEmailAddress {
  /**
   * An email address.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: e•••••r@example.com
   *
   * **Note: Some existing email addresses might not follow this pattern.**
   * @maxLength 80
   * @example "exampleuser@example.com"
   */
  emailAddress?: string;
}

export interface EmeraldClubTier {
  /** URN to the current Emerald Club Tier */
  current?: EmeraldClubTierUrn;
  /**
   * The expiration date of the tier
   * @format date
   * @example "2021-02-22"
   */
  expirationDate?: string;
  /**
   * URN to the previous year's Emerald Club Tier
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:renter:loyaltyProgram:EMERALD_CLUB:tier:\d+$
   * @example "urn:com.ehi:xqa:renter:loyaltyProgram:EMERALD_CLUB:tier:10070641"
   */
  previousYear?: string;
  /**
   * URN to the projected year end Emerald Club Tier
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:renter:loyaltyProgram:EMERALD_CLUB:tier:\d+$
   * @example "urn:com.ehi:xqa:renter:loyaltyProgram:EMERALD_CLUB:tier:10070641"
   */
  projectedYearEnd?: string;
}

export interface SearchProfileEmeraldClubTier {
  /**
   * URN to the current Emerald Club Tier
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:renter:loyaltyProgram:EMERALD_CLUB:tier:\d+$
   * @example "urn:com.ehi:xqa:renter:loyaltyProgram:EMERALD_CLUB:tier:10070641"
   */
  current?: string;
}

export interface SearchProfileEnterprisePlusTier {
  /**
   * URN to the current Enterprise Plus Tier
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:renter:loyaltyProgram:ENTERPRISE_PLUS:tier:\d+$
   * @example "urn:com.ehi:xqa:renter:loyaltyProgram:ENTERPRISE_PLUS:tier:10228969"
   */
  current?: string;
}

export interface EnterprisePlusTier {
  /** URN to the current Enterprise Plus Tier */
  current?: EnterprisePlusTierUrn;
  /**
   * The expiration date of the tier
   * @format date
   * @example "2021-02-22"
   */
  expirationDate?: string;
  /**
   * The date when membership is reviewed in order to evaluate tier eligibility.
   * @format date
   * @example "2021-02-22"
   */
  reviewDate?: string;
}

export type LoyaltyProgramResponseObject = BaseLoyaltyProgramResponseObject &
  (
    | BaseLoyaltyProgramResponseObjectTypeMapping<'EMERALD_CLUB', LoyaltyProgramEmeraldClub>
    | BaseLoyaltyProgramResponseObjectTypeMapping<'ENTERPRISE_PLUS', LoyaltyProgramEnterprisePlus>
    | BaseLoyaltyProgramResponseObjectTypeMapping<'ALAMO_INSIDERS', LoyaltyProgramAlamoInsiders>
  );

/** The unique identifier for loyalty program. */
export enum LoyaltyProgramId {
  ENTERPRISE_PLUS = 'ENTERPRISE_PLUS',
  EMERALD_CLUB = 'EMERALD_CLUB',
  ALAMO_INSIDERS = 'ALAMO_INSIDERS',
}

/** The unique identifier for loyalty program. */
export enum LoyaltyProgramIdOptional {
  ENTERPRISE_PLUS = 'ENTERPRISE_PLUS',
  EMERALD_CLUB = 'EMERALD_CLUB',
  ALAMO_INSIDERS = 'ALAMO_INSIDERS',
}

export enum LoyaltyProgramIdNonPlussed {
  EMERALD_CLUB = 'EMERALD_CLUB',
  ALAMO_INSIDERS = 'ALAMO_INSIDERS',
  NON_LOYALTY = 'NON_LOYALTY',
}

/**
 * The unique identifier for loyalty program, excluding Alamo Insiders.
 * @example "EMERALD_CLUB"
 */
export enum LoyaltyProgramIdNonAlamo {
  ENTERPRISE_PLUS = 'ENTERPRISE_PLUS',
  EMERALD_CLUB = 'EMERALD_CLUB',
}

/** The unique identifier for loyalty program, including non-loyalty, excluding One Club. */
export enum LoyaltyProgramIdNonLoyaltyExcludeOneClub {
  ENTERPRISE_PLUS = 'ENTERPRISE_PLUS',
  EMERALD_CLUB = 'EMERALD_CLUB',
  ALAMO_INSIDERS = 'ALAMO_INSIDERS',
  NON_LOYALTY = 'NON_LOYALTY',
}

/** The type of Loyalty Program. */
export enum LoyaltyProgramType {
  ENTERPRISE_PLUS = 'ENTERPRISE_PLUS',
  EMERALD_CLUB = 'EMERALD_CLUB',
  ALAMO_INSIDERS = 'ALAMO_INSIDERS',
}

export enum LoyaltyProgramIdEmeraldAndAlamoOnly {
  EMERALD_CLUB = 'EMERALD_CLUB',
  ALAMO_INSIDERS = 'ALAMO_INSIDERS',
}

export type LoyaltyProgramBase = BaseLoyaltyProgramBase &
  (
    | BaseLoyaltyProgramBaseTypeMapping<LoyaltyProgramType.EMERALD_CLUB, LoyaltyProgramEmeraldClub>
    | BaseLoyaltyProgramBaseTypeMapping<LoyaltyProgramType.ENTERPRISE_PLUS, LoyaltyProgramEnterprisePlus>
    | BaseLoyaltyProgramBaseTypeMapping<LoyaltyProgramType.ALAMO_INSIDERS, LoyaltyProgramAlamoInsiders>
    | BaseLoyaltyProgramBaseTypeMapping<'ONE_CLUB', LoyaltyProgram>
  );

export type LoyaltyProgramEmeraldClub = BaseLoyaltyProgramBase & {
  /**
   * The list of tiers related to the loyalty program.
   * @minItems 1
   */
  tiers?: ProgramTierEmeraldClub[];
  regionalProtections?: RegionalProtection[];
};

export type LoyaltyProgramEnterprisePlus = BaseLoyaltyProgramBase & {
  /**
   * The list of tiers related to the loyalty program.
   * @minItems 1
   */
  tiers?: ProgramTierEnterprisePlus[];
  /**
   * ComarchTiers complex is being deprecating and replaced by loyaltyBankTiers since Loyalty Bank is not managed by Comarch anymore.
   * @deprecated
   */
  comarchTiers?: ProgramTierComarch[];
  loyaltyBankTiers?: LoyaltyBankTier[];
};

export type LoyaltyProgramAlamoInsiders = BaseLoyaltyProgramBase;

export type LoyaltyProgram = BaseLoyaltyProgramBase & {
  /** The list of tiers related to the loyalty program. */
  tiers?: ProgramTier[];
  regionalProtections?: RegionalProtection[];
  coupons?: Coupon[];
  availableMarketingSources?: AvailableMarketingSource[];
};

export interface ProgramTierBase {
  /**
   * The name of the loyalty program tier.
   * @maxLength 35
   * @example "EXECUTIVE ELITE"
   */
  name?: string;
  /**
   * The abbreviation of the loyalty program tier.
   * @pattern ^[A-Z]{2}$
   * @example "EE"
   */
  abbreviation?: string;
  levelsOfService?: string[];
  /**
   * An ordinal describing the position of this tier.
   * @max 5
   * @example 1
   */
  rank?: number;
}

/** Loyalty and reward program tier. */
export type ProgramTierEmeraldClub = ProgramTierBase & {
  /** URN to the current Emerald Club Tier */
  urn?: EmeraldClubTierUrn;
  availableMarketingSources?: AvailableMarketingSource[];
  /**
   * Contains information about a coupon. Coupons will only be returned for the emerald club program.
   * Before the transition to the unified One Club state, they will be attached to a tier object.
   * After the transition, they will be part of the loyalty program object.
   */
  coupon?: Coupon;
  /** The number of credits to receive a free day coupon. */
  creditsThreshold?: CreditsThreshold;
  /** The minimum number of annual rentals needed to achieve the tier. */
  rentalsThreshold?: RentalsThreshold;
  /** The minimum number of annual rental days needed to achieve the tier. */
  rentalDaysThreshold?: RentalDaysThreshold;
};

/**
 * The number of credits to receive a free day coupon.
 * @max 999
 * @example 6
 */
export type CreditsThreshold = number;

/**
 * The minimum number of annual rentals needed to achieve the tier.
 * @max 999
 * @example 12
 */
export type RentalsThreshold = number;

/**
 * The minimum number of annual rental days needed to achieve the tier.
 * @max 999
 * @example 40
 */
export type RentalDaysThreshold = number;

/** Reward tier values supported by third-party vendor. */
export interface ProgramTierComarch {
  /** URN to the Comarch Tier */
  comarchTier?: ComarchTierURN;
  /** The minimum number of annual rentals needed to achieve the tier. */
  rentalsThreshold?: RentalsThreshold;
  /** The minimum number of annual rental days needed to achieve the tier. */
  rentalDaysThreshold?: RentalDaysThreshold;
  /**
   * An ordinal describing the position of this tier.
   * @max 5
   * @example 1
   */
  rank?: number;
}

/** Reward tier values supported by third-party vendor. */
export interface LoyaltyBankTier {
  /** URN to the Loyalty Bank Tier */
  loyaltyBankTier?: LoyaltyBankTierURN;
  /** The minimum number of annual rentals needed to achieve the tier. */
  rentalsThreshold?: RentalsThreshold;
  /** The minimum number of annual rental days needed to achieve the tier. */
  rentalDaysThreshold?: RentalDaysThreshold;
  /**
   * An ordinal describing the position of this tier.
   * @max 5
   * @example 1
   */
  rank?: number;
}

/** Loyalty program tier. */
export type ProgramTierEnterprisePlus = ProgramTierBase & {
  /** URN to the current Enterprise Plus Tier */
  urn?: EnterprisePlusTierUrn;
  availableMarketingSources?: AvailableMarketingSource[];
};

export type ProgramTier = ProgramTierBase & {
  /** URN to the current Emerald Club Tier */
  urn?: EmeraldClubTierUrn;
  /** The minimum number of annual rentals needed to achieve the tier. */
  rentalsThreshold?: RentalsThreshold;
  /** The minimum number of annual rental days needed to achieve the tier. */
  rentalDaysThreshold?: RentalDaysThreshold;
};

/** Contains the version for a set of terms and conditions. */
export interface TermsAndConditions {
  /**
   * The version of the terms and conditions document.
   * @maxLength 256
   * @pattern ^[0-9]+\.[0-9]+$
   * @example "9.0"
   */
  version?: string;
}

/**
 * Contains information about a coupon. Coupons will only be returned for the emerald club program.
 * Before the transition to the unified One Club state, they will be attached to a tier object.
 * After the transition, they will be part of the loyalty program object.
 */
export interface Coupon {
  /**
   * The id of the coupon.
   * - If the coupon is attached to a tier object, the id will be numerical.
   * - If the coupon is attached to a loyalty program, the id will contain alpha characters.
   * @maxLength 10
   * @example "10009135"
   */
  id?: string;
  /**
   * The name of the coupon.
   * @maxLength 200
   * @example "National Free Day - EC Executive VIP/Elite"
   */
  name?: string;
  /** URN to the Brand */
  brand?: BrandURN;
}

export interface RegionalProtection {
  /**
   * URN to the Region
   *
   * Note: This Region is different than the definition in locator. Instead, this represents one of these values:_
   * - UNITED_STATES_OF_AMERICA
   * - CANADA
   * - LATIN_AMERICA_AND_CARIBBEAN
   * - ASIA-PACIFIC
   * - EUROPE_THE_MIDDLE_EAST_AND_AFRICA
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:region:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:renter:region:ASIA_PACIFIC"
   */
  region?: string;
  /**
   * URN to the Regional Protection Type
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:regionalProtectionType:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:renter:regionalProtectionType:DAMAGE_WAIVER_EXCESS_PROTECTION"
   */
  type?: string;
  /**
   * The abbreviation of the regional protection.
   * @maxLength 8
   * @example "PC"
   */
  abbreviation?: string;
}

/**
 * URN to the available marketing source
 *
 * _Note: Before the transition to One Club, marketing sources will be attached to Emerald Club
 * and Enterprise tiers.  After the transition, they will be part of the base Emerald Club loyalty program._
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:marketingSource:[0-9]+$
 * @example "urn:com.ehi:xqa:reference:renter:marketingSource:7504"
 */
export type AvailableMarketingSource = string;

export interface Error {
  /** A unique ID/Code for a particular validation failure. */
  code: string;
  /**
   * The locale in ISO 639-3 for language and ISO 3166-1 alpha-3 for country code
   * @pattern ^[a-z]{3}-[A-Z]{3}$
   * @example "eng-USA"
   */
  locale: string;
  /** A human readable string describing the error which can be shown to an end-user. */
  localizedMessage: string;
  /**
   * A list of references to the specific element/field related to the error. This may be useful to distinguish to which field(s) an error applies, as a single code could be used for multiple fields.
   * @example []
   */
  paths?: string[];
  severity: 'INFO' | 'WARNING' | 'ERROR';
  /** Any additional debugging details which could be useful by a developer. */
  supportInformation?: string;
}

export type Errors = Error[];

export interface LegalIdentificationRequestObject {
  notificationOptions?: NotificationOptions;
  /** @maxItems 10 */
  legalIdentifications?: ManageLegalIdentification[];
}

/** Legal Identification for manage-legal-id. */
export type ManageLegalIdentification = ManageProfileLegalIdentification & {
  /** Designate whether driver license validation should be done. Defaults to true. When false, the driver license blacklist validation will be skipped. */
  performLicenseValidation?: PerformLicenseValidation;
};

/**
 * Designate whether driver license validation should be done. Defaults to true. When false, the driver license blacklist validation will be skipped.
 * @default true
 */
export type PerformLicenseValidation = boolean;

export interface SearchProfileLegalIdentification {
  /**
   * URN to the Legal Identification Type
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:legalIdentificationType:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:renter:legalIdentificationType:DRIVERS_LICENSE"
   */
  type?: string;
  /**
   * The ID number of the legal identification.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example (driver's license number): ••••2777
   *
   * Masked example (passport/national id number): ••••45
   * @maxLength 25
   * @example "F109283742777"
   */
  number?: string;
  /**
   * Element currently allows alphanumeric and special characters. Will be deprecated in the future.
   * @minLength 4
   * @maxLength 4
   * @example "1234"
   */
  lastFourDigits?: string;
  /**
   * The expiration date in YYYY-MM-DD format.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ••••-••-22
   * @example "2021-02-22"
   */
  expirationDate?: string;
  /**
   * The issue date in YYYY-MM-DD format.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ••••-••-22
   * @example "2021-02-22"
   */
  issueDate?: string;
  /** A URN to a country. `Protected Data` Updates can change it, but it cannot be removed. */
  country?: Country;
  /**
   * A URN to a country subdivision.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: urn:com.ehi:xqa:reference:location:countrySubdivision:•••
   */
  countrySubdivision?: CountrySubdivision;
}

export type LegalIdentification = LegalIdentificationWithNoDocumentAuthentication & {
  documentAuthentication?: DocumentAuthentication;
};

export interface LegalIdentificationWithNoDocumentAuthentication {
  /**
   * URN to the Legal Identification Type
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:legalIdentificationType:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:renter:legalIdentificationType:DRIVERS_LICENSE"
   */
  type?: string;
  /**
   * The ID number of the legal identification.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example (driver's license number): ••••2777
   *
   * Masked example (passport/national id number): ••••45
   * @maxLength 25
   * @example "F109283742777"
   */
  number?: string;
  /**
   * Element currently allows alphanumeric and special characters. Will be deprecated in the future.
   * @minLength 4
   * @maxLength 4
   * @example "1234"
   */
  lastFourDigits?: string;
  /**
   * The expiration date in YYYY-MM-DD format.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ••••-••-22
   * @example "2021-02-22"
   */
  expirationDate?: string;
  /**
   * The issue date in YYYY-MM-DD format.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ••••-••-22
   * @example "2021-02-22"
   */
  issueDate?: string;
  /** A URN to a country. `Protected Data` Updates can change it, but it cannot be removed. */
  country?: Country;
  /**
   * A URN to a country subdivision.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: urn:com.ehi:xqa:reference:location:countrySubdivision:•••
   */
  countrySubdivision?: CountrySubdivision;
  /**
   * The City of Issue for the legal identification
   * @maxLength 35
   * @example "San Diego"
   */
  issuingAuthority?: string;
  /**
   * The classification on a driver's license
   * @maxLength 3
   * @example "C"
   */
  classification?: string;
}

/** Legal Identification for a new profile with required fields. */
export type LegalIdentificationRequired = LegalIdentification;

/** Legal Identification for a new profile with required fields. */
export type LegalIdentificationRequiredWithNoDocumentAuthentication = UtilRequiredKeys<
  LegalIdentificationWithNoDocumentAuthentication,
  'type' | 'number' | 'country'
>;

export interface DocumentAuthentication {
  /**
   * The unique identifier of the specific Authentication request
   * @format uuid
   */
  transactionId?: string;
  /**
   * An indication of whether the document is considered authentic or not
   * @example true
   */
  isAuthentic?: boolean;
  /**
   * Determination of whether this driver's license is considered valid for use
   * @example true
   */
  isValid?: boolean;
  /** A Uniform Resource Name that provides context for an external reference into another system. */
  context?: ContextURN;
  /**
   * Date/time verification transaction occurred.  This value represents RFC3339 compliant UTC time.  This is acceptable in ISO 8601 and RFC 3339 (with T) 2019-10-12T07:20:50.52Z. This is only accepted in RFC 3339 (without T) 2019-10-12 07:20:50.52Z
   * @format date-time
   * @example "2019-10-12T07:20:50.5Z"
   */
  transactionDateTime?: string;
}

/**
 * Legal Identification for a new profile.
 *
 * **Note: State is required if country is US/Canada.**
 */
export type CreateProfileLegalIdentification = LegalIdentificationRequiredWithNoDocumentAuthentication & {
  /** Designate whether driver license validation should be done. Defaults to true. When false, the driver license blacklist validation will be skipped. */
  performLicenseValidation?: PerformLicenseValidation;
};

/**
 * Legal Identification for a new profile.
 *
 * **Note: State is required if country is US/Canada.**
 */
export type ManageProfileLegalIdentification = LegalIdentificationRequired & {
  /** Designate whether driver license validation should be done. Defaults to true. When false, the driver license blacklist validation will be skipped. */
  performLicenseValidation?: PerformLicenseValidation;
};

/**
 * A URN to a country subdivision.
 *
 * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
 *
 * Masked example: urn:com.ehi:xqa:reference:location:countrySubdivision:•••
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:countrySubdivision:([A-Z]{1,3}|•{3})$
 * @example "urn:com.ehi:xqa:reference:location:countrySubdivision:NM"
 */
export type CountrySubdivision = string;

/**
 * A URN to a country. `Protected Data` Updates can change it, but it cannot be removed.
 * @format urn
 * @pattern ^[•]{4}$|^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:country:[A-Z]{2,4}$
 * @example "urn:com.ehi:xqa:reference:location:country:USA"
 */
export type Country = string;

/** Name of an individual */
export interface Name {
  /**
   * @maxLength 35
   * @example "Mr."
   */
  title?: string;
  /**
   * The given name of the driver. Updates can change it, but it cannot be removed.
   * @minLength 2
   * @maxLength 35
   * @pattern ^[^\s*0-9%_][^*0-9%_]+$
   * @example "John"
   */
  givenName?: string;
  /**
   * The given name of the driver. Updates can change it, but it cannot be removed.
   * @minLength 2
   * @maxLength 60
   * @pattern ^[^\s*0-9%_][^*0-9%_]+$
   * @example "Smith"
   */
  surname?: string;
  /**
   * @maxLength 35
   * @example "Sr."
   */
  suffix?: string;
}

/** Name of an individual */
export interface ModifyName {
  /**
   * @maxLength 35
   * @example "Mr."
   */
  title?: string;
  /**
   * The given name of the driver. Updates can change it, but it cannot be removed.
   * @example "John"
   */
  givenName?: string;
  /**
   * The given name of the driver. Updates can change it, but it cannot be removed.
   * @example "Smith"
   */
  surname?: string;
  /**
   * @maxLength 35
   * @example "Sr."
   */
  suffix?: string;
}

export type CreateProfileName = UtilRequiredKeys<Name, 'givenName' | 'surname'>;

export type ModifyProfileName = UtilRequiredKeys<ModifyName, 'givenName' | 'surname'>;

export interface ModifyPaymentMethodObject {
  notificationOptions?: NotificationOptions;
  /** The unique number used to identify the method of payment */
  referenceId: PaymentMethodReferenceId;
  /** Alias of payment method */
  alias?: PaymentMethodAlias;
  /** Describes whether or not this payment method is the default method. */
  isDefault: PaymentMethodIsDefault;
  /**
   * The expiration of the credit card with a a format of MMYYYY.
   * Conditionally required: if payment method of referenceId is a credit card.
   */
  expiration?: CreditCardExpiration;
  /**
   * If the credit card was verified at time of rental.
   * Conditionally required: if payment method of referenceId is a credit card.
   */
  wasVerifiedAtRental?: CreditCardWasVerifiedAtRental;
}

export type PaymentMethod = BasePaymentMethod &
  (
    | BasePaymentMethodTypeMapping<'CREDIT_CARD', CreditCard>
    | BasePaymentMethodTypeMapping<'BILLING_ACCOUNT', BillingAccount>
    | BasePaymentMethodTypeMapping<'CENTRAL_BILLING', CentralBilling>
  );

/**
 * The unique number used to identify the method of payment
 * @pattern ^[0-9]{1,8}$
 * @example "12345678"
 */
export type PaymentMethodReferenceId = string;

/**
 * The unique number used to identify the method of payment
 * @maxLength 8
 * @pattern ^[0-9]{1,8}$
 * @example "12345678"
 */
export type PaymentMethodReferenceIdReadOnly = string;

/** Describes whether or not this payment method is the default method. */
export type PaymentMethodIsDefault = boolean;

/**
 * Alias of payment method
 * @maxLength 30
 * @example "WF Visa"
 */
export type PaymentMethodAlias = string;

/** @max 4 */
export type DriverProfilePhoneNumbers = PhoneNumber[];

/**
 * @maxItems 4
 * @minItems 1
 */
export type CreateProfilePhoneNumbers = CreateProfilePhoneNumber[];

export interface PhoneNumber {
  /**
   * URN to the Phone Type.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:phoneType:[A-Z_/]+$
   * @example "urn:com.ehi:xqa:reference:renter:phoneType:MOBILE_CELL"
   */
  phoneType?: string;
  /**
   * The phone number in E.164 format.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ••••0665
   * @format phone
   * @maxLength 14
   * @pattern ^[0-9•]+$
   * @example "3149843012"
   */
  number?: string;
  /**
   * The ordinal describing the priority of the phone number
   * @min 1
   * @max 4
   * @example 1
   */
  priority?: number;
  /** A URN to a country. `Protected Data` Updates can change it, but it cannot be removed. */
  country?: Country;
  /**
   * The phone number extension
   * @maxLength 6
   * @example "5"
   */
  extension?: string;
  /** Whether SMS is enabled for this phone number */
  supportsSMS?: boolean;
}

export type CreateProfilePhoneNumber = UtilRequiredKeys<PhoneNumber, 'phoneType' | 'number' | 'priority' | 'country'>;

export interface ModifyContactPhoneNumber {
  /**
   * URN to the Phone Type.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:phoneType:[A-Z_/]+$
   * @example "urn:com.ehi:xqa:reference:renter:phoneType:MOBILE_CELL"
   */
  phoneType: string;
  /**
   * The phone number in E.164 format.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ••••0665
   * @format phone
   * @example "3149843012"
   */
  number: string;
  /**
   * The ordinal describing the priority of the phone number
   * @min 1
   * @max 4
   * @example 1
   */
  priority?: number;
  /** A URN to a country. `Protected Data` Updates can change it, but it cannot be removed. */
  country: Country;
  /**
   * The phone number extension
   * @maxLength 6
   * @example "5"
   */
  extension?: string;
  /** Whether SMS is enabled for this phone number */
  supportsSMS?: boolean;
}

/**
 * The phone number in E.164 format.
 *
 * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
 *
 * Masked example: ••••0665
 * @format phone
 * @maxLength 15
 * @example "3149843012"
 */
export type SearchPhoneNumber = string;

export interface SearchProfilePhoneNumber {
  /**
   * URN to the Phone Type.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:phoneType:[A-Z_/]+$
   * @example "urn:com.ehi:xqa:reference:renter:phoneType:MOBILE_CELL"
   */
  phoneType?: string;
  /**
   * The phone number in E.164 format.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ••••0665
   * @format phone
   * @maxLength 15
   * @example "3149843012"
   */
  number?: string;
  /**
   * The ordinal describing the priority of the phone number
   * @min 1
   * @max 4
   * @example 1
   */
  priority?: number;
  /**
   * The phone number extension
   * @maxLength 6
   * @example "5"
   */
  extension?: string;
}

/** A reference document related to the Do Not Rent */
export interface ReferenceDocument {
  /**
   * The reference document number.
   * @maxLength 20
   * @example "123456"
   */
  number?: string;
  /**
   * URN to the branch relating to the Do Not Rent
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z]+:location:branch:[0-9]{7}$
   * @example "urn:com.ehi:xqa:location:branch:1234567"
   */
  branch?: string;
  /**
   * The amount owed. Follows the pattern:
   *
   * `^\${0,1}[0-9]{0,2},{0,1}[0-9]{0,3}\.[0-9]{0,2}$`
   *
   * **Note: Some existing data may not conform to this pattern.**
   * @example "$312.60"
   */
  amountOwed?: string;
  /**
   * An ordinal describing the order of a series of reference documents
   * @min 1
   * @max 4
   * @example 1
   */
  ordinal?: number;
  /**
   * An enum describing the Reference Document Type
   * @format enum
   * @example "CLAIM_NUMBER"
   */
  type?: 'ECARS_RENTAL_NUMBER' | 'ODYSSEY_RENTAL_NUMBER' | 'CLAIM_NUMBER';
}

export interface RenterWarning {
  /**
   * URN to the Renter Warning Type
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:renterWarningType:[A-Z_]+$
   * @example "urn:com.ehi:xqa:reference:renter:renterWarningType:CHARGEBACK"
   */
  reason?: string;
  /**
   * The report date of the warning
   * @format date
   * @example "2021-02-22"
   */
  reportDate?: string;
  /**
   * A URN to the department
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:renter:department:[0-9]{1,15}$
   * @example "urn:com.ehi:xqa:renter:department:806270485790722"
   */
  department?: string;
  /** A list of messages related to the Do Not Rent */
  messages?: string[];
  /**
   * A list of reference documents related to the Do Not Rent
   * @max 4
   */
  referenceDocuments?: ReferenceDocument[];
}

export type RenterWarningWithLicenseInfo = RenterWarning & {
  licenseInformation?: LicenseInformation;
};

/** @example true */
export type RenterWarningFlag = boolean;

export interface LicenseInformation {
  /**
   * The license number.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ••••2777
   * @maxLength 25
   * @example "F109283742777"
   */
  number?: string;
  /** A URN to a country. `Protected Data` Updates can change it, but it cannot be removed. */
  country?: Country;
  /**
   * A URN to a country subdivision.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: urn:com.ehi:xqa:reference:location:countrySubdivision:•••
   */
  countrySubdivision?: CountrySubdivision;
  /**
   * The license recipient's date of birth in YYYY-MM-DD format. Cannot be a future date.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ••••-••-22
   */
  dateOfBirth?: DateOfBirth;
}

/**
 * Date of birth object
 * @pattern ^[\d\-•]{10}$
 * @example "1990-02-22"
 */
export type DateOfBirth = string;

export interface SearchProfileDateOfBirth {
  /**
   * Date of birth object
   * @pattern ^[\d\-•]{10}$
   * @example "1990-02-22"
   */
  date?: string;
}

export type CreateProfileRequestBase = BaseCreateProfileRequestBase &
  (
    | BaseCreateProfileRequestBaseTypeMapping<'ALAMO_INSIDERS', CreateAlamoProfileRequest>
    | BaseCreateProfileRequestBaseTypeMapping<'ENTERPRISE_PLUS', CreateEnterprisePlusProfileRequest>
    | BaseCreateProfileRequestBaseTypeMapping<'EMERALD_CLUB', CreateEmeraldClubProfileRequest>
    | BaseCreateProfileRequestBaseTypeMapping<'NON_LOYALTY', CreateDriverProfileRequest>
  );

export interface NotificationOptions {
  /** How you would like to receive notifications. Multiple options may be chosen */
  notificationType?: ('PDF' | 'EMAIL')[];
}

export type CreateDriverProfileRequestObject = CreateDriverProfileRequest;

export type CreateDriverProfileRequest = BaseCreateProfileRequestBase & {
  profile: CreateRequestNonLoyaltyProfile;
};

export type CreateAlamoProfileRequestObject = CreateAlamoProfileRequest;

export type CreateAlamoProfileRequest = BaseCreateProfileRequestBase & {
  profile: CreateRequestAlamoProfile;
};

export type CreateEnterprisePlusProfileRequestObject = CreateEnterprisePlusProfileRequest;

export type CreateEnterprisePlusProfileRequest = BaseCreateProfileRequestBase & {
  profile: CreateRequestEnterprisePlusProfile;
};

export type CreateEmeraldClubProfileRequestObject = CreateEmeraldClubProfileRequest;

export type CreateEmeraldClubProfileRequest = BaseCreateProfileRequestBase & {
  profile: CreateRequestEmeraldClubProfile;
};

export interface CreateRequestDriverProfile {
  name: CreateProfileName;
  emailAddresses?: CreateProfileEmailAddresses;
  /** URN to the gender */
  gender?: GenderURN;
  /** @minItems 1 */
  legalIdentifications?: CreateProfileLegalIdentification[];
  /** Whether or not the driver profile is active. */
  isActive?: ProfileIsActive;
  /** The employee id */
  employeeIdentificationNumber?: EmployeeIdentificationNumber;
  /** @example false */
  declinedToProvideInsurance?: boolean;
  insurancePolicies?: InsurancePolicy[];
}

export type CreateRequestAlamoProfile = UtilRequiredKeys<CreateRequestDriverProfile, 'emailAddresses'> & {
  address: CreateAlamoProfileAddress;
  phoneNumbers?: DriverProfilePhoneNumbers;
  birthInformation?: BirthInformation;
  loyaltyMembership: CreateAlamoMembership;
};

export type CreateRequestEnterprisePlusProfile = UtilRequiredKeys<
  CreateRequestDriverProfile,
  'legalIdentifications'
> & {
  address: CreateECEPProfileAddress;
  phoneNumbers: CreateProfilePhoneNumbers;
  birthInformation: CreateProfileBirthInformation;
  loyaltyMembership: CreateEnterprisePlusMembership;
};

export type CreateRequestEmeraldClubProfile = UtilRequiredKeys<CreateRequestDriverProfile, 'legalIdentifications'> & {
  address: CreateECEPProfileAddress;
  emailAddresses?: CreateProfileEmailAddresses;
  phoneNumbers: CreateProfilePhoneNumbers;
  birthInformation: CreateProfileBirthInformation;
  loyaltyMembership: CreateEmeraldClubMembership;
};

export type CreateRequestNonLoyaltyProfile = UtilRequiredKeys<
  CreateRequestDriverProfile,
  'name' | 'legalIdentifications'
> & {
  name: CreateProfileName;
  address?: Address;
  phoneNumbers?: DriverProfilePhoneNumbers;
  birthInformation: CreateProfileBirthInformation;
  /** Only BillingAccount is supported for Subscription paymentMethod. */
  subscription?: Subscription;
};

export type WholeDriverProfileResponse = DriverProfile & Preferences;

/** Represents a full driver profile excluding loyalty membership. */
export interface DriverProfile {
  /** URN to the driver profile */
  urn?: DriverProfileURN;
  driverId?: DriverId;
  /** Name of an individual */
  name?: Name;
  address?: Address;
  emailAddresses?: DriverProfileAddresses;
  phoneNumbers?: DriverProfilePhoneNumbers;
  birthInformation?: BirthInformation;
  /** Only BillingAccount is supported for Subscription paymentMethod. */
  subscription?: Subscription;
  legalIdentifications?: LegalIdentification[];
  renterWarning?: RenterWarningWithLicenseInfo;
  /** URN to the gender */
  gender?: GenderURN;
  /**
   * The age of the driver.
   * @min 16
   * @example 30
   */
  age?: number;
  rentalStatistics?: RentalStatistics[];
  /** Information regarding the driver profile's last rental. */
  lastRental?: LastRental;
  /** Whether or not the driver profile is active. */
  isActive?: ProfileIsActive;
  /** URN to the driver profile */
  parentProfile?: DriverProfileURN;
  /** The employee id */
  employeeIdentificationNumber?: EmployeeIdentificationNumber;
  /** @example false */
  declinedToProvideInsurance?: boolean;
  /**
   * @format date
   * @example "1990-02-22"
   */
  declinedToProvideInsuranceDate?: string;
  insurancePolicies?: InsurancePolicy[];
  /**
   * @format date
   * @example "1990-02-22"
   */
  creationDate?: string;
}

/**
 * The employee id
 * @maxLength 30
 * @pattern ^[a-zA-Z0-9]+$
 * @example "E123RS"
 */
export type EmployeeIdentificationNumber = string;

/** Whether or not the driver profile is active. */
export type ProfileIsActive = boolean;

export type DriverProfileWithMembership = DriverProfile & {
  /**
   * Describes a driver's membership to a loyalty program.
   *
   * Includes the one club representation of a loyalty membership.
   */
  loyaltyMembership?: UnifiedLoyaltyMembership;
};

/** Parent class for preferences */
export interface Preferences {
  type?: 'PREFERENCES' | 'EMERALD_CLUB_PREFERENCES';
  /** Whether or not GPS was accepted */
  gpsAccepted?: boolean;
  /** Whether or not to include fuel service. */
  includeFuelService?: boolean;
  /** Whether or not to email the invoice to the driver. */
  emailInvoice?: boolean;
  /**
   * Global Email Opt In applies to all loyalty memberships and
   * it captures the consumers desire to opt in or opt out of
   * all email communication.
   */
  globalEmailOptIn?: boolean;
}

/** A collection of statistics regarding the driver profile. */
export interface RentalStatistics {
  /** URN to the Brand */
  brand: BrandURN;
  /**
   * Rentals belonging to the driver profile in the current year.
   * @min 0
   * @example 5
   */
  yearToDateRentals: number;
  /**
   * Total rental days belonging to the driver profile in the current year.
   * @min 0
   * @example 17
   */
  yearToDateRentalDays: number;
  /**
   * Total lifetime rentals belonging to the driver profile.
   * @min 0
   * @example 20
   */
  lifetimeRentals: number;
  /**
   * Total lifetime rental days belonging to the driver profile.
   * @min 0
   * @example 45
   */
  lifetimeRentalDays: number;
}

/** Information regarding the driver profile's last rental. */
export interface LastRental {
  /** URN to the rental Agreement */
  rentalAgreement: RentalAgreementURN;
  /** Information about the pickup of a vehicle during a rental. */
  pickupInformation?: PickupInformation;
  /** Information about the return of a vehicle during a rental. */
  returnInformation?: ReturnInformation;
  /** A rented vehicle. */
  vehicle?: Vehicle;
}

/** Information about the pickup of a vehicle during a rental. */
export interface PickupInformation {
  /**
   * The date of pickup.
   * @pattern ^[\d\-•]{10}$
   */
  date: string;
  /** URN to the branch */
  branch: BranchURN;
}

/** Information about the pickup of a vehicle during a rental. */
export interface PickupInformationWithNoMaskedDate {
  /**
   * The date of pickup.
   * @format date
   * @example "2022-06-30T00:00:00.000Z"
   */
  date: string;
  /** URN to the branch */
  branch: BranchURN;
}

/** Information about the pickup of a vehicle during a rental. */
export interface PickupInformationWithDateTime {
  /**
   * The date of pickup.
   * @format date-time
   * @example "2019-10-30T07:20:50.500Z"
   */
  date: string;
  /** URN to the branch */
  branch: BranchURN;
}

/** Information about the return of a vehicle during a rental. */
export interface ReturnInformation {
  /**
   * The date of return.
   * @pattern ^[\d\-•]{10}$
   */
  date: string;
  /** URN to the branch */
  branch: BranchURN;
}

/** Information about the return of a vehicle during a rental. */
export interface ReturnInformationWithNoMaskedDate {
  /**
   * The date of return.
   * @format date
   * @example "2022-06-30T00:00:00.000Z"
   */
  date: string;
  /** URN to the branch */
  branch?: BranchURN;
}

/** Information about the return of a vehicle during a rental. */
export interface ReturnInformationWithDateTime {
  /**
   * The date of return.
   * @format date-time
   * @example "2019-10-30T07:20:50.500Z"
   */
  date: string;
  /** URN to the branch */
  branch: BranchURN;
}

/** A rented vehicle. */
export interface Vehicle {
  /**
   * The model year of the vehicle.
   * @pattern ^[0-9]{4}$
   * @example "2014"
   */
  year?: string;
  /**
   * The make of the vehicle.
   * @minLength 0
   * @maxLength 8
   * @example "Toyota"
   */
  make?: string;
  /**
   * The model of the vehicle.
   * @minLength 0
   * @maxLength 8
   * @example "Camry"
   */
  model?: string;
  /**
   * The series code of the vehicle, which is limited to 8 characters. The series is used by EHI to distinguish differences between vehicles sharing
   * the same model, but which have slightly different specification details.
   * @maxLength 8
   * @example "1SG"
   */
  series?: string;
}

export interface CreateMembershipBase {
  /** URN to the Loyalty Program. */
  loyaltyProgram?: LoyaltyProgramURN;
  /** URN to the marketing source. */
  marketingSource?: MarketingSource;
  /** Whether the loyalty membership is cancelled. */
  isCancelled?: boolean;
  /** The alternate id of the contract associated with the profile. */
  rateContract?: EditableRateContract;
  acceptedTermsAndConditions?: CreateAcceptedTermsAndConditions;
  /**
   * Notes on the membership
   * @maxLength 4000
   */
  notes?: string;
}

export type CreateAlamoMembership = CreateMembershipBase & {
  travelPartnerPrograms?: TravelPartnerProgram[];
  /** Parent class for preferences */
  preferences?: Preferences;
};

export type CreateEnterprisePlusMembership = CreateMembershipBase & {
  /** the Individual membership number */
  number?: LoyaltyProgramNumber;
  /** Parent class for preferences */
  preferences?: Preferences;
  tier?: EnterprisePlusTier;
  /** @max 8 */
  paymentMethods?: PaymentMethodItem[];
};

export type CreateEmeraldClubMembership = CreateMembershipBase & {
  /** the Individual membership number */
  number?: LoyaltyProgramNumber;
  /** Whether the profile is earning travel partner program rewards. For Alamo Insiders this value will always be set to true. */
  isEarningTravelPartnerProgramReward: IsEarningTravelPartnerProgramReward;
  travelPartnerPrograms?: TravelPartnerProgram[];
  /** @max 8 */
  paymentMethods?: PaymentMethodItem[];
  tier?: EmeraldClubTier;
  regionalProtections?: MembershipRegionalProtection[];
  declinedLevelsOfService?: string[];
  /** Parent class for preferences */
  preferences?: Preferences;
};

/** Describes a driver's membership to a loyalty program. */
export type LoyaltyMembership =
  | AlamoInsidersLoyaltyMembership
  | EnterprisePlusLoyaltyMembership
  | EmeraldClubLoyaltyMembership;

/**
 * Describes a driver's membership to a loyalty program.
 *
 * Includes the one club representation of a loyalty membership.
 */
export type UnifiedLoyaltyMembership =
  | AlamoInsidersLoyaltyMembership
  | EnterprisePlusLoyaltyMembership
  | EmeraldClubLoyaltyMembership
  | OneClubLoyaltyMembershipWithPoints;

export type LoyaltyMembershipBase = BaseLoyaltyMembershipBase &
  (
    | BaseLoyaltyMembershipBaseTypeMapping<'ALAMO_INSIDERS', AlamoInsidersLoyaltyMembership>
    | BaseLoyaltyMembershipBaseTypeMapping<'ENTERPRISE_PLUS', EnterprisePlusLoyaltyMembership>
    | BaseLoyaltyMembershipBaseTypeMapping<'EMERALD_CLUB', EmeraldClubLoyaltyMembership>
    | BaseLoyaltyMembershipBaseTypeMapping<'ONE_CLUB', OneClubLoyaltyMembership>
  );

export type AlamoInsidersLoyaltyMembership = BaseLoyaltyMembershipBase & {
  /** Parent class for preferences */
  preferences?: Preferences;
};

export type EnterprisePlusLoyaltyMembership = BaseLoyaltyMembershipBase & {
  tier?: EnterprisePlusTier;
  /** @max 8 */
  paymentMethods?: PaymentMethodItem[];
  /** Parent class for preferences */
  preferences?: Preferences;
  /** Points on a membership */
  points?: Points;
};

export type EmeraldClubLoyaltyMembership = BaseLoyaltyMembershipBase & {
  /** Parent class for preferences */
  preferences?: Preferences;
  /** @max 8 */
  paymentMethods?: PaymentMethodItem[];
  tier?: EmeraldClubTier;
  coupons?: MembershipCoupon[];
  /** The total available coupons for the driver profile */
  availableCouponCount?: AvailableCouponCount;
  credits?: Credit[];
  /** Whether MRA is complete for the membership */
  isMRAComplete?: IsMRAComplete;
  regionalProtections?: MembershipRegionalProtection[];
  /** The total credits on the loyalty membership */
  creditBalance?: CreditBalance;
  /** How many credits are needed to receive a coupon */
  creditsToNextCoupon?: CreditsToNextCoupon;
  declinedLevelsOfService?: string[];
  /**
   * An estimate on how many points a membership has after converting existing
   * coupons and credits to points
   */
  predictedPoints?: PredictedPoints;
  /**
   * Whether the points have been migrated for this membership
   * @deprecated
   */
  isBankMigrated?: boolean;
  /**
   * The date and time that the bank was migrated
   * @deprecated
   * @format date-time
   */
  bankMigratedDateTime?: string;
  points?: Points;
};

/** Whether MRA is complete for the membership */
export type IsMRAComplete = boolean;

/**
 * The total available coupons for the driver profile
 * @format int32
 * @min 0
 * @example 3
 */
export type AvailableCouponCount = number;

/**
 * The total credits on the loyalty membership
 * @format int32
 * @min 0
 * @max 999999
 * @example 5
 */
export type CreditBalance = number;

/**
 * How many credits are needed to receive a coupon
 * @format int32
 * @min 0
 * @max 999
 * @example 7
 */
export type CreditsToNextCoupon = number;

/**
 * An estimate on how many points a membership has after converting existing
 * coupons and credits to points
 * @deprecated
 * @format int32
 * @min 0
 * @example 2932
 */
export type PredictedPoints = number;

export type OneClubLoyaltyMembership = BaseLoyaltyMembershipBase & {
  /** Parent class for preferences */
  preferences?: Preferences;
  /** @max 8 */
  paymentMethods?: PaymentMethodItem[];
  tier?: EmeraldClubTier;
  regionalProtections?: MembershipRegionalProtection[];
  declinedLevelsOfService?: string[];
  /** Whether MRA is complete for the membership */
  isMRAComplete?: boolean;
};

export type OneClubLoyaltyMembershipWithPoints = OneClubLoyaltyMembership & {
  /** Points on a membership */
  points?: Points;
};

export type EnterprisePlusLoyaltyMembershipWithPoints = EnterprisePlusLoyaltyMembership & {
  /** Points on a membership */
  points?: Points;
};

/**
 * the Individual membership number
 * @pattern ^[A-Za-z0-9]{1,9}$
 * @example "12a557b76"
 */
export type LoyaltyProgramNumber = string;

export interface ModifyRateContractResponse {
  /** Rate contract or contract Id associated with the profile. */
  rateContract?: RateContract;
  tier?: EnterprisePlusTier;
}

/** The alternate id of the contract associated with the profile. */
export interface EditableRateContract {
  /**
   * The alternate id of the contract
   * @example "EMXL02"
   */
  alternateContractId: string;
}

/** Rate contract or contract Id associated with the profile. */
export interface RateContract {
  /**
   * The name of the rate contract
   * @example "ENTERPRISE PLUS"
   */
  name?: string;
  /** URN to the Brand */
  brand?: BrandURN;
  /**
   * The id of the contract
   * @example "EMXL01"
   */
  contractId?: string;
  /**
   * The alternate id of the contract
   * @example "EMXL02"
   */
  alternateContractId?: string;
}

/** Affiliated Travel Partner Program to earn partner rewards instead of our loyalty program rewards. */
export interface TravelPartnerProgram {
  /** URN to the Brand */
  brand: BrandURN;
  /**
   * Travel Partner Code
   * @maxLength 3
   * @example "AA"
   */
  program: string;
  /**
   * The travel partner program membership id
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ••••9789
   * @maxLength 25
   * @example "AAA12345"
   */
  membershipId: string;
  /**
   * The travel partner program expiration date
   * @format date
   * @example "2021-02-22"
   */
  expirationDate?: string;
  /** Acceptance of the fee, if any associated with the Travel Partner Program. */
  surchargeOptIn?: boolean;
  /** Whether this travel partner program is the default for the profile. */
  isDefault: boolean;
}

/** Describes what terms and conditions have been accepted */
export interface AcceptedTermsAndConditions {
  /**
   * The accepted version
   * @maxLength 256
   * @pattern \d+(?:\.\d+)+
   * @example "1.2"
   */
  acceptedVersion: string;
  /** Whether the latest version of the terms and conditions have been accepted */
  hasAcceptedLatest?: boolean;
  /**
   * The date that the terms and conditions were accepted
   * @format date
   */
  acceptedDate?: string;
}

export type CreateAcceptedTermsAndConditions = UtilRequiredKeys<AcceptedTermsAndConditions, 'acceptedVersion'>;

/** Describes what terms and conditions have been accepted */
export interface FrictionlessTermsAndConditions {
  /**
   * The accepted version.
   * @maxLength 256
   * @example "1.2"
   */
  version?: string;
  /** Indicates if the loyalty program member has accepted or declined the terms & conditions. */
  isAccepted: boolean;
  /**
   * The date that the terms and conditions were modified.
   * @format date
   */
  lastModifiedDate?: string;
}

/** Whether the profile is earning travel partner program rewards. For Alamo Insiders this value will always be set to true. */
export type IsEarningTravelPartnerProgramReward = boolean;

/** Describes the added payment method as well as other fields that have been affected. */
export interface PaymentMethodResponseObject {
  /** Whether MRA is complete for the membership */
  isMRAComplete?: IsMRAComplete;
  /** @maxItems 8 */
  paymentMethods?: PaymentMethodItem[];
}

/** Response for enroll loyalty. */
export interface EnrollLoyaltyMethodResponseObject {
  /**
   * Describes a driver's membership to a loyalty program.
   *
   * Includes the one club representation of a loyalty membership.
   */
  loyaltyMembership?: UnifiedLoyaltyMembership;
}

/** Points on a membership */
export interface Points {
  /** URN to the Points Bank Status */
  status?: PointsBankStatusURN;
  /** The available balance of points on a membership */
  availableBalance?: Balance;
  /** URN to the Comarch Tier */
  comarchTier?: ComarchTierURN;
  /** Describes fields for Loyalty Bank Points and Tier Information. */
  loyaltyBankTierProgress?: LoyaltyBankTierProgress;
}

/** The available balance of points on a membership */
export interface Balance {
  /**
   * The points available
   * @example 2000
   */
  value?: number;
  /**
   * The expiration date of the points
   * @format date
   */
  expirationDate?: string;
}

/** Free Day Coupon */
export interface MembershipCoupon {
  /**
   * The id of the coupon
   * @pattern ^[0-9]{1,9}$
   * @example "2282947"
   */
  couponId?: string;
  /** A period of time */
  effectivePeriod?: Period;
  /** Contains redepemtion information for a coupon */
  redemption?: Redemption;
}

/** A period of time */
export interface Period {
  /**
   * Start of period
   * @format date-time
   */
  startDateTime?: string;
  /**
   * End of period
   * @format date-time
   */
  endDateTime?: string;
}

/** Contains redepemtion information for a coupon */
export interface Redemption {
  /** URN to the rental Agreement */
  rentalAgreement?: RentalAgreementURN;
  /** URN to the reservation */
  reservation?: ReservationURN;
  /**
   * The date that the reservation was applied
   * @format date-time
   */
  reservationAppliedDateTime?: string;
  /**
   * The date that the rental agreement was applied
   * @format date-time
   */
  pickUpDateTime?: string;
}

/** Regional protections on a loyalty membership */
export interface MembershipRegionalProtection {
  /** URN to the Region (Renter) */
  region: RegionURN;
  /** Whether the MRA has been signed for the region */
  isMRASigned?: boolean;
  protections?: Protection[];
}

/** A protection on a loyalty membership */
export interface Protection {
  /** URN to the Regional Protection Type */
  type: RegionalProtectionTypeURN;
  /** Whether the protection has been accepted. A null value is not allowed. */
  isAccepted: ProtectionIsAccepted;
  /** Whether the protection is signed */
  isSigned: boolean;
}

/** Regional protections on a loyalty membership */
export interface ModifyMembershipRegionalProtection {
  /** URN to the Region (Renter) */
  region: RegionURN;
  protections?: ModifyProtection[];
}

/** A protection on a loyalty membership */
export interface ModifyProtection {
  /** URN to the Regional Protection Type */
  type: RegionalProtectionTypeURN;
  /**
   * Whether the protection has been accepted. An actual value must be provided when managing
   * protections, so null is not allowed.
   */
  isAccepted: boolean;
  /** Whether the protection is signed */
  isSigned: boolean;
}

/** A credit on a loyalty membership with either a Rental Agreement or an Employee ID. */
export interface Credit {
  /** URN to the rental Agreement */
  rentalAgreement?: RentalAgreementURN;
  /** The employee id */
  employeeIdentificationNumber?: EmployeeIdentificationNumber;
  /**
   * The date the credit was earned
   * @format date
   */
  earnedDate?: string;
  /** Comments regarding the credit */
  comments?: string;
}

/**
 * Contains Master Rental Agreement Selections information for a profile in relation to a given renting location.
 *
 * Renter warning information will be returned if the driver is on Do Not Rent.
 */
export interface MRASelectionsResponse {
  mraInformation?: MembershipMRAInformation;
  products?: MembershipProduct[];
}

export interface MembershipMRAInformation {
  /** Whether Master Rental Agreement is complete for the membership */
  isMRAComplete?: boolean;
  /** Whether the Master Rental Agreement is signed for the renting location */
  isMRASignedForRentingLocation?: boolean;
}

/** Whether the protection has been accepted. A null value is not allowed. */
export type ProtectionIsAccepted = boolean;

/** Response fields for a search by media types other than driverId or membershipNumber */
export interface SearchProfileSearchResult {
  /** Patterned given name and surname fields for search */
  name?: SearchNameObject;
  birthInformation?: SearchProfileDateOfBirth;
  driverId?: DriverId;
  /** URN to the driver profile */
  urn?: DriverProfileURN;
  phoneNumbers?: SearchProfilePhoneNumber[];
  address?: Address;
  /**
   * - If an appropriate membership program is passed, the returned object will have a matching loyaltyMembershipProgram.
   * - If membership program is passed as empty in the request the non-loyalty profile is returned.
   * - If non-loyalty is passed for a loyalty member the non-loyalty profile information is returned.
   */
  loyaltyMembership?: SearchProfileLoyaltyMembershipSearchResult;
  legalIdentifications?: SearchProfileLegalIdentification[];
  renterWarningFlag?: RenterWarningFlag;
}

export interface SearchResult {
  /** Name of an individual */
  name?: Name;
  birthInformation?: BirthInformation;
  driverId?: DriverId;
  /** URN to the driver profile */
  urn?: DriverProfileURN;
  phoneNumbers?: DriverProfilePhoneNumbers;
  emailAddresses?: DriverProfileAddresses;
  address?: Address;
  /**
   * - If an appropriate membership program is passed, the returned object will have a matching loyaltyMembershipProgram.
   * - If membership program is passed as empty in the request the non-loyalty profile is returned.
   * - If non-loyalty is passed for a loyalty member the non-loyalty profile information is returned.
   */
  loyaltyMembership?: LoyaltyMembershipSearchResult;
  legalIdentifications?: LegalIdentification[];
  renterWarning?: RenterWarning;
  renterWarningFlag?: RenterWarningFlag;
}

export interface SearchSummaryResult {
  /** Name of an individual */
  name?: Name;
  driverId?: DriverId;
  /** URN to the driver profile */
  urn?: DriverProfileURN;
  /**
   * - If an appropriate membership program is passed, the returned object will have a matching loyaltyMembershipProgram.
   * - If membership program is passed as empty in the request the non-loyalty profile is returned.
   * - If non-loyalty is passed for a loyalty member the non-loyalty profile information is returned.
   */
  loyaltyMembership?: LoyaltyMembershipSearchSummaryResult;
  address?: Address;
  renterWarningFlag?: RenterWarningFlag;
}

/**
 * - If an appropriate membership program is passed, the returned object will have a matching loyaltyMembershipProgram.
 * - If membership program is passed as empty in the request the non-loyalty profile is returned.
 * - If non-loyalty is passed for a loyalty member the non-loyalty profile information is returned.
 */
export type SearchProfileLoyaltyMembershipSearchResult =
  | SearchProfileEmeraldClubMembershipSearchResult
  | SearchProfileAlamoInsidersMembershipSearchResult
  | SearchProfileEnterprisePlusMembershipSearchResult;

export type SearchProfileLoyaltyMembershipSearchResultBase = BaseSearchProfileLoyaltyMembershipSearchResultBase &
  (
    | BaseSearchProfileLoyaltyMembershipSearchResultBaseTypeMapping<
        'EMERALD_CLUB',
        SearchProfileEmeraldClubMembershipSearchResult
      >
    | BaseSearchProfileLoyaltyMembershipSearchResultBaseTypeMapping<
        'ALAMO_INSIDERS',
        SearchProfileAlamoInsidersMembershipSearchResult
      >
    | BaseSearchProfileLoyaltyMembershipSearchResultBaseTypeMapping<
        'ENTERPRISE_PLUS',
        SearchProfileEnterprisePlusMembershipSearchResult
      >
  );

export type SearchProfileAlamoInsidersMembershipSearchResult = BaseSearchProfileLoyaltyMembershipSearchResultBase;

export type SearchProfileEnterprisePlusMembershipSearchResult = BaseSearchProfileLoyaltyMembershipSearchResultBase & {
  tier?: SearchProfileEnterprisePlusTier;
};

export type SearchProfileEmeraldClubMembershipSearchResult = BaseSearchProfileLoyaltyMembershipSearchResultBase & {
  tier?: SearchProfileEmeraldClubTier;
};

/**
 * - If an appropriate membership program is passed, the returned object will have a matching loyaltyMembershipProgram.
 * - If membership program is passed as empty in the request the non-loyalty profile is returned.
 * - If non-loyalty is passed for a loyalty member the non-loyalty profile information is returned.
 */
export type LoyaltyMembershipSearchSummaryResult =
  | EmeraldClubMembershipSearchSummaryResult
  | AlamoInsidersMembershipSearchSummaryResult
  | EnterprisePlusMembershipSearchSummaryResult;

/**
 * - If an appropriate membership program is passed, the returned object will have a matching loyaltyMembershipProgram.
 * - If membership program is passed as empty in the request the non-loyalty profile is returned.
 * - If non-loyalty is passed for a loyalty member the non-loyalty profile information is returned.
 */
export type LoyaltyMembershipSearchResult =
  | EmeraldClubMembershipSearchResult
  | AlamoInsidersMembershipSearchResult
  | EnterprisePlusMembershipSearchResult;

export type LoyaltyMembershipSearchResultBase = BaseLoyaltyMembershipSearchResultBase &
  (
    | BaseLoyaltyMembershipSearchResultBaseTypeMapping<'EMERALD_CLUB', EmeraldClubMembershipSearchResult>
    | BaseLoyaltyMembershipSearchResultBaseTypeMapping<'ALAMO_INSIDERS', AlamoInsidersMembershipSearchResult>
    | BaseLoyaltyMembershipSearchResultBaseTypeMapping<'ENTERPRISE_PLUS', EnterprisePlusMembershipSearchResult>
  );

export type AlamoInsidersMembershipSearchResult = BaseLoyaltyMembershipSearchResultBase;

export type AlamoInsidersMembershipSearchSummaryResult = LoyaltyMembershipSearchResultBase & {
  tier?: EnterprisePlusTier;
};

export type EnterprisePlusMembershipSearchSummaryResult = LoyaltyMembershipSearchResultBase & {
  tier?: EnterprisePlusTier;
};

export type EnterprisePlusMembershipSearchResult = BaseLoyaltyMembershipSearchResultBase & {
  tier?: EnterprisePlusTier;
  /** @max 8 */
  paymentMethods?: PaymentMethodItem[];
};

export type EmeraldClubMembershipSearchSummaryResult = LoyaltyMembershipSearchResultBase & {
  tier?: EmeraldClubTier;
};

export type EmeraldClubMembershipSearchResult = BaseLoyaltyMembershipSearchResultBase & {
  tier?: EmeraldClubTier;
  declinedLevelsOfService?: string[];
  /** @max 8 */
  paymentMethods?: PaymentMethodItem[];
};

export type PaymentMethodItem = BasePaymentMethodItem &
  (
    | BasePaymentMethodItemTypeMapping<'CREDIT_CARD', CreditCard>
    | BasePaymentMethodItemTypeMapping<'BILLING_ACCOUNT', BillingAccount>
    | BasePaymentMethodItemTypeMapping<'CENTRAL_BILLING', CentralBilling>
  );

/**
 * URN to the marketing source.
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:marketingSource:[0-9]+$
 * @example "urn:com.ehi:xqa:reference:renter:marketingSource:7504"
 */
export type MarketingSource = string;

/**
 * URN to the current Emerald Club Tier
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:renter:loyaltyProgram:EMERALD_CLUB:tier:\d+$
 * @example "urn:com.ehi:xqa:renter:loyaltyProgram:EMERALD_CLUB:tier:10070641"
 */
export type EmeraldClubTierUrn = string;

/**
 * URN to the current Enterprise Plus Tier
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:renter:loyaltyProgram:ENTERPRISE_PLUS:tier:\d+$
 * @example "urn:com.ehi:xqa:renter:loyaltyProgram:ENTERPRISE_PLUS:tier:10228969"
 */
export type EnterprisePlusTierUrn = string;

/**
 * UUID of the profile
 * @pattern ^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$
 * @example "e014e1ab-c820-458d-a0ac-143b2c84c19b"
 */
export type DriverProfileUuid = string;

/**
 * URN to the Loyalty Program.
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:renter:loyaltyProgram:[A-Z_]+$
 * @example "urn:com.ehi:xqa:renter:loyaltyProgram:EMERALD_CLUB"
 */
export type LoyaltyProgramURN = string;

/**
 * URN to the Brand
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:brand:[A-Z_/]+$
 * @example "urn:com.ehi:xqa:reference:renter:brand:NATIONAL"
 */
export type BrandURN = string;

/**
 * URN to the Comarch Tier
 * @deprecated
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:comarchTier:[A-Z_]+$
 * @example "urn:com.ehi:xqa:reference:renter:comarchTier:PLUS"
 */
export type ComarchTierURN = string;

/**
 * URN to the rental Agreement
 * @format urn
 * @pattern ^urn:com\.ehi:[a-z]+:rental\.fulfillment:rentalAgreement:[a-zA-Z0-9]{1,10}$
 * @example "urn:com.ehi:xqa:rental.fulfillment:rentalAgreement:214994199"
 */
export type RentalAgreementURN = string;

/**
 * URN to the reservation
 * @format urn
 * @pattern ^urn:com\.ehi:[a-z]+:rental\.booking:reservation:[a-zA-Z0-9]{1,10}$
 * @example "urn:com.ehi:xqa:rental.booking:reservation:753324299"
 */
export type ReservationURN = string;

/**
 * URN to the Region (Location)
 * @format urn
 * @example "urn:com.ehi:xqa:location:region:RGN_BTN_99"
 */
export type LocationRegionURN = string;

/**
 * URN to the Region (Renter)
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:region:[A-Z_]+$
 * @example "urn:com.ehi:xqa:reference:renter:region:ASIA_PACIFIC"
 */
export type RegionURN = string;

/**
 * URN to the Regional Protection Type
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:regionalProtectionType:[A-Z_]+$
 * @example "urn:com.ehi:xqa:reference:renter:regionalProtectionType:DAMAGE_WAIVER_EXCESS_PROTECTION"
 */
export type RegionalProtectionTypeURN = string;

/**
 * URN to the Protection Application Status
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:protectionApplicationStatus:[A-Z_]+$
 * @example "urn:com.ehi:xqa:reference:renter:protectionApplicationStatus:INCLUDED"
 */
export type ProtectionApplicationStatusURN = string;

/**
 * URN to the driver profile
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:renter:driverProfile:[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$
 * @example "urn:com.ehi:xqa:renter:driverProfile:e014e1ab-c820-458d-a0ac-143b2c84c19b"
 */
export type DriverProfileURN = string;

/**
 * URN to the gender
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:gender:[A-Z_]+$
 * @example "urn:com.ehi:xqa:reference:renter:gender:FEMALE"
 */
export type GenderURN = string;

/**
 * URN to the branch
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z]+:location:branch:[0-9]{7}$
 * @example "urn:com.ehi:xqa:location:branch:1019999"
 */
export type BranchURN = string;

/**
 * A Uniform Resource Name that provides context for an external reference into another system.
 * @format urn
 * @pattern ^urn:com\.ehi:([A-Za-z0-9-._~@!$&'()*+,;=\/:]|[A-Za-z0-9-_:])+
 * @example "urn:com.ehi:xqa:rental:rentalAgreement:4378295891"
 */
export type ContextURN = string;

/**
 * A context for an external reference into another system for either a Reservation Number or Rental Agreement Number
 * @format urn
 * @example ["urn:com.ehi:xqa:rental.booking:reservation:8374296898","urn:com.ehi:xqa:rental.fulfillment:rentalAgreement:4378295891"]
 */
export type RetroClaimContext = string;

/**
 * URN to the Loyalty Bank Tier
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:loyaltyBankTier:[A-Z_]+$
 * @example "urn:com.ehi:xqa:reference:renter:loyaltyBankTier:PLUS"
 */
export type LoyaltyBankTierURN = string;

/**
 * URN to the Points Bank Status
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:pointsBankStatus:[A-Z_]+$
 * @example "urn:com.ehi:xqa:reference:renter:pointsBankStatus:ACTIVE"
 */
export type PointsBankStatusURN = string;

/**
 * URN to the Vehicle Class
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:vehicle:vehicleClass:[A-Z0-9]{4}$
 * @example "urn:com.ehi:xqa:reference:vehicle:vehicleClass:ICAR"
 */
export type VehicleClassURN = string;

/**
 * URN to the Loyalty Status Reason
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:renter:loyaltyStatusReason:[A-Z_]+$
 * @example "urn:com.ehi:xqa:reference:renter:loyaltyStatusReason:MERGED"
 */
export type LoyaltyStatusReasonURN = string;

/**
 * URI to a driver profile
 * @pattern ^/renter/driverProfile/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$
 * @example "/renter/driverProfile/e014e1ab-c820-458d-a0ac-143b2c84c19b"
 */
export type DriverProfileURI = string;

/**
 * Notes on the membership
 * @maxLength 4000
 */
export type Notes = string;

/** Driver proof of insurance. */
export interface InsurancePolicy {
  /**
   * Name of insurance company.
   * @maxLength 45
   * @example "State Farm"
   */
  providerName?: string;
  /**
   * Insurance company's unique identifier for driver's insurance policy.
   *
   * `Protected Data` may be masked under certain circumstances based on EHI's data masking rules.
   *
   * Masked example: ••••6777
   * @maxLength 26
   * @example "D7684-2352"
   */
  number?: string;
  /**
   * Date insured driver's policy expires.
   * @pattern ^[\d\-•]{10}$
   * @example "2024-01-01"
   */
  expirationDate?: string;
  providerPhoneNumbers?: ProviderPhoneNumber[];
}

export interface ProviderPhoneNumber {
  /**
   * @format phone
   * @pattern ^[0-9]{0,15}$
   * @example "13149843012"
   */
  number: string;
  /** A URN to a country. `Protected Data` Updates can change it, but it cannot be removed. */
  country?: Country;
  /**
   * The phone number extension
   * @maxLength 6
   * @example "5"
   */
  extension?: string;
}

export interface ManageInsuranceObject {
  /** @example false */
  declinedToProvideInsurance?: boolean;
  insurancePolicies?: InsurancePolicy[];
}

export interface CheckRenterProgramsEligibilityRequestObject {
  /** UUID of the profile */
  driverProfileUuid: DriverProfileUuid;
  /** The unique identifier for loyalty program. */
  loyaltyProgram: LoyaltyProgramId;
}

export interface CheckLoyaltyEligibilityResponseObject {
  /** UUID of the profile */
  driverProfileUuid?: DriverProfileUuid;
  isLoyaltyEligible?: boolean;
}

export interface CheckProgramEligibilityResponseObject {
  /** UUID of the profile */
  driverProfileUuid?: DriverProfileUuid;
  isFrictionlessTermsAccepted?: boolean;
  isFrictionlessEnrollmentEligible?: boolean;
  isFrictionlessExperienceEligible?: boolean;
}

export interface TravelPartnerProgramsRequestObject {
  notificationOptions?: NotificationOptions;
  /** Whether the profile is earning travel partner program rewards. For Alamo Insiders this value will always be set to true. */
  isEarningTravelPartnerProgramReward: IsEarningTravelPartnerProgramReward;
  travelPartnerPrograms?: TravelPartnerProgram[];
}

export interface TravelPartnerProgramsResponseObject {
  /** URN to the driver profile */
  urn?: DriverProfileURN;
  /** Whether the profile is earning travel partner program rewards. For Alamo Insiders this value will always be set to true. */
  isEarningTravelPartnerProgramReward?: IsEarningTravelPartnerProgramReward;
  travelPartnerProgram?: TravelPartnerProgram[];
}

export interface ModifyContactInfoResponseObject {
  address?: Address;
  /** @minItems 1 */
  emailAddresses?: ModifyContactEmailAddress[];
  /** @minItems 1 */
  phoneNumbers?: ModifyContactPhoneNumber[];
}

/** Describes a urn object that refers to transactional profile. */
export interface ApplyTransactionalProfileURNRequestObject {
  /** URN to transactional profile. */
  transactionalProfileUrn: TransactionalProfileURN;
}

/**
 * URN to transactional profile.
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:people:transactionalProfile:[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$
 * @example "urn:com.ehi:xqa:people:transactionalProfile:e014e1ab-c820-458d-a0ac-143b2c84c19b"
 */
export type TransactionalProfileURN = string;

/** Describes which properties to convert to a Driver Profile. */
export interface ApplyTransactionalProfileInformationRequestObject {
  /** URN to transactional profile. */
  transactionalProfileUrn: TransactionalProfileURN;
  notificationOptions?: NotificationOptions;
  profile: CreateRequestNonLoyaltyProfile;
}

/**
 * The expiration date of the loyalty tier
 * @format date
 * @example "2030-02-22"
 */
export type CurrentLoyaltyBankTierExpiration = string;

/** Describes fields for Loyalty Bank Points and Tier Information. */
export interface LoyaltyBankTierProgress {
  /** URN to the Loyalty Bank Tier */
  currentLoyaltyBankTier: LoyaltyBankTierURN;
  /** The expiration date of the loyalty tier */
  currentLoyaltyBankTierExpiration?: CurrentLoyaltyBankTierExpiration;
  /** URN to the Loyalty Bank Tier */
  nextLoyaltyBankTier: LoyaltyBankTierURN;
  /**
   * Total rental days belonging to the driver profile in the current year.
   * @min 0
   * @example 17
   */
  accruedRentalDays: number;
  /**
   * Rentals belonging to the driver profile in the current year.
   * @min 0
   * @example 5
   */
  accruedRentals: number;
  /**
   * Additional rental days required for next tier.
   * @min 0
   * @example 17
   */
  additionalRequiredRentalDays: number;
  /**
   * Additional rentals required for next tier.
   * @min 0
   * @example 5
   */
  additionalRequiredRentals: number;
}

/** Loyalty points redemption transaction. */
export interface LoyaltyPointsRedemptionTransaction {
  /** Redemption Transaction Id. */
  redemptionTransactionId: RedemptionTransactionId;
}

/** Cancel Redemption response object. */
export interface CancelRedemptionResponseObject {
  /**
   * Points in redemption transaction
   * @min 0
   */
  pointsReturned: number;
}

/** Retrieve Redemption Details response object. */
export interface RetrieveRedemptionDetailsResponseObject {
  /** Redemption Transaction Id. */
  redemptionTransactionId: RedemptionTransactionId;
  /**
   * Points redeemed during corresponding redemption transaction
   * @min 1
   * @example 100
   */
  pointsRedeemed: number;
}

/**
 * Redemption Transaction Id.
 * @min 1
 * @example 125194129
 */
export type RedemptionTransactionId = number;

/**
 * Represents loyalty coupon id, example free day
 * @pattern ^[a-zA-Z0-9]{1,10}$
 * @example "22829457"
 */
export type LoyaltyCouponId = string;

export interface ContractualProtections {
  /** URN to the Region (Renter) */
  region?: RegionURN;
  /** URN to the Regional Protection Type */
  type?: RegionalProtectionTypeURN;
  /**
   * Code returned from Pricing
   * @example "RAP"
   */
  pricingCode?: string;
  /** A URN to a country. `Protected Data` Updates can change it, but it cannot be removed. */
  country?: Country;
  /** URN to the Brand */
  brand?: BrandURN;
  /** Whether the protection has been accepted. A null value is not allowed. */
  isAccepted?: ProtectionIsAccepted;
}

/** Find Contractual Selections request object. */
export interface FindContractualSelectionsRequestObject {
  /** URN to the marketing source. */
  marketingSource: MarketingSource;
  /**
   * The alternate id of the contract
   * @example "EMXL02"
   */
  alternateContractId: string;
}

/** Matching Contractual Selections response object. */
export interface FindContractualSelectionsResponseObject {
  /** URN to the Loyalty Program. */
  loyaltyProgram?: LoyaltyProgramURN;
  tier?: Tier;
  /** Rate contract or contract Id associated with the profile. */
  rateContract?: RateContract;
  /** URN to the marketing source. */
  marketingSource?: MarketingSource;
  /** Parent class for preferences */
  preferences?: Preferences;
  contractualProtections?: ContractualProtections[];
}

type BaseLoyaltyProgramResponseObject = object;

type BaseLoyaltyProgramResponseObjectTypeMapping<Key, Type> = {
  type: Key;
} & Type;

interface BaseLoyaltyProgramBase {
  /** URN to the Loyalty Program. */
  urn?: LoyaltyProgramURN;
  /** The type of Loyalty Program. */
  type: LoyaltyProgramType;
  /** The name of the loyalty program. */
  name?: 'Enterprise Plus' | 'Emerald Club' | 'Alamo Insiders';
  /** The abbreviation of the loyalty program. */
  abbreviation?: 'EP' | 'NE' | 'QS';
  /** The legacy code of the loyalty program. */
  legacyCode?: 1 | 2 | 3;
  /** @minItems 1 */
  termsAndConditions?: TermsAndConditions[];
}

type BaseLoyaltyProgramBaseTypeMapping<Key, Type> = {
  type: Key;
} & Type;

interface BasePaymentMethod {
  /** The unique number used to identify the method of payment */
  referenceId?: PaymentMethodReferenceIdReadOnly;
  type: 'CREDIT_CARD' | 'BILLING_ACCOUNT' | 'CENTRAL_BILLING';
  /** Alias of payment method */
  alias?: PaymentMethodAlias;
  /** Describes whether or not this payment method is the default method. */
  isDefault: PaymentMethodIsDefault;
}

type BasePaymentMethodTypeMapping<Key, Type> = {
  type: Key;
} & Type;

interface BaseCreateProfileRequestBase {
  type: 'ALAMO_INSIDERS' | 'ENTERPRISE_PLUS' | 'EMERALD_CLUB' | 'NON_LOYALTY';
  notificationOptions?: NotificationOptions;
}

type BaseCreateProfileRequestBaseTypeMapping<Key, Type> = {
  type: Key;
} & Type;

interface BaseLoyaltyMembershipBase {
  type?: 'ALAMO_INSIDERS' | 'ENTERPRISE_PLUS' | 'EMERALD_CLUB' | 'ONE_CLUB';
  /** the Individual membership number */
  number?: LoyaltyProgramNumber;
  /** URN to the Loyalty Program. */
  loyaltyProgram?: LoyaltyProgramURN;
  /** URN to the marketing source. */
  marketingSource?: MarketingSource;
  /** Whether the loyalty membership is cancelled. */
  isCancelled?: boolean;
  /** URN to the Loyalty Status Reason */
  statusReason?: LoyaltyStatusReasonURN;
  /**
   * The date of enrollment to the loyalty program.
   * @format date
   */
  enrollDate?: string;
  /** @minItems 1 */
  rateContracts?: RateContract[];
  travelPartnerProgram?: TravelPartnerProgram[];
  /** Whether the profile is earning travel partner program rewards. For Alamo Insiders this value will always be set to true. */
  isEarningTravelPartnerProgramReward?: IsEarningTravelPartnerProgramReward;
  /** Describes what terms and conditions have been accepted */
  acceptedTermsAndConditions?: AcceptedTermsAndConditions;
  /** Describes what terms and conditions have been accepted */
  frictionlessTermsAndConditions?: FrictionlessTermsAndConditions;
  /** Whether the profile is a first time rental. */
  isFirstTimeRental?: boolean;
  /** URN to the driver profile */
  parentProfile?: DriverProfileURN;
  /** Notes on the membership */
  notes?: Notes;
  /**
   * Whether the driver is registered on the website or not.
   * @example true
   */
  isRegistered?: boolean;
}

type BaseLoyaltyMembershipBaseTypeMapping<Key, Type> = {
  type: Key;
} & Type;

interface BaseSearchProfileLoyaltyMembershipSearchResultBase {
  type: 'EMERALD_CLUB' | 'ALAMO_INSIDERS' | 'ENTERPRISE_PLUS';
  /** the Individual membership number */
  number?: LoyaltyProgramNumber;
  /** URN to the Loyalty Program. */
  loyaltyProgram?: LoyaltyProgramURN;
}

type BaseSearchProfileLoyaltyMembershipSearchResultBaseTypeMapping<Key, Type> = {
  type: Key;
} & Type;

interface BaseLoyaltyMembershipSearchResultBase {
  type: 'EMERALD_CLUB' | 'ALAMO_INSIDERS' | 'ENTERPRISE_PLUS';
  /** the Individual membership number */
  number?: LoyaltyProgramNumber;
  /** URN to the Loyalty Program. */
  loyaltyProgram?: LoyaltyProgramURN;
}

type BaseLoyaltyMembershipSearchResultBaseTypeMapping<Key, Type> = {
  type: Key;
} & Type;

type BasePaymentMethodItem = object;

type BasePaymentMethodItemTypeMapping<Key, Type> = {
  type: Key;
} & Type;

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'https://{env}.api.ehi.dev';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Driver Profile
 * @version 3.8.0
 * @license Enterprise Holdings, Inc. API Marketplace Terms of Service
 * @baseUrl https://{env}.api.ehi.dev
 * @contact API Office <APIOffice@ehi.com>
 *
 * An API for managing driver profiles
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  renter = {
    /**
     * @description Creates a new profile and, optionally, a loyalty membership.
     *
     * @tags Driver Profiles
     * @name CreateDriverProfile
     * @summary Create a Driver Profile
     * @request POST:/renter/driverProfile
     * @secure
     */
    createDriverProfile: (data: CreateAlamoProfileRequestObject, params: RequestParams = {}) =>
      this.request<DriverProfileWithMembership, Errors>({
        path: `/renter/driverProfile`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description This API provides the ability to search for driver profiles by: - Driver Id and membership program - Membership number - Name - Date of Birth - Driver's License - Phone Number - Name Phone Number - Name Email - Name Country - The order of the profiles returned in the response will follow the same order as sent in the request. If multiple objects are returned for one request, they are next ordered by Loyalty Membership - Any field(s) which do not have data will be absent from the response instead of the service returning "null" for the value. - If a driver profile is not found for the corresponding membership program, no profile, error or warning is returned. - Conditionally required, either driverId or uuid is required. - **NOTE:  Subscription Members, National Email Specials, and Deals on Wheels are not currently supported.**
     *
     * @tags Driver Profiles
     * @name SearchProfiles
     * @summary Search for Driver Profiles
     * @request POST:/renter/driverProfile/search
     * @secure
     */
    searchProfiles: (data: SearchRequestObject, params: RequestParams = {}) =>
      this.request<SearchResponse, Errors>({
        path: `/renter/driverProfile/search`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Get a Driver Profile by UUID.
     *
     * @tags Driver Profiles
     * @name GetDriverProfileByUuid
     * @summary Get a Driver Profile by UUID.
     * @request GET:/renter/driverProfile/{uuid}
     * @secure
     */
    getDriverProfileByUuid: (uuid: DriverProfileUuid, params: RequestParams = {}) =>
      this.request<DriverProfile, Errors>({
        path: `/renter/driverProfile/${uuid}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Update the Driver profile data - Conditionally required, if paymentMethodType is Billing Account or Central Billing, number is required. - Conditionally required, if paymentMethodType is Billing Account, partnerAccountId is required. - Conditionally required, if address is in USA or CAN, countrySubdivision is required. - Conditionally required, if Driver's License is in USA or CAN, countrySubdivision is required. - Conditionally required, if country is USA or CAN, expirationDate is required. If country is not USA or CAN, either expirationDate or issueDate is required. - Conditionally required, if country is not USA or CAN, either expirationDate or issueDate is required. - Conditionally required, if adding a Credit Card, Membership number is required. - Conditionally required, if creating Enterprise Signature or Executive, rateContract is required. - Conditionally required, if creating Enterprise Signature or Executive, reviewDate is required. - Conditionally required, if passing more than 1 email address, priority is required.
     *
     * @tags Driver Profiles
     * @name UpdateDriverProfile
     * @summary Update Driver Profile by UUID.
     * @request POST:/renter/driverProfile/{uuid}
     * @secure
     */
    updateDriverProfile: (
      uuid: DriverProfileUuid,
      data: ModifyDriverProfileRequestObject,
      params: RequestParams = {}
    ) =>
      this.request<ModifyDriverProfileResponseObject, Errors>({
        path: `/renter/driverProfile/${uuid}`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Get a Driver Profile's Loyalty Program Membership by UUID.
     *
     * @tags Driver Profiles
     * @name GetDriverProfileLoyaltyMembershipByUuid
     * @summary Get a Driver Profile's Loyalty Program Membership by UUID.
     * @request GET:/renter/driverProfile/{uuid}/loyaltyMembership
     * @secure
     */
    getDriverProfileLoyaltyMembershipByUuid: (uuid: DriverProfileUuid, params: RequestParams = {}) =>
      this.request<LoyaltyMembership, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Update Loyalty Membership stateful data
     *
     * @tags Driver Profiles
     * @name UpdateLoyaltyMembership
     * @summary Update Loyalty Membership stateful data.
     * @request PATCH:/renter/driverProfile/{uuid}/loyaltyMembership
     * @secure
     */
    updateLoyaltyMembership: (uuid: DriverProfileUuid, data: UpdateLoyaltyMembership, params: RequestParams = {}) =>
      this.request<any, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership`,
        method: 'PATCH',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Modify terms and conditions
     *
     * @tags Driver Profiles
     * @name ModifyTermsConditions
     * @summary Modify terms and conditions.
     * @request POST:/renter/driverProfile/{uuid}/loyaltyMembership/modify-terms-and-conditions
     * @secure
     */
    modifyTermsConditions: (
      uuid: DriverProfileUuid,
      data: ModifyTermsConditionsRequestObject,
      params: RequestParams = {}
    ) =>
      this.request<any, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership/modify-terms-and-conditions`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Modify contact info - Conditionally required, if address exists on the profile, address complex is required. - Conditionally required, if address is in the USA or CAN, countrySubdivision is required. - Conditionally required, if number of records for phoneNumber is greater than 1, priority is required. - Conditionally required, if Alamo Insider or if login credentials exist for the driver, emailAddress complex is required. - Conditionally required, if phone number exists on the profile, phoneNumber complex is required.
     *
     * @tags Driver Profiles
     * @name ModifyContactInfo
     * @summary Modify contact info.
     * @request POST:/renter/driverProfile/{uuid}/modify-contact-information
     * @secure
     */
    modifyContactInfo: (uuid: DriverProfileUuid, data: ModifyContactInfoRequestObject, params: RequestParams = {}) =>
      this.request<ModifyContactInfoResponseObject, Errors>({
        path: `/renter/driverProfile/${uuid}/modify-contact-information`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description This service allows the user to update all of the same information as the following endpoints: - POST /renter/driverProfile/{uuid} - POST /renter/driverProfile/{uuid}/modify-contact-information - POST /renter/driverProfile/{uuid}/manage-legal-identification - POST /renter/driverProfile/{uuid}/manage-insurance Requests using the `application/prs.com-ehi.driver-profile-and-loyalty-preferences+json` Content-Type header type can also modify the preferences for all Loyalty programs as the manage preferences endpoint. - POST /renter/driverProfile/{uuid}/manage-preferences All validations that are on the individual endpoints apply here as well. The required object complexes are conditionally required based on the LoyaltyProgramIdNonLoyaltyExcludeOneClub value: - ENTERPRISE_PLUS: name, legal identification, address, phone, and birth information - EMERALD_CLUB:    name, legal identification, address, phone, and birth information - ALAMO_INSIDERS:  name, address, and email - NON_LOYALTY:     name, legal identification, and birth information Note: It is expected that all existing fields should be passed in the request.  If a particular field is not included, that data will be deleted from the database.
     *
     * @tags Driver Profiles
     * @name ModifyWholeDriverProfile
     * @summary Modify any driver profile's personal info.
     * @request POST:/renter/driverProfile/{uuid}/modify-driver-profile
     * @secure
     */
    modifyWholeDriverProfile: (
      uuid: DriverProfileUuid,
      data: ModifyDriverProfileAndLoyaltyPreferencesRequestObject,
      params: RequestParams = {}
    ) =>
      this.request<WholeDriverProfileResponse, Errors>({
        path: `/renter/driverProfile/${uuid}/modify-driver-profile`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Add a payment method to the loyalty program. - Conditionally required, if paymentMethodType is Credit Card, cardBrand is required. - Conditionally required, if paymentMethodType is Billing Account or Central Billing, number is required. - Conditionally required, if paymentMethodType is Billing Account, partnerAccountId is required.
     *
     * @tags Driver Profiles
     * @name AddPaymentMethod
     * @summary Add a payment method.
     * @request POST:/renter/driverProfile/{uuid}/loyaltyMembership/add-payment-method
     * @secure
     */
    addPaymentMethod: (uuid: DriverProfileUuid, data: AddPaymentMethodRequestObject, params: RequestParams = {}) =>
      this.request<PaymentMethodResponseObject, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership/add-payment-method`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Convert non-loyalty profile to loyalty profile.
     *
     * @tags Driver Profiles
     * @name EnrollLoyalty
     * @summary Convert non-loyalty profile to loyalty profile
     * @request POST:/renter/driverProfile/{uuid}/loyaltyMembership/enroll-to-loyalty
     * @secure
     */
    enrollLoyalty: (uuid: DriverProfileUuid, data: EnrollLoyaltyMethodRequestObject, params: RequestParams = {}) =>
      this.request<EnrollLoyaltyMethodResponseObject, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership/enroll-to-loyalty`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Modify a payment method on the loyalty program.
     *
     * @tags Driver Profiles
     * @name ModifyPaymentMethod
     * @summary Modify a payment method.
     * @request POST:/renter/driverProfile/{uuid}/loyaltyMembership/modify-payment-method
     * @secure
     */
    modifyPaymentMethod: (uuid: DriverProfileUuid, data: ModifyPaymentMethodObject, params: RequestParams = {}) =>
      this.request<PaymentMethodResponseObject, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership/modify-payment-method`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Remove a payment method from the loyalty program.
     *
     * @tags Driver Profiles
     * @name RemovePaymentMethodFromLoyaltyProgram
     * @summary Remove a payment method.
     * @request POST:/renter/driverProfile/{uuid}/loyaltyMembership/remove-payment-method
     * @secure
     */
    removePaymentMethodFromLoyaltyProgram: (
      uuid: DriverProfileUuid,
      data: RemovePaymentMethodRequestObject,
      params: RequestParams = {}
    ) =>
      this.request<PaymentMethodResponseObject, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership/remove-payment-method`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Modify loyalty membership preferences by driver profile UUID if applicable.
     *
     * @tags Driver Profiles
     * @name ModifyPreferences
     * @summary Modify loyalty membership preferences.
     * @request POST:/renter/driverProfile/{uuid}/loyaltyMembership/modify-preferences
     * @secure
     */
    modifyPreferences: (uuid: DriverProfileUuid, data: ModifyPreferencesRequestObject, params: RequestParams = {}) =>
      this.request<Preferences, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership/modify-preferences`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Manage loyalty membership protections by driver profile UUID if applicable. Protections refer to specific coverage that a driver can opt into based on location.
     *
     * @tags Driver Profiles
     * @name ManageProtections
     * @summary Manage loyalty membership protections.
     * @request POST:/renter/driverProfile/{uuid}/loyaltyMembership/manage-protections
     * @secure
     */
    manageProtections: (uuid: DriverProfileUuid, data: ManageProtectionsRequestObject, params: RequestParams = {}) =>
      this.request<MembershipRegionalProtection[], Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership/manage-protections`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description The rate contract identifies business partners and pulls in all pricing and perk information that has been negotiated with that partner. The service is used to modify the rate contract.  Modifications to rate contract for Enterprise Plus may impact the tier.  Rate contract details from Pricing and the current tier will be returned in the response.
     *
     * @tags Driver Profiles
     * @name ModifyRateContract
     * @summary Modify loyalty membership rate contract
     * @request POST:/renter/driverProfile/{uuid}/loyaltyMembership/modify-rate-contract
     * @secure
     */
    modifyRateContract: (uuid: DriverProfileUuid, data: ModifyRateContractRequestObject, params: RequestParams = {}) =>
      this.request<ModifyRateContractResponse, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership/modify-rate-contract`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description The Modify Last Rental API is used to build a renter’s rental history and, where it applies, award loyalty program level upgrades and rental reward credits
     *
     * @tags Driver Profiles
     * @name ModifyLastRental
     * @summary Modify the state of last rental.
     * @request POST:/renter/driverProfile/{uuid}/modify-last-rental
     * @secure
     */
    modifyLastRental: (uuid: DriverProfileUuid, data: ModifyLastRentalRequestObject, params: RequestParams = {}) =>
      this.request<any, Errors>({
        path: `/renter/driverProfile/${uuid}/modify-last-rental`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Modify rental statistics by driver profile uuid
     *
     * @tags Driver Profiles
     * @name ModifyStatistics
     * @summary Modify rental statistics by driver profile uuid
     * @request POST:/renter/driverProfile/{uuid}/modify-rental-statistics
     * @secure
     */
    modifyStatistics: (uuid: DriverProfileUuid, data: RentalStatistics, params: RequestParams = {}) =>
      this.request<any, Errors>({
        path: `/renter/driverProfile/${uuid}/modify-rental-statistics`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Modify the state of loyalty membership status
     *
     * @tags Driver Profiles
     * @name ModifyStatus
     * @summary Modify the state of loyalty membership status.
     * @request POST:/renter/driverProfile/{uuid}/loyaltyMembership/modify-status
     * @secure
     */
    modifyStatus: (uuid: DriverProfileUuid, data: ModifyStatusRequestObject, params: RequestParams = {}) =>
      this.request<any, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership/modify-status`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Get a Driver Profile's MRA (Master Rental Agreement) Selections by driver profile UUID. **Note: Only Emerald Club is supported for this endpoint.**
     *
     * @tags Driver Profiles
     * @name GetDriverProfileMraSelectionsByUuid
     * @summary Driver Profile MRA (Master Rental Agreement) Selections
     * @request POST:/renter/driverProfile/{uuid}/loyaltyMembership/retrieve-master-rental-agreement-selections
     * @secure
     */
    getDriverProfileMraSelectionsByUuid: (
      uuid: DriverProfileUuid,
      data: MRARequestObject,
      params: RequestParams = {}
    ) =>
      this.request<MRASelectionsResponse, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership/retrieve-master-rental-agreement-selections`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Add, update, or remove profile data related to travel partner programs, by driver profile UUID.
     *
     * @tags Driver Profiles
     * @name ModifyTravelPartnerProgramsByUuid
     * @summary Manage travel partner program
     * @request POST:/renter/driverProfile/{uuid}/loyaltyMembership/manage-travel-partner-programs
     * @secure
     */
    modifyTravelPartnerProgramsByUuid: (
      uuid: DriverProfileUuid,
      data: TravelPartnerProgramsRequestObject,
      params: RequestParams = {}
    ) =>
      this.request<TravelPartnerProgramsResponseObject, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership/manage-travel-partner-programs`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Add or update driver personal insurance policy information by driver profile UUID. Previous policies are removed.
     *
     * @tags Driver Profiles
     * @name ManageInsuranceByUuid
     * @summary Manage Personal Insurance
     * @request POST:/renter/driverProfile/{uuid}/manage-insurance
     * @secure
     */
    manageInsuranceByUuid: (uuid: DriverProfileUuid, data: ManageInsuranceObject, params: RequestParams = {}) =>
      this.request<ManageInsuranceObject, Errors>({
        path: `/renter/driverProfile/${uuid}/manage-insurance`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Frictionless enrollment will be orchestrated through a series of steps and a final calculation that the profile meets the program requirements.  A series of service calls will be made to add data elements to the profile. The Check Renter Programs Eligibility endpoint will be created to return the eligibility status for Frictionless of a profile.
     *
     * @tags Driver Profiles
     * @name CheckFrictionlessEligibility
     * @summary Check Frictionless Eligibility
     * @request POST:/renter/driverProfile/check-program-eligibility
     * @secure
     */
    checkFrictionlessEligibility: (data: CheckRenterProgramsEligibilityRequestObject[], params: RequestParams = {}) =>
      this.request<CheckProgramEligibilityResponseObject[], Errors>({
        path: `/renter/driverProfile/check-program-eligibility`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Redirects to the appropriate endpoint for getting the driver profile
     *
     * @tags Driver Profiles
     * @name GetByDriverIdRedirect
     * @summary Redirect when trying to get profile by driver id
     * @request GET:/renter/driver/{driverId}
     * @secure
     */
    getByDriverIdRedirect: (driverId: DriverId, params: RequestParams = {}) =>
      this.request<any, Errors>({
        path: `/renter/driver/${driverId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get all Warning Departments
     *
     * @tags Warning Department
     * @name GetWarningDepartments
     * @summary Get a list of Warning Departments
     * @request GET:/renter/warningDepartment
     * @secure
     */
    getWarningDepartments: (params: RequestParams = {}) =>
      this.request<WarningDepartment[], Errors>({
        path: `/renter/warningDepartment`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get a Warning Department by Department Id
     *
     * @tags Warning Department
     * @name GetWarningDepartmentById
     * @summary Get a Warning Department by its id.
     * @request GET:/renter/warningDepartment/{departmentId}
     * @secure
     */
    getWarningDepartmentById: (departmentId: WarningDepartmentId, params: RequestParams = {}) =>
      this.request<WarningDepartment, Errors>({
        path: `/renter/warningDepartment/${departmentId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get all Loyalty Program URNs
     *
     * @tags Loyalty Program
     * @name GetLoyaltyPrograms
     * @summary Get all Loyalty Programs
     * @request GET:/renter/loyaltyProgram
     * @secure
     */
    getLoyaltyPrograms: (params: RequestParams = {}) =>
      this.request<LoyaltyProgramURN[], Errors>({
        path: `/renter/loyaltyProgram`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get a Loyalty Program by its id. - To retrieve a loyalty program in the existing state, supply a media type of `application/prs.com-ehi.loyalty-program+json` and the chosen program. - To retrieve the unified single version of loyalty program, supply a media type of `application/prs.com-ehi.unified-loyalty-program+json` and a program of `EMERALD_CLUB`.
     *
     * @tags Loyalty Program
     * @name GetLoyaltyProgramById
     * @summary Get a Loyalty Program by its id.
     * @request GET:/renter/loyaltyProgram/{program}
     * @secure
     */
    getLoyaltyProgramById: (program: LoyaltyProgramId, params: RequestParams = {}) =>
      this.request<LoyaltyProgram, Errors>({
        path: `/renter/loyaltyProgram/${program}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get the next Loyalty Membership Number for a new loyaly membership
     *
     * @tags Loyalty Program
     * @name GenerateNextMembershipNumber
     * @summary Get the next Loyalty Membership Number for a new loyalty membership
     * @request POST:/renter/loyaltyProgram/{program}/generate-next-membership-number
     * @secure
     */
    generateNextMembershipNumber: (program: LoyaltyProgramId, params: RequestParams = {}) =>
      this.request<LoyaltyProgramNumber, Errors>({
        path: `/renter/loyaltyProgram/${program}/generate-next-membership-number`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Manage legal identification by driver profile uuid - Conditionally required, if country is USA or CAN, expirationDate is required. If country is not USA or CAN, either expirationDate or issueDate is required. - Conditionally required, if country is not USA or CAN, either expirationDate or issueDate is required. - Conditionally required, if country is USA or CAN, countrySubdivision is required.
     *
     * @tags Driver Profiles
     * @name ManageLegalIdentificationByUuid
     * @summary Manage legal identification by driver profile uuid
     * @request POST:/renter/driverProfile/{uuid}/manage-legal-identification
     * @secure
     */
    manageLegalIdentificationByUuid: (
      uuid: DriverProfileUuid,
      data: LegalIdentificationRequestObject,
      params: RequestParams = {}
    ) =>
      this.request<ManageLegalIdentification[], Errors>({
        path: `/renter/driverProfile/${uuid}/manage-legal-identification`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description This service redeposits points if a reservation is a no-show for an Emerald Club member.
     *
     * @tags Loyalty Bank
     * @name ProcessNoShow
     * @summary Process No Show.
     * @request POST:/renter/driverProfile/{uuid}/loyaltyMembership/process-no-show
     * @secure
     */
    processNoShow: (uuid: DriverProfileUuid, data: ProcessNoShowRequestObject, params: RequestParams = {}) =>
      this.request<any, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership/process-no-show`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description This service allows existing loyalty members to request points to be redeposited in their loyalty bank. Members may decide to make this request as a result of the below reason: 1. Member didn't show up at reservation and never completed rental.
     *
     * @tags Loyalty Bank
     * @name RedepositPoints
     * @summary Redeposit points.
     * @request POST:/renter/driverProfile/{uuid}/loyaltyMembership/redeposit-points
     * @secure
     */
    redepositPoints: (uuid: DriverProfileUuid, data: RedepositPointsRequestObject, params: RequestParams = {}) =>
      this.request<any, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership/redeposit-points`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description The Retro Claim process is available to Loyalty members to allow them to claim completed rentals they believe are missing from their account activity. Members may decide to make this request as a result of the below reason: 1. Loyalty member number wasn't provided during reservation.
     *
     * @tags Loyalty Bank
     * @name RetroClaim
     * @summary Retro Claim.
     * @request POST:/renter/driverProfile/{uuid}/loyaltyMembership/retro-claim
     * @secure
     */
    retroClaim: (uuid: DriverProfileUuid, data: RetroClaimRequestObject, params: RequestParams = {}) =>
      this.request<any, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership/retro-claim`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description The new Loyalty Bank Information API will be consumed by clients and provide the ability for users to retrieve member Loyalty bank information by passing in a combination of Driver UUID and Loyalty membership program in the endpoint request URL.
     *
     * @tags Loyalty Bank
     * @name GetPointsByUuid
     * @summary Get Loyalty Bank Points by UUID.
     * @request GET:/renter/driverProfile/{uuid}/loyaltyMembership/points
     * @secure
     */
    getPointsByUuid: (uuid: DriverProfileUuid, params: RequestParams = {}) =>
      this.request<Points, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership/points`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description This service will allow subtracting points from a member's loyalty bank and will return a unique redemption transaction identifier. The member's loyalty bank is housed by an external provider, and this API acts a pass-through to that provider. It is recommended for clients to store the unique redemption transaction identifier that is returned if they need to later cancel the points redemption transaction or retrieve details about the points redemption transaction. Those functions will be managed through separate API calls that require the unique redemption transaction identifier.
     *
     * @tags Loyalty Bank
     * @name RedeemPoints
     * @summary Redeem Points.
     * @request POST:/renter/driverProfile/{uuid}/loyaltyMembership/redeem-points
     * @secure
     */
    redeemPoints: (uuid: DriverProfileUuid, data: LoyaltyPointsRedemptionRequestObject, params: RequestParams = {}) =>
      this.request<LoyaltyPointsRedemptionTransaction, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership/redeem-points`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description This service will allow cancelling a previous redemption and including those points in a new redemption request.
     *
     * @tags Loyalty Bank
     * @name ReplacePointsRedemption
     * @summary Modify loyalty points redemption.
     * @request POST:/renter/driverProfile/{uuid}/loyaltyMembership/replace-redemption
     * @secure
     */
    replacePointsRedemption: (
      uuid: DriverProfileUuid,
      data: ReplaceLoyaltyPointsRedemptionRequestObject,
      params: RequestParams = {}
    ) =>
      this.request<LoyaltyPointsRedemptionTransaction, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership/replace-redemption`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Cancel redemption transaction and add points back to member's loyalty bank. Returns balance of points on account.
     *
     * @tags Loyalty Bank
     * @name CancelRedemption
     * @summary Cancel redemption transaction.
     * @request POST:/renter/driverProfile/{uuid}/loyaltyMembership/cancel-redemption
     * @secure
     */
    cancelRedemption: (uuid: DriverProfileUuid, data: LoyaltyPointsRedemptionTransaction, params: RequestParams = {}) =>
      this.request<CancelRedemptionResponseObject, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership/cancel-redemption`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Create Driver Profile and lock Transactional Profile
     *
     * @tags Driver Profiles
     * @name ApplyTransactionalProfile
     * @summary Converts a Transactional Profile to a Driver Profile
     * @request POST:/renter/driverProfile/apply-transactional-profile
     * @secure
     */
    applyTransactionalProfile: (data: ApplyTransactionalProfileInformationRequestObject, params: RequestParams = {}) =>
      this.request<DriverProfileWithMembership, Errors>({
        path: `/renter/driverProfile/apply-transactional-profile`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Retrieves redemption details and returns the points redeemed for a Redemption ID.
     *
     * @tags Loyalty Bank
     * @name RetrieveRedemptionDetails
     * @summary Retrieve Redemption Details.
     * @request POST:/renter/driverProfile/{uuid}/loyaltyMembership/retrieve-redemption-details
     * @secure
     */
    retrieveRedemptionDetails: (
      uuid: DriverProfileUuid,
      data: LoyaltyPointsRedemptionTransaction,
      params: RequestParams = {}
    ) =>
      this.request<RetrieveRedemptionDetailsResponseObject, Errors>({
        path: `/renter/driverProfile/${uuid}/loyaltyMembership/retrieve-redemption-details`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Returns matching Contractual Selections that include default products, protections, and set tier levels.
     *
     * @tags Contractual Selections
     * @name FindContractualSelections
     * @summary Find matching Contractual Selections.
     * @request POST:/renter/find-contractual-selections
     * @secure
     */
    findContractualSelections: (data: FindContractualSelectionsRequestObject, params: RequestParams = {}) =>
      this.request<FindContractualSelectionsResponseObject, Errors>({
        path: `/renter/find-contractual-selections`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),
  };
}
