import { Location } from 'services/location/locationRollupTypes';
import { getCache, removeCache, setCache } from 'context/queryClient/queryUtil';
import { PaymentMethod } from 'services/location/locationTypes';

export const QUERY_KEY_LOCATION = 'location';
export const QUERY_KEY_LOCATION_REFERENCE_PAYMENT_TYPES = 'locationPaymentTypes';
export const QUERY_KEY_LOCATION_PAYMENT_METHODS = 'locationPaymentMethods';

export function setLocation(location: Location): void {
  setCache([QUERY_KEY_LOCATION], location);
}

export function getLocationCache(locationId: string): Location | undefined {
  return getCache([QUERY_KEY_LOCATION, locationId]);
}

export function removeLocationPaymentTypes(): void {
  removeCache({ queryKey: [QUERY_KEY_LOCATION] });
}

export function setLocationPaymentTypesCache(location: Location): void {
  setCache([QUERY_KEY_LOCATION_REFERENCE_PAYMENT_TYPES], location);
}

export function getLocationPaymentTypesCache(locationId: string): Location | undefined {
  return getCache([QUERY_KEY_LOCATION_REFERENCE_PAYMENT_TYPES, locationId]);
}

export function removeLocationPaymentTypesCache(): void {
  removeCache({ queryKey: [QUERY_KEY_LOCATION_REFERENCE_PAYMENT_TYPES] });
}

export function setLocationPaymentMethodsCache(locationId: string): void {
  setCache([QUERY_KEY_LOCATION_PAYMENT_METHODS], locationId);
}

export function getLocationPaymentMethodsCache(locationId: string): PaymentMethod[] | undefined {
  return getCache([QUERY_KEY_LOCATION_PAYMENT_METHODS, locationId]);
}

export function removeLocationPaymentMethodsCache(locationId: string): void {
  removeCache({ queryKey: [QUERY_KEY_LOCATION_PAYMENT_METHODS, locationId] });
}
