import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { LaunchDarklyFeatureFlags } from 'redux/slices/featureFlags/FeatureFlagsSliceTypes';

/**
 * This is a map of config feature flags our application and the default value it will have to LD flag set.
 * Setting this list up allows us to tell launchDarkly the specific keys we care about and only get updates for those keys.
 * The name must match what is in launch darkly as the key. The default value here should always be false we will use launchDarkly to drive everything else.
 */
export const getDefaultFeatureFlagsValues = (): LDFlagSet => {
  const featureFlags = getAppConfigCache()?.featureFlags ?? [];
  return Object.fromEntries(featureFlags.map((flag) => [flag.flagName, flag.defaultValue]));
};

/**
 * Maps LDFlagSet to LaunchDarklyFeatureFlags for feature flags store
 */
export const convertLdFeatureFlags = (flags: LDFlagSet): LaunchDarklyFeatureFlags => {
  return {
    locationFeatureFlagEnabled: flags['rpu-location-enabled'] ?? false,
    isAndroidTabletUserAgent: flags['rops-android-tablet-user-agent'] ?? false,
  };
};

/**
 * Maps config feature flags to LaunchDarklyFeatureFlags for feature flags store
 */
export const defaultLaunchDarklyFeatureFlags = (): LaunchDarklyFeatureFlags => {
  return convertLdFeatureFlags(getDefaultFeatureFlagsValues());
};
