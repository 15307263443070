import { FC, ReactNode } from 'react';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import { EhiThemeProvider } from '@ehi/ui';
import { DatePickerLocalizationProvider } from 'components/shared/i18n/DatePickerLocalizationProvider';

type AppThemeProps = {
  children: ReactNode;
};

export const AppTheme: FC<AppThemeProps> = ({ children }) => {
  return (
    <StyledEngineProvider>
      <EhiThemeProvider>
        <DatePickerLocalizationProvider>{children}</DatePickerLocalizationProvider>
      </EhiThemeProvider>
    </StyledEngineProvider>
  );
};
