import { FC, useState } from 'react';
import { LoadableView } from 'components/shared/ui/spinner/loadableView/LoadableView';
import { FullScreenSpinner } from 'components/shared/ui/spinner/FullScreenSpinner';
import { useEffectOnlyOnce } from 'hooks/useEffectOnlyonce';
import { createDefaultInstance } from 'components/shared/axios/axiosConfiguration';
import ErrorWrapper from 'components/shared/errors/ErrorWrapper';
import { AxiosConfigGuardProps } from './AxiosConfigTypes';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';

export const AxiosConfigGuard: FC<AxiosConfigGuardProps> = ({ children }: AxiosConfigGuardProps) => {
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING);

  useEffectOnlyOnce(async () => {
    try {
      createDefaultInstance();
      setLoadingState(LoadingState.SUCCESS);
    } catch (error) {
      setLoadingState(LoadingState.ERROR);
    }
  });

  return (
    <LoadableView loadingComponent={<FullScreenSpinner />} errorComponent={<ErrorWrapper />} state={loadingState}>
      {children}
    </LoadableView>
  );
};
