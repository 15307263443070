import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { RouterPaths } from 'app/router/RouterPaths';
import { InternalTransactionParams } from 'utils/routing/InternalTransactionParams';
import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { generateSearchParams } from 'utils/routing/urlUtils';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'components/shared/i18n';
import { useResSnackbarContext } from 'context/resSnackbar/ResSnackbarContext';

export type SnackbarProps = {
  num: string;
  isOpen: boolean;
};
const autoHideDuration = 6000;

export default function ReservationSnackBar({ num, isOpen }: SnackbarProps) {
  const navigate = useNavigate();
  const { setSnackBarRes } = useResSnackbarContext();
  const { t } = useTranslations();
  const message = t('quickReservation.resCreated', { resNum: num });

  const handleClose = () => {
    setSnackBarRes({ num: '', isOpen: false });
  };
  const onViewReservation = () => {
    setSnackBarRes({ num: '', isOpen: false });
    navigate(
      `${RouterPaths.PreProcessor}?${generateSearchParams({
        [InternalTransactionParams.TransactionType]: TransactionTypes.View,
        [InternalTransactionParams.Res]: num,
      })}`
    );
  };
  const action = (
    <>
      <Button color='secondary' size='small' onClick={onViewReservation}>
        {t('common.view')}
      </Button>
      <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
        <CloseIcon fontSize='small' />
      </IconButton>
    </>
  );

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isOpen}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        message={message}
        action={action}
      />
    </div>
  );
}
