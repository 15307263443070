import { useMemo } from 'react';
import { To, useSearchParams } from 'react-router-dom';
import { HashPaths, RouterPaths } from 'app/router/RouterPaths';

/**
 *
 * @param paramKeys
 * @returns Record object with param keys as keys
 */
export const useQueryParamsByKey = (paramKeys: string[]): Record<string, string> => {
  const [searchParams] = useSearchParams();

  return useMemo(() => {
    return paramKeys.reduce((all, it) => {
      all[it] = searchParams.get(it) || '';
      return all;
    }, {} as Record<string, string>);
  }, [searchParams, paramKeys]);
};

const DRIVER_ISSUE_CODES = ['BOOK2063', 'BOOK2057', 'BOOK2051'];
const WHEN_AND_WHERE_ISSUE_CODES = ['BOOK2044', 'BOOK2045', 'BOOK2046', 'BOOK2047', 'BOOK2048', 'BOOK2049', 'BOOK2050'];
const VEHICLE_ISSUE_CODES = ['BOOK2052', 'book2053'];
const ADD_ONS_ISSUE_CODES = ['book2034', 'book2035', 'book2036', 'book2039', 'book2040'];

export const getCorrelatedRoutePathForBookingError = (bookingIssueCode: string): To | undefined => {
  if (DRIVER_ISSUE_CODES.includes(bookingIssueCode)) {
    return { pathname: RouterPaths.Driver, hash: HashPaths.AddDriver };
  } else if (WHEN_AND_WHERE_ISSUE_CODES.includes(bookingIssueCode)) {
    return RouterPaths.WhenAndWhere;
  } else if (VEHICLE_ISSUE_CODES.includes(bookingIssueCode)) {
    return RouterPaths.Vehicle;
  } else if (ADD_ONS_ISSUE_CODES.includes(bookingIssueCode)) {
    return RouterPaths.AddOns;
  }
  return undefined;
};
