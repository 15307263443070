import { FC, useRef, useState } from 'react';
import { TabbedDialog, TabOption } from 'components/shared/ui/dialogs/TabbedDialog';
import {
  DriverSearch,
  NoResultSearchValuesType,
  noResultsSearchInitialValues,
} from 'components/flexFlow/driver/searchDriver/DriverSearch';
import { useTranslations } from 'components/shared/i18n';
import { CreateDriverForm } from 'components/flexFlow/driver/createDriver/CreateDriverForm';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';

export type AddDriverDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  addDriver?: (driver: DriverData) => void;
};

export enum AddDriverTabKeys {
  addDriverTab,
  createDriverTab,
}

export const AddDriverDialog: FC<AddDriverDialogProps> = ({ open, onClose, title }) => {
  const { t } = useTranslations();
  const formRef = useRef<{ handleSubmit: () => Promise<void> }>(null);
  const [selectedTab, setSelectedTab] = useState<AddDriverTabKeys>(AddDriverTabKeys.addDriverTab);
  // We need to use this to pass renter information form values to create driver, when search results are empty.
  const [noResultsFormValues, setNoResultsFormValues] =
    useState<NoResultSearchValuesType>(noResultsSearchInitialValues);

  const submitForm = async () => {
    if (formRef.current) {
      await formRef.current?.handleSubmit();
    }
  };

  const tabOptions: TabOption[] = [
    {
      label: t('driver.searchDrivers'),
      content: (
        <DriverSearch
          onClose={onClose}
          navigateToCreateDriver={(values: NoResultSearchValuesType) => {
            setNoResultsFormValues(values);
            setSelectedTab(AddDriverTabKeys.createDriverTab);
          }}
        />
      ),
      actions: {
        secondaryAction: {
          label: t('common.cancel'),
          onClick: onClose,
        },
      },
    },
    {
      label: t('driver.createNewDriver'),
      content: <CreateDriverForm onClose={onClose} formRef={formRef} noResultsFormValues={noResultsFormValues} />,
      actions: {
        primaryAction: {
          label: t('driverSearch.add'),
          onClick: submitForm,
        },
        secondaryAction: {
          label: t('common.cancel'),
          onClick: onClose,
        },
      },
    },
  ];

  return (
    <>
      <TabbedDialog
        title={title}
        open={open}
        tabOptions={tabOptions}
        onClose={onClose}
        selectedTab={selectedTab}
        onTabChange={(selected) => {
          setSelectedTab(selected);
        }}
        contentPadding={0}
        showDividers={true}
        data-testId={'addDriverDialog'}
      />
    </>
  );
};
