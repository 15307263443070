import { FC, useEffect, useMemo } from 'react';
import { BranchLookupCard } from 'components/shared/uiModels/branchLookup/branchLookupDataTypes';
import { Map, useMap } from '@vis.gl/react-google-maps';
import { BRANCH_LIST_OFFSET } from 'components/flexFlow/whenAndWhere/branchLookup/BranchCardList';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { ClusteredBranchLookupMarkers } from './ClusteredBranchLookupMarkers';

type BranchLookupMapProps = {
  branches: BranchLookupCard[];
  scrollAndSelectBranch: (stationId?: string) => void;
};
export const BranchLookupMap: FC<BranchLookupMapProps> = ({ branches, scrollAndSelectBranch }) => {
  const map = useMap();
  const appConfig = getAppConfigCache();
  const googleMapId = appConfig?.branchLookupGoogleMapId;

  const branchPositions = useMemo(() => {
    return branches.map((branch, index) => {
      return {
        key: index + 1,
        position: branch.geoLocation,
        stationId: branch.stationId,
      };
    });
  }, [branches]);

  useEffect(() => {
    if (map && branches[0].geoLocation) {
      // We need this to update the center once the map is Initialized based on search
      map?.setCenter(branches[0].geoLocation);
    }
  }, [map, branches]);

  return (
    <Map
      style={{
        height: window.innerHeight - window.innerHeight * 0.06 - BRANCH_LIST_OFFSET,
        width: '96vw',
        position: 'relative',
      }}
      defaultZoom={13}
      mapId={googleMapId}
      streetViewControl={false}
      zoomControl={false}
      fullscreenControl={false}
      mapTypeControl={false}
      defaultCenter={branches[0].geoLocation}>
      <ClusteredBranchLookupMarkers
        branches={branchPositions}
        handleMarkerClick={(stationId) => scrollAndSelectBranch(stationId)}
      />
      ;
    </Map>
  );
};
