import { Body2, H6 } from '@ehi/ui';
import { Grid } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import {
  DynamicGrid,
  EstimatedChargesContent,
  EstimatedChargesTitle,
  EstimatedTotalAmountText,
  EstimatedTotalText,
} from 'components/flexFlow/review/Review.styles';
import { estimatedChargesData } from 'utils/reviewPageUtils';

export const EstimatedCharges = () => {
  const { t } = useTranslations();

  const { renterCharges } = estimatedChargesData;

  return (
    <EstimatedChargesTitle>
      <H6 gutterBottom>{t('common.estimatedCharges')}</H6>
      <EstimatedChargesContent>
        <DynamicGrid container spacing={2} pb={2}>
          <Grid container item xs={12} justifyContent='space-between'>
            <Grid item xs={8}>
              <Body2 fontWeight='bold'>{t('review.renterCharges')}</Body2>
            </Grid>
            <Grid item xs={4}>
              <Body2 fontWeight='bold' align='right'>
                {t('review.total')}
              </Body2>
            </Grid>
          </Grid>
        </DynamicGrid>
        <DynamicGrid container item xs={12} spacing={2} py={2}>
          <Grid item xs={6}>
            <Body2>{t('common.vehicle').toUpperCase()}</Body2>
          </Grid>
          <Grid item xs={3}>
            <Body2 align='right'>{`${renterCharges.vehicle.duration}${t('whenWhere.days')}`}</Body2>
          </Grid>
          <Grid item xs={3}>
            <Body2 align='right'>{renterCharges.vehicle.total}</Body2>
          </Grid>
        </DynamicGrid>
        <DynamicGrid container item xs={12} spacing={2} py={2}>
          <Grid item xs={8}>
            <Body2>{t('review.extras').toUpperCase()}</Body2>
          </Grid>
          <Grid item xs={4}>
            <Body2 align='right'>{renterCharges.extras.total}</Body2>
          </Grid>
        </DynamicGrid>
        <DynamicGrid container item xs={12} spacing={2} py={2}>
          <Grid item xs={8}>
            <Body2>{t('review.taxesAndFeesReview').toUpperCase()}</Body2>
          </Grid>
          <Grid item xs={4}>
            <Body2 align='right'>{renterCharges.taxesAndFees.total}</Body2>
          </Grid>
        </DynamicGrid>
        <Grid container item xs={12} justifyContent='flex-end' sx={{ mt: 3 }}>
          <Grid item>
            <EstimatedTotalText>{t('review.estimatedTotalReview')}</EstimatedTotalText>
            <EstimatedTotalAmountText>{renterCharges.estimatedTotal}</EstimatedTotalAmountText>
          </Grid>
        </Grid>
      </EstimatedChargesContent>
    </EstimatedChargesTitle>
  );
};
