/**
 * Populate Menu options for dialogs
 *
 * @param cleanup { () => void } Callback to use when needed during onClick execution (not before or after)
 * @param collectErrorInformation { () => string } Callback used to collect error data when going to support.
 * @returns MenuItem[]
 */
import { useTranslations } from 'components/shared/i18n';
import { useSupport } from 'hooks/useSupport';
import { useState } from 'react';
import { MenuItem } from 'components/shared/ui/moreMenu/Menu';

export const useDialogMenuItems = (cleanup?: () => void, collectErrorInformation?: () => string): MenuItem[] => {
  const { t } = useTranslations();
  const { goToSupport } = useSupport();
  const [disableSupportWebAfterClicked, setDisableSupportWebAfterClicked] = useState<boolean>(false);
  const menuItems = [] as MenuItem[];

  menuItems.push({
    title: t('common.support'),
    onClick: () => {
      setDisableSupportWebAfterClicked(true);
      /**
       * Cleanup function used to close AlertContext dialog,
       *    - after screenshot is generated asynchronously
       *    - before navigating to support page.
       * Otherwise, either the dialog isn't in the screenshot or the dialog is still open on the support page.
       */
      goToSupport(cleanup, collectErrorInformation);
    },
    disabled: disableSupportWebAfterClicked,
  });

  return menuItems;
};
