import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  AllToggleContainer,
  ButtonContainer,
  EhiButtonReset,
  HeaderContainer,
  StyledFormControl,
  StyledInputLabel,
  StyledMenu,
  StyledText,
  SubHeaderContainer,
} from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookup.styles';
import { useTranslations } from 'components/shared/i18n';
import { useLocationGroups } from 'services/location/locationQueries';
import { GroupSummary } from 'services/location/locationTypes';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { BranchLookupFields } from './BranchLookupTypes';
import { IconButton, InputAdornment, ListSubheader } from '@mui/material';
import { Body1, ehiTheme, H6 } from '@ehi/ui';
import { FormTextField } from 'components/shared/forms/FormTextField';

const DAILY_RENTAL = 'DAILY_RENTAL';

export const GroupDropDown: React.FC = () => {
  const [selectedGroups, setSelectedGroups] = useState<{ name: string | undefined; index: number }[]>([]);
  const [allGroupsSelected, setAllGroupsSelected] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { t } = useTranslations();
  const { setValue, control } = useFormContext();
  const [countrySelect, watchedGroups] = useWatch({
    name: [BranchLookupFields.Country, BranchLookupFields.Groups],
  });

  const country = countrySelect ?? 'USA';
  const { data: locationGroups } = useLocationGroups(country, DAILY_RENTAL);

  const groupOptions = useMemo(() => {
    if (locationGroups && Array.isArray(locationGroups)) {
      return locationGroups.map((group: GroupSummary, index: number) => ({
        name: group.description,
        index: index + 1,
      }));
    }
    return [];
  }, [locationGroups]);

  useEffect(() => {
    if (groupOptions.length) {
      setSelectedGroups(groupOptions);
      setValue(BranchLookupFields.Groups, groupOptions);
    }
  }, [groupOptions, setValue]);

  useEffect(() => {
    if (watchedGroups && watchedGroups !== selectedGroups) {
      setSelectedGroups(watchedGroups);
    }
  }, [watchedGroups, selectedGroups]);

  useEffect(() => {
    setAllGroupsSelected(selectedGroups.length === groupOptions.length);
  }, [selectedGroups, groupOptions.length]);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const value = event.target.value as string[];
      const newSelectedGroups = value
        .map((name) => groupOptions.find((option) => option.name === name))
        .filter(Boolean) as { name: string; index: number }[];
      setSelectedGroups(newSelectedGroups);
      setAllGroupsSelected(newSelectedGroups.length === groupOptions.length);
      setValue(BranchLookupFields.Groups, newSelectedGroups);
    },
    [groupOptions, setValue]
  );

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const toggleAllGroups = useCallback(() => {
    const newSelectedGroups = allGroupsSelected ? [] : groupOptions;
    setSelectedGroups(newSelectedGroups);
    setAllGroupsSelected(!allGroupsSelected);
    setValue(BranchLookupFields.Groups, newSelectedGroups);
  }, [allGroupsSelected, groupOptions, setValue]);

  const handleReset = useCallback(() => {
    setSelectedGroups(groupOptions);
    setSearchTerm('');
    setAllGroupsSelected(true);
    setValue(BranchLookupFields.Groups, groupOptions);
  }, [groupOptions, setValue]);

  const filteredOptions = useMemo(
    () => groupOptions.filter((option) => option.name?.toLowerCase().includes(searchTerm.toLowerCase())),
    [searchTerm, groupOptions]
  );

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setIsOpen(false);
  }, []);

  const open = Boolean(anchorEl);

  const renderSelectedGroups = useCallback(
    (selected: string[]) => {
      if (selected.length === 0 || groupOptions.length === selected.length) {
        return t('common.all');
      } else if (selected.length === 1) {
        return selected[0];
      } else {
        return `${selected[0].substring(0, 15)}..., +${selected.length - 1}`;
      }
    },
    [t, groupOptions]
  );

  return (
    <StyledFormControl>
      <StyledInputLabel shrink={selectedGroups.length > 0} data-testid='groupsFilter'>
        {isOpen ? t('whenWhere.groupNumber') : t('location.group')}
      </StyledInputLabel>
      <Controller
        name={BranchLookupFields.Groups}
        control={control}
        render={() => (
          <Select
            multiple
            value={selectedGroups.map((group) => group.name ?? '')}
            onChange={handleChange}
            renderValue={renderSelectedGroups}
            data-testid='branchLookupGroupSelect'
            onClick={handleClick}
            open={isOpen}
            onClose={handleClose}
            MenuProps={{
              PaperProps: {
                style: {
                  display: 'none',
                },
              },
            }}
          />
        )}
      />
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        slotProps={{
          paper: {
            style: {
              width: anchorEl ? `${anchorEl.clientWidth * 1.25}px` : 'auto',
              maxHeight: window.innerHeight / 1.75,
              ...(window.innerWidth <= 800 && {
                minWidth: '25%',
              }),
            },
          },
          root: { sx: { '.MuiList-root': { padding: 0 } } },
        }}>
        <SubHeaderContainer style={{ top: 0 }}>
          <ListSubheader>
            <HeaderContainer>
              <H6>{t('location.group')}</H6>
              <IconButton size='small' onClick={handleClose}>
                <CloseIcon color={'secondary'} data-testid='group-close-dropdown' />
              </IconButton>
            </HeaderContainer>
          </ListSubheader>
          <ListSubheader>
            <FormTextField
              name='search'
              variant='outlined'
              placeholder={t('whenWhere.searchForGroupNumber')}
              value={searchTerm}
              onChange={handleSearchChange}
              fullWidth
              autoFocus
              onKeyDown={(e) => e.stopPropagation()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </ListSubheader>
          <ListSubheader>
            <AllToggleContainer>
              <Body1 variant='body1'>{t('whenWhere.allGroups')}</Body1>
              <Switch checked={allGroupsSelected} onChange={toggleAllGroups} data-testid='allGroupsSwitch' />
            </AllToggleContainer>
          </ListSubheader>
          <ListSubheader>
            <StyledText>{t('whenWhere.groupNumbers')}</StyledText>
          </ListSubheader>
        </SubHeaderContainer>
        {filteredOptions.map((group) => (
          <MenuItem
            key={group.index}
            data-testid={`group-${group.index}`}
            value={group.name}
            onClick={() => {
              const newSelectedGroups = selectedGroups.some((selected) => selected.name === group.name)
                ? selectedGroups.filter((selected) => selected.name !== group.name)
                : [...selectedGroups, group];
              setSelectedGroups(newSelectedGroups);
              setAllGroupsSelected(newSelectedGroups.length === groupOptions.length);
              setValue(BranchLookupFields.Groups, newSelectedGroups);
            }}>
            <Checkbox checked={selectedGroups.some((selected) => selected.name === group.name)} />
            <ListItemText primary={group.name} primaryTypographyProps={{ style: { whiteSpace: 'normal' } }} />
          </MenuItem>
        ))}
        <SubHeaderContainer style={{ bottom: 0 }}>
          <ButtonContainer>
            <EhiButtonReset
              onClick={handleReset}
              data-testid='branchLookupGroupReset'
              sx={{ margin: `${ehiTheme.spacing(0.5, 0.5, 0, 0)}` }}>
              {t('common.reset')}
            </EhiButtonReset>
          </ButtonContainer>
        </SubHeaderContainer>
      </StyledMenu>
    </StyledFormControl>
  );
};
