import { ReactNode } from 'react';

export type LoadableViewProps = {
  children: ReactNode;
  loadingComponent: JSX.Element;
  errorComponent: JSX.Element;
  state: LoadingState;
};

export enum LoadingState {
  LOADING,
  SUCCESS,
  ERROR,
}
