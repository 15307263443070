import { Body1 } from '@ehi/ui';
import { FC } from 'react';
import { FullHeightContainer, GridAreaContainer, PageContainer } from 'components/shared/ui/styles/Global.styles';
import { useTranslations } from 'components/shared/i18n/useTranslations';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from 'app/router/RouterPaths';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { useReservationSessionHelper } from 'components/shared/preprocessor/useReservationSessionHelper';

const ErrorWrapper: FC = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { clearEditorSession } = useReservationSessionHelper();

  return (
    <FullHeightContainer>
      <PageContainer>
        <GridAreaContainer>
          <Dialog
            a11yKey='tabbed'
            data-testid='error-wrapper'
            open={true}
            onClose={undefined}
            title={t('error.whoops')}
            actions={{
              secondaryAction: {
                label: t('error.home'),
                onClick: () => {
                  clearEditorSession();
                  navigate(RouterPaths.Search);
                },
              },
              primaryAction: {
                label: t('error.relaunch'),
                onClick: () => {
                  window.location.reload();
                },
              },
            }}
            maxWidth={false}>
            <Body1 data-testid={'errorDescription'}>{t('error.description')}</Body1>
          </Dialog>
        </GridAreaContainer>
      </PageContainer>
    </FullHeightContainer>
  );
};

export default ErrorWrapper;
