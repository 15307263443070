import React, { useState } from 'react';
import { LoadableView } from 'components/shared/ui/spinner/loadableView/LoadableView';
import { FullScreenSpinner } from 'components/shared/ui/spinner/FullScreenSpinner';
import ErrorWrapper from 'components/shared/errors/ErrorWrapper';
import { getSecrets } from 'services/secrets/secretsService';
import { useEffectOnlyOnce } from 'hooks/useEffectOnlyonce';
import { SecretsContext, secretsInitialState } from 'context/secrets/SecretsContext';
import { Secrets } from 'services/secrets/SecretsServiceTypes';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';

interface SecretsManagerGuardProps {
  children: React.ReactNode;
}

export const SecretsManagerGuard = ({ children }: SecretsManagerGuardProps) => {
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING);
  const [secrets, setSecretsState] = useState<Secrets>(secretsInitialState);

  useEffectOnlyOnce(async () => {
    try {
      const response = await getSecrets();
      setSecretsState(response);
      setLoadingState(LoadingState.SUCCESS);
    } catch (error) {
      setLoadingState(LoadingState.ERROR);
    }
  });

  return (
    <SecretsContext.Provider value={{ secrets, setSecretsState }}>
      <LoadableView loadingComponent={<FullScreenSpinner />} errorComponent={<ErrorWrapper />} state={loadingState}>
        {children}
      </LoadableView>
    </SecretsContext.Provider>
  );
};
