import { useCallback } from 'react';
import { SearchResult } from 'services/renter/driverProfile/driverProfileTypes';
import { DriverSearchValues, SearchByType } from 'components/flexFlow/driver/searchDriver/DriverSearchTypes';
import { useRenterSearch } from 'services/renter/useRenterSearch';
import { ServiceState } from 'services/types/ServiceStateTypes';
import { LoyaltyProgram } from 'utils/constants';

export type RenterSearchHookResponse = ServiceState & {
  search: (values: DriverSearchValues) => Promise<SearchResult[]>;
};
export const LoyaltyProgramsByBrand: LoyaltyProgram[] = [
  LoyaltyProgram.ENTERPRISE_PLUS,
  LoyaltyProgram.EMERALD_CLUB,
  LoyaltyProgram.ALAMO_INSIDERS,
];

export const useRenterProfileSearch = (): RenterSearchHookResponse => {
  const {
    searchRenterWithPhoneNumber,
    searchRenterWithDateOfBirth,
    searchRentersWithLicense,
    searchRenterWithLoyalty,
  } = useRenterSearch();

  const internalSearch = useCallback(
    async (values: DriverSearchValues): Promise<SearchResult[]> => {
      if (values.searchBy === SearchByType.LoyaltyNumber) {
        return searchRenterWithLoyalty(
          values.loyaltyNumber,
          LoyaltyProgramsByBrand.map((loyaltyProgram) => loyaltyProgram)
        );
      } else if (values.searchBy === SearchByType.RenterInformation) {
        if (values.phoneNumber) {
          return searchRenterWithPhoneNumber(
            values.firstName,
            values.lastName,
            values.phoneNumber,
            [...LoyaltyProgramsByBrand, LoyaltyProgram.NON_LOYALTY],
            values.dateOfBirth?.toISODate() || ''
          );
        } else {
          return searchRenterWithDateOfBirth(values.firstName, values.lastName, values.dateOfBirth?.toISODate() || '', [
            ...LoyaltyProgramsByBrand,
            LoyaltyProgram.NON_LOYALTY,
          ]);
        }
      } else if (values.searchBy === SearchByType.DriversLicense) {
        return searchRentersWithLicense(values.licenseNumber, values.country, values?.state, [
          ...LoyaltyProgramsByBrand,
          LoyaltyProgram.NON_LOYALTY,
        ]);
      } else {
        return [];
      }
    },
    [searchRentersWithLicense, searchRenterWithDateOfBirth, searchRenterWithPhoneNumber, searchRenterWithLoyalty]
  );

  const search = useCallback(
    async (values: DriverSearchValues): Promise<SearchResult[]> => {
      return internalSearch(values).then((results) => Promise.all(results.map((result) => result)));
    },
    [internalSearch]
  );

  return {
    search,
  };
};
