import { getYupErrors } from 'components/shared/forms/yupValidationUtil';
import { useCallback } from 'react';
import { FieldValues, ResolverResult } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';

// https://react-hook-form.com/advanced-usage
export const useYupValidationResolver = <T extends AnyObjectSchema>(validationSchema: T) =>
  useCallback(
    async <TFieldValues extends FieldValues>(data: TFieldValues): Promise<ResolverResult<TFieldValues>> => {
      const errors = await getYupErrors(validationSchema, data);

      if (errors) {
        return {
          values: {},
          errors: errors,
        };
      } else {
        return {
          values: data,
          errors: {},
        };
      }
    },
    [validationSchema]
  );
