import { Box, styled } from '@mui/material';
import { Caption, ehiTheme } from '@ehi/ui';

export const RateAndBillingSectionBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: ${ehiTheme.spacing(3)};
`;

export const RateSourceInfoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  padding: ${ehiTheme.spacing(2)};
  margin-top: ${ehiTheme.spacing(2)};
`;

export const RateSourceLink = styled(Caption, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{
  disabled?: boolean;
}>(({ disabled = false }) => {
  const color = disabled ? '#00000061' : '#4d789d';
  const cursor = disabled ? 'unset' : 'pointer';
  return {
    display: 'flex',
    alignItems: 'center',
    cursor,
    color,
  };
});
