import { useDomainQuery } from 'context/queryClient/queryUtil';
import {
  getPhoneTypes,
  getRenterDepartmentTypes,
  getWarningDepartments,
  getWarningReasons,
  retrieveLegalIdentificationType,
} from 'services/renter/renterReferenceService';

const RENTER_DEPARTMENT_TYPES_QUERY_KEY = 'renterDepartmentTypes';
const RENTER_WARNING_REASONS_KEY = 'renterWarningReasons';
const RENTER_WARNING_DEPARTMENT_QUERY_KEY = 'renterWarningDepartments';
const RENTER_LEGAL_IDENTIFICATION_QUERY_KEY = 'renterLegalIdentificationType';

export const usePhoneTypesQuery = () => {
  return useDomainQuery({ queryKey: 'phoneType', queryFn: () => getPhoneTypes() });
};

export const useGetRenterDepartmentTypes = () => {
  return useDomainQuery({ queryKey: RENTER_DEPARTMENT_TYPES_QUERY_KEY, queryFn: () => getRenterDepartmentTypes() });
};

export const useGetWarningReasons = () => {
  return useDomainQuery({ queryKey: RENTER_WARNING_REASONS_KEY, queryFn: () => getWarningReasons() });
};

export const useGetRenterWarningDepartments = () => {
  return useDomainQuery({ queryKey: RENTER_WARNING_DEPARTMENT_QUERY_KEY, queryFn: () => getWarningDepartments() });
};

export const useGetLegalIdentificationTypes = () => {
  return useDomainQuery({
    queryKey: RENTER_LEGAL_IDENTIFICATION_QUERY_KEY,
    queryFn: () => retrieveLegalIdentificationType(),
  });
};
