import { FC } from 'react';

export const AccessDeniedGraphic: FC = () => {
  return (
    <svg
      width='354px'
      height='213px'
      viewBox='0 0 354 213'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <title>Access Denied Graphic</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <rect id='path-1' x='0' y='0' width='296' height='68' rx='10'></rect>
        <rect id='path-3' x='0' y='0' width='296' height='68' rx='10'></rect>
      </defs>
      <g id='Desktop' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Access-Denied' transform='translate(-775.000000, -290.000000)'>
          <g id='Group-9' transform='translate(599.000000, 224.000000)'>
            <g id='Access-Denied-Graphic' transform='translate(176.000000, 66.000000)'>
              <path
                d='M33.7919287,55.673805 C33.7919287,-12.5261495 113.839145,27.8888967 205.710456,27.8888967 C297.581767,27.8888967 350,62.8759257 350,97.3650838 C350,131.854242 337.70451,190 306.533988,190 C275.363467,190 227.181671,159.430478 195.537609,159.430478 C163.893547,159.430478 123.78055,190 85.713022,190 C47.6454936,190 5,176.930424 5,136.269522 C5,95.6086197 33.7919287,95.6086197 33.7919287,55.673805 Z'
                id='Path-9'
                fill='#386997'
                opacity='0.100000001'></path>
              <path
                d='M90,17 L111,17 L111,11.5 C111,5.70101013 106.29899,1 100.5,1 C94.7010101,1 90,5.70101013 90,11.5 L90,17 Z'
                id='Rectangle'
                stroke='#383838'
                strokeWidth='2'
                fill='#CF0908'></path>
              <path
                d='M259,17 L280,17 L280,11.5 C280,5.70101013 275.29899,1 269.5,1 C263.70101,1 259,5.70101013 259,11.5 L259,17 Z'
                id='Rectangle-Copy-4'
                stroke='#383838'
                strokeWidth='2'
                fill='#CF0908'></path>
              <rect
                id='Rectangle'
                stroke='#383838'
                strokeWidth='2'
                fill='#FFFFFF'
                x='86'
                y='17'
                width='29'
                height='8'
                rx='2'></rect>
              <rect
                id='Rectangle-Copy-5'
                stroke='#383838'
                strokeWidth='2'
                fill='#FFFFFF'
                x='255'
                y='17'
                width='29'
                height='8'
                rx='2'></rect>
              <path
                d='M90,25 L90,209 C90,210.656854 91.3431458,212 93,212 L108,212 C109.656854,212 111,210.656854 111,209 L111,25 L90,25 Z'
                id='Rectangle'
                stroke='#383838'
                strokeWidth='2'
                fill='#FFFFFF'></path>
              <path
                d='M259,25 L259,209 C259,210.656854 260.343146,212 262,212 L277,212 C278.656854,212 280,210.656854 280,209 L280,25 L259,25 Z'
                id='Rectangle-Copy-3'
                stroke='#383838'
                strokeWidth='2'
                fill='#FFFFFF'></path>
              <g id='Group-6' transform='translate(37.000000, 35.000000)'>
                <mask id='mask-2' fill='white'>
                  <use xlinkHref='#path-1'></use>
                </mask>
                <use id='Mask' fill='#FFFFFF' xlinkHref='#path-1'></use>
                <g mask='url(#mask-2)' fill='#386997'>
                  <g transform='translate(-152.000000, -100.000000)'>
                    <polygon
                      id='Rectangle'
                      transform='translate(160.000000, 115.500000) rotate(-45.000000) translate(-160.000000, -115.500000) '
                      points='-7.14062545 56.640625 316.571875 108.928125 327.140625 174.359375 3.42812455 122.071875'></polygon>
                    <polygon
                      id='Rectangle-Copy'
                      transform='translate(326.000000, 115.500000) rotate(-45.000000) translate(-326.000000, -115.500000) '
                      points='158.859375 56.640625 482.571875 108.928125 493.140625 174.359375 169.428125 122.071875'></polygon>
                    <polygon
                      id='Rectangle-Copy-2'
                      transform='translate(492.000000, 115.500000) rotate(-45.000000) translate(-492.000000, -115.500000) '
                      points='324.859375 56.640625 648.571875 108.928125 659.140625 174.359375 335.428125 122.071875'></polygon>
                  </g>
                </g>
                <rect
                  id='Mask'
                  stroke='#383838'
                  strokeWidth='2'
                  mask='url(#mask-2)'
                  x='1'
                  y='1'
                  width='294'
                  height='66'
                  rx='10'></rect>
              </g>
              <g id='Group-6-Copy' transform='translate(37.000000, 112.000000)'>
                <mask id='mask-4' fill='white'>
                  <use xlinkHref='#path-3'></use>
                </mask>
                <use id='Mask' fill='#FFFFFF' xlinkHref='#path-3'></use>
                <g id='Group-6' mask='url(#mask-4)' fill='#386997'>
                  <g transform='translate(-152.000000, -145.000000)'>
                    <polygon
                      id='Rectangle'
                      transform='translate(160.000000, 160.500000) rotate(-45.000000) translate(-160.000000, -160.500000) '
                      points='-7.14062545 101.640625 316.571875 153.928125 327.140625 219.359375 3.42812455 167.071875'></polygon>
                    <polygon
                      id='Rectangle-Copy'
                      transform='translate(326.000000, 160.500000) rotate(-45.000000) translate(-326.000000, -160.500000) '
                      points='158.859375 101.640625 482.571875 153.928125 493.140625 219.359375 169.428125 167.071875'></polygon>
                    <polygon
                      id='Rectangle-Copy-2'
                      transform='translate(492.000000, 160.500000) rotate(-45.000000) translate(-492.000000, -160.500000) '
                      points='324.859375 101.640625 648.571875 153.928125 659.140625 219.359375 335.428125 167.071875'></polygon>
                  </g>
                </g>
                <rect
                  id='Mask'
                  stroke='#383838'
                  strokeWidth='2'
                  mask='url(#mask-4)'
                  x='1'
                  y='1'
                  width='294'
                  height='66'
                  rx='10'></rect>
              </g>
              <rect
                id='Rectangle'
                stroke='#383838'
                strokeWidth='2'
                fill='#995FF5'
                x='64'
                y='212'
                width='232'
                height='1'
                rx='0.5'></rect>
              <rect
                id='Rectangle-Copy-7'
                stroke='#383838'
                strokeWidth='2'
                fill='#995FF5'
                x='19'
                y='212'
                width='30'
                height='1'
                rx='0.5'></rect>
              <rect
                id='Rectangle-Copy-8'
                stroke='#383838'
                strokeWidth='2'
                fill='#995FF5'
                x='1'
                y='212'
                width='8'
                height='1'
                rx='0.5'></rect>
              <rect
                id='Rectangle-Copy-6'
                stroke='#383838'
                strokeWidth='2'
                fill='#995FF5'
                x='307'
                y='212'
                width='46'
                height='1'
                rx='0.5'></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
