export enum FeatureFlagState {
  DEFAULT,
  ENABLED,
  DISABLED,
}

export type OverriddenFeatureFlags = {
  locationFeatureFlagEnabled: FeatureFlagState;
  isAndroidTabletUserAgent: FeatureFlagState;
};

export type LaunchDarklyFeatureFlags = {
  locationFeatureFlagEnabled: boolean;
  isAndroidTabletUserAgent: boolean;
};

export type FeatureFlags = {
  ldFlags: LaunchDarklyFeatureFlags;
  overriddenFlags: OverriddenFeatureFlags;
};

export const defaultOverriddenFeatureFlags: OverriddenFeatureFlags = {
  locationFeatureFlagEnabled: FeatureFlagState.DEFAULT,
  isAndroidTabletUserAgent: FeatureFlagState.DEFAULT,
};
