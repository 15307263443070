import { styled, Tab } from '@mui/material';
import { ehiTheme } from '@ehi/ui';

export const FlexiAppBar = styled('div')`
  position: fixed;
  height: 72px;
  z-index: 2;
  background-color: #f5f5f5;
  width: calc(100vw - 108px);
  padding-bottom: ${ehiTheme.spacing(1.25)};
  &.drawer-open {
    width: calc(100vw - 468px);
  }
  @media (max-width: 800px) {
    width: calc(100vw - 40px);
  }
`;

export const FlexiTab = styled(Tab)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  minWidth: '2rem',
  alignSelf: 'baseline',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'fit-content',
}));

export const FlexMenuContainer = styled('div')`
  flex: 1;
  padding: ${ehiTheme.spacing(0, 2.5, 2.5)};
`;
