import { FC, memo } from 'react';
import BranchLookupCardDarkIcon from 'images/branchLookupCardDarkIcon.svg';
import BranchLookupCardLightIcon from 'images/branchLookupCardLightIcon.svg';
import { BrandLogo } from 'components/shared/ui/logos/BrandLogo';
import { Brand } from 'utils/constants';
import {
  CardBottomLeftBox,
  CardContent,
  CardDescription,
  CardImageContainer,
  CardIndexBox,
  CardIndexTypography,
  CardInfoBox,
  CardInfoContainer,
  CardInfoItem,
  CardStationInfo,
  CardStationText,
  DisabledInfoContainer,
  DisabledText,
  StyledAirplaneIcon,
  StyledStoreIcon,
} from 'components/flexFlow/whenAndWhere/branchLookup/BranchCard.styles';
import { BranchLookupCard } from 'components/shared/uiModels/branchLookup/branchLookupDataTypes';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslations } from 'components/shared/i18n';
import { useAppSelector } from 'redux/hooks';
import { parseUrn } from 'utils/urnUtils';
import { selectBrand, selectIsModifyFlow } from 'redux/selectors/bookingEditor';

const IS_SELECTED_CLASS_NAME = 'isSelected';
const DISABLED_CLASS_NAME = 'disabled';

type BranchCardProps = {
  index: number;
  result: BranchLookupCard;
  isSelected: boolean;
  onClick: () => void;
};

const BranchCard: FC<BranchCardProps> = ({ index, result, isSelected, onClick }) => {
  const { t } = useTranslations();
  const brandId = useAppSelector(selectBrand);
  const isModifyScreen = useAppSelector(selectIsModifyFlow);
  const parsedBrand = parseUrn(brandId) as Brand;
  const isBrandMismatch = isModifyScreen && parsedBrand && result?.brand !== parsedBrand;
  const isCardDisabled = result.isDisabled || !!isBrandMismatch;

  const addDashBeforeLastWord = (text: string | undefined): string => {
    if (!text) return '';
    const words = text.split(' ');
    if (words.length > 1) {
      const lastWord = words.pop();
      return `${words.join(' ')} - ${lastWord}`;
    }
    return text;
  };

  return (
    <CardContent
      className={`${isSelected ? IS_SELECTED_CLASS_NAME : ''} ${isCardDisabled ? DISABLED_CLASS_NAME : ''}`}
      onClick={!isCardDisabled ? onClick : undefined}
      data-testid={`branchCard-${index}`}
      selected={isSelected}
      isDisabled={isCardDisabled}>
      <CardImageContainer>
        <img
          src={isSelected ? BranchLookupCardLightIcon : BranchLookupCardDarkIcon}
          alt='Branch Icon'
          style={{ width: '75%', height: '75%' }}
        />
        <CardIndexBox>
          <CardIndexTypography variant='caption' selected={isSelected}>
            {index + 1}
          </CardIndexTypography>
        </CardIndexBox>
      </CardImageContainer>
      <CardInfoBox>
        <CardDescription selected={isSelected}>{addDashBeforeLastWord(result.description)}</CardDescription>
        <CardInfoItem selected={isSelected}>{result.address?.lines}</CardInfoItem>
        <CardInfoItem selected={isSelected}>
          {`${result.address?.city}, ${result.address?.countrySubdivisionAbbreviation} ${result.address?.postalCode} ${result.address?.countryCode}`}
        </CardInfoItem>
        <CardInfoContainer>
          <CardInfoItem selected={isSelected}>{result.phone}</CardInfoItem>
          <CardStationInfo>
            <CardStationText selected={isSelected}>{result?.stationId}</CardStationText>
            <BrandLogo brand={result?.brand as Brand} height={20} width={20} />
            {result?.airport ? <StyledAirplaneIcon selected={isSelected} /> : <StyledStoreIcon selected={isSelected} />}
          </CardStationInfo>
        </CardInfoContainer>
      </CardInfoBox>
      {isCardDisabled && (
        <CardBottomLeftBox>
          <DisabledInfoContainer>
            <InfoIcon style={{ color: '#00000061', fontSize: '20px' }} />
            <DisabledText>
              {result.isDisabled ? t('whenWhere.branchCardDisableMessage') : t('whenWhere.branchCardMismatchBrand')}
            </DisabledText>
          </DisabledInfoContainer>
        </CardBottomLeftBox>
      )}
    </CardContent>
  );
};

export default memo(BranchCard);
