import { BranchEffectivePolicy } from 'services/location/locationTypes';

export const estimatedChargesData = {
  renterCharges: {
    vehicle: { duration: '28', total: '$653.99' },
    extras: { total: '$92.61' },
    taxesAndFees: { total: '$175.56' },
    estimatedTotal: '$922.16',
  },
};

/**
 * reorders the policies based on the following order
 * 1. Renter Requirements (REQ1, REQ2, etc.)
 * 2. Mandatory Policies in alphabetical order
 * 3. All other policies in alphabetical order
 **/
export const reorderPolices = (policies: BranchEffectivePolicy[] | undefined): BranchEffectivePolicy[] | undefined => {
  if (!policies?.length) {
    return undefined;
  }
  const renterRequired = policies?.filter((policy) => policy.name.includes('REQ'));

  const mandatory = policies
    ?.filter((policy) => policy.mandatoryReviewNeeded)
    .sort((a, b) => a.localizedName.localeCompare(b.localizedName));

  const remaining = policies
    ?.filter((policy) => !policy.name.includes('REQ') && policy.mandatoryReviewNeeded === false)
    .sort((a, b) => a.localizedName.localeCompare(b.localizedName));

  return [...renterRequired, ...mandatory, ...remaining];
};
