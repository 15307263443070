import {
  retrieveAllAncillaryFeeTypes,
  retrieveAllEquipmentTypes,
  retrieveAllProtectionTypes,
  retrieveReservationLifeCycleTypes,
  retrieveReservationStatuses,
} from './rentalReferenceService';
import { useDomainQuery } from 'context/queryClient/queryUtil';
import { UseQueryResult } from '@tanstack/react-query';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { loadEhiLocationCookie } from '@ehi/location';
import { getLocationCache } from 'services/location/locationCache';
import { COUNTRY_CODE_US } from 'utils/constants';
import { ProtectionType } from './rentalReferenceTypes';

const EQUIPMENT_TYPE_QUERY_KEY = 'equipmentType';
const PROTECTION_TYPE_QUERY_KEY = 'protectionType';
const ANCILLARY_FEE_TYPE_QUERY_KEY = 'ancillaryFeeType';
const RESERVATION_LIFECYCLE_QUERY_KEY = 'resLifeCycle';
const RESERVATION_STATUS_QUERY_KEY = 'reservationStatus';

export const useEquipmentTypesQuery = () => {
  return useDomainQuery({ queryKey: EQUIPMENT_TYPE_QUERY_KEY, queryFn: () => retrieveAllEquipmentTypes() });
};

export const useProtectionTypesQuery = (): UseQueryResult<ProtectionType[], EhiErrors> => {
  const cookie = loadEhiLocationCookie();
  const locationCache = getLocationCache(cookie?.peoplesoftId ?? '');
  const countryCode = locationCache?.location.countryCode ?? COUNTRY_CODE_US;

  return useDomainQuery({
    queryKey: [PROTECTION_TYPE_QUERY_KEY, countryCode],
    queryFn: () => retrieveAllProtectionTypes(countryCode),
  });
};

export const useAncillaryFeeTypesQuery = () => {
  return useDomainQuery({ queryKey: ANCILLARY_FEE_TYPE_QUERY_KEY, queryFn: () => retrieveAllAncillaryFeeTypes() });
};

export const useReservationLifeCycleTypesQuery = () => {
  return useDomainQuery({
    queryKey: RESERVATION_LIFECYCLE_QUERY_KEY,
    queryFn: () => retrieveReservationLifeCycleTypes(),
  });
};

export const useReservationStatusQuery = () => {
  return useDomainQuery({ queryKey: RESERVATION_STATUS_QUERY_KEY, queryFn: () => retrieveReservationStatuses() });
};
