import { FC } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { CreateDriverFields } from 'components/flexFlow/driver/createDriver/CreateDriverTypes';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { useFormContext, useWatch } from 'react-hook-form';
import { SelectField } from 'components/shared/forms/SelectField';
import {
  AGE_OPTIONS,
  getRegionLabel,
  resetFieldsClearErrors,
} from 'components/flexFlow/driver/createDriver/createDriverUtils';
import { CountrySelectField } from 'components/shared/forms/CountrySelectField';
import { FormTextField } from 'components/shared/forms/FormTextField';
import { CountrySubDivisionSelectField } from 'components/shared/forms/CountrySubDivisionSelectField';
import { piiField } from '@ehi/support';
import { FormSectionHeader, Section } from 'components/flexFlow/driver/createDriver/CreateDriverStyles';

export const DriverSection: FC = () => {
  const { t } = useTranslations();
  const { clearErrors, resetField, setValue } = useFormContext();
  const [driverCountry] = useWatch({
    name: [CreateDriverFields.DriverCountry],
  });

  const regionLabel = getRegionLabel(driverCountry);

  return (
    <Section data-testid={'driverSection'}>
      <FormSectionHeader>{t('driver.driver')}</FormSectionHeader>
      <GridContainer>
        <GridItem sm={4}>
          <FormTextField
            name={CreateDriverFields.LastName}
            label={t('driver.lastName')}
            data-testid={'lastNameField'}
            inputProps={{ maxLength: 50 }}
            required
          />
        </GridItem>
        <GridItem sm={4}>
          <FormTextField
            name={CreateDriverFields.FirstName}
            label={t('driver.firstName')}
            data-testid={'firstNameField'}
            inputProps={{ maxLength: 50 }}
            required
          />
        </GridItem>
        <GridItem sm={4}>
          <SelectField
            name={CreateDriverFields.Age}
            label={t('driver.age')}
            data-testid={'menu-age'}
            options={AGE_OPTIONS}
            hasNoneOption={false}
          />
        </GridItem>
        <GridItem sm={6}>
          <CountrySelectField
            name={CreateDriverFields.DriverCountry}
            label={t('location.country')}
            data-testid={'driverCountry'}
            onChange={(event) => {
              setValue(CreateDriverFields.DriverCountry, event.target.value);
              resetFieldsClearErrors(
                [
                  CreateDriverFields.StreetAddress1,
                  CreateDriverFields.StreetAddress2,
                  CreateDriverFields.City,
                  CreateDriverFields.SubRegion,
                  CreateDriverFields.PostalCode,
                ],
                resetField,
                clearErrors
              );
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer className={piiField} data-testid={'address-container'}>
        {driverCountry && (
          <>
            <GridItem sm={6}>
              <FormTextField
                name={CreateDriverFields.StreetAddress1}
                label={t('location.streetAddress1')}
                data-testid={'streetAddress1'}
                required
              />
            </GridItem>
            <GridItem sm={6}>
              <FormTextField
                name={CreateDriverFields.StreetAddress2}
                label={t('location.streetAddress2')}
                data-testid={'streetAddress2'}
              />
            </GridItem>
            <GridItem sm={4}>
              <FormTextField name={CreateDriverFields.City} label={t('location.city')} data-testid={'city'} required />
            </GridItem>
            {regionLabel && (
              <GridItem sm={4}>
                <CountrySubDivisionSelectField
                  name={CreateDriverFields.SubRegion}
                  label={t(regionLabel)}
                  data-testid={'subRegion'}
                  country={driverCountry}
                  required
                />
              </GridItem>
            )}
            <GridItem sm={4}>
              <FormTextField
                name={CreateDriverFields.PostalCode}
                label={t('location.postalCode')}
                data-testid={'postalCode'}
                required
              />
            </GridItem>
          </>
        )}
      </GridContainer>
    </Section>
  );
};
