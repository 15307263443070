import { FunctionComponent, useMemo } from 'react';
import { SelectField } from 'components/shared/forms/SelectField';
import { useCountriesQuery } from 'services/location/locationQueries';
import { Option } from 'components/shared/ui/OptionTypes';
import { generateAllCountryMenuItemsWithCorpororate } from 'utils/formUtils';
import { SelectFieldProps } from './FormFieldTypes';

export const CountrySelectField: FunctionComponent<Omit<SelectFieldProps, 'options'>> = (props) => {
  const { data } = useCountriesQuery();
  const options = useMemo<Option<string>[]>(() => {
    if (!data?.length) return [];

    return generateAllCountryMenuItemsWithCorpororate(data);
  }, [data]);

  return <SelectField {...props} options={options} />;
};
