import { useTranslations } from 'components/shared/i18n';
import { VehicleCard } from 'components/flexFlow/vehicle/VehicleCard';
import { RouterPaths } from 'app/router/RouterPaths';
import { FC, useEffect, useMemo, useState } from 'react';
import { filterVehicleList } from 'components/flexFlow/vehicle/vehicleSearch/vehicleSearchUtils';
import { useWatch } from 'react-hook-form';
import { VehicleSearchFields, VehicleSectionProps } from 'components/flexFlow/vehicle/VehicleTypes';
import { BASE_URL, NoResultsView } from 'components/shared/ui/noResultsView/NoResultsView';
import { Virtuoso } from 'react-virtuoso';
import { useAppSelector } from 'redux/hooks';
import { selectHasLocationData } from 'redux/selectors/bookingEditor';
import { useNavigate } from 'react-router';
import { VehicleListHeader, VehicleListItem } from 'components/flexFlow/vehicle/VehiclePage.styles';
import { ehiTheme } from '@ehi/ui';
import { Box } from '@mui/material';

export const VehicleList: FC<VehicleSectionProps> = ({ vehicles }): JSX.Element => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const hasLocationData = useAppSelector(selectHasLocationData);
  const [scrollParent, setScrollParent] = useState<HTMLElement | undefined>(undefined);

  useEffect(() => {
    setScrollParent((window.document.getElementById('scrolling-view') as HTMLElement) ?? undefined);
  }, []);

  const [carClass, vehicleType, fuelType, passengerNumber] = useWatch({
    name: [
      VehicleSearchFields.VehicleClass,
      VehicleSearchFields.VehicleType,
      VehicleSearchFields.FuelType,
      VehicleSearchFields.PassengerNumber,
    ],
  });

  const vehicleList = useMemo(() => {
    if (vehicles?.length && (carClass || vehicleType || fuelType || passengerNumber)) {
      return filterVehicleList(vehicles, carClass, vehicleType, fuelType, passengerNumber);
    } else {
      return vehicles;
    }
  }, [vehicles, carClass, vehicleType, fuelType, passengerNumber]);

  function VehicleCountHeader() {
    return (
      <VehicleListHeader>
        {`${t('common.showing')} ${vehicleList?.length ?? 0} ${t('common.results')}`}
      </VehicleListHeader>
    );
  }

  return (
    <Box style={{ backgroundColor: ehiTheme.palette.background.default }}>
      {hasLocationData && vehicleList.length > 0 ? (
        <Virtuoso
          useWindowScroll
          customScrollParent={scrollParent}
          totalCount={vehicleList.length}
          components={{ Header: VehicleCountHeader, Item: VehicleListItem }}
          itemContent={(index: number) => {
            return <VehicleCard vehicle={vehicleList[index]} />;
          }}
        />
      ) : (
        <NoResultsView
          noResultsTitle='search.noResults'
          noResultsDescription={!hasLocationData ? 'vehicle.noResultsDescription' : undefined}
          handleNavigate={
            !hasLocationData
              ? () => {
                  navigate(BASE_URL + RouterPaths.WhenAndWhere);
                }
              : undefined
          }
        />
      )}
    </Box>
  );
};
