import { UseQueryResult } from '@tanstack/react-query';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { VehicleContent } from './vehicleContentTypes';
import { loadEhiLocationCookie } from '@ehi/location';
import { getLocationCache } from 'services/location/locationCache';
import { getVehicleContent } from './vehicleContentService';
import { ISO2_COUNTRY_CODE_US } from 'utils/constants';
import { useDomainQuery } from 'context/queryClient/queryUtil';

const VEHICLE_CONTENT_QUERY_KEY = 'vehicleContent';

export const useVehicleContentQuery = (): UseQueryResult<VehicleContent, EhiErrors> => {
  const cookie = loadEhiLocationCookie();
  const locationCache = getLocationCache(cookie?.peoplesoftId ?? '');
  const countryCode = locationCache?.location.countryIso2;
  return useDomainQuery({
    queryKey: [VEHICLE_CONTENT_QUERY_KEY, countryCode],
    queryFn: () => getVehicleContent(countryCode ?? ISO2_COUNTRY_CODE_US),
  });
};
