import { styled } from '@mui/material';
import { Card } from 'components/shared/ui/card/Card';

export const SummaryCardStyled = styled(Card)(({ theme }) => ({
  minHeight: 'unset',
  '& .MuiCardHeader-title': {
    h6: {
      color: theme.palette.primary.main,
    },
  },
}));
