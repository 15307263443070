export const formatPhoneNumber = (value: string | undefined): string => {
  if (!value || !value?.trim()?.length) {
    return '';
  }

  // Checks the length of the phone number by only checking numbers. replace() removes special characters, etc.
  if (value.replace(/\D/g, '').length > 10) {
    return value;
  }

  const phoneRegex = /^\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/;
  return value.replace(phoneRegex, '($1) $2-$3');
};
