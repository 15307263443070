import { Error001 } from 'components/shared/errors/ErrorCodes';
import { NavigationConstants } from 'components/navigation/navigationConstants';

export const validateCallingApp = (params: URLSearchParams): void => {
  if (params.get(NavigationConstants.CALLING_APP_PARAM) === null) {
    throw new Error(Error001);
  }
};

// Validation for Form Page
export const validate001 = (params: URLSearchParams): void => {
  validateCallingApp(params);
};
