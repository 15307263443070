import { useAlert } from 'components/shared/alert/AlertContext';
import { useCallback } from 'react';
import {
  associateRenterToReservationEditor,
  updatePickupInformation,
  updateRemarkForReservationEditor,
  updateReturnInformation,
} from 'services/booking/bookingService';
import { safelyCatchError } from 'utils/errorUtils';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { TransactionalProfileResponseContent } from 'services/renter/transactionalProfile/transactionalProfileTypes';
import { Renter } from 'services/booking/bookingTypes';
import { createQuickResProfileRequestBody } from 'services/renter/renterServiceUtils';
import { createTransactionalProfile } from 'services/renter/transactionalProfile/transactionalProfileService';
import { RenterTypes } from 'components/shared/uiModels/driver/driverDataTypes';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { logError } from 'components/shared/logger/splunkLogger';

export type QuickResHelperHook = {
  associateToRenter: (editorId: string, profileUrn?: string) => Promise<void>;
  createProfile: (editorId: string, lastName: string, firstName: string, phone?: string) => Promise<void>;
  handleAddingRemarks: (
    handleSaveError: (error: unknown) => Promise<void>,
    editorId: string,
    preferences?: string
  ) => Promise<boolean>;
  savePickupAndReturnLocation: (editorId: string, locationUrn: string) => Promise<ServiceResultType>;
};

export const useQuickResHelper = (): QuickResHelperHook => {
  const { showAlert } = useAlert();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();

  const savePickupAndReturnLocation = useCallback(
    async (editorId: string, currentLocationUrn: string) => {
      let errors;
      try {
        await Promise.all([
          updatePickupInformation(editorId, { branch: currentLocationUrn }),
          updateReturnInformation(editorId, { branch: currentLocationUrn }),
        ]);
      } catch (error) {
        const ehiErrorsResponse = safelyCatchError(error);
        errors = ehiErrorsResponse.errors;
        await showAlert({ responseMessages: errors });
      }
      return { success: !errors, errors: errors };
    },
    [showAlert]
  );

  const handleAddingRemarks = async (
    handleSaveError: (error: unknown) => Promise<void>,
    editorId: string,
    preferences?: string
  ): Promise<boolean> => {
    if (!preferences) return true;
    try {
      await updateRemarkForReservationEditor(editorId, preferences);
      return true;
    } catch (error) {
      await handleSaveError(error);
      return false;
    }
  };

  const createProfile = async (editorId: string, lastName: string, firstName: string, phone?: string) => {
    let createProfileResponse: TransactionalProfileResponseContent | undefined = undefined;
    try {
      const createRequestBody = createQuickResProfileRequestBody(lastName, firstName, phone);
      createProfileResponse = await createTransactionalProfile(createRequestBody);
    } catch (error) {
      logError({ error, message: `Create transactional profile failed for editor id ${editorId}` });
    }
    createProfileResponse?.urn && (await associateToRenter(editorId, createProfileResponse?.urn));
  };

  const associateToRenter = async (editorId: string, profileUrn?: string) => {
    try {
      const associateRenterRequestBody = {
        type: RenterTypes.TransactionalProfile,
        profile: profileUrn ? profileUrn : undefined,
      } as Renter;
      await updateAndRefresh(() => associateRenterToReservationEditor(editorId, associateRenterRequestBody));
    } catch (error) {
      logError({ error, message: `Associate profile to editor failed for editor id ${editorId}` });
    }
  };

  return {
    associateToRenter,
    createProfile,
    handleAddingRemarks,
    savePickupAndReturnLocation,
  };
};
