import { FunctionComponent } from 'react';
import { useTranslations } from 'components/shared/i18n';
import DynamicHelmet from 'components/shared/helmet/DynamicHelmet';
import { Outlet } from 'react-router-dom';
import { ResActionsProvider } from 'context/resActions/ResActionsContext';

export const CreateRouter: FunctionComponent = () => {
  const { t } = useTranslations();

  return (
    <ResActionsProvider label={t('common.create')}>
      <DynamicHelmet title={t('common.createReservation')} />
      <Outlet />
    </ResActionsProvider>
  );
};
