import { FC } from 'react';

type NationalIconRoundProps = {
  height: number;
  width: number;
};
export const NationalRoundIcon: FC<NationalIconRoundProps> = ({ height = 72, width = 72 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 72 72'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-testid={'nationalLogo'}>
      <rect width='72' height='72' rx='36' fill='#00703C' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.1 52.1C21 52 18 53 16.8 50.4C16.2 48.7 16.7 47.4 17.7 46.6C18.9 45.6 20.7 45.1 23.3 46.2L22.1 52.1ZM19.4 38C19.4 38.1 23.7 33.5 35.5 43.1C44.6 50.5 50.1 46.7 50.1 46.7L49.1 51.3C49.1 51.3 44.7 55.9 33.1 46.7C23.5 39 18.5 42.6 18.5 42.6C18.5 42.6 19.3 37.8 19.4 38ZM21.2 29.3C21.2 29.4 25.5 24.9 37.3 34.4C46.4 41.8 51.9 38 51.9 38L50.9 42.6C50.9 42.6 46.5 47.2 34.9 38C25.2 30.3 20.2 33.9 20.2 33.9C20.2 33.9 21.2 29.1 21.2 29.3ZM23.1 20.7C23.1 20.7 24.1 19.7 26.5 19.7C29 19.7 33.1 20.9 39.2 25.8C48.3 33.2 53.8 29.4 53.8 29.4L52.8 34C52.8 34 48.4 38.6 36.8 29.4C27.2 21.7 22.2 25.3 22.2 25.3C22.2 25.3 23.1 20.5 23.1 20.7ZM49.9 20.4C51 20.5 54 19.5 55.2 22.1C55.8 23.8 55.3 25.1 54.3 25.9C53.1 26.9 51.3 27.4 48.7 26.3L49.9 20.4Z'
        fill='#B3D88C'
      />
    </svg>
  );
};
