import { FC, useCallback, useState } from 'react';
import { AppBar, Box } from '@mui/material';
import { DriverSearchForm } from 'components/flexFlow/driver/searchDriver/DriverSearchForm';
import { useRenterProfileSearch } from 'components/flexFlow/driver/searchDriver/useRenterProfileSearch';
import { DriverSearchValues } from './DriverSearchTypes';
import { MarginWrapper, StyledList } from 'components/shared/ui/styles/Global.styles';
import { Subtitle1 } from '@ehi/ui';
import { DividerWithMargin } from 'components/shared/ui/styles/Divider.styles';
import DriverSearchResult from './DriverSearchResult';
import { SearchResult } from 'services/renter/driverProfile/driverProfileTypes';
import { NoResultsView } from 'components/shared/ui/noResultsView/NoResultsView';
import { useTranslations } from 'components/shared/i18n';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { DriverProfileRenter, Renter } from 'services/booking/bookingTypes';
import { associateRenterToReservationEditor, updateAdditionalDrivers } from 'services/booking/bookingService';
import { loadCounterCookie, loadEhiLocationCookie } from '@ehi/location';
import { useAppSelector } from 'redux/hooks';
import {
  selectAdditionalDrivers,
  selectBookingEditorId,
  selectDriverProfileRenter,
} from 'redux/selectors/bookingEditor';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { logError } from 'components/shared/logger/splunkLogger';
import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { ErrorSeverity } from '@ehi/analytics';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { usePhoneTypesQuery } from 'services/renter/renterReferenceQueries';
import { toDateTimeString, YEAR_MONTH_DAY_FORMAT } from 'utils/dateUtils';

export type NoResultSearchValuesType = {
  lastName: string;
  firstName: string;
  phoneNumber: string;
  dob: string;
};

export type DriverSearchProps = {
  navigateToCreateDriver: (noResultSearchValues: NoResultSearchValuesType) => void;
  onClose: () => void;
};

export const noResultsSearchInitialValues: NoResultSearchValuesType = {
  phoneNumber: '',
  firstName: '',
  lastName: '',
  dob: '',
};

export const DriverSearch: FC<DriverSearchProps> = ({ navigateToCreateDriver, onClose }: DriverSearchProps) => {
  const { t } = useTranslations();
  const { search } = useRenterProfileSearch();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { showAlert } = useAlert();
  const cookieLocation = loadEhiLocationCookie();
  const counterCookie = loadCounterCookie();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const driverProfileRenter = useAppSelector(selectDriverProfileRenter);
  const additionalDrivers = useAppSelector(selectAdditionalDrivers);

  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<SearchResult[] | undefined>();
  const [noResultSearchValues, setNoResultSearchValues] =
    useState<NoResultSearchValuesType>(noResultsSearchInitialValues);

  const { data: phoneTypeDomain, isFetching: isPhoneDomainLoading } = usePhoneTypesQuery();

  const handleError = async (errors: ResponseMessage[] | undefined) => {
    if (errors) {
      setLoading(false);
      await showAlert({
        variant: 'error',
        description: `${t('driver.addDriverError')}: ${errors?.[0]?.localizedMessage || ''}`,
      });
    } else {
      onClose();
    }
  };
  const handleLogError = (error: unknown, message: string) => {
    logError({
      error: {
        message: t(message),
        supportInformation: {
          transactionType: TransactionTypes.CreateFullRes,
          location: cookieLocation,
          counter: counterCookie?.counterId,
          serviceError: error,
        },
      },
      severity: ErrorSeverity.Fatal,
    });
    setLoading(false);
  };

  const searchForRenter = useCallback(
    async (values: DriverSearchValues): Promise<SearchResult[]> => {
      setLoading(true);
      return search(values)
        .then(async (results) => {
          setResults(results);
          return results;
        })
        .catch(() => {
          setResults([]);
          return [];
        })
        .finally(() => {
          if (values) {
            setNoResultSearchValues({
              phoneNumber: values.phoneNumber,
              firstName: values.firstName,
              lastName: values.lastName,
              dob: toDateTimeString(values.dateOfBirth, YEAR_MONTH_DAY_FORMAT) ?? '',
            });
          }
          setLoading(false);
        });
    },
    [search, setResults, setLoading]
  );

  const handleAddDriver = async (driverData: SearchResult) => {
    setLoading(true);
    if (!driverProfileRenter) {
      const requestBody = {
        type: 'DRIVER_PROFILE',
        profile: driverData?.urn,
      } as DriverProfileRenter;

      requestBody.membership = driverData?.loyaltyMembership?.loyaltyProgram
        ? {
            loyaltyProgram: driverData?.loyaltyMembership?.loyaltyProgram,
          }
        : undefined;

      try {
        const { errors } = await updateAndRefresh(() =>
          associateRenterToReservationEditor(bookingEditorId, requestBody as Renter)
        );
        await handleError(errors);
      } catch (error) {
        handleLogError(error, 'driver.addDriverError');
      }
    } else {
      try {
        const currentAdditionalDrivers = additionalDrivers && additionalDrivers.length > 0 ? additionalDrivers : [];
        const requestBody = [
          ...currentAdditionalDrivers,
          {
            name: { given: driverData.name?.givenName ?? '', surname: driverData.name?.surname ?? '' },
            profile: driverData.urn,
          },
        ];
        const { errors } = await updateAndRefresh(() => updateAdditionalDrivers(bookingEditorId, requestBody));
        await handleError(errors);
      } catch (error) {
        handleLogError(error, 'driver.addAdditionalDriverError');
      }
    }
  };

  return (
    <>
      <AppBar position='sticky' color='inherit' style={{ boxShadow: 'none' }}>
        <DriverSearchForm search={searchForRenter} />
      </AppBar>
      <MarginWrapper>
        {results && results.length > 0 && (
          <StyledList>
            <Subtitle1 data-testid='result-header'>{`${results.length} ${t('driverSearch.results')}`} </Subtitle1>
            <DividerWithMargin />
            {results.map((renter, index: number) => {
              return (
                <DriverSearchResult
                  onClose={onClose}
                  testId={`driverSearchResult-${index}`}
                  searchResult={renter}
                  key={index}
                  onAddDriver={(renter: SearchResult) => handleAddDriver(renter)}
                  phoneTypeDomain={phoneTypeDomain}
                />
              );
            })}
          </StyledList>
        )}
        {results && results.length === 0 && (
          <Box paddingTop={12}>
            <NoResultsView
              noResultsTitle='driverSearch.noResultsTitle'
              noResultsDescription='driverSearch.noResultsAddInfo'
              isBackgroundColor={false}
              handleNavigate={() => navigateToCreateDriver(noResultSearchValues)}
            />
          </Box>
        )}
      </MarginWrapper>
      <ProgressOverlay inProgress={loading || isPhoneDomainLoading} />
    </>
  );
};
