import { FC, useMemo } from 'react';
import { CreateDriverFields } from 'components/flexFlow/driver/createDriver/CreateDriverTypes';
import {
  getLicenseIssuerLabel,
  issuerRegionCountries,
  transformLegalIdTypes,
} from 'components/flexFlow/driver/createDriver/createDriverUtils';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { CountrySubDivisionSelectField } from 'components/shared/forms/CountrySubDivisionSelectField';
import { FormTextField } from 'components/shared/forms/FormTextField';
import { CorporateCountry } from 'utils/countryUtils';
import { CountrySelectField } from 'components/shared/forms/CountrySelectField';
import { SelectField } from 'components/shared/forms/SelectField';
import { DatePickerField } from 'components/shared/forms/DatePickerField';
import { useTranslations } from 'components/shared/i18n';
import { useGetLegalIdentificationTypes } from 'services/renter/renterReferenceQueries';

type SubSectionProps = {
  licenseCountry: CorporateCountry;
};

export const LicenseIssuerField: FC<SubSectionProps> = ({ licenseCountry }) => {
  const { t } = useTranslations();
  const licenseIssuerLabel = useMemo(() => getLicenseIssuerLabel(licenseCountry), [licenseCountry]);
  const issuerDropdown = useMemo(
    () => licenseCountry && issuerRegionCountries.includes(licenseCountry),
    [licenseCountry]
  );

  return (
    <GridItem sm={6}>
      {issuerDropdown ? (
        <CountrySubDivisionSelectField
          name={CreateDriverFields.LicenseIssuerRegion}
          label={t(licenseIssuerLabel)}
          data-testid={'licenseIssuerRegion'}
          country={licenseCountry}
          required
        />
      ) : (
        <FormTextField
          name={CreateDriverFields.LicenseIssuerAuth}
          label={t(licenseIssuerLabel)}
          data-testid={'licenseIssuerField'}
          required
          disabled={licenseCountry === CorporateCountry.Spain || licenseCountry === CorporateCountry.GreatBritain}
        />
      )}
    </GridItem>
  );
};

export const IdentificationNumberField = () => {
  const { t } = useTranslations();

  return (
    <GridItem sm={4}>
      <FormTextField
        name={CreateDriverFields.IdentificationNumber}
        label={t('driver.identificationNumber')}
        data-testid={'idNumber'}
        required
      />
    </GridItem>
  );
};

export const LicenseDVLAField = () => {
  const { t } = useTranslations();

  return (
    <GridItem sm={4}>
      <FormTextField
        name={CreateDriverFields.LicenseDVLA}
        label={t('driver.dvla')}
        data-testid={'licenseDVLA'}
        disabled
        value={t('driver.licensePresented')}
      />
    </GridItem>
  );
};

export const PlaceOfBirthField = () => {
  const { t } = useTranslations();

  return (
    <GridItem sm={6}>
      <FormTextField
        name={CreateDriverFields.PlaceOfBirth}
        label={t('driver.placeOfBirth')}
        data-testid={'placeOfBirth'}
        required
      />
    </GridItem>
  );
};

export const SpainSubSection = () => {
  const { t } = useTranslations();
  const { data } = useGetLegalIdentificationTypes();
  const legalIdTypeOptions = useMemo(() => {
    return data && transformLegalIdTypes(data);
  }, [data]);

  return (
    <GridContainer data-testid={'idSectionSpain'}>
      <GridItem sm={12}>
        <h3>{t('driver.identification')}</h3>
      </GridItem>
      <GridItem sm={6}>
        <CountrySelectField
          name={CreateDriverFields.IdIssuingCountry}
          data-testid={'idIssuingCountry'}
          label={t('driver.issuingCountry')}
        />
      </GridItem>
      <GridItem sm={6}>
        <SelectField
          name={CreateDriverFields.IdType}
          label={t('driver.idType')}
          data-testid={'idType'}
          options={legalIdTypeOptions ?? []}
        />
      </GridItem>
      <GridItem sm={6}>
        <FormTextField
          name={CreateDriverFields.IdPassportNumber}
          label={t('driver.idPassport')}
          data-testid={'idPassportNumber'}
          type='text'
          inputProps={{ maxLength: 15 }}
        />
      </GridItem>
      <GridItem sm={6}>
        <DatePickerField
          name={CreateDriverFields.IdExpirationDate}
          label={t('driver.expDate')}
          data-testid={'idExpirationDate'}
        />
      </GridItem>
    </GridContainer>
  );
};
