import { AxiosResponse } from 'axios';
import { store } from 'redux/store';
import { setBookingEditorDirty } from 'redux/slices/booking/bookingEditorSlice';

export const bookingEditorStateInterceptor = (response: AxiosResponse): AxiosResponse => {
  if (
    response.config.url?.startsWith('/rental/booking/reservationEditor/') &&
    (response.config.method === 'post' || response.config.method === 'put' || response.config.method === 'delete') &&
    response.status > 199 &&
    response.status < 300
  ) {
    store.dispatch(setBookingEditorDirty(true));
  }
  return response;
};
