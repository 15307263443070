/**
 * Constants for processing reservations internally
 */
export enum InternalTransactionParams {
  /** Query param - Type of transaction @see TicketTransactionTypes */
  TransactionType = 'transactionType',

  /** Query param - reservation to process */
  Res = 'res',

  NotFound = 'notFound',
}
