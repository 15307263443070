import { CircularProgress, styled } from '@mui/material';

export const SpinnerContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const FullScreenSpinnerContainer = styled(SpinnerContainer)`
  width: 100vw;
  height: 100vh;
`;

export const StyledSpinner = styled(CircularProgress)``;
StyledSpinner.defaultProps = {
  size: '3rem',
};
