import { ResponseMessage } from 'services/types/ResponseMessageTypes';

export type EhiErrors = {
  errors?: ResponseMessage[];
  status?: number;
};

export enum HttpStatusCode {
  BadRequest = 400,
  NotFound = 404,
  RequestTimeout = 408,
  InternalServerError = 500,
  ServiceUnavailable = 503,
}
