import { FC, useMemo } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient as queryClientObject } from 'context/queryClient/queryUtil';
import { isCypressRun, isDevBuild } from 'utils/buildInfoUtil';
import { QueryClientInfo, QueryClientProps } from './QueryTypes';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { DefaultOptions, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { del, get, set } from 'idb-keyval';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';

const cypressDefaultOptions: DefaultOptions = {
  queries: {
    retry: false,
  },
};

const indexedDBQueryStorage = {
  getItem: async (key: string): Promise<string> => {
    return (await get(key)) as string;
  },
  setItem: (key: string, value: string): Promise<void> => set(key, value),
  removeItem: (key: string): Promise<void> => {
    return del(key);
  },
};

export const QueryClientProviderWrapper: FC<QueryClientProps> = ({ children }) => {
  const { queryClient, persister } = useMemo((): QueryClientInfo => {
    if (!isCypressRun()) {
      return {
        queryClient: queryClientObject,
        persister: createAsyncStoragePersister({
          storage: indexedDBQueryStorage,
        }),
      };
    }

    return {
      queryClient: new QueryClient({ defaultOptions: cypressDefaultOptions }),
    };
  }, []);

  return (
    <>
      {persister ? (
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{
            persister: persister,
            buster: APP_VERSION,
          }}>
          {children}
          {isDevBuild() ? <ReactQueryDevtools initialIsOpen={false} /> : null}
        </PersistQueryClientProvider>
      ) : (
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
      )}
    </>
  );
};
