import { Vehicle, Vehicles } from 'components/shared/uiModels/vehicle/vehicleDataTypes';
import { VehicleContent, VehicleContentType } from 'services/vehicleContent/vehicleContentTypes';
import { VehicleRates } from 'services/booking/bookingTypes';
import { parseUrn } from 'utils/urnUtils';

const transformVehicleFromVehicleContent = (vehicleContentType: VehicleContentType): Vehicle => {
  return {
    passengers: vehicleContentType.passengers,
    vehicleClass: vehicleContentType.carClass,
    countryCode: vehicleContentType.countryCode,
    description: vehicleContentType.description,
    imageKey: vehicleContentType.imageKey,
    luggageSpace: vehicleContentType.luggageSpace,
    similarModels: vehicleContentType.similarModels,
    transmissionType: vehicleContentType.transmissionType,
    features: vehicleContentType.features,
    translatedVehicleFeatures: vehicleContentType.translatedVehicleFeatures,
    locale: vehicleContentType.locale,
    groupCategory: vehicleContentType.groupCategory,
    groupCategoryCode: vehicleContentType.groupCategoryCode,
    isExpanded: false,
  };
};

const transformVehicleFromVehicleRates = (
  vehicleContentType: VehicleContentType,
  vehicleRate: VehicleRates
): Vehicle => {
  return {
    ...transformVehicleFromVehicleContent(vehicleContentType),
    vehicleRates: vehicleRate,
  };
};

export const transformVehicleListFromApiData = (
  vehicleContent: VehicleContent | undefined,
  vehicleRates: VehicleRates[] | undefined
): Vehicles => {
  if (!vehicleContent) {
    return [];
  }
  const vehicles = new Array<Vehicle>();

  if (vehicleRates) {
    vehicleContent.forEach((vehicleContentType) => {
      const vehicleRate = vehicleRates.find(
        (vehicleRate) => parseUrn(vehicleRate.vehicleClassToReserve) === vehicleContentType.carClass
      );

      if (vehicleRate) {
        vehicles.push(transformVehicleFromVehicleRates(vehicleContentType, vehicleRate));
      }
    });
  } else {
    vehicleContent.forEach((vehicleContentType) => {
      vehicles.push(transformVehicleFromVehicleContent(vehicleContentType));
    });
  }

  return vehicles;
};
