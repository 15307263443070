import { Checkbox, CheckboxProps, FormControlLabel, FormControlLabelProps } from '@mui/material';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

export type CheckboxFieldProps<TFieldValues extends FieldValues = FieldValues> = CheckboxProps &
  Pick<FormControlLabelProps, 'labelPlacement'> & {
    name: Path<TFieldValues>;
    label?: string;
    dense?: boolean;
  };

/**
 * For use within React Hooks forms
 */
export const CheckboxField = <T extends FieldValues>({
  name,
  label,
  labelPlacement = 'end',
  dense,
  ...props
}: CheckboxFieldProps<T>) => {
  const { control } = useFormContext<T>();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const { ref, ...fieldProps } = field;

        return (
          <FormControlLabel
            checked={field.value}
            sx={dense ? { margin: 0 } : {}}
            control={<Checkbox {...fieldProps} inputRef={ref} sx={dense ? { paddingY: 0 } : {}} {...props} />}
            label={label}
            labelPlacement={labelPlacement}
          />
        );
      }}
    />
  );
};
