import { FC } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { QuickResFields } from 'components/quickRes/QuickResTypes';
import { GridContainer } from 'components/shared/ui/styles/Grid.styles';
import { StyledGridItem } from 'components/quickRes/QuickRes.styles';
import { DatePickerField } from 'components/shared/forms/DatePickerField';
import { TimePickerField } from 'components/shared/forms/TimePickerField';
import { DateTime } from 'luxon';

interface Props {
  handleDateChange: (date: DateTime | '') => void;
  handleTimeChange: (date: DateTime | '') => void;
  timezone: string;
}

const RentalPickup: FC<Props> = ({ handleDateChange, handleTimeChange, timezone }) => {
  const { t } = useTranslations();

  return (
    <>
      <h3>{t('whenWhere.rentalStart')}</h3>
      <GridContainer data-testid={'rentalStartSection'}>
        <StyledGridItem xs={12} sm={12} md={6} pl={0}>
          <DatePickerField
            name={QuickResFields.StartDate}
            label={t('whenWhere.date')}
            submitOnChange={handleDateChange}
            disablePast
            required
          />
        </StyledGridItem>
        <StyledGridItem xs={12} sm={12} md={6} pl={0}>
          <TimePickerField
            name={QuickResFields.StartTime}
            label={t('whenWhere.time')}
            submitOnChange={handleTimeChange}
            timezone={timezone}
            required
          />
        </StyledGridItem>
      </GridContainer>
    </>
  );
};

export default RentalPickup;
