import { Iso2Locales } from '@ehi/location';
import i18n from 'components/shared/i18n/i18n';
import { LocaleItem } from './LocalesTypes';

const SupportedLocales = [Iso2Locales.enCA, Iso2Locales.enUS, Iso2Locales.frCA, Iso2Locales.frUS];

export const isValidLocale = (locale: string | Iso2Locales | undefined): locale is Iso2Locales => {
  return locale ? SupportedLocales.includes(locale as Iso2Locales) : false;
};

export const localeMap: Map<string, LocaleItem> = new Map([
  [Iso2Locales.enUS, { iso3Locale: 'eng-USA', iso3Country: 'USA', iso2Country: 'US', iso2Language: 'en' }],
  [Iso2Locales.enCA, { iso3Locale: 'eng-CAN', iso3Country: 'CAN', iso2Country: 'CA', iso2Language: 'en' }],
  [Iso2Locales.frCA, { iso3Locale: 'fra-CAN', iso3Country: 'CAN', iso2Country: 'CA', iso2Language: 'fr' }],
  [Iso2Locales.frUS, { iso3Locale: 'fra-USA', iso3Country: 'USA', iso2Country: 'US', iso2Language: 'fr' }],
]);

export const getCurrentIso3Locale = (): string => {
  return localeMap.get(getCurrentIso2Locale())?.iso3Locale ?? '';
};

export const getCurrentIso2Locale = (): string => {
  return i18n?.language ?? 'en-US';
};

export const formatLocale = (
  languageCode: string | undefined,
  countryCode: string | undefined
): Iso2Locales | undefined => {
  if (languageCode && countryCode) {
    const locale = languageCode + '-' + countryCode;
    return isValidLocale(locale) ? locale : undefined;
  } else {
    return undefined;
  }
};
