import { SvgIconTypeMap } from '@mui/material';

export type IconWrapperProps = Partial<SvgIconTypeMap['props']> & {
  component: any;
  className?: string;
};

export enum PageStatus {
  Complete,
  Incomplete,
  NavigationOnly,
}

export type StepIconProps = {
  status: PageStatus;
};
