import { Body2, EhiButton, H6 } from '@ehi/ui';
import { Box, Grid } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { useAppSelector } from 'redux/hooks';
import {
  selectHasDatetimeData,
  selectHasLocationData,
  selectIsReadOnlyFlow,
  selectRateSource,
} from 'redux/selectors/bookingEditor';
import { NegotiatedRateSource, RateSource } from 'services/booking/bookingTypes';
import { parseUrn } from 'utils/urnUtils';
import { RateAndBillingSectionBox } from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { RateSourceInfo } from 'components/flexFlow/rateAndBilling/section/rateSource/RateSourceInfo';

export const RateSourceSection = () => {
  const { t } = useTranslations();

  const hasLocationData = useAppSelector(selectHasLocationData);
  const hasDatetimeData = useAppSelector(selectHasDatetimeData);
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);

  const rateSource: RateSource | undefined = useAppSelector(selectRateSource);
  const negotiatedRateSource = rateSource as NegotiatedRateSource;
  const accountNumber = negotiatedRateSource ? parseUrn(negotiatedRateSource.account) : undefined;
  const rateProduct = parseUrn(rateSource?.rateProduct);

  return (
    <RateAndBillingSectionBox data-testid='rateSourceSection'>
      <Box display={'flex'} justifyContent={'space-between'}>
        <H6>{t('rateAndBilling.rateSource')}</H6>
        {!isReadOnly && <EhiButton>{t('common.edit')}</EhiButton>}
      </Box>
      <Grid container>
        <Grid item xs={6} sm={6} data-testid='accountNumber'>
          <Caption2 data-testid='label' display={'inline'}>
            {t('rateAndBilling.accountNumber')}
          </Caption2>
          {accountNumber && (
            <Body2 data-testid='textBody' bold display={'inline'}>
              {accountNumber}
            </Body2>
          )}
        </Grid>
        <Grid item xs={6} sm={6} data-testid='rateProduct'>
          <Caption2 data-testid='label' display={'inline'}>
            {t('rateAndBilling.rateProduct')}
          </Caption2>
          {rateProduct && (
            <Body2 data-testid='textBody' bold display={'inline'}>
              {rateProduct}
            </Body2>
          )}
        </Grid>
      </Grid>
      {hasLocationData && hasDatetimeData && !isReadOnly && <RateSourceInfo />}
    </RateAndBillingSectionBox>
  );
};
