import { parseUrn } from 'utils/urnUtils';
import { NegotiatedRateSource, RateSource } from 'services/booking/bookingTypes';
import { useAppSelector } from 'redux/hooks';
import { selectRateSource } from 'redux/selectors/bookingEditor';
import { useBusinessAccountQuery } from 'services/businessAccount/accountQueries';
import { RateSourceInfo } from 'components/shared/uiModels/rateSource/rateSourceTypes';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { transformRateSourceInfoFromApi } from 'components/shared/uiModels/rateSource/rateSourceTransformer';

type UseAccountDetailsReturn = {
  rateSourceInfo: RateSourceInfo | undefined;
  isRateSourceInfoLoading: boolean;
  rateSourceErrors: EhiErrors | null;
};

export const useAccountDetails = (): UseAccountDetailsReturn => {
  const rateSource: RateSource | undefined = useAppSelector(selectRateSource);
  const negotiatedRateSource = rateSource as NegotiatedRateSource;
  const accountNumber = parseUrn(negotiatedRateSource?.account);

  const {
    data: accountDetails,
    isFetching: isAccountDetailsLoading,
    error: accountErrors,
  } = useBusinessAccountQuery(accountNumber, {
    enabled: !!negotiatedRateSource && accountNumber.length > 0,
  });

  return {
    rateSourceInfo: accountDetails ? transformRateSourceInfoFromApi(accountDetails) : undefined,
    isRateSourceInfoLoading: isAccountDetailsLoading,
    rateSourceErrors: accountErrors,
  };
};
