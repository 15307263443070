import { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import {
  ACCEPT,
  ACCEPT_APPLICATION_JSON,
  ACCEPT_LOYALTY_PROGRAM,
  AUTHORIZATION,
  CALLING_APPLICATION,
  CONTENT_TYPE,
  CONTENT_TYPE_APPLICATION_JSON,
  EHI_CALLER_ID,
  EHI_CALLING_APPLICATION,
  EHI_DEVICE_LOCATION_ID,
  EHI_LOCALE,
  EHI_ORIGIN_APPLICATION,
  EHI_REQUEST_ID,
  EHI_WORKFLOW_ID,
  ORIGIN_APP_VERSION,
  SPAN_ID,
  TIMEZONE,
  TRACE_ID,
} from 'services/headerConstants';
import { authStore } from '@ehi/auth';
import { userAgent } from 'utils/deviceInfo';
import { loadEhiLocationCookie } from '@ehi/location';
import { getLocationCache } from 'services/location/locationCache';
import { ContextApi, ContextEndpoint } from 'services/appConfig/appConfigTypes';
import { AxiosRequestConfig } from 'axios/index';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { loadItem, WORKFLOW_ID_KEY } from 'utils/localStorageUtils';

export const ehiHeaderInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const { eid, jwt } = authStore.getSnapshot();
  const cookie = loadEhiLocationCookie();
  const locationCache = getLocationCache(cookie?.peoplesoftId ?? '');

  if (!config.headers) {
    config.headers = {} as AxiosRequestHeaders;
  }

  // Authorization headers
  config.headers[AUTHORIZATION] = `Bearer ${jwt}`;
  config.headers[EHI_CALLER_ID] = `${eid}_${userAgent()}`;

  // Location headers
  config.headers[TIMEZONE] = locationCache?.location.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
  config.headers[EHI_DEVICE_LOCATION_ID] = locationCache?.location.stationId ?? '';
  // Verify the value
  config.headers[EHI_LOCALE] = locationCache?.location.locale.iso3Locale ?? 'eng-USA';

  // EHI required headers
  if (!config.headers[EHI_CALLING_APPLICATION]) {
    config.headers[EHI_ORIGIN_APPLICATION] = CALLING_APPLICATION;
    config.headers[EHI_CALLING_APPLICATION] = CALLING_APPLICATION;
  }

  config.headers[EHI_REQUEST_ID] = generateRandomHexString(16);
  config.headers[SPAN_ID] = generateRandomHexString(16);
  config.headers[TRACE_ID] = generateRandomHexString(32);
  config.headers[EHI_WORKFLOW_ID] = loadItem(WORKFLOW_ID_KEY);

  if (!config.headers[CONTENT_TYPE]) {
    config.headers[CONTENT_TYPE] = CONTENT_TYPE_APPLICATION_JSON;
  }

  const endpoint = getAppConfigCache()?.serviceEndpoints?.superEdge;
  if ((endpoint as ContextEndpoint)?.contextApis) {
    const contextApi = getMatchingContextApi(endpoint as ContextEndpoint, config);
    config.headers[ORIGIN_APP_VERSION] = contextApi.env;
    config.apiName = contextApi.name;
  }
  setAcceptHeader(config, endpoint);

  return config;
};

export const generateRandomHexString = (length: number): string => {
  let output = '';
  for (let i = 0; i < length; ++i) {
    output += Math.floor(Math.random() * 16)
      .toString(16)
      .toLowerCase();
  }
  return output;
};

export const getMatchingContextApi = (contextEndpoint: ContextEndpoint, config: AxiosRequestConfig): ContextApi => {
  const configUrl: string = config.url || '';
  const contextApi = contextEndpoint.contextApis.find(({ context }) => {
    const pattern = new RegExp(`^${context}`);
    return configUrl.match(pattern);
  });
  if (contextApi) {
    return contextApi;
  } else {
    throw new Error(`No matching context for url declared. Url: ${configUrl} Host: ${config.baseURL}`);
  }
};

const setAcceptHeader = (config: InternalAxiosRequestConfig, contextEndpoint: ContextEndpoint | undefined) => {
  let accept = config.headers[ACCEPT] as string;
  let version;

  if (config.method?.toUpperCase() === 'GET' && !accept) {
    accept = ACCEPT_APPLICATION_JSON;
  }

  if (contextEndpoint?.contextApis) {
    const contextApi = getMatchingContextApi(contextEndpoint, config);
    // We cannot send in the version in the Accept header for loyalty program endpoint within Driver Profile services
    version = accept !== ACCEPT_LOYALTY_PROGRAM && contextApi.version;
  }

  if (version) {
    const delimiter = ';\u0020';
    const versionAndDelimiter = `${delimiter}version=${version}`;
    accept = accept ? `${accept}${versionAndDelimiter}` : `*/*${versionAndDelimiter}`;
  }
  config.headers[ACCEPT] = accept;
};
