import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { QuickResFields } from 'components/quickRes/QuickResTypes';
import { useTranslations } from 'components/shared/i18n';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { MAX_CHARS } from 'components/quickRes/quickResUtils';

export const QuickResPreferencesTextField: FC = () => {
  const { t } = useTranslations();
  const { control, trigger } = useFormContext();

  return (
    <GridContainer>
      <GridItem sm={12}>
        <Typography variant='h6' gutterBottom>
          {t('quickReservation.preferences')}
        </Typography>
      </GridItem>

      <GridItem sm={12}>
        <Controller
          control={control}
          name={QuickResFields.Preferences}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              style={{ marginBottom: '6rem' }}
              value={field.value || ''}
              id={QuickResFields.Preferences}
              placeholder={t('quickReservation.preferences')}
              multiline
              rows={4}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              inputProps={{
                maxLength: MAX_CHARS,
                'aria-label': field.name,
                'data-testid': field.name,
              }}
              onBlur={async () => {
                await trigger(QuickResFields.Preferences);
              }}
            />
          )}
        />
      </GridItem>
    </GridContainer>
  );
};
