import { createContext } from 'react';
import { SecretsContextProps } from './SecretsContextTypes';

export const secretsInitialState = {
  reservationsDevPendoKey: '',
  reservationsDevSupportKey: '',
  reservationsDevLaunchDarklyKey: '',
  reservationsGcsApiKey: '',
  reservationsGoogleMapsApiKey: '',
};
export const SecretsContext = createContext<SecretsContextProps>({
  secrets: secretsInitialState,
  setSecretsState: () => undefined,
});
