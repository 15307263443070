import { DateTime } from 'luxon';

export enum SearchByType {
  RenterInformation = 'driver.renterInformation',
  LoyaltyNumber = 'driver.loyaltyNumber',
  DriversLicense = 'driver.driversLicense',
}

export interface DriverSearchValues {
  searchBy?: SearchByType;
  loyaltyNumber: string;
  licenseNumber: string;
  country: string;
  state: string | undefined;
  lastName: string;
  firstName: string;
  dateOfBirth: DateTime | undefined;
  phoneNumber: string;
}

export enum DriverSearchFields {
  SearchBy = 'searchBy',
  LoyaltyNumber = 'loyaltyNumber',
  LicenseNumber = 'licenseNumber',
  Country = 'country',
  State = 'state',
  LastName = 'lastName',
  FirstName = 'firstName',
  DOB = 'dateOfBirth',
  PhoneNumber = 'phoneNumber',
}
