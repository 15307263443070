import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { Grid } from '@mui/material';
import { Body2, EhiButton, ehiTheme } from '@ehi/ui';
import { FormTextField } from 'components/shared/forms/FormTextField';
import { QuickResFields } from 'components/quickRes/QuickResTypes';
import { InputIconButton } from 'components/shared/ui/InputIconButton/InputIconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FieldLoadingIndicator } from 'components/shared/forms/FieldLoadingIndicator';
import { FieldClearIcon } from 'components/shared/ui/FieldClearIcon';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { AccountNumberSearch, SectionContainer, SectionInfo } from 'components/quickRes/QuickRes.styles';
import { useFormContext, useWatch } from 'react-hook-form';
import { parseUrn } from 'utils/urnUtils';
import { retrieveAccount } from 'services/businessAccount/businessAccountService';
import { NegotiatedRateSource, RateSource } from 'services/booking/bookingTypes';
import { modifyRateSource } from 'services/booking/bookingService';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAppSelector } from 'redux/hooks';
import {
  selectBookingEditorId,
  selectPickup,
  selectRateSource,
  selectReturn,
  selectVehicleClassSelection,
} from 'redux/selectors/bookingEditor';
import { transformRateSourceInfoFromApi } from 'components/shared/uiModels/rateSource/rateSourceTransformer';
import { EMPTY_VALUE } from 'utils/constants';

export const QuickResRateSource: FC = () => {
  const { t } = useTranslations();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const [loading, setLoading] = useState(false);

  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const rateSource = useAppSelector(selectRateSource);
  const vehicle = useAppSelector(selectVehicleClassSelection);
  const pickupInfo = useAppSelector(selectPickup);
  const returnInfo = useAppSelector(selectReturn);

  const { setValue, setError, clearErrors } = useFormContext();
  const [accountNumber, accountName, accountType] = useWatch({
    name: [QuickResFields.AccountNumber, QuickResFields.AccountName, QuickResFields.AccountType],
  });

  const isValidAccountNumber = (rateSource as NegotiatedRateSource)?.account;
  const showRateSourceInfo = useMemo(
    () => isValidAccountNumber || (pickupInfo?.dateTime && returnInfo?.dateTime),
    [isValidAccountNumber, pickupInfo?.dateTime, returnInfo?.dateTime]
  );
  const showRateProduct = useMemo(() => {
    // If pickup datetime, return datetime and vehicle are removed from editor, the rate product remains
    return isValidAccountNumber && pickupInfo?.dateTime && returnInfo?.dateTime && vehicle;
  }, [isValidAccountNumber, pickupInfo?.dateTime, returnInfo?.dateTime, vehicle]);
  const rateProduct = useMemo(() => {
    if (showRateProduct) {
      return parseUrn(rateSource?.rateProduct);
    } else {
      return '';
    }
  }, [rateSource?.rateProduct, showRateProduct]);

  const handleClearingAccountNumber = useCallback(async () => {
    if (isValidAccountNumber) {
      try {
        setLoading(true);
        const rateSourceRequestBody: RateSource = {
          type: 'RETAIL',
        };
        const { errors } = await updateAndRefresh(() => modifyRateSource(bookingEditorId, rateSourceRequestBody));
        if (!errors) {
          clearErrors(QuickResFields.AccountNumber);
        } else {
          setError(QuickResFields.AccountNumber, { message: t('rateAndBilling.invalidAccountNumber') });
        }
      } catch {
        setError(QuickResFields.AccountNumber, { message: t('rateAndBilling.invalidAccountNumber') });
      } finally {
        setValue(QuickResFields.AccountNumber, EMPTY_VALUE);
        setValue(QuickResFields.AccountName, '--');
        setValue(QuickResFields.AccountType, t('rateAndBilling.retail'));
        setLoading(false);
      }
    } else {
      clearErrors(QuickResFields.AccountNumber);
      setValue(QuickResFields.AccountNumber, EMPTY_VALUE);
    }
  }, [bookingEditorId, clearErrors, isValidAccountNumber, setError, setValue, t, updateAndRefresh]);

  const handleApplyingAccountNumber = useCallback(async () => {
    try {
      setLoading(true);
      const businessAccount = transformRateSourceInfoFromApi(await retrieveAccount(accountNumber));

      if (businessAccount.urn) {
        const rateSourceRequestBody: RateSource = {
          type: 'NEGOTIATED',
          account: businessAccount.urn ?? undefined,
        };
        const { errors } = await updateAndRefresh(() => modifyRateSource(bookingEditorId, rateSourceRequestBody));
        if (!errors) {
          clearErrors(QuickResFields.AccountNumber);
          setValue(QuickResFields.AccountName, businessAccount.name);
          setValue(QuickResFields.AccountType, businessAccount.type);
          return;
        }
      }

      setError(QuickResFields.AccountNumber, { message: t('rateAndBilling.invalidAccountNumber') });
    } catch {
      setError(QuickResFields.AccountNumber, { message: t('rateAndBilling.invalidAccountNumber') });
    } finally {
      setLoading(false);
    }
  }, [accountNumber, bookingEditorId, clearErrors, setError, setValue, t, updateAndRefresh]);

  return (
    <SectionContainer data-testid='rateSource'>
      <h3 data-testid='header'>{t('rateAndBilling.rateSource')}</h3>
      <AccountNumberSearch>
        <Grid item xs={4} sm={4}>
          <FormTextField
            name={QuickResFields.AccountNumber}
            label={t('rateAndBilling.accountNumber')}
            data-testid={QuickResFields.AccountNumber}
            fullWidth
            onChange={(e) => setValue(QuickResFields.AccountNumber, e.target.value)}
            InputProps={{
              startAdornment: isValidAccountNumber && (
                <InputIconButton position={'start'} icon={<CheckCircleIcon color='success' />} />
              ),
              endAdornment: loading ? (
                <FieldLoadingIndicator />
              ) : (
                <InputIconButton
                  icon={<FieldClearIcon />}
                  label={t('common.clear')}
                  onClick={handleClearingAccountNumber}
                  disabled={accountNumber.length === 0}
                />
              ),
            }}
          />
        </Grid>
        <Grid item xs={2} sm={2}>
          <EhiButton
            data-testid='applyButton'
            variant='contained'
            style={{ marginTop: 0, marginBottom: 0 }}
            disabled={!accountNumber || accountNumber?.length < 3}
            onClick={handleApplyingAccountNumber}>
            {t('common.apply')}
          </EhiButton>
        </Grid>
      </AccountNumberSearch>
      {showRateSourceInfo && (
        <SectionInfo>
          <RateSourceInfoColumn label={t('rateAndBilling.account')} data={accountName} />
          <RateSourceInfoColumn label={t('rateAndBilling.accountType')} data={accountType} />
          <RateSourceInfoColumn label={t('rateAndBilling.rateProduct')} data={rateProduct} />
        </SectionInfo>
      )}
    </SectionContainer>
  );
};
export default QuickResRateSource;

const RateSourceInfoColumn = ({ label, data }: { label: string; data: string }) => {
  return (
    <Grid item xs={3} sm={3}>
      <Caption2 sx={{ color: '#0000008A' }}>{label}</Caption2>
      <Body2 bold marginTop={ehiTheme.spacing(0.5)}>
        {data}
      </Body2>
    </Grid>
  );
};
