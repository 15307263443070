import { FC } from 'react';
import { SpinnerContainer, StyledSpinner } from './spinner.styles';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';

export type ProgressOverlayProps = {
  inProgress: boolean;
  fullPageSpinner?: boolean;
};

export const ProgressOverlay: FC<ProgressOverlayProps> = ({ inProgress, fullPageSpinner }: ProgressOverlayProps) => {
  return inProgress ? (
    <Dialog
      a11yKey='in-progress'
      disableEscapeKeyDown
      open={inProgress}
      data-testid={'in-progress'}
      aria-labelledby='progress-dialog-title'
      aria-describedby='progress-dialog-description'
      maxWidth={'md'}
      fullWidth
      PaperProps={{
        style: {
          width: '100%',
          height: '100%',
          backgroundColor: fullPageSpinner ? 'default' : 'transparent',
          boxShadow: 'none',
        },
      }}>
      <SpinnerContainer>
        <StyledSpinner />
      </SpinnerContainer>
    </Dialog>
  ) : null;
};
