import {
  CarClassText,
  DistanceAllotmentText,
  StyledLuggageIcon,
  StyledPassengerIcon,
  VehicleDescriptionText,
  VehicleSpecText,
  VehicleSpecTextContainer,
  VehicleTotalRateText,
} from 'components/flexFlow/vehicle/VehiclePage.styles';
import { FC, useMemo, useState } from 'react';
import AutoTransmissionDarkIcon from 'images/autoTransmissionDarkIcon.svg';
import AutoTransmissionLightIcon from 'images/autoTransmissionLightIcon.svg';
import { Grid } from '@mui/material';
import { useLocale, useTranslations } from 'components/shared/i18n';
import { GridVerticalContainer } from 'components/shared/ui/styles/Grid.styles';
import { ehiTheme } from '@ehi/ui';
import { ListItemCard } from 'components/shared/ui/card/Card';
import { ExpandLessButton, ExpandMoreButton, RadioButton } from 'components/shared/ui/styles/Global.styles';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectVehicleClassSelection } from 'redux/selectors/bookingEditor';
import { deleteVehicleClassSelection, updateVehicleClassSelection } from 'services/booking/bookingService';
import { EHI_DOMAINS, generateReferenceUrn, parseUrn } from 'utils/urnUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { AdditionalVehicleInfo } from 'components/flexFlow/vehicle/additionalVehicleInfo/AdditionalVehicleInfo';
import { VehicleCardProps } from 'components/flexFlow/vehicle/VehicleTypes';
import { useAlert } from 'components/shared/alert/AlertContext';
import { safelyCatchError } from 'utils/errorUtils';
import { formatCurrency } from 'utils/currencyUtils';
import { getAutoTransmissionDescription, getDistanceAllotmentText } from 'utils/vehicleUtils';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';

const IS_SELECTED_CLASS_NAME = 'isSelected';

export const VehicleCard: FC<VehicleCardProps> = ({ vehicle }): JSX.Element => {
  const { t } = useTranslations();
  const { locale } = useLocale();
  const appConfig = getAppConfigCache();
  const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? '';
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { showAlert } = useAlert();

  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const vehicleClassSelection = useAppSelector(selectVehicleClassSelection);
  const isSelected = useMemo(() => {
    return parseUrn(vehicleClassSelection?.preferred) === vehicle.vehicleClass;
  }, [vehicleClassSelection?.preferred, vehicle]);

  const [isVehicleUpdating, setIsVehicleUpdating] = useState(false);
  const [additionalInfoExpanded, setAdditionalInfoExpanded] = useState(vehicle.isExpanded);

  const updateVehicleClass = async () => {
    if (!vehicle.vehicleClass) {
      return;
    }
    setIsVehicleUpdating(true);
    try {
      const callToUpdateVehicle = isSelected
        ? await deleteVehicleClassSelection(bookingEditorId)
        : await updateVehicleClassSelection(bookingEditorId, {
            type: 'PAYLATER',
            vehicleClass: generateReferenceUrn(
              EHI_DOMAINS.vehicle.name,
              EHI_DOMAINS.vehicle.vehicleClass,
              vehicle.vehicleClass,
              defaultEhiDatabase
            ),
          });
      const { errors } = await updateAndRefresh(async () => callToUpdateVehicle);
      if (errors) {
        await showAlert({ responseMessages: errors });
      }
    } catch (error) {
      await showAlert({
        variant: 'error',
        description: safelyCatchError(error).errors?.[0].localizedMessage || '',
      });
    } finally {
      setIsVehicleUpdating(false);
    }
  };

  return (
    <ListItemCard className={isSelected ? IS_SELECTED_CLASS_NAME : ''} data-testid={'vehicleCard'}>
      <GridVerticalContainer>
        <Grid item>
          <Grid container paddingBottom={ehiTheme.spacing(1)}>
            <Grid item xs={1} sm={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <RadioButton checked={isSelected} onClick={updateVehicleClass} name={'vehicleRadioButton'} />
            </Grid>
            <Grid item xs={7} sm={7} paddingTop={ehiTheme.spacing(1.5)}>
              <Grid>
                <Grid item>
                  <CarClassText
                    selected={isSelected}>{`${vehicle.description} (${vehicle.vehicleClass})`}</CarClassText>
                </Grid>
                <Grid item>
                  <VehicleDescriptionText selected={isSelected}>
                    {vehicle.similarModels + ' ' + t('vehicle.similarModels')}
                  </VehicleDescriptionText>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <VehicleSpecTextContainer data-testid={'autoTransContainer'}>
                        {isSelected ? (
                          <img src={AutoTransmissionLightIcon} alt={vehicle.transmissionType} />
                        ) : (
                          <img src={AutoTransmissionDarkIcon} alt={vehicle.transmissionType} />
                        )}
                        <VehicleSpecText selected={isSelected}>
                          {getAutoTransmissionDescription(t, vehicle.transmissionType)}
                        </VehicleSpecText>
                      </VehicleSpecTextContainer>
                    </Grid>
                    <Grid item>
                      <VehicleSpecTextContainer data-testid={'passengerContainer'}>
                        <StyledPassengerIcon selected={isSelected} />
                        <VehicleSpecText selected={isSelected}>{vehicle.passengers}</VehicleSpecText>
                      </VehicleSpecTextContainer>
                    </Grid>
                    <Grid item>
                      <VehicleSpecTextContainer data-testid={'luggageContainer'}>
                        <StyledLuggageIcon selected={isSelected} />
                        <VehicleSpecText selected={isSelected}>{vehicle.luggageSpace}</VehicleSpecText>
                      </VehicleSpecTextContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} sm={4} paddingTop={ehiTheme.spacing(1.5)}>
              <Grid container height={'100%'}>
                <Grid item xs={9} sm={9}>
                  <GridVerticalContainer>
                    <Grid item>
                      <VehicleTotalRateText>
                        {vehicle.vehicleRates?.paylater?.total?.amount
                          ? `${formatCurrency(
                              vehicle.vehicleRates?.paylater?.total?.amount,
                              vehicle.vehicleRates?.paylater?.total?.currencyCode,
                              locale
                            )} ${t('rates.estTotal')}`
                          : `-- ${t('rates.estTotal')}`}
                      </VehicleTotalRateText>
                    </Grid>
                    {vehicle.vehicleRates && (
                      <Grid item data-testid={'distanceAllotmentContainer'}>
                        <DistanceAllotmentText data-testid={'distanceAllotmentText'} selected={isSelected}>
                          {t(getDistanceAllotmentText(vehicle))}
                        </DistanceAllotmentText>
                      </Grid>
                    )}
                  </GridVerticalContainer>
                </Grid>
                <Grid item xs={3} sm={3} textAlign={'center'} alignSelf={'center'}>
                  {additionalInfoExpanded ? (
                    <ExpandLessButton
                      data-testid={'expandLessButton'}
                      className={isSelected ? IS_SELECTED_CLASS_NAME : ''}
                      onClick={() => {
                        vehicle.isExpanded = false;
                        setAdditionalInfoExpanded(false);
                      }}
                    />
                  ) : (
                    <ExpandMoreButton
                      data-testid={'expandMoreButton'}
                      className={isSelected ? IS_SELECTED_CLASS_NAME : ''}
                      onClick={() => {
                        vehicle.isExpanded = true;
                        setAdditionalInfoExpanded(true);
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {additionalInfoExpanded && (
          <Grid item bgcolor={ehiTheme.palette.background.default} height={'100%'} width={'100%'}>
            <AdditionalVehicleInfo vehicle={vehicle} isSelected={isSelected} />
          </Grid>
        )}
      </GridVerticalContainer>
      <ProgressOverlay inProgress={isVehicleUpdating} />
    </ListItemCard>
  );
};
