import { useLocation, useNavigate } from 'react-router-dom';
import { FC } from 'react';

import { deepLinkTo } from 'components/deeplink/deepLinkFactory';
import { setNavigationCache } from 'app/router/navigationManager';
import { useEffectOnlyOnce } from 'hooks/useEffectOnlyonce';

export const DeepLink: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffectOnlyOnce(() => {
    const navigation = deepLinkTo(new URLSearchParams(location.search));
    if (navigation) {
      setNavigationCache(navigation);
    }
    let pathName = navigation?.pathName;
    if (!pathName) {
      pathName = '/';
    }
    navigate(pathName, { state: navigation });
  });
  return <></>;
};
