import { createSearchParams } from 'react-router-dom';

/**
 * Generates a URL search string.
 * If a param value is null or undefined, the param will not be added to the search string.
 *
 * @param {params} params object representing the key/value pairs to generate the URL param string with
 * @returns {string} search string (not including ?)
 */
export const generateSearchParams = (params?: Record<string, string | undefined | null>): string => {
  if (!params) {
    return '';
  }

  const truthyParams = Object.entries(params).reduce((all, [key, value]) => {
    if (value) {
      all[key] = value;
    }
    return all;
  }, {} as Record<string, string>);

  return createSearchParams(truthyParams).toString();
};
