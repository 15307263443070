import { FC } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { QuickResFields } from 'components/quickRes/QuickResTypes';
import { GridContainer } from 'components/shared/ui/styles/Grid.styles';
import { StyledGridItem } from 'components/quickRes/QuickRes.styles';
import { DateTime } from 'luxon';
import { TimePickerField } from 'components/shared/forms/TimePickerField';
import { DatePickerField } from 'components/shared/forms/DatePickerField';

interface Props {
  handleReturnDateChange: (date: DateTime | '') => void;
  handleReturnTimeChange: (date: DateTime | '') => void;
  pickupDate: DateTime | '';
  timezone: string;
}

const RentalReturn: FC<Props> = ({ handleReturnDateChange, handleReturnTimeChange, pickupDate, timezone }) => {
  const { t } = useTranslations();

  return (
    <>
      <h3>{t('whenWhere.rentalReturn')}</h3>
      <GridContainer data-testid={'rentalReturnSection'}>
        <StyledGridItem xs={12} sm={12} md={6} pl={0}>
          <DatePickerField
            name={QuickResFields.ReturnDate}
            label={t('whenWhere.date')}
            submitOnChange={handleReturnDateChange}
            minDate={pickupDate || undefined}
            disablePast={pickupDate ? DateTime.now() < pickupDate : false}
          />
        </StyledGridItem>
        <StyledGridItem xs={12} sm={12} md={6} pl={0}>
          <TimePickerField
            name={QuickResFields.ReturnTime}
            label={t('whenWhere.time')}
            submitOnChange={handleReturnTimeChange}
            timezone={timezone}
          />
        </StyledGridItem>
      </GridContainer>
    </>
  );
};

export default RentalReturn;
