import { FC, useMemo, useState } from 'react';
import { useLocale, useTranslations } from 'components/shared/i18n';
import { VehicleAutoCompleteField } from 'components/shared/forms/VehicleAutoCompleteField';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { QuickResFields, QuickResVehicleProps } from 'components/quickRes/QuickResTypes';
import { useFormContext } from 'react-hook-form';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { deleteVehicleClassSelection, updateVehicleClassSelection } from 'services/booking/bookingService';
import { EHI_DOMAINS, generateReferenceUrn } from 'utils/urnUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { useVehicleRates } from 'services/booking/useVehicleRates';
import { transformVehicleListFromApiData } from 'components/shared/uiModels/vehicle/vehicleTransformer';
import { EMPTY_VALUE } from 'utils/constants';
import { getDistanceAllotmentString, getRateString } from 'components/quickRes/quickResUtils';
import { Grid } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Body2, ehiTheme } from '@ehi/ui';
import { SectionContainer, SectionInfo } from 'components/quickRes/QuickRes.styles';
import { GridItem } from 'components/shared/ui/styles/Grid.styles';

const QuickResVehicle: FC<QuickResVehicleProps> = ({ vehicleContent = [] }) => {
  const { t } = useTranslations();
  const { locale } = useLocale();
  const appConfig = getAppConfigCache();
  const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? '';
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const [isVehicleUpdating, setIsVehicleUpdating] = useState<boolean>(false);
  const { setValue, setError, clearErrors, watch } = useFormContext();
  const [carClass] = watch([QuickResFields.VehicleClass]);
  const { vehicleRates, isLoading: isVehicleRatesLoading } = useVehicleRates();
  const isLoading = isVehicleUpdating || isVehicleRatesLoading;

  const vehicles = useMemo(
    () => transformVehicleListFromApiData(vehicleContent, vehicleRates),
    [vehicleRates, vehicleContent]
  );

  const vehicleRateDetails =
    vehicles?.length && vehicleRates?.length ? vehicles.filter((item) => item.vehicleClass === carClass)[0] : undefined;

  const rateString = useMemo(() => {
    return vehicleRateDetails ? getRateString(vehicleRateDetails, t, locale) : '';
  }, [vehicleRateDetails, t, locale]);

  const distanceAllotmentString = useMemo(() => {
    return vehicleRateDetails ? getDistanceAllotmentString(vehicleRateDetails, t) : '';
  }, [vehicleRateDetails, t]);

  const hasVehicleInfo = !!rateString || !!distanceAllotmentString;

  const updateVehicleClass = async (vehicleToAdd: string | undefined) => {
    setIsVehicleUpdating(true);

    try {
      const callToUpdateVehicle = !vehicleToAdd
        ? await deleteVehicleClassSelection(bookingEditorId)
        : await updateVehicleClassSelection(bookingEditorId, {
            type: 'PAYLATER',
            vehicleClass: generateReferenceUrn(
              EHI_DOMAINS.vehicle.name,
              EHI_DOMAINS.vehicle.vehicleClass,
              vehicleToAdd,
              defaultEhiDatabase
            ),
          });
      const { errors } = await updateAndRefresh(async () => callToUpdateVehicle);
      if (errors) {
        setError(QuickResFields.VehicleClass, { message: errors?.[0]?.localizedMessage });
      } else {
        clearErrors(QuickResFields.VehicleClass);
        setValue(QuickResFields.VehicleClass, vehicleToAdd ? vehicleToAdd : EMPTY_VALUE);
        document.getElementsByName(QuickResFields.VehicleClass).item(0).blur();
      }
    } catch (error) {
      setError(QuickResFields.VehicleClass, { message: (error as EhiErrors)?.errors?.[0]?.localizedMessage });
    } finally {
      setIsVehicleUpdating(false);
    }
  };

  return (
    <SectionContainer>
      <h3 data-testid={'res-vehicle'}>{t('common.vehicle')}</h3>
      <GridItem sm={12} style={{ padding: ehiTheme.spacing(2, 0) }}>
        <VehicleAutoCompleteField
          vehicles={vehicles}
          isLoading={isLoading}
          selectedVehicleClass={carClass}
          setVehicle={(vehicle) => updateVehicleClass(vehicle)}
          resetVehicle={() => updateVehicleClass(undefined)}
        />
      </GridItem>
      {hasVehicleInfo && !isLoading && (
        <SectionInfo data-testid={'vehicleRateSection'} sx={{ justifyContent: 'flex-end' }}>
          {rateString && (
            <VehicleInfoColumn dataTestid={'vehicleRateInfo'} label={t('vehicle.currentRate')} data={rateString} />
          )}
          <VehicleInfoColumn
            dataTestid={'distanceAllotmentInfo'}
            label={t('vehicle.distanceAllotment')}
            data={distanceAllotmentString}
          />
        </SectionInfo>
      )}
    </SectionContainer>
  );
};
export default QuickResVehicle;

const VehicleInfoColumn = ({ dataTestid, label, data }: { dataTestid: string; label: string; data: string }) => {
  return (
    <Grid item xs={6} sm={6} data-testid={dataTestid}>
      <Caption2 sx={{ color: '#0000008A' }}>{label}</Caption2>
      <Body2 bold marginTop={ehiTheme.spacing(0.5)}>
        {data}
      </Body2>
    </Grid>
  );
};
