import { TFunction } from 'i18next';
import { PeriodicDistance, PeriodicVehicleRate } from 'services/booking/bookingTypes';
import { Vehicle, Vehicles } from 'components/shared/uiModels/vehicle/vehicleDataTypes';
import { VehicleContent, VehicleContentType } from 'services/vehicleContent/vehicleContentTypes';

const AUTOMATIC = 'A';
const AUTOMATIC_FOUR_WHEEL_DRIVE = 'B';
const MANUAL_ALL_WHEEL_DRIVE = 'C';
const AUTOMATIC_ALL_WHEEL_DRIVE = 'D';
const MANUAL = 'M';
const MANUAL_FOUR_WHEEL_DRIVE = 'N';

export const getAutoTransmissionDescription = (t: TFunction, autoTransKey?: string) => {
  if (autoTransKey === AUTOMATIC) {
    return t('vehicle.transmissionKeys.automatic');
  } else if (autoTransKey === AUTOMATIC_FOUR_WHEEL_DRIVE) {
    return t('vehicle.transmissionKeys.automaticFourWheelDrive');
  } else if (autoTransKey === MANUAL_ALL_WHEEL_DRIVE) {
    return t('vehicle.transmissionKeys.manualAllWheelDrive');
  } else if (autoTransKey === AUTOMATIC_ALL_WHEEL_DRIVE) {
    return t('vehicle.transmissionKeys.automaticAllWheelDrive');
  } else if (autoTransKey === MANUAL) {
    return t('vehicle.transmissionKeys.manual');
  } else if (autoTransKey === MANUAL_FOUR_WHEEL_DRIVE) {
    return t('vehicle.transmissionKeys.manualFourWheelDrive');
  } else {
    return;
  }
};

export const getTotalDistance = (includedDistance: PeriodicDistance) => {
  const { distancePerMonth, distancePerWeek, distancePerDay } = includedDistance;
  return (distancePerDay ?? 0) + (distancePerWeek ?? 0) + (distancePerMonth ?? 0);
};

export const getDistanceAllotmentText = (vehicle: Vehicle) => {
  const rate = vehicle?.vehicleRates?.paylater?.vehicleRate;
  const vehiclePeriodRate: PeriodicVehicleRate | undefined = rate && (rate as PeriodicVehicleRate);
  const includedDistanceRates: PeriodicDistance | undefined = vehiclePeriodRate?.includedDistance;

  if (
    (includedDistanceRates && getTotalDistance(includedDistanceRates) > 0) ||
    (rate?.totalIncludedDistance && rate?.totalIncludedDistance > 0)
  ) {
    return 'vehicle.limitedMileage';
  } else {
    return 'vehicle.unlimitedMileage';
  }
};

/**
 * Returns car class description either from GCS pr Vehicle Rates.
 * When no vehicles, return carClass
 * EX: ECAR -> ECONOMY
 * @param vehicleClass
 * @param vehicles
 */

export const getCarClassDescription = (
  vehicleClass: string,
  vehicles: Vehicles | VehicleContent | undefined
): string => {
  if (vehicleClass.length === 0) {
    return vehicleClass;
  }

  let vehicleClassDescription;
  if (vehicles) {
    for (let i = 0; i < vehicles.length; i++) {
      if ((vehicles[i] as Vehicle)?.vehicleClass === vehicleClass) {
        vehicleClassDescription = (vehicles[i] as Vehicle).description;
        break;
      } else if ((vehicles[i] as VehicleContentType).carClass === vehicleClass) {
        vehicleClassDescription = (vehicles[i] as VehicleContentType).description;
        break;
      }
    }
  }

  return vehicleClassDescription ? vehicleClassDescription : vehicleClass;
};

/**
 * Returns car class and description either from GCS pr Vehicle Rates.
 * When no vehicles, return carClass
 * EX: ECAR -> ECONOMY (ECAR)
 * @param vehicleClass
 * @param vehicles
 */
export const getCarClassAndDescription = (
  vehicleClass: string,
  vehicles: Vehicles | VehicleContent | undefined
): string => {
  const vehicleClassDescription = getCarClassDescription(vehicleClass, vehicles);
  return vehicleClassDescription ? `${vehicleClassDescription} (${vehicleClass})` : vehicleClass;
};
