import { useCallback } from 'react';
import {
  ACCEPT,
  ACCEPT_APPLICATION_JSON,
  CONTENT_TYPE,
  EHI_ACCEPT_PROTECTED_DATA,
  PROTECTED_DATA_VALUE,
} from 'services/headerConstants';
import { LoyaltyProgram } from 'utils/constants';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { generateReferenceUrn } from 'utils/urnUtils';
import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import {
  LoyaltyProgramIdNonLoyaltyExcludeOneClub,
  LoyaltyProgramType,
  SearchByDriverLicenseRequestObject,
  SearchByMembershipNumberRequestObject,
  SearchByNameDateOfBirthRequestObject,
  SearchByNamePhoneRequestObject,
  SearchResult,
} from 'services/renter/driverProfile/driverProfileTypes';

const RENTER_DRIVER_ENDPOINT = '/renter/driverProfile';

const LOCATION = 'location';
const COUNTRY = 'country';
const COUNTRY_SUB_DIVISION = 'countrySubdivision';

export interface DriverProfileService {
  searchRenterWithLoyalty: (loyaltyNumber: string, membershipPrograms: LoyaltyProgram[]) => Promise<SearchResult[]>;
  searchRenterWithPhoneNumber: (
    firstName: string,
    lastName: string,
    phoneNumber: string,
    membershipPrograms: LoyaltyProgram[],
    dob?: string
  ) => Promise<SearchResult[]>;
  searchRenterWithDateOfBirth: (
    firstName: string,
    lastName: string,
    dob: string,
    membershipPrograms: LoyaltyProgram[]
  ) => Promise<SearchResult[]>;
  searchRentersWithLicense: (
    licenseNumber: string,
    country: string,
    state: string | undefined,
    membershipPrograms: LoyaltyProgram[]
  ) => Promise<SearchResult[]>;
}

export type RenterSearchRequest =
  | SearchByNameDateOfBirthRequestObject
  | SearchByDriverLicenseRequestObject
  | SearchByMembershipNumberRequestObject
  | SearchByNamePhoneRequestObject;

enum SearchRequestTypes {
  Loyalty_Search = 'application/prs.ehi-com.driver-profile-by-membership-number+json',
  Name_Phone_Search = 'application/prs.ehi-com.driver-profile-by-name-phone+json',
  Name_DOB_Search = 'application/prs.ehi-com.driver-profile-by-name-date-of-birth+json',
  License_Search = 'application/prs.ehi-com.driver-profile-by-license-information+json',
}

export const convertToLoyaltyProgramIdNonLoyalty = (
  loyaltyProgram?: LoyaltyProgram
): LoyaltyProgramIdNonLoyaltyExcludeOneClub => {
  switch (loyaltyProgram) {
    case LoyaltyProgram.ENTERPRISE_PLUS:
      return LoyaltyProgramIdNonLoyaltyExcludeOneClub.ENTERPRISE_PLUS;
    case LoyaltyProgram.EMERALD_CLUB:
      return LoyaltyProgramIdNonLoyaltyExcludeOneClub.EMERALD_CLUB;
    case LoyaltyProgram.ALAMO_INSIDERS:
      return LoyaltyProgramIdNonLoyaltyExcludeOneClub.ALAMO_INSIDERS;
    default:
      return LoyaltyProgramIdNonLoyaltyExcludeOneClub.NON_LOYALTY;
  }
};

export const convertToLoyaltyProgramType = (loyaltyProgramExcludeNonLoyalty: LoyaltyProgram): LoyaltyProgramType => {
  switch (loyaltyProgramExcludeNonLoyalty) {
    case LoyaltyProgram.ENTERPRISE_PLUS:
      return LoyaltyProgramType.ENTERPRISE_PLUS;
    case LoyaltyProgram.EMERALD_CLUB:
      return LoyaltyProgramType.EMERALD_CLUB;
    case LoyaltyProgram.ALAMO_INSIDERS:
      return LoyaltyProgramType.ALAMO_INSIDERS;
    default:
      return LoyaltyProgramType.ENTERPRISE_PLUS;
  }
};

export const useRenterSearch = (): DriverProfileService => {
  const axiosInstance = getDefaultAxiosInstance();
  const appConfig = getAppConfigCache();
  const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? '';

  const searchForRenter = useCallback(
    async (request: RenterSearchRequest, searchType: SearchRequestTypes) => {
      const { data } = await axiosInstance.post(`${RENTER_DRIVER_ENDPOINT}/search`, request, {
        genericUrl: `${RENTER_DRIVER_ENDPOINT}/search`,
        headers: {
          [ACCEPT]: ACCEPT_APPLICATION_JSON,
          [CONTENT_TYPE]: searchType,
          [EHI_ACCEPT_PROTECTED_DATA]: PROTECTED_DATA_VALUE,
        },
      });
      return data.results;
    },
    [axiosInstance]
  );

  const searchRenterWithPhoneNumber = useCallback(
    async (
      firstName: string,
      lastName: string,
      phoneNumber: string,
      membershipPrograms: LoyaltyProgram[],
      dob?: string
    ): Promise<SearchResult[]> => {
      const searchRequest: SearchByNamePhoneRequestObject = {
        givenName: `${firstName}*`,
        surname: `${lastName}*`,
        dateOfBirth: dob,
        phoneNumbers: [phoneNumber],
        //
        loyaltyPrograms: membershipPrograms.map(convertToLoyaltyProgramIdNonLoyalty),
      };

      return searchForRenter(searchRequest, SearchRequestTypes.Name_Phone_Search);
    },
    [searchForRenter]
  );

  const searchRenterWithDateOfBirth = useCallback(
    async (
      firstName: string,
      lastName: string,
      dob: string,
      membershipPrograms: LoyaltyProgram[]
    ): Promise<SearchResult[]> => {
      const searchRequest: SearchByNameDateOfBirthRequestObject = {
        givenName: `${firstName}*`,
        surname: `${lastName}*`,
        dateOfBirth: dob,
        loyaltyPrograms: membershipPrograms.map(convertToLoyaltyProgramIdNonLoyalty),
      };
      return searchForRenter(searchRequest, SearchRequestTypes.Name_DOB_Search);
    },
    [searchForRenter]
  );

  const searchRentersWithLicense = useCallback(
    async (
      licenseNumber: string,
      country: string,
      state: string | undefined,
      membershipPrograms: LoyaltyProgram[]
    ): Promise<SearchResult[]> => {
      return searchForRenter(
        {
          number: licenseNumber,
          country: generateReferenceUrn(LOCATION, COUNTRY, country, defaultEhiDatabase),
          countrySubdivision: state
            ? generateReferenceUrn(LOCATION, COUNTRY_SUB_DIVISION, state, defaultEhiDatabase)
            : undefined,
          loyaltyPrograms: membershipPrograms.map(convertToLoyaltyProgramIdNonLoyalty),
        },
        SearchRequestTypes.License_Search
      );
    },
    [searchForRenter, defaultEhiDatabase]
  );

  const searchRenterWithLoyalty = useCallback(
    async (loyaltyNumber: string, membershipPrograms: LoyaltyProgram[]): Promise<SearchResult[]> => {
      const searches = await Promise.all(
        membershipPrograms.map((loyaltyProgram) =>
          searchForRenter(
            {
              membershipNumber: loyaltyNumber,
              loyaltyProgram: convertToLoyaltyProgramType(loyaltyProgram),
            },
            SearchRequestTypes.Loyalty_Search
          )
        )
      );
      return searches.reduce((previousValue, currentValue) => {
        return [...previousValue, ...currentValue];
      }, []);
    },
    [searchForRenter]
  );

  return {
    searchRenterWithDateOfBirth,
    searchRenterWithPhoneNumber,
    searchRentersWithLicense,
    searchRenterWithLoyalty,
  };
};
