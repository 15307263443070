import { useLocale } from 'components/shared/i18n';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { MixedDateTime, parseDateTime } from 'utils/dateUtils';

export const useDateTimeFormater = () => {
  const { locale } = useLocale();

  /**
   * Helper function that parses the date and sets the locale & timezone.
   * Used for converting arbitrary date-time inputs in other functions here.
   */
  const getLocalizedDateTime = useCallback(
    (timezone: string, dateTime?: MixedDateTime): DateTime | undefined => {
      return parseDateTime(dateTime)?.setLocale(locale).setZone(timezone);
    },
    [locale]
  );

  return {
    getLocalizedDateTime,
  };
};
