import * as Yup from 'yup';
import { Vehicles } from 'components/shared/uiModels/vehicle/vehicleDataTypes';
import { ALL_VALUE } from 'components/shared/forms/SelectField';
import { parseInt } from 'lodash';
import { VehicleSearchValues } from 'components/flexFlow/vehicle/VehicleTypes';

export const MINIMUM_PASSENGER_NUM = 2;

export const validationSchema = Yup.object().shape({
  vehicleClass: Yup.string(),
  vehicleType: Yup.string(),
  fuelType: Yup.string(),
  passengerNumber: Yup.string(),
});

export const vehicleTypeOptions = [
  { label: 'vehicle.cars', value: 'cars' },
  { label: 'vehicle.suvs', value: 'suvs' },
  { label: 'vehicle.trucks', value: 'trucks' },
  { label: 'vehicle.vans', value: 'vans' },
];

export const fuelTypeOptions = [
  { label: 'vehicle.gasoline', value: '169' },
  { label: 'vehicle.diesel', value: '171' },
  { label: 'vehicle.hybrid', value: '168' },
  { label: 'vehicle.electric', value: '170' },
];

export const passengerNumberOptions = [
  { label: '2+', value: '2' },
  { label: '4+', value: '4' },
  { label: '5+', value: '5' },
  { label: '7+', value: '7' },
  { label: '8+', value: '8' },
  { label: '12+', value: '12' },
  { label: '15+', value: '15' },
];

export const vehicleSearchInitialValues: VehicleSearchValues = {
  vehicleClass: '',
  vehicleType: ALL_VALUE,
  fuelType: ALL_VALUE,
  passengerNumber: MINIMUM_PASSENGER_NUM.toString(),
};

const filterByVehicleType = (vehicles: Vehicles, vehicleType: string) => {
  return vehicles.filter(
    (vehicle) =>
      (vehicle.groupCategory && vehicle.groupCategory.toUpperCase() === vehicleType.toUpperCase()) ||
      vehicleType === ALL_VALUE
  );
};

const filterByFuelType = (vehicles: Vehicles, fuelType: string) => {
  return vehicles.filter(
    (vehicle) => vehicle.features?.find((feature) => feature === fuelType) || fuelType === ALL_VALUE
  );
};

const filterByPassengerNumber = (vehicles: Vehicles, passengerNumber: string) => {
  return vehicles.filter(
    (vehicle) =>
      (vehicle.passengers && parseInt(vehicle.passengers) >= parseInt(passengerNumber)) ||
      parseInt(passengerNumber) === MINIMUM_PASSENGER_NUM
  );
};

export const filterVehicleList = (
  vehicles: Vehicles,
  carClass: string,
  vehicleType: string,
  fuelType: string,
  passengerNumber: string
) => {
  if (carClass) {
    return vehicles.filter((vehicle) => vehicle.vehicleClass.toUpperCase() === carClass.toUpperCase());
  } else if (vehicleType || passengerNumber || fuelType) {
    let filteredArray: Vehicles = vehicles;
    filteredArray = vehicleType ? filterByVehicleType(filteredArray, vehicleType) : filteredArray;
    filteredArray = fuelType ? filterByFuelType(filteredArray, fuelType) : filteredArray;
    filteredArray = passengerNumber ? filterByPassengerNumber(filteredArray, passengerNumber) : filteredArray;
    return filteredArray;
  } else {
    return vehicles;
  }
};
