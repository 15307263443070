import { Path, To } from 'react-router-dom';
import { RouterPaths } from 'app/router/RouterPaths';
import { FlexiFunctionComponent, RouterMatch } from './FlexFlowTypes';

export type FlexRoute = {
  component?: FlexiFunctionComponent;
  path: RouterPaths;
  titleKey: string;
  // only needed if flex navigation needs to override 'path' (such as android nav)
  to?: Partial<Path>;
  position: number;
  // previous and next are generally not needed, only for outliers that skip around or go outside of flex flow list
  previous?: To;
  next?: To;
  // only set this to true if flex navigation needs to keep the previous path in the history stack so that back navigation can work
  includeBackNavigation?: boolean;
};

// Actual user facing page
export type FlexPage = FlexRoute & {
  component: FlexiFunctionComponent;
};

export const isFlexPage = (route: FlexRoute): route is FlexPage => {
  return route.component !== undefined && route.path !== undefined;
};

const hasFlexDetails = (handle: any): handle is { flexDetails: FlexRoute } => {
  return handle?.flexDetails !== undefined;
};

export const getFlexDetailsFromMatches = (matches: RouterMatch[]): FlexRoute | undefined =>
  matches.find((match) => hasFlexDetails(match?.handle))?.handle?.flexDetails;
