import { Box, Divider, IconButton, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ResSummaryDrawerContext } from 'context/resSummary/ResSummaryDrawerContext';
import { useContext } from 'react';
import { ResSummaryDrawerHeader } from 'components/resSummary/ReservationSummaryDrawer.styles';
import { InlineSummaryContainer } from 'components/resSummary/InlineSummaryContainer';
import { Body1, ehiTheme } from '@ehi/ui';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { DriversSummary } from 'components/resSummary/driverSummary/DriversSummary';
import { WhenAndWhereSummary } from 'components/resSummary/whenAndWhere/WhenAndWhereSummary';
import { RateSourceAndBillToSummary } from 'components/resSummary/rateSourceAndBillTo/RateSourceAndBillToSummary';
import { VehicleSummary } from 'components/resSummary/vehicle/VehicleSummary';
import { AddOnsSummary } from 'components/resSummary/AddOnsSummary/AddOnsSummary';
import { PaymentSummary } from 'components/resSummary/payment/PaymentSummary';
import { useTranslations } from 'components/shared/i18n';
import { useAppSelector } from 'redux/hooks';
import { selectReservationData } from 'redux/selectors/bookingEditor';
import { parseUrn } from 'utils/urnUtils';

export const InlineReservationSummary = (): JSX.Element => {
  const { t } = useTranslations();
  const { setOpenDrawer } = useContext(ResSummaryDrawerContext);
  const reservation = useAppSelector(selectReservationData);
  const resStatus = reservation ? parseUrn(reservation.status) : '';
  const resNumber = reservation ? parseUrn(reservation?.reservation) : '';
  const isMobileOrTablet = useMediaQuery('(max-width:800px)');

  return (
    <InlineSummaryContainer data-testid='inlineSummaryContainer'>
      <ResSummaryDrawerHeader data-testid='resSummaryDrawerHeader'>
        <span>{t('reservationSummary.title')}</span>
        <IconButton aria-label='close' onClick={() => setOpenDrawer(false)} data-testid='closeButton'>
          <CloseIcon />
        </IconButton>
      </ResSummaryDrawerHeader>
      <Divider />
      <Box alignItems={'center'} margin={ehiTheme.spacing(1)} data-testid='summaryContentBox'>
        {isMobileOrTablet && (
          <div data-testid='mobileReservationDetails'>
            <Body1 display={'inline'} marginRight={'10px'} bold data-testid='resStatus'>
              {resStatus}
            </Body1>
            <Caption2 display={'inline'} data-testid='resNumber'>{`${t(
              'common.resNumberSuffix'
            )} ${resNumber}`}</Caption2>
          </div>
        )}
        <WhenAndWhereSummary />
        <RateSourceAndBillToSummary />
        <VehicleSummary />
        <AddOnsSummary />
        <DriversSummary />
        <PaymentSummary />
      </Box>
    </InlineSummaryContainer>
  );
};
