import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { ehiTheme } from '@ehi/ui';

export const Container = styled('div')`
  padding: ${ehiTheme.spacing(4)};
`;

export const LabelContainer = styled('div')`
  position: relative;
  margin-bottom: ${ehiTheme.spacing(2)};
  margin-top: ${ehiTheme.spacing(4)};
`;

export const StyledInputLabel = styled(InputLabel)`
  position: absolute;
  top: ${ehiTheme.spacing(-0.5)};
  left: ${ehiTheme.spacing(2)};
  background: white;
  padding: ${ehiTheme.spacing(0, 0.5)};
  font-size: ${ehiTheme.spacing(2)};
  color: #6b6b6b;
  pointer-events: none;
  transition: 0.2s ease all;
  z-index: 1;
`;

export const StyledSelectWrapper = styled('div')`
  position: relative;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  padding-top: ${ehiTheme.spacing(1.25)};
  height: ${ehiTheme.spacing(6)};
  & .MuiSelect-select {
    display: flex;
    align-items: center;
    font-size: ${ehiTheme.spacing(2.4)};
    padding-top: ${ehiTheme.spacing(2)};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  padding: ${ehiTheme.spacing(0.25, 1.25)};
`;

export const Placeholder = styled('div')`
  color: #6b6b6b;
  font-size: ${ehiTheme.spacing(2)};
  padding-bottom: ${ehiTheme.spacing(1)};
`;
