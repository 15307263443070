import { Box, Grid } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { EhiButton, H6 } from '@ehi/ui';
import { RateAndBillingSectionBox } from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow } from 'redux/selectors/bookingEditor';

export const BillToSection = () => {
  const { t } = useTranslations();
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);

  return (
    <RateAndBillingSectionBox data-testid='billToSection'>
      <Box display={'flex'} justifyContent={'space-between'}>
        <H6>{t('rateAndBilling.billTo')}</H6>
        {!isReadOnly && <EhiButton>{t('common.edit')}</EhiButton>}
      </Box>
      <Grid container>
        <Grid item xs={6} sm={6} data-testid='accountNumber'>
          <Caption2 data-testid='label' display={'inline'}>
            {t('rateAndBilling.accountNumber')}
          </Caption2>
        </Grid>
        <Grid item xs={6} sm={6} data-testid='billingNumber'>
          <Caption2 data-testid='label' display={'inline'}>
            {t('rateAndBilling.billingNumber')}
          </Caption2>
        </Grid>
      </Grid>
    </RateAndBillingSectionBox>
  );
};
