import { FC, SyntheticEvent, useCallback, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import {
  ButtonContainer,
  CountryDropDownHeader,
  CountryDropDownHeaderItem,
  StyledDistanceTextField,
} from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookup.styles';
import { IconButton, ListSubheader, Radio } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { useFormContext } from 'react-hook-form';
import { BranchLookupFields } from './BranchLookupTypes';
import { EhiButton, ehiTheme, H6 } from '@ehi/ui';
import { distances, DistanceValues } from 'utils/branchLookupUtils';
import { Close } from '@mui/icons-material';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';

export const DistanceDropDown: FC = () => {
  const { t } = useTranslations();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const distanceOptions = distances(t('location.miles'));
  const { watch, setValue, resetField } = useFormContext();
  const [distance] = watch([BranchLookupFields.Distance]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, []);

  const handleOpen = (event: SyntheticEvent<Element, Event>) => {
    setAnchorEl(event.currentTarget as HTMLElement);
    setOpen(true);
  };

  const handleReset = () => {
    resetField(BranchLookupFields.Distance);
  };

  return (
    <StyledDistanceTextField
      name={BranchLookupFields.Distance}
      data-testid={'distanceDropdown'}
      label={t('location.distance')}
      select
      sx={{
        '& fieldset': {
          borderRadius: 33,
        },
      }}
      SelectProps={{
        open: open,
        renderValue: (value) => distanceOptions?.find((option) => option?.value === value)?.name ?? '',
        value: distance,
        MenuProps: {
          anchorEl: anchorEl,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          sx: {
            '&& .Mui-selected': { backgroundColor: '#ffffff' },
            transition: 'none !important',
          },
          slotProps: {
            paper: {
              style: {
                width: anchorEl ? `${anchorEl.clientWidth}px` : 'auto',
                maxHeight: window.innerHeight / 1.75,
                ...(window.innerWidth <= 800 && {
                  minWidth: '15%',
                }),
              },
            },
            root: {
              sx: {
                '.MuiList-root': { padding: 0 },
              },
            },
          },
        },
        onClose: handleClose,
        onOpen: handleOpen,
      }}>
      <ListSubheader>
        <CountryDropDownHeader data-testid={'distance-drop-down-header'}>
          <CountryDropDownHeaderItem>
            <H6>{t('location.distance')}</H6>
            <IconButton onClick={handleClose} edge='end'>
              <Close color={'secondary'} data-testid='distance-close-dropdown' />
            </IconButton>
          </CountryDropDownHeaderItem>
        </CountryDropDownHeader>
      </ListSubheader>

      {distanceOptions.map((distanceOption, index) => (
        <MenuItem
          key={distanceOption.value}
          value={distanceOption.value}
          data-testid={'distance-' + index}
          sx={{ paddingLeft: `${ehiTheme.spacing(0.5)}` }}
          onClick={() => {
            setValue(BranchLookupFields.Distance, distanceOption);
          }}>
          <Radio checked={distanceOption.value === distance} />
          <ListItemText primary={distanceOption.name} />
        </MenuItem>
      ))}
      <ButtonContainer data-testid={'distance-drop-down-footer'}>
        <EhiDivider />
        <EhiButton onClick={handleReset} disabled={distance === DistanceValues.TWENTY_FIVE}>
          {t('common.clear')}
        </EhiButton>
      </ButtonContainer>
    </StyledDistanceTextField>
  );
};
