import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { TRANSACTION_CACHE_OPTIONS } from 'context/queryClient/cacheOptions';
import { getTransactionalProfile } from 'services/renter/transactionalProfile/transactionalProfileService';
import { TransactionalProfileResponseContent } from 'services/renter/transactionalProfile/transactionalProfileTypes';

const TRANSACTIONAL_PROFILE_QUERY_KEY = 'driverProfile';

export const useGetTransactionalProfileQuery = (
  transactionalProfileId: string,
  queryOptions: Partial<UseQueryOptions<TransactionalProfileResponseContent, EhiErrors>> = {}
): UseQueryResult<TransactionalProfileResponseContent, EhiErrors> => {
  const queryKey = [TRANSACTIONAL_PROFILE_QUERY_KEY, transactionalProfileId];
  const queryFn = () => getTransactionalProfile(transactionalProfileId);
  const options = {
    ...TRANSACTION_CACHE_OPTIONS,
    ...queryOptions,
  };

  return useQuery<TransactionalProfileResponseContent, EhiErrors>(queryKey, queryFn, options);
};
