import { Box, Card, styled } from '@mui/material';
import { H6 } from '@ehi/ui';

export const StyledH2 = styled(H6)`
  font-weight: bold;
  padding-bottom: 16px;
`;

export const WarningContainer = styled('div')`
  display: grid;
  height: 40vh;
  place-items: center;
  overflow: auto;
`;

export const ActionsContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding: 8px 0px;
`;

export const LocationPageCard = styled(Card)`
  margin: 0px;
  padding: 24px 32px 8px;
`;

export const StyledContainer = styled(Box)`
  display: grid;
  height: 100vh;
  place-items: center;
  width: 100vw;
`;

export const StyledSetLocation = styled(Box)`
  width: 40vw;
  @media (max-width: 1024px) {
    width: 80vw;
  }
  @media (max-width: 840px) {
    width: 80vw;
  }
`;
