import { FC } from 'react';
import { LoadableViewProps, LoadingState } from './LoadableViewTypes';

export const LoadableView: FC<LoadableViewProps> = ({ errorComponent, loadingComponent, children, state }) => {
  switch (state) {
    case LoadingState.LOADING:
      return loadingComponent;
    case LoadingState.SUCCESS:
      return <>{children}</>;
    case LoadingState.ERROR:
      return errorComponent;
  }
};
