export enum NavigationConstants {
  CALLING_APP_PARAM = 'callingApp',
  RETURN_URL = 'returnUrl',
  DEEP_LINK_ID_PARAM = 'deeplinkId',
  BACK_URL = 'backUrl',
  RESERVATION_NUMBER = 'res',
  PORT = 'port',
  ORIGIN = 'origin',
  LOCATION_ID = 'locationId',
  WORKFLOW_ID = 'workflowId',
}

export enum CallingApps {
  PLANNING = 'Planning',
  RFS = 'RFS',
  GRES = 'Greenlight Reservation',
}
