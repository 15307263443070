import { Divider, styled, Theme } from '@mui/material';

export type DividerProps = Light | Dark;

export type Light = {
  light?: boolean;
  dark?: never;
};

export type Dark = {
  light?: never;
  dark?: boolean;
};

export const EhiDivider = styled(Divider)<DividerProps>(({ light, dark, theme }: { theme: Theme } & DividerProps) => {
  const color =
    (light && theme.ehi?.palette.border.light) ||
    (dark && theme.ehi?.palette.border.dark) ||
    theme.ehi?.palette.border.main;

  return {
    backgroundColor: 'transparent',
    borderColor: color,
  };
});

export const DividerWithMargin = styled(EhiDivider)(({ theme }: { theme: Theme }) => ({
  margin: theme.spacing(1, 0),
}));

export const DividerWithFullWidth = styled(EhiDivider)(({ theme }: { theme: Theme }) => ({
  margin: theme.spacing(1, -2),
}));

export const RenterWarningDivider = styled(EhiDivider)(({ theme }: { theme: Theme }) => ({
  margin: theme.spacing(2, 0),
  borderColor: theme.ehi?.palette.border.light,
}));
