import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import { SwipeDirection, useSwipe } from './useSwipe';
import {
  DrawerButton,
  DrawerButtonDown,
  DrawerButtonUp,
  DrawerListView,
  StyledDrawerList,
  StyledScrollList,
  drawerButtonStyles,
} from './BranchLookup.styles';

type BottomDrawerProps = {
  branchHeader: ReactNode;
  children: ReactNode;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  disabled?: boolean;
  isMapView?: boolean;
};

export const BottomDrawer: FC<BottomDrawerProps> = ({
  branchHeader,
  children,
  open,
  onOpen,
  onClose,
  isMapView,
  disabled = false,
}: BottomDrawerProps) => {
  const swipeDirection = open ? SwipeDirection.Down : SwipeDirection.Up;
  const { handleSwipeStart, handleSwipeEnd } = useSwipe(swipeDirection, open ? onClose : onOpen, true);

  const drawerHeader = () => (
    <Box sx={drawerButtonStyles}>
      <DrawerButton />
      {branchHeader}
    </Box>
  );

  return disabled || !isMapView ? (
    <DrawerListView className={isMapView ? 'mapView' : ''}>{children}</DrawerListView>
  ) : (
    <>
      {!open && (
        <DrawerButtonUp
          variant='contained'
          data-testid={'drawerButtonUp'}
          onClick={onOpen}
          onTouchStart={handleSwipeStart}
          onTouchEnd={handleSwipeEnd}>
          {drawerHeader()}
        </DrawerButtonUp>
      )}
      <StyledDrawerList
        disableSwipeToOpen={true}
        variant='persistent'
        anchor='bottom'
        open={open}
        onClose={() => {
          // Empty function since this prop is mandatory
        }}
        onOpen={() => {
          // Empty function since this prop is mandatory
        }}>
        <DrawerButtonDown
          onClick={onClose}
          onTouchStart={handleSwipeStart}
          onTouchEnd={handleSwipeEnd}
          data-testid={'drawerButtonDown'}>
          {drawerHeader()}
        </DrawerButtonDown>
        <StyledScrollList data-testid={'swipeableDrawerList'}>{open && children}</StyledScrollList>
      </StyledDrawerList>
    </>
  );
};
