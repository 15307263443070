import { FC, MutableRefObject, useCallback, useState } from 'react';
import { CreateDriverValues } from 'components/flexFlow/driver/createDriver/CreateDriverTypes';
import { FormProvider, useForm } from 'react-hook-form';
import { DriverSection } from 'components/flexFlow/driver/createDriver/section/DriverSection';
import { LicenseSection } from 'components/flexFlow/driver/createDriver/section/LicenseSection';
import { ContactSection } from 'components/flexFlow/driver/createDriver/section/ContactSection';
import { MarginWrapper } from 'components/shared/ui/styles/Global.styles';
import { DividerWithFullWidth } from 'components/shared/ui/styles/Divider.styles';
import { ehiTheme } from '@ehi/ui';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import {
  createDriverInitialValues,
  createDriverValidationSchema,
  isValidFullProfile,
} from 'components/flexFlow/driver/createDriver/createDriverUtils';
import { createDriverProfile } from 'services/renter/driverProfile/driverProfileService';
import {
  transformToCreateDriverProfileRequest,
  transformToCreateTransactionalProfileRequest,
} from 'services/renter/driverTransformer';
import { safelyCatchError } from 'utils/errorUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { createTransactionalProfile } from 'services/renter/transactionalProfile/transactionalProfileService';
import { RenterTypes } from 'components/shared/uiModels/driver/driverDataTypes';
import { Renter } from 'services/booking/bookingTypes';
import { associateRenterToReservationEditor, updateAdditionalDrivers } from 'services/booking/bookingService';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useAppSelector } from 'redux/hooks';
import {
  selectAdditionalDrivers,
  selectBookingEditorId,
  selectDriverProfileRenter,
} from 'redux/selectors/bookingEditor';
import { useTranslations } from 'components/shared/i18n';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { DriverProfile } from 'services/renter/driverProfile/driverProfileTypes';
import { NoResultSearchValuesType } from 'components/flexFlow/driver/searchDriver/DriverSearch';
import { TransactionalProfileResponseContent } from 'services/renter/transactionalProfile/transactionalProfileTypes';

export type CreateDriverFormProps = {
  onClose: () => void;
  formRef?: MutableRefObject<{ handleSubmit: () => Promise<void> } | null>;
  noResultsFormValues: NoResultSearchValuesType;
};

export const CreateDriverForm: FC<CreateDriverFormProps> = ({ onClose, formRef, noResultsFormValues }) => {
  const { t } = useTranslations();
  const appConfig = getAppConfigCache();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const driverProfileRenter = useAppSelector(selectDriverProfileRenter);
  const additionalDrivers = useAppSelector(selectAdditionalDrivers);
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState(false);

  const resolver = useYupValidationResolver(createDriverValidationSchema(t));
  const formMethods = useForm<CreateDriverValues>({
    resolver: resolver,
    defaultValues: createDriverInitialValues(noResultsFormValues),
  });

  const invokeAlert = useCallback(
    async (description: string | JSX.Element) => {
      setLoading(false);
      await showAlert({
        variant: 'error',
        description: description,
      });
    },
    [showAlert]
  );

  const handleError = useCallback(
    async (errors: ResponseMessage[] | undefined, message: string) => {
      if (errors) {
        await invokeAlert(`${t(message)}: ${errors?.[0]?.localizedMessage || ''}`);
      } else {
        setLoading(false);
        onClose();
      }
    },
    [invokeAlert, onClose, t]
  );

  const handleUpdateEditor = useCallback(
    async (driverProfile: DriverProfile, isTransactionProfile?: boolean) => {
      if (!driverProfile.urn) {
        await invokeAlert(t('driver.addDriverError'));
      } else {
        if (!driverProfileRenter) {
          const requestBody = {
            type: isTransactionProfile ? RenterTypes.TransactionalProfile : RenterTypes.DriverProfile,
            profile: driverProfile.urn,
          } as Renter;

          const { errors } = await updateAndRefresh(() =>
            associateRenterToReservationEditor(bookingEditorId, requestBody)
          );
          await handleError(errors, 'driver.addDriverError');
        } else {
          const currentAdditionalDrivers = additionalDrivers && additionalDrivers.length > 0 ? additionalDrivers : [];

          const requestBody = [
            ...currentAdditionalDrivers,
            {
              name: { given: driverProfile.name?.givenName, surname: driverProfile.name?.surname ?? '' },
              profile: driverProfile.urn,
            },
          ];
          const { errors } = await updateAndRefresh(() => updateAdditionalDrivers(bookingEditorId, requestBody));
          await handleError(errors, 'driver.addAdditionalDriverError');
        }
      }
    },
    [bookingEditorId, driverProfileRenter, invokeAlert, t, updateAndRefresh, additionalDrivers, handleError]
  );

  const handleCreatingProfile = useCallback(
    async (values: CreateDriverValues) => {
      setLoading(true);
      try {
        if (isValidFullProfile(values)) {
          const driverProfile: DriverProfile = await createDriverProfile(
            transformToCreateDriverProfileRequest(values, appConfig?.defaultEhiDatabase ?? '')
          );
          await handleUpdateEditor(driverProfile);
        } else {
          const transactionalProfile: TransactionalProfileResponseContent = await createTransactionalProfile(
            transformToCreateTransactionalProfileRequest(values, appConfig?.defaultEhiDatabase ?? '')
          );
          const driverProfile: DriverProfile = {
            name: {
              surname: transactionalProfile.personalInformation?.name?.surname ?? '',
              givenName: transactionalProfile.personalInformation?.name?.givenName ?? '',
            },
            urn: transactionalProfile.urn,
          };

          await handleUpdateEditor(driverProfile, true);
        }
      } catch (error) {
        const ehiErrorsResponse = safelyCatchError(error);
        if (ehiErrorsResponse?.errors) {
          await showAlert({ responseMessages: ehiErrorsResponse?.errors });
        }
        setLoading(false);
      }
    },
    [appConfig?.defaultEhiDatabase, handleUpdateEditor, showAlert]
  );

  if (formRef) {
    formRef.current = { handleSubmit: formMethods.handleSubmit(handleCreatingProfile) };
  }

  return (
    <MarginWrapper data-testid={'createDriverForm'}>
      <FormProvider {...formMethods}>
        <DriverSection />
        <DividerWithFullWidth style={{ borderColor: ehiTheme.palette.divider }} />
        <LicenseSection />
        <DividerWithFullWidth style={{ borderColor: ehiTheme.palette.divider }} />
        <ContactSection />
      </FormProvider>
      <ProgressOverlay inProgress={loading} />
    </MarginWrapper>
  );
};
