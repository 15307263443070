import { EhiDialog, EhiDialogProps } from '@ehi/ui';
import { FC } from 'react';
import { useDialogMenuItems } from 'components/shared/ui/dialogs/useDialogMenuItems';
import { Menu } from 'components/shared/ui/moreMenu/Menu';

export const Dialog: FC<EhiDialogProps> = (props) => {
  const moreMenuItems = useDialogMenuItems();
  const titleActions = props.titleActions || [];
  return (
    <EhiDialog {...props} titleActions={[...titleActions, <Menu menuItems={moreMenuItems} key={'more-menu'} />]} />
  );
};
