import { styled } from '@mui/material';
import { GridItem } from 'components/shared/ui/styles/Grid.styles';
import { ehiTheme, Subtitle1 } from '@ehi/ui';

export const Section = styled('section')`
  color: ${ehiTheme.palette.text.primary};
`;

export const FormSectionHeader = styled(Subtitle1)`
  color: ${ehiTheme.palette.text.primary};
  font-weight: 700;
  padding-bottom: ${ehiTheme.spacing(1)};
  margin-left: ${ehiTheme.spacing(1)};
`;

export const PhoneGridItem = styled(GridItem)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    '&&&': {
      paddingBottom: 0,
    },
  },
}));
