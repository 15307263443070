import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { Iso2Locales, loadLocaleCookie } from '@ehi/location';

const getDefaultLanguage = (): string => {
  return loadLocaleCookie() ?? navigator.language;
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: getDefaultLanguage(),
    fallbackLng: {
      // TODO: temp until we start moving things to proper localization files
      [Iso2Locales.frUS]: [Iso2Locales.frCA, Iso2Locales.enUS],
      default: [Iso2Locales.enUS],
    },
    keySeparator: '.',
    returnNull: false,
    load: 'currentOnly',
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
