import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ViewStreamOutlinedIcon from '@mui/icons-material/ViewStreamOutlined';
import {
  buttonImages,
  OutlinedIconSelected,
  OutlinedIconNonSelected,
  OutlinedDisabledIconGray,
  OutlinedDisabledIconWhite,
  StyledIconButton,
} from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookup.styles';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { Box } from '@mui/material';

export type BranchLookupButtonProps = {
  name: string;
  disabled?: boolean;
  icon: string;
  selected?: boolean;
  onClick?: (event: any) => void;
};
const BUTTON_HEIGHT = 40;

export const BranchLookupIconButton: FC<BranchLookupButtonProps> = ({
  name,
  disabled,
  icon,
  selected,
  onClick,
}: BranchLookupButtonProps) => {
  const form = useFormContext();
  const iconToView = () => {
    if (disabled) {
      if (icon === buttonImages.listIcon) {
        return (
          <Box borderTop={1} borderLeft={1} borderBottom={1} height={BUTTON_HEIGHT}>
            <ViewStreamOutlinedIcon data-testid={'listButton'} style={OutlinedDisabledIconGray} onClick={onClick} />
          </Box>
        );
      } else {
        return (
          <Box borderTop={1} borderRight={1} borderBottom={1} height={BUTTON_HEIGHT}>
            <MapOutlinedIcon data-testid={'mapButton'} style={OutlinedDisabledIconWhite} onClick={onClick} />{' '}
          </Box>
        );
      }
    }

    let styleValue = OutlinedIconNonSelected;
    if (selected) {
      styleValue = OutlinedIconSelected;
    }

    if (icon === buttonImages.listIcon) {
      return (
        <Box borderTop={1} borderLeft={1} borderBottom={1} height={BUTTON_HEIGHT}>
          <ViewStreamOutlinedIcon data-testid={'listButton'} style={styleValue} onClick={onClick} />
        </Box>
      );
    } else {
      return (
        <Box borderTop={1} borderRight={1} borderBottom={1} height={BUTTON_HEIGHT}>
          <MapOutlinedIcon data-testid={'mapButton'} style={styleValue} onClick={onClick} />
        </Box>
      );
    }
  };

  return (
    <Controller
      name={name}
      control={form.control}
      render={() => (
        <>
          <StyledIconButton id={name} data-testid={name} disabled={disabled}>
            {iconToView()}
          </StyledIconButton>
        </>
      )}
    />
  );
};
