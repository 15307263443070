import { getScreen, parseUserAgent } from 'utils/deviceInfo';

export enum DeviceState {
  DOCKED = 'Docked',
  UNDOCKED_PORTRAIT = 'Undocked protrait',
  UNDOCKED_LANDSCAPE = 'Undocked landscape',
  DESKTOP = 'Desktop',
}
export enum DeviceType {
  DESKTOP = 'Desktop',
  MOBILE = 'Mobile',
  TABLET = 'Tablet',
}

export type Device = {
  deviceState: DeviceState;
  isAndroid: boolean;
  deviceType: DeviceType;
};

const TABLET_WIDTH = 1280;
const TABLET_HEIGHT = 800;
const DEVICE_TYPE_TABLET = 'tablet';
const DEVICE_TYPE_MOBILE = 'mobile';
const OS_NAME_ANDROID = 'Android';

export const detectDevice = (): Device => {
  const { device, os } = parseUserAgent();
  const { width, height } = getScreen();

  // First verify that we are on an Android tablet
  if ((device.type === DEVICE_TYPE_TABLET || device.type === DEVICE_TYPE_MOBILE) && os.name === OS_NAME_ANDROID) {
    // Default to undocked portrait
    let deviceState: DeviceState = DeviceState.UNDOCKED_PORTRAIT;
    const deviceType: DeviceType = device.type === DEVICE_TYPE_TABLET ? DeviceType.TABLET : DeviceType.MOBILE;
    if (
      // A tablet is docked when its landscaped and the width and height does not match the tablet values
      width > height &&
      width !== TABLET_WIDTH &&
      height !== TABLET_HEIGHT
    ) {
      deviceState = DeviceState.DOCKED;
    } else if (
      // A tablet is undocked landscape when the orientation of the device is landscape and does match the tablet width and height values
      width > height
    ) {
      deviceState = DeviceState.UNDOCKED_LANDSCAPE;
    }
    return { deviceState, isAndroid: true, deviceType };
  } else {
    // If the device operating system is not android and not table or mobile for the type then it is true desktop or other
    return { deviceState: DeviceState.DESKTOP, isAndroid: false, deviceType: DeviceType.DESKTOP };
  }
};
