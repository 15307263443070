import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import './components/shared/i18n/i18n';
import App from './app/App';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import { isCypressRun } from 'utils/buildInfoUtil';

// expose store when run in Cypress
if (isCypressRun()) {
  (window as any).store = store;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
