import { styled } from '@mui/material';
import IconWrapper from 'components/shared/progressIndicator/IconWrapper';

export const StepContent = styled('div')`
  flex: 2 1 auto;
`;

export const StyledStepIcon = styled(IconWrapper)(({ theme }) => ({
  alignContent: 'center',
  margin: theme.spacing(1),
}));
