import parse from 'ua-parser-js';

export function parseUserAgent() {
  return parse(navigator.userAgent);
}

export function userAgent() {
  return navigator.userAgent.split(' ')[0];
}

export function getScreen() {
  return window.screen;
}
