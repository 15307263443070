import { ChangeEvent, FC } from 'react';
import { CheckboxField } from 'components/shared/forms/CheckboxField';
import {
  PeoAmount,
  PeoCheckboxContainer,
  PeoCode,
  PeoListItemContainer,
  PeoQuantityAndRate,
  PeoSelectability,
  PeoTitle,
} from './Peo.styles';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow } from 'redux/selectors/bookingEditor';
import { getCounterOptions, getItemRates, getSelectabilityDescription } from 'utils/peoUtils';
import { useLocale, useTranslations } from 'components/shared/i18n';
import { Rate } from 'services/booking/bookingTypes';
import { SelectField } from 'components/shared/forms/SelectField';
import { useFormContext } from 'react-hook-form';

export type PeoListItemProps = {
  checkBoxFieldName: string;
  title: string;
  code: string;
  rate: Rate | undefined;
  selectability: string;
  index: number;
  showCountDropdown?: boolean;
  quantityName?: string;
  isQuantityDisabled?: boolean;
};

const DISPLAY_COUNTER_CLASS_NAME = 'displayQuantity';

export const PeoListItem: FC<PeoListItemProps> = ({
  index,
  title,
  code,
  rate,
  selectability,
  checkBoxFieldName,
  showCountDropdown,
  quantityName,
  isQuantityDisabled,
}: PeoListItemProps) => {
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);
  const { t } = useTranslations();
  const { locale } = useLocale();
  const { setValue } = useFormContext();

  const handlePeoChange = (value: number) => {
    setValue(checkBoxFieldName, value > 0, { shouldDirty: true });
    if (quantityName) setValue(quantityName, value, { shouldDirty: true });
  };

  const showQuantity = showCountDropdown && !isReadOnly && !isQuantityDisabled;

  return (
    <PeoListItemContainer key={`peo-item-${index}`} data-testid={`peo-item-${index}`}>
      <PeoCheckboxContainer>
        {!isReadOnly && (
          <CheckboxField
            name={checkBoxFieldName}
            data-testid={checkBoxFieldName}
            color={'primary'}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handlePeoChange(e.target.checked ? 1 : 0);
            }}
          />
        )}
        <div>
          <PeoTitle readOnly={!isReadOnly} data-testid='peoTitle'>
            {title}
          </PeoTitle>
          <PeoCode data-testid='peoCode'>{code}</PeoCode>
        </div>
      </PeoCheckboxContainer>
      <PeoQuantityAndRate className={showQuantity ? DISPLAY_COUNTER_CLASS_NAME : ''}>
        {showQuantity && quantityName && (
          <SelectField
            data-testid={quantityName}
            name={quantityName}
            options={getCounterOptions()}
            hasNoneOption={false}
            disabled={false}
            onChange={(event) => {
              handlePeoChange(Number.parseInt(event.target.value, 10));
            }}
          />
        )}
        <div>
          <PeoAmount data-testid='peoAmount'>{rate && getItemRates(rate, t, locale)}</PeoAmount>
          <PeoSelectability data-testid='peoSelectability'>
            {getSelectabilityDescription(selectability, t)}
          </PeoSelectability>
        </div>
      </PeoQuantityAndRate>
    </PeoListItemContainer>
  );
};
