import { styled } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

export const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  verticalAlign: 'middle',
}));
StyledWarningIcon.defaultProps = {
  fontSize: 'small',
};
