import { DateTime } from 'luxon';

export interface WhenAndWhereValues {
  startDate: DateTime | string;
  startTime: DateTime | string;
  returnDate: DateTime | string;
  returnTime: DateTime | string;
  startDateTime: DateTime | string;
  returnDateTime: DateTime | string;
  startLocation: string;
  returnLocation: string;
  startLocationCurrentTime: DateTime | string;
  returnLocationCurrentTime: DateTime | string;
  startLocationUrn: string;
  returnLocationUrn: string;
  startLocationTimezone: string | undefined;
  returnLocationTimezone: string | undefined;
}

export enum WhenAndWhereFields {
  StartDate = 'startDate',
  StartTime = 'startTime',
  ReturnDate = 'returnDate',
  ReturnTime = 'returnTime',
  StartDateTime = 'startDateTime',
  ReturnDateTime = 'returnDateTime',
  ReturnLocation = 'returnLocation',
  StartLocation = 'startLocation',
  StartLocationCurrentTime = 'startLocationCurrentTime',
  ReturnLocationCurrentTime = 'returnLocationCurrentTime',
  StartLocationUrn = 'startLocationUrn',
  ReturnLocationUrn = 'returnLocationUrn',
  StartLocationTimezone = 'startLocationTimezone',
  ReturnLocationTimezone = 'returnLocationTimezone',
}
