import { FC, useState } from 'react';
import { LoadableView } from 'components/shared/ui/spinner/loadableView/LoadableView';
import { FullScreenSpinner } from 'components/shared/ui/spinner/FullScreenSpinner';
import ErrorWrapper from 'components/shared/errors/ErrorWrapper';
import { useEffectOnlyOnce } from 'hooks/useEffectOnlyonce';
import { createSplunkLogger } from 'components/shared/logger/splunkLogger';
import { LoggerManagerProps } from './LoggingTypes';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';

export const LoggerManager: FC<LoggerManagerProps> = ({ children }) => {
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING);

  useEffectOnlyOnce(async () => {
    try {
      createSplunkLogger();
      setLoadingState(LoadingState.SUCCESS);
    } catch (error) {
      setLoadingState(LoadingState.ERROR);
    }
  });

  return (
    <LoadableView loadingComponent={<FullScreenSpinner />} errorComponent={<ErrorWrapper />} state={loadingState}>
      {children}
    </LoadableView>
  );
};
