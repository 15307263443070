import { Box, FormControl, InputLabel, styled } from '@mui/material';
import { H6 } from '@ehi/ui';

export const StyledContainer = styled(Box)`
  display: grid;
  height: 100vh;
  width: 100vw;
  place-items: center;
`;
export const StyledSetCounter = styled(Box)`
  width: 40vw;
  @media (max-width: 1024px) {
    width: 80vw;
  }
  @media (max-width: 840px) {
    width: 80vw;
  }
  padding: 20px 24px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const CounterLabel = styled(InputLabel)`
  padding-right: 5px;
`;
export const CounterOptions = styled(FormControl)`
  width: 100%;
  background: ${(props) => props.theme.ehi.palette.global.white};

  fieldset {
    border-radius: 0;
  }

  && {
    background: ${(props) => props.theme.ehi.palette.global.white};

    .MuiSelect-icon {
      color: ${(props) => props.theme.palette.primary.main};
    }

    .Mui-focused {
      background: none;
    }

    .MuiSelect-outlined {
      background: ${(props) => props.theme.ehi.palette.global.white};
    }
  }
`;
export const ActionsContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding: 8px 0;
`;

export const SubTitle = styled('span')`
  display: block;
  font-size: 18px;
  margin-bottom: 21px;
  color: #7c7c7c;
  white-space: normal;
  @media (max-width: 1280px) {
    text-align: center;
  }
`;

export const CounterTitle = styled(H6)`
  color: ${({ theme }) => theme.palette.text.primary};
  padding-bottom: ${(props) => props.theme.spacing(2)};
`;
