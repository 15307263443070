import { useTranslations } from 'components/shared/i18n';
import { Box } from '@mui/material';
import { Body1 } from '@ehi/ui';
import { FunctionComponent } from 'react';

export const EmptyView: FunctionComponent = () => {
  const { t } = useTranslations();
  return (
    <Box display='flex' sx={{ padding: (theme) => theme.spacing(2), justifyContent: 'center' }}>
      <Body1>{t('peo.noOptions')}</Body1>
    </Box>
  );
};
