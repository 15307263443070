import { FC } from 'react';
import { AlamoLoyaltyProgramLogo } from 'assets/loyalty/AlamoLoyaltyProgramLogo';
import { EnterpriseLoyaltyProgramLogo } from 'assets/loyalty/EnterpriseLoyaltyProgramLogo';
import { NationalLoyaltyProgramLogo } from 'assets/loyalty/NationalLoyaltyProgramLogo';
import { LoyaltyProgram } from 'utils/constants';

type LoyaltyProgramLogoProps = {
  membershipProgram: string;
  height: number;
  width: number;
};

export const LoyaltyProgramLogo: FC<LoyaltyProgramLogoProps> = ({ membershipProgram, width = 102, height = 80 }) => {
  switch (membershipProgram) {
    case LoyaltyProgram.ENTERPRISE_PLUS:
      return <EnterpriseLoyaltyProgramLogo height={height} width={width} />;
    case LoyaltyProgram.EMERALD_CLUB:
      return <NationalLoyaltyProgramLogo height={height} width={width} />;
    case LoyaltyProgram.ALAMO_INSIDERS:
      return <AlamoLoyaltyProgramLogo height={height} width={width} />;
    default:
      return null;
  }
};
