import { FC } from 'react';
import {
  CustomBody2,
  EmptySelectionContainer,
  SelectedBrandsContainer,
} from 'components/flexFlow/whenAndWhere/branchLookup/brandFilter/BrandFilterDropDown.styles';
import { useTranslations } from 'components/shared/i18n';
import { Body1 } from '@ehi/ui';

interface SelectedBrandsDisplayProps {
  value: string[];
  brands: string[];
}
export const SelectedBrandsDisplay: FC<SelectedBrandsDisplayProps> = ({ value, brands }) => {
  const { t } = useTranslations();
  const selected = value;

  if (selected.length === 0) {
    return (
      <EmptySelectionContainer>
        <Body1>{t('location.brands')}</Body1>
      </EmptySelectionContainer>
    );
  }

  let displayText;

  if (selected.length === brands.length) {
    displayText = t('whenWhere.allBrands');
  } else if (selected.length === 1) {
    displayText = selected[0];
  } else {
    displayText = `${selected[0]}, +${selected.length - 1}`;
  }

  return (
    <SelectedBrandsContainer>
      <CustomBody2>{displayText}</CustomBody2>
    </SelectedBrandsContainer>
  );
};
