import { H6 } from '@ehi/ui';
import { Paper, styled, Typography } from '@mui/material';

export const HeaderName = styled(Typography)`
  font-family: Robotto, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #4d789d;
`;
export const HeaderValue = styled(Typography)`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

export const StyledPaper = styled(Paper)`
  border-radius: 0;
  position: sticky;
  top: 0;
  z-index: 100;
`;

export const RentalHeaderH6 = styled(H6)`
  line-height: 1.2;
  font-weight: normal;
  text-align: start;
  white-space: nowrap;
`;
