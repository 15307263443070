import { configureStore } from '@reduxjs/toolkit';
import featureFlagsReducer from 'redux/slices/featureFlags/featureFlagsSlice';
import bookingEditorReducer from 'redux/slices/booking/bookingEditorSlice';

export const store = configureStore({
  reducer: {
    featureFlags: featureFlagsReducer,
    bookingEditor: bookingEditorReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {reducers: 'all'}
export type AppDispatch = typeof store.dispatch;
