import { styled } from '@mui/material';
import { Box } from '@mui/system';
import { ehiTheme } from '@ehi/ui';
import { secondaryText } from 'components/shared/ui/styles/Global.styles';

export const NoResultsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledDescription = styled('span')`
  font-size: ${ehiTheme.typography.subtitle1.fontSize};
  font-weight: normal;
  line-height: ${ehiTheme.spacing(1.5)};
  color: ${secondaryText};
  padding: ${ehiTheme.spacing(4, 2, 4, 0)};
  word-wrap: break-word;
  max-width: 750px;
`;

export const StyledMessage = styled('div')`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const SuggestionsHeader = styled('span')`
  font-size: ${ehiTheme.typography.h6.fontSize};
  font-weight: bold;
  line-height: ${ehiTheme.spacing(1.5)};
  color: ${secondaryText};
  padding-bottom: ${ehiTheme.spacing(1.25)};
`;

export const StyledLink = styled('a')`
  color: ${ehiTheme.palette.primary.main};
  text-decoration: underline;
  cursor: pointer;
`;

export const TextWrapper = styled('div')`
  display: grid;
  justify-content: center;
  max-width: 750px;
  padding: ${ehiTheme.spacing(16, 12, 12, 12)};
`;
