import { FC } from 'react';

type EnterpriseIconRoundProps = {
  height: number;
  width: number;
};
export const EnterpriseRoundIcon: FC<EnterpriseIconRoundProps> = ({ height = 72, width = 72 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 72 72'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-testid={'enterpriseLogo'}>
      <g clipPath='url(#clip0_1923_26957)'>
        <rect width='72' height='72' rx='36' fill='#00A664' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M61.9101 34.252C60.5854 27.6691 52.8801 21 40.7123 21H40.7027C29.9681 21 22.1734 26.6921 19.6525 32.5343C19.5964 32.6536 19.3964 33.1635 19.3261 33.3872H-1V35.7157H53.2535C56.3306 35.7157 56.794 35.5562 57.1407 35.2294C57.2382 35.1463 57.3181 35.0077 57.3181 34.7195C57.3155 32.923 52.9901 24.5816 40.6912 24.5816C30.142 24.5816 25.6867 30.7408 24.7157 32.5346C24.5417 32.8489 24.4297 33.0802 24.3059 33.3852L22.9216 33.3866V33.3852C23.1276 32.8657 23.3861 32.3989 23.3861 32.3989C24.4344 30.2736 28.7875 23.3594 40.6909 23.3594C53.0552 23.3594 58.5163 31.7746 58.6257 34.9125C58.6532 35.6557 58.5007 36.0751 58.0672 36.3917C57.6834 36.6731 57.2284 36.935 53.2529 36.9356H-1V39.3722L13.3607 39.3724H13.4253C30.5074 39.3727 54.9188 39.3732 55.9084 39.3722C58.7186 39.3508 60.7284 39.2938 61.6027 38.2241C62.1995 37.495 62.295 36.2679 61.9101 34.252ZM52.9039 32.9346C52.8931 32.9557 52.8809 32.9757 52.8672 32.9949C52.6398 33.3139 52.0203 33.3741 51.4327 33.3872H51.4326L51.4322 33.3872H28.0029C28.1841 32.956 28.4915 32.5343 28.4915 32.5343C30.0425 30.2581 33.0237 28.4509 36.4154 27.5705C37.5569 27.2741 38.745 27.0827 39.9407 27.0139C40.2572 26.9956 40.5743 26.986 40.8912 26.9852C46.1902 26.9684 50.4895 28.7579 52.6828 31.8935C52.8996 32.2702 52.9887 32.5486 52.9584 32.7647C52.9497 32.8261 52.9315 32.8824 52.9039 32.9346ZM49.6812 43.4447H54.4943C49.5585 48.5458 40.548 48.1852 40.1641 48.1669C31.9826 47.958 27.0156 44.1601 25.0323 40.7284H28.8683C31.2508 43.6041 35.8361 45.5577 40.5907 45.5577C43.5773 45.5577 47.5811 45.1433 49.6812 43.4447ZM56.0441 43.5781L56.1453 43.4447H60.3087C60.199 43.6447 60.1111 43.7804 59.962 43.985C56.9303 47.9452 52.2674 51.9865 40.6906 52C29.4066 52.017 22.1922 46.4558 19.8059 40.7284H23.6309C25.7079 44.8878 31.5856 49.2464 40.127 49.3891C41.2517 49.4247 51.2527 49.5486 56.0441 43.5781Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1923_26957'>
          <rect width='72' height='72' rx='36' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
