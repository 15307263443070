import { Brand } from 'utils/constants';
import { FC } from 'react';
import { EnterpriseRoundIcon } from 'assets/brand/EnterpriseRoundIcon';
import { AlamoRoundIcon } from 'assets/brand/AlamoRoundIcon';
import { NationalRoundIcon } from 'assets/brand/NationalRoundIcon';
import { BrandLogoProps } from './BrandLogoTypes';
import { BrandPlaceholderIcon } from 'assets/brand/BrandPlaceholderIcon';

export const BrandLogo: FC<BrandLogoProps> = ({ brand, height = 80, width = 102 }) => {
  switch (brand) {
    case Brand.ENTERPRISE:
    case Brand.ET:
      return <EnterpriseRoundIcon height={height} width={width} />;
    case Brand.ALAMO:
    case Brand.AL:
      return <AlamoRoundIcon height={height} width={width} />;
    case Brand.NATIONAL:
    case Brand.ZL:
      return <NationalRoundIcon height={height} width={width} />;
    default:
      return <BrandPlaceholderIcon />;
  }
};
