import {
  BranchDetails,
  BranchDetailsSearchCriteria,
  BranchEffectivePolicy,
  BranchHierarchy,
  BranchId,
  BranchSearchResults,
  BranchV2,
  Countries,
  CountrySubdivision,
  GroupSummary,
  PaymentMethod,
  Station,
} from 'services/location/locationTypes';
import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import { parseUrn } from 'utils/urnUtils';
import { isAllowedBranch } from 'utils/locationUtils';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { filterLocationTypes } from 'utils/branchLookupUtils';
import { OperationalLocationType, PaymentType } from 'services/location/locationReferenceTypes';
import { RentalBrand } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupTypes';
import { currentDate } from 'utils/dateUtils';

const ROOT_PATH = '/location';
const REFERENCE_ROOT_PATH = '/reference/location';
const PAYMENT_TYPE_PATH = '/paymentType';
const PAYMENT_METHOD_PATH = '/paymentMethod';
const COUNTRY_PATH = '/country';
const BRANCH_PATH = '/branch';
const STATION_PATH = '/station';
const HIERARCHY_PATH = '/hierarchy';
const GROUP_PATH = '/group';
const LOCATION_TYPES_PATH = '/operationalLocationType';
const COUNTRY_CODE_QUERY_PARAM = 'countryCode';
const CAPABILITIES_QUERY_PARAM = 'capabilities';
const REFERENCE = '/reference';
const RENTAL = '/rental';
const BRAND = '/brand';
const RETRIEVE_EFFECTIVE_POLICIES = '/retrieve-effective-policies';

export const getCountries = async (): Promise<Countries> => {
  return (
    await getDefaultAxiosInstance().get(`${ROOT_PATH}${COUNTRY_PATH}`, {
      genericUrl: `${ROOT_PATH}${COUNTRY_PATH}`,
    })
  ).data;
};

export const getCountrySubdivisions = async (countryIso3Code: string): Promise<Array<CountrySubdivision>> => {
  return (
    (
      await getDefaultAxiosInstance().get(`${ROOT_PATH}${COUNTRY_PATH}/${countryIso3Code}`, {
        genericUrl: `${ROOT_PATH}${COUNTRY_PATH}/{countryIso3Code}`,
      })
    ).data?.subdivision || []
  );
};

export const getMultipleBranchDetails = async (locationIds: Array<string>) => {
  const response = await getDefaultAxiosInstance().get<BranchDetails[]>(
    `${ROOT_PATH}${BRANCH_PATH}?ids=${locationIds?.join(',')}`,
    {
      genericUrl: `${ROOT_PATH}${BRANCH_PATH}`,
    }
  );
  return response.data;
};

export const getBranchByPeopleSoftId = async (branchId: BranchId): Promise<BranchV2> => {
  const { data } = await getDefaultAxiosInstance().get(`${ROOT_PATH}${BRANCH_PATH}/${branchId}`, {
    genericUrl: `${ROOT_PATH}${BRANCH_PATH}/{branchId}`,
  });
  return data;
};

export const getStation = async (stationId: string): Promise<Station> => {
  const { data } = await getDefaultAxiosInstance().get(`${ROOT_PATH}${STATION_PATH}/${stationId}`, {
    genericUrl: `${ROOT_PATH}${STATION_PATH}/{locationId}`,
  });

  return data;
};

export const getBranchHierarchy = async (locationId: string): Promise<BranchHierarchy> => {
  const searchParams: URLSearchParams = new URLSearchParams();
  searchParams.append('functionalHierarchy', 'true');
  const { data } = await getDefaultAxiosInstance().get(
    `${ROOT_PATH}${BRANCH_PATH}/${locationId}${HIERARCHY_PATH}?`.concat(searchParams.toString()),
    {
      genericUrl: `${ROOT_PATH}${BRANCH_PATH}/{locationId}${HIERARCHY_PATH}`,
    }
  );
  return data;
};
export const getLocationByUrn = async (urn: string | undefined, localizedMessage: string): Promise<BranchV2> => {
  const branch = await getBranchByPeopleSoftId(parseUrn(urn));
  if (isAllowedBranch(branch)) {
    return branch;
  } else {
    // Throw an EhiError, so it behaves the same as service failure
    // eslint-disable-next-line no-throw-literal
    throw { errors: [{ localizedMessage: localizedMessage }] } as EhiErrors;
  }
};

/**
 * Retrieves Payment Methods By Location Id
 */
export const getPaymentMethodsByLocation = async (locationId: string): Promise<PaymentMethod[]> => {
  const { data } = await getDefaultAxiosInstance().get(
    `${ROOT_PATH}${BRANCH_PATH}/${locationId}${PAYMENT_METHOD_PATH}`,
    {
      genericUrl: `${ROOT_PATH}${BRANCH_PATH}/{locationId}${PAYMENT_METHOD_PATH}`,
    }
  );

  return data;
};

/**
 * Provides reference data for all types of payment
 */
export const getPaymentTypes = async (): Promise<PaymentType[]> => {
  const path = `${REFERENCE_ROOT_PATH}${PAYMENT_TYPE_PATH}`;
  const { data } = await getDefaultAxiosInstance().get(path, {
    genericUrl: path,
  });

  return filterPaymentTypes(data);
};

const SEARCH_PATH = '/search';

export const searchBranches = async (searchCriteria: BranchDetailsSearchCriteria): Promise<BranchSearchResults> => {
  const { data } = await getDefaultAxiosInstance().post(`${ROOT_PATH}${BRANCH_PATH}${SEARCH_PATH}`, searchCriteria, {
    genericUrl: `${ROOT_PATH}${BRANCH_PATH}${SEARCH_PATH}`,
  });
  return data;
};

const filterPaymentTypes = (paymentTypes: PaymentType[]): PaymentType[] => {
  return paymentTypes.filter(
    (paymentType) =>
      paymentType.code == 'CREDIT_CARD' ||
      paymentType.code == 'DEBIT_CARD' ||
      paymentType.code == 'UNKNOWN' ||
      paymentType.code == 'CENTRAL_BILL'
  );
};

export const getGroups = async (countryCode: string, capabilities: string): Promise<GroupSummary[]> => {
  const response = await getDefaultAxiosInstance().get<GroupSummary[]>(
    `${ROOT_PATH}${GROUP_PATH}?${COUNTRY_CODE_QUERY_PARAM}=${countryCode}&${CAPABILITIES_QUERY_PARAM}=${capabilities}`,
    {
      genericUrl: `${ROOT_PATH}${GROUP_PATH}`,
    }
  );
  return response.data;
};

export const getRentalBrands = async (): Promise<RentalBrand[]> => {
  const { data } = await getDefaultAxiosInstance().get(`${REFERENCE}${RENTAL}${BRAND}`);
  return data;
};

export const getLocationTypes = async (): Promise<OperationalLocationType[]> => {
  const response = (
    await getDefaultAxiosInstance().get(`${REFERENCE_ROOT_PATH}${LOCATION_TYPES_PATH}`, {
      genericUrl: `${REFERENCE_ROOT_PATH}${LOCATION_TYPES_PATH}`,
    })
  ).data;

  return filterLocationTypes(response);
};

export const retrieveBranchEffectivePolicies = async (branchId: string): Promise<BranchEffectivePolicy[]> => {
  const response = (
    await getDefaultAxiosInstance().post(
      `${ROOT_PATH}${BRANCH_PATH}/${branchId}${RETRIEVE_EFFECTIVE_POLICIES}`,
      {
        effectiveDate: currentDate,
        // Note: Natres uses AVAILAB for delivery channel
        // Need to check with product to create new channel for green light
        deliveryChannel: 'AVAILAB',
      },
      {
        genericUrl: `${ROOT_PATH}${BRANCH_PATH}/{branchId}${RETRIEVE_EFFECTIVE_POLICIES}`,
      }
    )
  ).data;

  return response;
};
