import { CALLING_APP_ID } from 'utils/constants';
import { FC } from 'react';
import { Logout } from '@ehi/auth';
import { getAppConfigCache } from 'services/appConfig/appConfigService';

export const LogoutPage: FC = () => {
  const appConfig = getAppConfigCache();
  if (appConfig === undefined) {
    throw new Error('App Config Was not retrieved.');
  }
  return (
    <Logout
      logoutParams={{
        callingAppId: CALLING_APP_ID,
        authWebUrl: new URL(appConfig.externalAppEndpoints.authWeb),
        port: 3000,
        redirectUrl: appConfig.webEnvironment === 'localhost' ? undefined : new URL(window.location.origin),
      }}
    />
  );
};
