import { getCache, prefetch, setCache } from 'context/queryClient/queryUtil';
import { SESSION_CACHE_OPTIONS } from 'context/queryClient/cacheOptions';
import { AppConfig } from 'services/appConfig/appConfigTypes';
import axios from 'axios';

const APP_CONFIG_QUERY_KEY = ['appConfig'];

export const getAppConfig = async (): Promise<AppConfig> => {
  const { data } = await axios.get<AppConfig>('/appConfig.json');

  return data;
};

export async function prefetchAppConfig(): Promise<void> {
  return prefetch({ queryKey: APP_CONFIG_QUERY_KEY, queryFn: getAppConfig, ...SESSION_CACHE_OPTIONS });
}

export function getAppConfigCache(): AppConfig | undefined {
  return getCache<AppConfig>(APP_CONFIG_QUERY_KEY);
}

export function setAppConfigCache(appConfigData: AppConfig): void {
  setCache(APP_CONFIG_QUERY_KEY, appConfigData);
}
