import { FC } from 'react';

type AlamoIconRoundProps = {
  height: number;
  width: number;
};
export const AlamoRoundIcon: FC<AlamoIconRoundProps> = ({ height = 72, width = 72 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 72 72'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-testid={'alamoLogo'}>
      <rect width='72' height='72' rx='36' fill='#0060A9' />
      <path
        d='M40.6 22.1H30.8L17.2 49.9H25.1L27.7 44.2H43L45.8 49.9H54.8L40.6 22.1ZM30.4 38.9L35.2 28L40.4 38.9H30.4Z'
        fill='#FFF200'
      />
    </svg>
  );
};
