import { LegalIdentification, ReferenceDocument } from 'services/renter/driverProfile/driverProfileTypes';

export const RENT = 'RENT';
export const DO_NOT_RENT = 'DO_NOT_RENT';
export type RenterWarningOption = 'RENT' | 'DO_NOT_RENT';

export interface RenterWarningFieldsValues {
  [RenterWarningFields.SelectedOption]: string;
}

export enum RenterWarningFields {
  SelectedOption = 'selectedOption',
}

export interface DriverWarningData {
  name: string;
  dob: string;
  email: string;
  phone: string;
  phoneType: string;
  reason: string;
  warningMessage: string;
  message: string;
  reportDate: string;
  address: {
    lines: string[];
    cityStateZip: string;
  };
  department: {
    urn?: string;
    type?: string;
    phoneNumber?: string;
    group?: string;
    region?: string;
    availableReasons?: string[];
    description?: string;
  };
  legalIdentification: LegalIdentification;
  referenceDocuments: ReferenceDocument[];
}
