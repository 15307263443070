import { FunctionComponent, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { FlexiFlowMenu } from 'components/shared/flexiFlow/FlexiFlowMenu';
import { InlineReservationSummary } from 'components/resSummary/InlineReservationSummary';
import { ResSummaryDrawerContext } from 'context/resSummary/ResSummaryDrawerContext';
import { FlexiFlowRouterProps } from './FlexFlowTypes';
import { FlexMenuContainer } from 'components/shared/flexiFlow/FlexiFlowMenu.styles';

export const FlexMenuWrapper: FunctionComponent<FlexiFlowRouterProps> = ({ pages }) => {
  const { openDrawer } = useContext(ResSummaryDrawerContext);
  return (
    <div style={{ display: 'flex' }}>
      {openDrawer && <InlineReservationSummary />}
      <FlexMenuContainer>
        <FlexiFlowMenu pages={pages} />
        <Outlet />
      </FlexMenuContainer>
    </div>
  );
};
