import { FC, useMemo, useState } from 'react';
import { formatPhoneNumber } from 'utils/phoneNumberUtils';
import { Body1, EhiButton, ehiTheme, Subtitle1 } from '@ehi/ui';
import ListItem from '@mui/material/ListItem';
import { piiField } from '@ehi/support';
import { parseUrn } from 'utils/urnUtils';
import { SearchResult } from 'services/renter/driverProfile/driverProfileTypes';
import { DriverListItem, FlexContainer } from './DriverSearch.styles';
import { useTranslations } from 'components/shared/i18n';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { LoyaltyProgramLogo } from 'components/shared/ui/logos/LoyaltyProgramLogo';
import { toDateTimeString } from 'utils/dateUtils';
import { findDriverLicense } from 'services/renter/driverTransformer';
import { StyledWarningIcon } from 'components/shared/ui/styles/WarningIcon.styles';
import { RentalHeaderH6 } from 'components/customerHeader/CustomerHeader.styles';
import { RenterWarningDialog } from 'components/flexFlow/driver/renterWarning/RenterWarningDialog';
import { useGetLoyaltyProgram } from 'services/renter/driverProfile/driverProfileQueries';
import { PhoneType } from 'services/renter/renterReferenceTypes';

export interface DriverResultsParams {
  searchResult: SearchResult;
  onClose: () => void;
  onAddDriver?: (driver: SearchResult) => void;
  testId?: string;
  phoneTypeDomain?: PhoneType[];
}

const DriverSearchResult: FC<DriverResultsParams> = ({
  searchResult,
  onAddDriver,
  testId,
  onClose,
  phoneTypeDomain,
}: DriverResultsParams) => {
  const { t } = useTranslations();
  const { data: loyaltyPrograms } = useGetLoyaltyProgram();
  const [showRenterWarningDialog, setShowRenterWarningDialog] = useState(false);
  const driverLicenseInformation = findDriverLicense(searchResult.legalIdentifications);
  const loyaltyProgramName = useMemo(() => {
    return loyaltyPrograms?.find((value) => value.urn === searchResult.loyaltyMembership?.loyaltyProgram)?.name;
  }, [loyaltyPrograms, searchResult.loyaltyMembership?.loyaltyProgram]);
  const phoneType = useMemo(() => {
    return phoneTypeDomain?.find((phone) => phone.urn === searchResult?.phoneNumbers?.[0]?.phoneType)?.name;
  }, [phoneTypeDomain, searchResult?.phoneNumbers]);

  return (
    <ListItem divider component={DriverListItem} data-testid={testId}>
      <FlexContainer>
        {searchResult.renterWarningFlag && (
          <StyledWarningIcon data-testid='renterWarning' sx={{ paddingTop: ehiTheme.spacing(0.2) }} />
        )}
        <RentalHeaderH6 bold>
          {searchResult?.name?.surname}, {searchResult.name?.givenName}
        </RentalHeaderH6>
      </FlexContainer>
      <GridContainer className={piiField} style={{ margin: ehiTheme.spacing(1, -1) }}>
        <GridItem sm={4}>
          <Body1 color='textPrimary'>{searchResult.address?.addressLine1}</Body1>
          <Body1 color='textPrimary'>
            {searchResult?.address?.city}, {parseUrn(searchResult?.address?.countrySubdivision)}{' '}
            {searchResult?.address?.postalCode} {parseUrn(searchResult?.address?.country)}
          </Body1>
          {phoneType && (
            <Body1 color='textPrimary'>
              {`${phoneType}: ${formatPhoneNumber(searchResult?.phoneNumbers?.[0].number) ?? ''}`}
            </Body1>
          )}
          <Body1 color='textPrimary' style={{ wordBreak: 'break-all' }}>
            {`${t('driverSearch.email')} ${formatPhoneNumber(searchResult?.emailAddresses?.[0].emailAddress) ?? ''}`}
          </Body1>
        </GridItem>
        <GridItem sm={4}>
          <Subtitle1 display='inline' color='textPrimary'>
            {t('driverSearch.dlSuffix')}
          </Subtitle1>{' '}
          <Body1 display='inline' color='textPrimary'>
            {driverLicenseInformation?.number}
          </Body1>
          <Body1 color='textPrimary'>
            {driverLicenseInformation?.countrySubdivision
              ? `${parseUrn(driverLicenseInformation?.countrySubdivision)}, `
              : ''}
            {parseUrn(driverLicenseInformation?.country)}
          </Body1>
          <Subtitle1 display='inline' color='textPrimary'>
            {t('driverSearch.dlExp')}
          </Subtitle1>{' '}
          <Body1 display='inline' color='textPrimary'>
            {toDateTimeString(driverLicenseInformation?.expirationDate, t('format.date'))}
          </Body1>
          <br />
          <Subtitle1 display='inline' color='textPrimary'>
            {t('driverSearch.dob')}
          </Subtitle1>{' '}
          <Body1 display='inline' color='textPrimary'>
            {toDateTimeString(searchResult.birthInformation?.date, t('format.date'))}
          </Body1>
        </GridItem>
        <GridItem sm={4}>
          <LoyaltyProgramLogo
            membershipProgram={parseUrn(searchResult.loyaltyMembership?.loyaltyProgram)}
            height={24}
            width={64}
          />
          <br />
          {loyaltyProgramName && <Subtitle1 display='inline'>{loyaltyProgramName}</Subtitle1>}
          <br />
          <Body1 display='inline'>{searchResult.loyaltyMembership?.number}</Body1>
        </GridItem>
      </GridContainer>
      <FlexContainer $justifyContent='flex-end'>
        <EhiButton
          onClick={() => {
            if (searchResult.renterWarningFlag) {
              setShowRenterWarningDialog(true);
            } else {
              onAddDriver?.(searchResult);
            }
          }}>
          {t('driverSearch.add')}
        </EhiButton>
      </FlexContainer>
      {showRenterWarningDialog && (
        <RenterWarningDialog
          searchResult={searchResult}
          onCancel={() => {
            setShowRenterWarningDialog(false);
            onClose();
          }}
          onSubmit={() => {
            onAddDriver?.(searchResult);
          }}
        />
      )}
    </ListItem>
  );
};

export default DriverSearchResult;
