import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import { ACCEPT, CONTENT_TYPE_BUSINESS_ACCOUNT_RESERVATION_FLOWS } from 'services/headerConstants';
import { BusinessAccount } from 'services/businessAccount/businessAccountTypes';

const BUSINESS_ACCOUNT_PATH = '/businessAccount';
const ACCOUNT_PATH = '/account';

export const retrieveAccount = async (accountNumber: string): Promise<BusinessAccount> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance?.get(`${BUSINESS_ACCOUNT_PATH}${ACCOUNT_PATH}/${accountNumber}`, {
    genericUrl: `${BUSINESS_ACCOUNT_PATH}${ACCOUNT_PATH}/{accountNumber}`,
    headers: {
      [ACCEPT]: [CONTENT_TYPE_BUSINESS_ACCOUNT_RESERVATION_FLOWS],
    },
  });
  return response?.data;
};
