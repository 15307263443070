import { MenuItem } from '@mui/material';
import { FunctionComponent, useMemo, useState } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { Option } from 'components/shared/ui/OptionTypes';
import { FormTextField } from 'components/shared/forms/FormTextField';
import { SelectFieldProps } from './FormFieldTypes';

export const ALL_VALUE = 'all' as const;

export const SelectField: FunctionComponent<SelectFieldProps> = ({
  options,
  hasNoneOption,
  hasAllOption,
  ...props
}) => {
  const { t } = useTranslations();
  const [open, setOpen] = useState(false);
  const [shouldOpen, setShouldOpen] = useState(true);
  const allOptions = useMemo(() => {
    const allOptions = [];
    if (hasAllOption) {
      allOptions.push({ label: t('common.all'), value: ALL_VALUE });
    } else if (hasNoneOption) {
      allOptions.push({ label: t('common.none'), value: '' });
    }
    allOptions.push(...options);

    return allOptions;
  }, [hasAllOption, hasNoneOption, options, t]);

  const handleBlur = () => {
    setShouldOpen(true);
  };

  const handleFocus = () => {
    if (!open && shouldOpen) {
      handleOpen();
    }
  };

  const handleClose = () => {
    setOpen(false);
    setShouldOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const getOptions = (options: Option[]) => {
    return options.map((option) => {
      return (
        <MenuItem disableRipple value={option.value} key={option.value}>
          {t(option.label)}
        </MenuItem>
      );
    });
  };

  return (
    <FormTextField
      {...props}
      select
      SelectProps={{
        open: open,
        onClose: handleClose,
        onOpen: handleOpen,
        onFocus: handleFocus,
        onBlur: handleBlur,
      }}>
      {getOptions(allOptions)}
    </FormTextField>
  );
};
