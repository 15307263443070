import { styled } from '@mui/material';
import { BoxShadow, contentContainerBackground } from 'components/shared/ui/styles/Global.styles';
import { ehiTheme } from '@ehi/ui';

export const ErrorContainer = styled(BoxShadow)`
  grid-area: center;
  border-radius: ${ehiTheme.spacing(0.5)};
  background-color: ${contentContainerBackground};
  text-align: center;
  padding: ${ehiTheme.spacing(5)};
  margin: auto;
`;

export const NetworkErrorContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;
